<template>
  <div v-if="showJustificationModal" class="c-justificationmodal__wrapper">
    <div class="c-justificationmodal__container">
      <div class="c-justificationmodal__top-container">
        <div class="c-justificationmodal__title-div">
          <h4 class="c-justificationmodal__title">Review Purchase Request</h4>
          <div class="c-justificationmodal__items-group">
            <div>
              <div class="c-justificationmodal__item">
                <span>Person that approves: </span>
                <p>{{ user }}</p>
              </div>
              <div class="c-justificationmodal__item">
                <span>PR Amount: </span>
                <p>
                  {{ formatNumber((pramount * currencyRate).toFixed(2)) }}
                  {{ currency }}
                </p>
              </div>
              <div>
                <div class="c-justificationmodal__item">
                  <span class="c-justificationmodal__label"><span class="cst-input-field--mandatory c-justificationmodal__label">* </span>Date: </span>

                  <input class="cst-input" type="date" id="date" required v-model="selectedDate" />
                </div>
                <div class="c-justificationmodal__item">
                  <span class="c-justificationmodal__label"><span class="cst-input-field--mandatory c-justificationmodal__label">* </span>Comment: </span>

                  <textarea class="cst-input" id="justification-comment" v-model="justificationComment" maxlength="200" required></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-justificationmodal__cta">
        <div class="c-justificationmodal__cta-buttons">
          <button type="button" :disabled="checkInputLength()" class="cst-button cst-button-primary c-justificationmodal__confirm-btn" @click="confirmAction">Approve</button>
          <button type="button" class="cst-button cst-button-primary c-justificationmodal__decline-btn" @click="declineAction" :disabled="justificationComment.length < 1">Decline</button>
          <button class="cst-button c-justificationmodal__cancel-btn" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { defineProps, ref, watch, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatNumber } from "@/utils/helpers/formatNumber";

  interface RequestData {
    id: number;
  }

  const props = defineProps({
    itemId: Number,
    pramount: {
      type: Number,
      default: 0,
    },
  });

  const { CHANGE_JUSTIFICATION_MODAL_VIEW, CHANGE_PURCHASE_REQUEST_STATUS, DECLINE_PURCHASE_REQUEST, FETCH_PURCHASE_REQUESTS } = VUEX_ACTIONS;
  const selectedItem = ref<RequestData | null>(null);

  const propsItemId = computed(() => props.itemId);
  const store = useStore();
  const selectedDate = ref("");
  const user = computed(() => store.getters.getCurrentUser);
  const requests = computed(() => store.getters.getPurchaseRequests);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const showJustificationModal = computed(() => store.getters.getShowJustificationModal);
  const justificationComment = ref("");

  onMounted(() => {
    if (showJustificationModal.value) {
      selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === props.itemId) || null;
    }
  });

  const confirmAction = () => {
    const payload = {
      prID: props.itemId,
      status: "Proposed",
      isJustification: true,
      justification_status: "Signed",
      justified_by_name: user.value,
      justification_comment: justificationComment.value,
      justification_date: selectedDate.value,
    };
    store.dispatch(CHANGE_PURCHASE_REQUEST_STATUS, payload);
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
    store.dispatch(FETCH_PURCHASE_REQUESTS);
    justificationComment.value = "";
    selectedDate.value = "";
  };

  const declineAction = () => {
    const payload = {
      itemId: props.itemId,
      message: justificationComment.value,
    };
    store.dispatch(DECLINE_PURCHASE_REQUEST, payload);
    justificationComment.value = "";
    selectedDate.value = "";
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
  };

  const closeModal = () => {
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
    justificationComment.value = "";
    selectedDate.value = "";
  };

  const checkInputLength = () => {
    return justificationComment.value.length < 1 || selectedDate.value.length < 1;
  };

  watch([showJustificationModal], () => {
    selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === propsItemId.value) || null;
  });
</script>
