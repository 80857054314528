<template>
  <div class="c-platformcard__wrapper">
    <h1 class="c-platformcard__name">
      <strong> {{ props.unit.name }}</strong>
    </h1>
    <p class="c-platformcard__properties"><strong>Owner: </strong>{{ ownerUsername }}</p>
    <p class="c-platformcard__properties"><strong>Budget: </strong>{{ props.unit.budget }} {{ selectedCurrency }}</p>
    <div class="c-platformcard__summary">
      <div class="c-platformcard__btns">
        <button type="button" class="cst-button cst-button-primary" data-testid="c-platformcard-prdetails-btn" @click="handleDetailsClick">Details</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PropType, defineProps, defineEmits, computed, watch, ref } from "vue";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";

  const props = defineProps({
    unit: {
      type: Object as PropType<IPlatform>,
      required: true,
    },
  });
  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const ownerUsername = ref("");

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (Number(user.id) === props.unit.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  const handleDetailsClick = () => {
    emit("show-platform-details", props.unit);
  };

  convertUsernameFromId();

  watch([users], () => {
    convertUsernameFromId();
  });
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const emit = defineEmits(["show-platform-details"]);
</script>
