import { AXIOS_REQUEST } from "../../config";
import axios from "axios";
import { Commit, Dispatch } from "vuex";
import { IInvoice, IInvoiceListItem } from "@/utils/interfaces/IInvoice";
import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
import { CST_BACKEND_URL } from "@/store/config";

interface InvoicesState {
  invoices: IInvoice[];
  invoiceFromOrder: IUserOrders | null;
  createdInvoice: IInvoice | null;
  applicationInvoices: IInvoice[];
  projectInvoices: IInvoice[];
  areInvoicesFetched: boolean;
  invoicesFromProjAppPO: IInvoice[];
  areInvoicesforPOFetched: boolean;
  isPdfGenerated: boolean;
  generatedPDF: Blob | null;
  generatedPDFId: number | null;
  productInvoices: IInvoice[];
}

interface InvoicesContext {
  commit: Commit;
  dispatch: Dispatch;
  state: InvoicesState;
}

const state: InvoicesState = {
  invoices: [],
  invoiceFromOrder: null,
  createdInvoice: null,
  applicationInvoices: [],
  projectInvoices: [],
  areInvoicesFetched: false,
  invoicesFromProjAppPO: [],
  areInvoicesforPOFetched: false,
  isPdfGenerated: false,
  generatedPDF: null,
  generatedPDFId: null,
  productInvoices: [],
};

const mutations = {
  setInvoices(state: InvoicesState, payload: IInvoice[]) {
    state.invoices = payload;
  },
  setInvoiceFromOrder(state: InvoicesState, payload: IUserOrders) {
    state.invoiceFromOrder = payload;
  },
  setCreatedInvoice(state: InvoicesState, payload: IInvoice) {
    state.createdInvoice = payload;
  },
  setApplicationInvoices(state: InvoicesState, payload: IInvoice[]) {
    state.applicationInvoices = payload;
  },
  setProjectInvoices(state: InvoicesState, payload: IInvoice[]) {
    state.projectInvoices = payload;
  },
  setAreInvoicesFetched(state: InvoicesState, areFetched: boolean) {
    state.areInvoicesFetched = areFetched;
  },
  setInvoicesFromProjAppPO(state: InvoicesState, payload: IInvoice[]) {
    state.invoicesFromProjAppPO = payload;
  },
  setAreInvoicesForPOFetched(state: InvoicesState, areFetched: boolean) {
    state.areInvoicesforPOFetched = areFetched;
  },
  setIsPdfGenerated(state: InvoicesState, isGenerated: boolean) {
    state.isPdfGenerated = isGenerated;
  },
  setPDF(state: InvoicesState, payload: Blob) {
    state.generatedPDF = payload;
  },
  setPDFId(state: InvoicesState, id: number) {
    state.generatedPDFId = id;
  },
  setProductInvoices(state: InvoicesState, payload: IInvoice[]) {
    state.productInvoices = payload;
  },
};

const getters = {
  getInvoices: (state: InvoicesState) => state.invoices,
  getInvoiceFromOrder: (state: InvoicesState) => state.invoiceFromOrder,
  getCreatedInvoice: (state: InvoicesState) => state.createdInvoice,
  getApplicationInvoices: (state: InvoicesState) => state.applicationInvoices,
  getProjectInvoices: (state: InvoicesState) => state.projectInvoices,
  getAreInvoicesFetched: (state: InvoicesState) => state.areInvoicesFetched,
  getInvoicesFromProjAppPO: (state: InvoicesState) => state.invoicesFromProjAppPO,
  getAreInvoicesForPOFetched: (state: InvoicesState) => state.areInvoicesforPOFetched,
  getIsPdfGenerated: (state: InvoicesState) => state.isPdfGenerated,
  getPDF: (state: InvoicesState) => state.generatedPDF,
  getProductInvoices: (state: InvoicesState) => state.productInvoices,
};

const actions = {
  fetchInvoices(context: InvoicesContext) {
    AXIOS_REQUEST("GET", "/invoices")
      .then((response) => {
        const invoicesDO: IInvoice[] = [];
        for (const id in response.data) {
          invoicesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setInvoices", invoicesDO);
        context.commit("setAreInvoicesFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  fetchProjectInvoices(context: InvoicesContext, projectID: number) {
    AXIOS_REQUEST("GET", `/projects/${projectID}/invoices`)
      .then((response) => {
        const invoicesDO: IInvoice[] = [];
        for (const id in response.data) {
          invoicesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setProjectInvoices", invoicesDO);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  fetchApplicationInvoices(context: InvoicesContext, applicationID: number) {
    AXIOS_REQUEST("GET", `/applications/${applicationID}/invoices`)
      .then((response) => {
        const invoicesDO: IInvoice[] = [];
        for (const id in response.data) {
          invoicesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setApplicationInvoices", invoicesDO);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  createInvoice(
    context: InvoicesContext,
    payload: {
      name: string;
      date: Date;
      start_date: string | null;
      end_data: string | null;
      purchaseorder_id: number;
      items: IInvoiceListItem[];
    }
  ) {
    AXIOS_REQUEST("POST", "/invoices", payload)
      .then((response) => {
        context.commit("setCreatedInvoice", response.data);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error creating invoice: ${error}`);
      });
  },

  async fetchInvoicesFromPO(context: InvoicesContext, purchaseOrderID: number) {
    await AXIOS_REQUEST("GET", `/purchaseorders/${purchaseOrderID}/invoices`)
      .then((response) => {
        context.commit("setInvoicesFromProjAppPO", response.data);
        context.commit("setAreInvoicesForPOFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  generatePDF(context: InvoicesContext, payload: { invoiceID: number; language: string }) {
    axios({
      url: `${CST_BACKEND_URL}/pdf/${payload.invoiceID}/${payload.language}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        context.commit("setIsPdfGenerated", true);
        context.commit("setPDF", blob);
        context.commit("setPDFId", payload.invoiceID);
        context.dispatch("updateRequestMessage", `SUCCESS: Invoice generated!`);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `ERROR: Invoice cannot be generated`);
      });
  },

  downloadPDF(context: InvoicesContext) {
    const pdfBlob = context.state.generatedPDF;
    if (pdfBlob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = `invoice_${context.state.generatedPDFId}`;
      link.click();
    } else {
      console.error("No PDF available to download.");
    }
  },
};

const invoicesModule = {
  state,
  getters,
  mutations,
  actions,
};

export default invoicesModule;
