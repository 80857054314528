<template>
  <div class="c-requestmessage__wrapper" v-if="isVisibleRequestMessage" data-testid="requestmessage-wrapper">
    <div class="c-requestmessage__container" data-testid="requestmessage" :class="[color]">
      {{ requestMessage }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeUnmount } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "RequestMessage",
    computed: {
      color(): string {
        const isError = this.requestMessage?.toLowerCase().includes("error");
        return isError ? "c-requestmessage__container-error" : "c-requestmessage__container-success";
      },
    },
    setup() {
      const { UPDATE_IS_VISIBLE_REQUEST_MESSAGE } = VUEX_ACTIONS;
      const store = useStore();
      const showRequestMessage = ref(false);
      const requestMessage = computed(() => store.getters.getRequestMessage);
      const isVisibleRequestMessage = computed(() => store.getters.getIsVisibleRequestMessage);

      onBeforeUnmount(() => {
        store.commit(UPDATE_IS_VISIBLE_REQUEST_MESSAGE, false);
      });

      return {
        requestMessage,
        showRequestMessage,
        isVisibleRequestMessage,
      };
    },
  });
</script>
