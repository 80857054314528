import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";
import store from "@/store";

interface BudgetPlanningFiltersState {
  proposalFilters: { id: number; name: string }[];
  demandFilters: { id: number; name: string }[];
}

interface BudgetPlanningFiltersContext {
  commit: Commit;
  state: BudgetPlanningFiltersState;
}

const state: BudgetPlanningFiltersState = {
  proposalFilters: [],
  demandFilters: [],
};

const mutations = {
  setProposalFilters(state: BudgetPlanningFiltersState, payload: { id: number; name: string }[]) {
    state.proposalFilters = payload;
  },
  setDemandFilters(state: BudgetPlanningFiltersState, payload: { id: number; name: string }[]) {
    state.demandFilters = payload;
  },
};

const getters = {
  getProposalFilters: (state: BudgetPlanningFiltersState) => state.proposalFilters,
  getDemandFilters: (state: BudgetPlanningFiltersState) => state.demandFilters,
};

const actions = {
  async fetchFutureProposalFilters(context: BudgetPlanningFiltersContext) {
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets/proposals?username=${store.getters.getCurrentUser}`);
      const proposalFiltersDO: { id: number; name: string }[] = [];
      for (const id in response.data) {
        proposalFiltersDO.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setProposalFilters", proposalFiltersDO);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchFutureDemandFilters(context: BudgetPlanningFiltersContext) {
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets/demands?username=${store.getters.getCurrentUser}`);
      const demandFiltersDO: { id: number; name: string }[] = [];
      for (const id in response.data) {
        demandFiltersDO.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setDemandFilters", demandFiltersDO);
    } catch (error) {
      console.error(error);
    }
  },
};

const budgetPlanningFiltersModule = {
  state,
  getters,
  mutations,
  actions,
};

export default budgetPlanningFiltersModule;
