import { IProjectApplication } from "@/utils/interfaces/IProductTypes";
import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface ApplicationsState {
  applications: IProjectApplication[];
  application: IProjectApplication[];
  singleApplication: IProjectApplication[];
  productApplications: IProjectApplication[];
  applicationsInProductFetched: boolean;
  ownedApplications: IProjectApplication[];
}

interface ApplicationsContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: ApplicationsState = {
  applications: [],
  application: [],
  singleApplication: [],
  productApplications: [],
  applicationsInProductFetched: false,
  ownedApplications: [],
};

const mutations = {
  setApplications(state: ApplicationsState, payload: IProjectApplication[]) {
    state.applications = payload;
  },
  setApplication(state: ApplicationsState, payload: IProjectApplication[]) {
    state.application = payload;
  },
  setSingleApplication(state: ApplicationsState, payload: IProjectApplication[]) {
    state.singleApplication = payload;
  },
  setProductApplications(state: ApplicationsState, payload: IProjectApplication[]) {
    state.productApplications = payload;
  },
  setApplicationsInProductFetched(state: ApplicationsState, payload: boolean) {
    state.applicationsInProductFetched = payload;
  },
  setOwnedApplications(state: ApplicationsState, payload: IProjectApplication[]) {
    state.ownedApplications = payload;
  },
};

const getters = {
  getApplications: (state: ApplicationsState) => state.applications,
  getApplication: (state: ApplicationsState) => state.application,
  getSingleApplication: (state: ApplicationsState) => state.singleApplication,
  getProductApplications: (state: ApplicationsState) => state.productApplications,
  getApplicationsInProductFetched: (state: ApplicationsState) => state.applicationsInProductFetched,
  getOwnedApplications: (state: ApplicationsState) => state.ownedApplications,
};

const actions = {
  async fetchSingleApplication(context: ApplicationsContext, applicationId: string) {
    try {
      const response = await AXIOS_REQUEST("GET", `/applications/${applicationId}`);
      const singleApplicationDO: IProjectApplication[] = response.data;
      context.commit("setSingleApplication", singleApplicationDO);
    } catch (error) {
      console.error(error);
    }
  },

  fetchApplications(context: ApplicationsContext) {
    AXIOS_REQUEST("GET", "/applications")
      .then((response) => {
        const applicationsDO: IProjectApplication[] = [];
        for (const id in response.data) {
          applicationsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setApplications", applicationsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchOwnedApplications(context: ApplicationsContext, payload: { username: string }) {
    AXIOS_REQUEST("GET", `/ownership/${payload.username}/ownedapplications`)
      .then((response) => {
        const applicationsDO: IProjectApplication[] = [];
        for (const id in response.data) {
          applicationsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setOwnedApplications", applicationsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchApplicationsInProduct(context: ApplicationsContext, productID: number) {
    const applicationsDO: IProjectApplication[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/products/${productID}/applications`);
      for (const id in response.data) {
        applicationsDO.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setProductApplications", applicationsDO);
      context.commit("setApplicationsInProductFetched", true);
    } catch (error) {
      context.commit("setProductApplications", applicationsDO);
      context.commit("setApplicationsInProductFetched", true);
      console.error(error);
    }
  },

  createNewApplication(context: ApplicationsContext, payload: IProjectApplication) {
    AXIOS_REQUEST("POST", "/applications", payload)
      .then((response) => {
        const applicationDO: IProjectApplication = response.data;
        context.commit("setApplication", applicationDO);
        context.dispatch("updateRequestMessage", `Application created successfully!`);
      })
      .catch((error) => {
        if (error.response.data === "Application with name you provided already exists!") {
          context.dispatch("updateRequestMessage", `Error: ${error.response.data}`);
        } else {
          console.error(error);
          context.dispatch("updateRequestMessage", `Error: ${error.message}`);
        }
      });
  },

  updateApplication(context: ApplicationsContext, payload: IProjectApplication) {
    AXIOS_REQUEST("PUT", "/applications/" + payload.id, payload)
      .then((response) => {
        const applicationDO: IProjectApplication = response.data;
        context.commit("setApplication", applicationDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const applicationsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default applicationsModule;
