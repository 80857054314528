import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cst-new-product-type--wrapper",
  "data-testid": "createprod-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!
  const _component_NewProductForm = _resolveComponent("NewProductForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    (_ctx.rolesFetched)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_NewProductForm, { "data-testid": "createprod-newprodform" })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}