import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "v-managecostunit__container" }
const _hoisted_2 = { class: "v-managecostunit__box--column" }
const _hoisted_3 = { class: "v-managecostunit__box--wrapper" }
const _hoisted_4 = { class: "v-managecostunit__title" }
const _hoisted_5 = { class: "v-managecostunit__body" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "cst-inputbox__actions--row-wrapper__spacebetween" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "v-managecostunit__box--column" }
const _hoisted_13 = { class: "v-managecostunit__box" }
const _hoisted_14 = {
  key: 0,
  class: "v-managecostunit__box--wrapper"
}
const _hoisted_15 = { class: "v-managecostunit__title" }
const _hoisted_16 = { class: "v-managecostunit__body" }
const _hoisted_17 = { class: "cst-input-field" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "cst-input-field" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "cst-input-field" }
const _hoisted_23 = { class: "cst-inputbox__actions--row-wrapper__spacebetween" }
const _hoisted_24 = { class: "cst-inputbox__actions--row-wrapper__smallgap" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = ["disabled"]
const _hoisted_27 = ["disabled"]
const _hoisted_28 = {
  key: 0,
  class: "v-managecostunit__box--wrapper"
}
const _hoisted_29 = { class: "v-managecostunit__title" }
const _hoisted_30 = { class: "v-managecostunit__body" }
const _hoisted_31 = { class: "cst-input-field" }
const _hoisted_32 = ["value"]
const _hoisted_33 = { class: "cst-input-field" }
const _hoisted_34 = ["disabled"]
const _hoisted_35 = ["value"]
const _hoisted_36 = { class: "v-createcu--formwrapper__form--budget" }
const _hoisted_37 = { class: "cst-input-field" }
const _hoisted_38 = ["disabled"]
const _hoisted_39 = { class: "cst-input-field" }
const _hoisted_40 = { class: "cst-inputbox__actions--row-wrapper__spacebetween" }
const _hoisted_41 = { class: "cst-inputbox__actions--row-wrapper__smallgap" }
const _hoisted_42 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, "Manage Cost Unit " + _toDisplayString(_ctx.unit), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[23] || (_cache[23] = _createElementVNode("label", { for: "owner" }, "Select new manager:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedManager) = $event)),
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSelectManager && _ctx.handleSelectManager(...args)))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: user.id,
                    value: user.id,
                    id: "selectedManager"
                  }, _toDisplayString(user.username), 9, _hoisted_7))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.selectedManager]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[24] || (_cache[24] = _createElementVNode("label", { for: "owner" }, "Cost Unit Status", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSelectStatus && _ctx.handleSelectStatus(...args))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedStatus) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.costUnitStatus, (status, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: status
                  }, _toDisplayString(status), 9, _hoisted_9))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.selectedStatus]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              type: "button",
              class: "cst-button cst-button-primary v-managecostunit__button",
              disabled: _ctx.changeBtnDisabled,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateCostUnit && _ctx.handleUpdateCostUnit(...args)))
            }, "Save", 8, _hoisted_11)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          (_ctx.fetchedSuccessfully)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, "Manage Products in " + _toDisplayString(_ctx.unit), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _cache[26] || (_cache[26] = _createElementVNode("label", { for: "owner" }, "Select product:", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedProduct) = $event)),
                      disabled: _ctx.isAddingNewProduct,
                      onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fetchRemainingPercentage(Number(_ctx.selectedProduct), Number(_ctx.costUnitID))))
                    }, [
                      _cache[25] || (_cache[25] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a product", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchedProducts, (product) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: product.id,
                          value: product.id
                        }, _toDisplayString(product.name), 9, _hoisted_19))
                      }), 128))
                    ], 40, _hoisted_18), [
                      [_vModelSelect, _ctx.selectedProduct]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[27] || (_cache[27] = _createElementVNode("label", { for: "name" }, "Allocate budget (%):", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(["cst-input", { 'cst-input__warning': _ctx.isExceedingPercentage }]),
                      input: "number",
                      placeholder: "Example: 10%",
                      id: "percentage",
                      min: "1",
                      max: "100",
                      onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.preventDotInInput && _ctx.preventDotInInput(...args))),
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.percentage) = $event)),
                      required: "",
                      disabled: _ctx.percentageDisabled
                    }, null, 42, _hoisted_21), [
                      [_vModelText, _ctx.percentage]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _cache[28] || (_cache[28] = _createElementVNode("label", { for: "name" }, "Available budget (%):", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      input: "number",
                      placeholder: "Example: 10%",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.remainingAmount) = $event)),
                      id: "remainingAmount",
                      disabled: ""
                    }, null, 512), [
                      [_vModelText, _ctx.remainingAmount]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "cst-button cst-button-primary v-managecostunit__button",
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateProductBudget && _ctx.handleUpdateProductBudget(...args))),
                      disabled: _ctx.saveProductBtnDisabled
                    }, "Update", 8, _hoisted_25),
                    _createElementVNode("button", {
                      type: "button",
                      class: "cst-button v-managecostunit__button v-managecostunit__button--danger",
                      onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.deleteProduct && _ctx.deleteProduct(...args))),
                      disabled: _ctx.deleteProductBtnDisabled
                    }, "Delete", 8, _hoisted_26)
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "cst-button cst-button-primary v-managecostunit__button",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleAddingNewProduct && _ctx.handleAddingNewProduct(...args))),
                    disabled: _ctx.isAddingNewProduct
                  }, "Add New", 8, _hoisted_27)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isAddingNewProduct)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("b", null, "Allocate Products to " + _toDisplayString(_ctx.unit), 1)
              ]),
              _createElementVNode("form", {
                onSubmit: _cache[22] || (_cache[22] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[30] || (_cache[30] = _createElementVNode("label", { for: "productfamily" }, "Select product family", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      required: "",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedProductFamily) = $event)),
                      onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.fetchProductsFromPF && _ctx.fetchProductsFromPF(...args)))
                    }, [
                      _cache[29] || (_cache[29] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a product family", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamilies, (family) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: family.id,
                          value: family.id,
                          id: "selectedFamilyProduct"
                        }, _toDisplayString(family.name), 9, _hoisted_32))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, _ctx.selectedProductFamily]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _cache[32] || (_cache[32] = _createElementVNode("label", { for: "product" }, "Select existing product", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      required: "",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.selectedNewProduct) = $event)),
                      disabled: _ctx.isProductDropdownDisabled,
                      onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.fetchRemainingPercentage(Number(_ctx.selectedNewProduct), Number(_ctx.costUnitID))))
                    }, [
                      _cache[31] || (_cache[31] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a product", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: product.id,
                          value: product.id,
                          id: "selectedProduct"
                        }, _toDisplayString(product.name), 9, _hoisted_35))
                      }), 128))
                    ], 40, _hoisted_34), [
                      [_vModelSelect, _ctx.selectedNewProduct]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _cache[33] || (_cache[33] = _createElementVNode("label", { for: "name" }, "Allocate budget (%):", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: _normalizeClass(["cst-input", {
                    'cst-input__warning': _ctx.isExceedingPercentageNewProduct,
                  }]),
                        input: "number",
                        placeholder: "Example: 10%",
                        id: "percentage",
                        min: "1",
                        max: "100",
                        onInput: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.preventDotInInput && _ctx.preventDotInInput(...args))),
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.percentageNewProduct) = $event)),
                        required: "",
                        disabled: _ctx.percentageDisabledNew
                      }, null, 42, _hoisted_38), [
                        [_vModelText, _ctx.percentageNewProduct]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _cache[34] || (_cache[34] = _createElementVNode("label", { for: "name" }, "Available budget (%):", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        input: "number",
                        placeholder: "Example: 10%",
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.remainingAmountNewProduct) = $event)),
                        id: "remainingAmount",
                        disabled: ""
                      }, null, 512), [
                        [_vModelText, _ctx.remainingAmountNewProduct]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      type: "submit",
                      disabled: _ctx.allocateProductBtnDisabled,
                      onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.handleAllocateProduct && _ctx.handleAllocateProduct(...args)))
                    }, "Allocate", 8, _hoisted_42),
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      type: "submit",
                      onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.handleFinish && _ctx.handleFinish(...args)))
                    }, "Finish")
                  ])
                ])
              ], 32)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}