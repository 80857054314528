import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-polist__main-container" }
const _hoisted_2 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "v-expiration-spinner"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "v-polist__no-orders-text"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "v-polist__expiration-list" }
const _hoisted_7 = { class: "v-polist__filter" }
const _hoisted_8 = {
  class: "v-polist__table-container",
  "data-testid": "v-expiration__table"
}
const _hoisted_9 = { class: "v-polist__table-wrapper" }
const _hoisted_10 = {
  "aria-describedby": "Table of Purchase Orders",
  class: "v-polist__table v-polist__list-table"
}
const _hoisted_11 = { class: "v-polist__table-head-row" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { "data-testid": "expiration-ordertext" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["onClick"]

import { computed, ref, watch } from "vue";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
  import { sortByColumn } from "@/utils/helpers/sortByColumn";
  import { useStore } from "vuex";
  import ListPODetails from "@/components/modals/C_ListPODetails.vue";
  import { useRouter } from "vue-router";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_ListPOExpiration',
  setup(__props) {

  const { CHANGE_PO_DETAILS_VIEW, FETCH_PURCHASE_ORDERS, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const selectedProductFamilyLocal = ref("");
  const selectedPOName = ref("");
  const orders = computed(() => store.getters.getPurchaseOrders);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedItem = ref({} as IUserOrders);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const hasPermission = computed(() => userRoles.value.includes("edit-purchase-request"));
  const areOrdersFetched = computed(() => store.getters.getAreOrdersFetched);
  const paginatedList = ref(orders.value?.slice(0, 10));
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const fetchPurchaseOrders = () => {
    store.dispatch(FETCH_PURCHASE_ORDERS);
  };

  fetchPurchaseOrders();

  const filteredOrdersByDate = computed(() => {
    if (!orders.value) {
      return [];
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const previousMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1; // wrapping around to the previous year

    const ordersByDate = orders.value.filter((order: { deliverydate: string | number | Date }) => {
      if (!order.deliverydate) {
        return false;
      }
      const orderDate = new Date(order.deliverydate);
      const orderYear = orderDate.getFullYear();
      const orderMonth = orderDate.getMonth();

      return orderYear === currentYear && (orderMonth === currentMonth || orderMonth === previousMonth);
    });
    return ordersByDate;
  });

  const showNoOrdersMessage = computed(() => {
    if (filteredOrdersByDate.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const productFamilies = computed(() => {
    // unique product family names for the current user
    const uniqueProductFamilies = new Set(filteredOrdersByDate.value.flatMap((order: IUserOrders) => order.listitems.map((listItem: { productfamilyname: string }) => listItem.productfamilyname)));
    return Array.from(uniqueProductFamilies);
  });

  const filteredOrders = computed<IUserOrders[]>(() => {
    const isShowAll = selectedProductFamilyLocal.value === "";
    if (isShowAll) {
      // If no product family is selected, return filteredOrdersByDate as is
      return filteredOrdersByDate.value;
    }
    return filteredOrdersByDate.value.filter((order: IUserOrders) => order.listitems.some((listItem: { productfamilyname: string }) => listItem.productfamilyname === selectedProductFamilyLocal.value));
  });

  const paginatedPOExpirationList = ref(filteredOrders.value.slice(0, 10));

  const totalPages = computed(() => {
    if (filteredOrders.value.length > 10 && filteredOrders.value.length % 10 != 0) {
      return Math.floor(filteredOrders.value.length / 10) + 1;
    } else {
      return filteredOrders.value.length / 10;
    }
  });

  const showPoDetails = (item: IUserOrders) => {
    store.dispatch(CHANGE_PO_DETAILS_VIEW, true);
    selectedPOName.value = item.ordertext;
    selectedItem.value = item;
  };

  const handleRenew = async (item: IUserOrders) => {
    const stringifiedData = JSON.stringify(item);
    localStorage.setItem("archiveItemEdit", stringifiedData);
    store.commit("setRenewOrderItem", item);
    store.commit("setIsRenewingPr", true);
    setTimeout(() => {
      router.push("/renew-purchase-request");
    }, 500);
  };

  watch([filteredOrders], () => {
    paginatedList.value = orders.value?.slice(0, 10);
    paginatedPOExpirationList.value = filteredOrders.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IUserOrders>) => {
    paginatedPOExpirationList.value = filteredList;
    paginatedList.value = filteredList;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _cache[16] || (_cache[16] = _createElementVNode("h1", {
        class: "v-polist__title-all",
        "data-testid": "v-expiration__heading"
      }, "Expiration List of Purchase Orders", -1)),
      (!areOrdersFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (showNoOrdersMessage.value)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_4, "No expiring Purchase Orders to show"))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[12] || (_cache[12] = _createElementVNode("h5", null, "Choose a Product Family", -1)),
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProductFamilyLocal).value = $event)),
                        "data-testid": "expiration-select-prodfamily"
                      }, [
                        _cache[11] || (_cache[11] = _createElementVNode("option", {
                          value: "",
                          selected: ""
                        }, "Show All", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productFamilies.value, (productFamily, index) => {
                          return (_openBlock(), _createElementBlock("option", { key: index }, _toDisplayString(productFamily), 1))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, selectedProductFamilyLocal.value]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("table", _hoisted_10, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", _hoisted_11, [
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-listpo-ordernumber-sort-ordernum",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(sortByColumn)('ordernumber', orders.value)))
                            }, "Order Number"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-status",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(sortByColumn)('status', orders.value)))
                            }, "Status"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-listpo-ordernumber-sort-prownername",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(sortByColumn)('prownername', orders.value)))
                            }, "Owner"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-customercontact",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(sortByColumn)('customercontact', orders.value)))
                            }, "Customer Contact"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-ordertext",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(sortByColumn)('ordertext', orders.value)))
                            }, "Description"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-orderamount",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(sortByColumn)('orderamount', orders.value)))
                            }, "PO Amount"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-amountCurrentYear",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(sortByColumn)('orderamount', orders.value)))
                            }, "Current year"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-amountNextYear",
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(sortByColumn)('orderamount', orders.value)))
                            }, "Next Year"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-currency",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (_unref(sortByColumn)('currency', orders.value)))
                            }, "Currency"),
                            _createElementVNode("th", {
                              "data-testid": "v-expiration-ordernumber-sort-deliverydate",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(sortByColumn)('deliverydate', orders.value)))
                            }, "Delivery Date (End Date)"),
                            _cache[13] || (_cache[13] = _createElementVNode("th", null, "Details", -1)),
                            (hasPermission.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_12, "Renew"))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedPOExpirationList.value, (item, index) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: index,
                              class: "v-polist__table-row"
                            }, [
                              _createElementVNode("td", null, _toDisplayString(item.ordernumber), 1),
                              _createElementVNode("td", null, _toDisplayString(item.status), 1),
                              _createElementVNode("td", null, _toDisplayString(item.prownername), 1),
                              _createElementVNode("td", null, _toDisplayString(item.customercontact), 1),
                              _createElementVNode("td", _hoisted_13, _toDisplayString(item.ordertext), 1),
                              _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)((item.orderamount * currencyRate.value).toFixed(2))), 1),
                              _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate.value).toFixed(2))), 1),
                              _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate.value).toFixed(2))), 1),
                              _createElementVNode("td", null, _toDisplayString(item.currency), 1),
                              _createElementVNode("td", null, _toDisplayString(item.deliverydate ? _unref(formatDate)(item.deliverydate) : ""), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("button", {
                                  title: "Show",
                                  class: "c-requestbtns c-requestbtns-secondary-show",
                                  "data-testid": "v-expirationpo-detailsbtn",
                                  onClick: ($event: any) => (showPoDetails(item))
                                }, _cache[14] || (_cache[14] = [
                                  _createElementVNode("p", null, "Show", -1),
                                  _createElementVNode("svg", {
                                    fill: "#000000",
                                    width: "15px",
                                    height: "15px",
                                    viewBox: "-1 0 19 19",
                                    class: "cf-icon-svg"
                                  }, [
                                    _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                  ], -1)
                                ]), 8, _hoisted_14)
                              ]),
                              (hasPermission.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                    _createElementVNode("button", {
                                      title: "Renew",
                                      class: "c-requestbtns c-requestbtns-secondary-renew",
                                      "data-testid": "v-expirationpo-renewbtn",
                                      onClick: ($event: any) => (handleRenew(item))
                                    }, _cache[15] || (_cache[15] = [
                                      _createElementVNode("p", null, "Renew", -1),
                                      _createElementVNode("svg", {
                                        width: "15px",
                                        height: "15px",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M12 20.5001C16.6944 20.5001 20.5 16.6945 20.5 12.0001C20.5 9.17456 19.1213 6.67103 17 5.1255M13 22.4001L11 20.4001L13 18.4001M12 3.5001C7.30558 3.5001 3.5 7.30568 3.5 12.0001C3.5 14.8256 4.87867 17.3292 7 18.8747M11 5.6001L13 3.6001L11 1.6001",
                                          stroke: "#000000",
                                          "stroke-width": "2",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ]), 8, _hoisted_16)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ])
                      ]),
                      (totalPages.value > 1)
                        ? (_openBlock(), _createBlock(C_Pagination, {
                            key: 0,
                            totalPages: totalPages.value,
                            itemList: orders.value,
                            onPaginateList: paginateList,
                            "data-testid": "pages-indicator"
                          }, null, 8, ["totalPages", "itemList"]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
          ])),
      _createVNode(ListPODetails, {
        selectedPOName: selectedPOName.value,
        selectedItem: selectedItem.value,
        supplierNameEnabled: supplierEnabled.value
      }, null, 8, ["selectedPOName", "selectedItem", "supplierNameEnabled"])
    ])
  ], 64))
}
}

})