import { IProjectApplication } from "@/utils/interfaces/IProductTypes";
import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface ProjectsState {
  projects: IProjectApplication[];
  project: IProjectApplication[];
  singleProject: IProjectApplication[];
  productProjects: IProjectApplication[];
  projectsInProductFetched: boolean;
  ownedProjects: IProjectApplication[];
}

interface ProjectsContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: ProjectsState = {
  projects: [],
  project: [],
  singleProject: [],
  productProjects: [],
  projectsInProductFetched: false,
  ownedProjects: [],
};

const mutations = {
  setProjects(state: ProjectsState, payload: IProjectApplication[]) {
    state.projects = payload;
  },
  setProject(state: ProjectsState, payload: IProjectApplication[]) {
    state.project = payload;
  },
  setSingleProject(state: ProjectsState, payload: IProjectApplication[]) {
    state.singleProject = payload;
  },
  setProductProjects(state: ProjectsState, payload: IProjectApplication[]) {
    state.productProjects = payload;
  },
  setProjectsInProductFetched(state: ProjectsState, payload: boolean) {
    state.projectsInProductFetched = payload;
  },
  setOwnedProjects(state: ProjectsState, payload: IProjectApplication[]) {
    state.ownedProjects = payload;
  },
};

const getters = {
  getProjects: (state: ProjectsState) => state.projects,
  getProject: (state: ProjectsState) => state.project,
  getSingleProject: (state: ProjectsState) => state.singleProject,
  getProductProjects: (state: ProjectsState) => state.productProjects,
  getProjectsInProductFetched: (state: ProjectsState) => state.projectsInProductFetched,
  getOwnedProjects: (state: ProjectsState) => state.ownedProjects,
};

const actions = {
  async fetchSingleProject(context: ProjectsContext, projectId: string) {
    try {
      const response = await AXIOS_REQUEST("GET", `/projects/${projectId}`);
      const singleProjectDO: IProjectApplication[] = response.data;
      context.commit("setSingleProject", singleProjectDO);
    } catch (error) {
      console.error(error);
    }
  },

  fetchProjects(context: ProjectsContext) {
    AXIOS_REQUEST("GET", "/projects")
      .then((response) => {
        const projectsDO: IProjectApplication[] = [];
        for (const id in response.data) {
          projectsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setProjects", projectsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchOwnedProjects(
    context: ProjectsContext,
    payload: {
      username: string;
    }
  ) {
    AXIOS_REQUEST("GET", `/ownership/${payload.username}/ownedprojects`)
      .then((response) => {
        const projectsDO: IProjectApplication[] = [];
        for (const id in response.data) {
          projectsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setOwnedProjects", projectsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchProjectsInProduct(context: ProjectsContext, productID: number) {
    const projectsDO: IProjectApplication[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/products/${productID}/projects`);
      for (const id in response.data) {
        projectsDO.push({
          id: id,
          ...response.data[id],
        });
      }

      context.commit("setProductProjects", projectsDO);
      context.commit("setProjectsInProductFetched", true);
    } catch (error) {
      context.commit("setProductProjects", projectsDO);
      context.commit("setProjectsInProductFetched", true);
      console.error(error);
    }
  },

  createNewProject(context: ProjectsContext, payload: IProjectApplication) {
    AXIOS_REQUEST("POST", "/projects", payload)
      .then((response) => {
        const projectDO: IProjectApplication = response.data;
        context.dispatch("updateRequestMessage", `Project created successfully!`);
        context.commit("setProject", projectDO);
      })
      .catch((error) => {
        if (error.response.data === "Project with name you provided already exists!") {
          context.dispatch("updateRequestMessage", `Error: ${error.response.data}`);
        } else {
          console.error(error);
          context.dispatch("updateRequestMessage", `Error: ${error.message}`);
        }
      });
  },

  /* TO:DO Check if this route is implemented on backend */
  updateProject(context: ProjectsContext, payload: IProjectApplication) {
    AXIOS_REQUEST("PUT", "/projects/" + payload.id, payload)
      .then((response) => {
        const projectDO: IProjectApplication = response.data;
        context.commit("setProject", projectDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const projectsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default projectsModule;
