import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "c-productdetails__modal-overlay"
}
const _hoisted_2 = {
  class: "c-productdetails__modal-container",
  "data-testid": "product-details-container"
}
const _hoisted_3 = {
  key: 0,
  class: "cst-spinner c-productdetails__spinner"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "c-productdetails__modal-header" }
const _hoisted_6 = { class: "c-productdetails__modal-title" }
const _hoisted_7 = { class: "c-productdetails__modal-header-closebtn" }
const _hoisted_8 = { class: "c-productdetails__notes" }
const _hoisted_9 = { class: "c-productdetails__note" }
const _hoisted_10 = {
  key: 0,
  class: "c-productdetails__notes-wrapper"
}
const _hoisted_11 = { class: "c-productdetails__modal-content" }
const _hoisted_12 = { class: "c-productdetails__modal-content-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductLastYearChart = _resolveComponent("ProductLastYearChart")!
  const _component_ProductChart = _resolveComponent("ProductChart")!
  const _component_ProductNextYearChart = _resolveComponent("ProductNextYearChart")!

  return (_ctx.showProductDetails)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.product.name), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Close modal icon",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, "*Amounts are shown in " + _toDisplayString(_ctx.currency), 1),
                  _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"c-productdetails__notes-wrapper\"><span class=\"c-productdetails__label-green\"></span><div class=\"c-productdetails__note\">Capital</div></div><div class=\"c-productdetails__notes-wrapper\"><span class=\"c-productdetails__label-blue\"></span><div class=\"c-productdetails__note\">Expenses</div></div>", 2)),
                  (_ctx.undefinedEnabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[1] || (_cache[1] = [
                        _createElementVNode("span", { class: "c-productdetails__label-gray" }, null, -1),
                        _createElementVNode("div", { class: "c-productdetails__note" }, "Undefined", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_ProductLastYearChart, { product: _ctx.product }, null, 8, ["product"])
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_ProductChart, {
                        productApplications: _ctx.productApplications,
                        productProjects: _ctx.productProjects,
                        product: _ctx.product
                      }, null, 8, ["productApplications", "productProjects", "product"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_ProductNextYearChart, { product: _ctx.product }, null, 8, ["product"])
                  ])
                ])
              ]))
        ])
      ]))
    : _createCommentVNode("", true)
}