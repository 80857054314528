<template>
  <div class="c-listitemcard__content">
    <button v-if="!isDetails" class="c-listitemcard__deletebtn" @click="removeItem" data-testid="listitemcard-deletebtn">
      <img src="../../../public/remove.svg" alt="Remove list item button" />
    </button>
    <h3 data-testid="listitemcard-name">{{ data.name }}</h3>
    <p><span>Position: </span>{{ data.position }}</p>
    <p><span>Type: </span>{{ isDetails ? data.costtype.split(" ")[0] : data.type }}</p>
    <p>
      <span>Cost Type: </span>
      {{ isDetails ? data.costtype.split(" ")[1] : data.costtype ? data.costtype : "-" }}
    </p>
    <p v-if="data?.resourcename?.length > 0"><span>Resource Name: </span>{{ data.resourcename }}</p>
    <p v-if="data.quantity > 0"><span>Person Days: </span>{{ data.quantity }}</p>
    <p v-if="data.daily_rate > 0"><span>Daily rate: </span>{{ data.daily_rate }}</p>
    <p><span>Amount: </span>{{ formatNumber((Number(data.amount) * currencyRate).toFixed(2)) + " " + (selectedCurrency || data.currency) }}</p>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from "vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { useStore } from "vuex";

  export default defineComponent({
    name: "ListItemCard",
    props: {
      data: {
        type: Object,
        required: true,
      },
      isDetails: {
        type: Boolean,
      },
      lastrequestcurrency: {
        type: String,
      },
    },
    methods: {
      removeItem() {
        this.$emit("removeItem", this.data?.position);
      },
    },
    setup() {
      const store = useStore();
      const currencyRate = computed(() => store.getters.getCurrencyRate);
      const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
      return {
        formatNumber,
        currencyRate,
        selectedCurrency,
      };
    },
  });
</script>
