<template>
  <div>
    <div v-if="entitiesToShow.length > 0" class="c-table-overview__header">
      <div>Overview</div>
      <div>Name</div>
      <div>Type</div>
      <div>Owner</div>
      <div>{{ financialGoalLabel }}</div>
      <div>Forecast</div>
      <div>Actuals</div>
    </div>
    <div v-if="entitiesToShow.length > 0">
      <div v-for="(entity, index) in entitiesToShow" :key="index">
        <div class="c-table-overview__body-row">
          <div class="c-table-overview__body-row--expand-icon">
            <img :src="'./arrow-down.svg'" alt="expand down icon" @click="handleOverviewClick(entity)" />
          </div>
          <div>
            {{ entity.name }}
          </div>
          <div>Family</div>
          <div>{{ entity.owner ? convertUsernameFromId(entity.owner.toString()) : "-" }}</div>
          <div>
            {{ entity.budget ? formatNumber(Number(entity.budget).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.forecast ? formatNumber(((Number(entity.forecast) + Number(entity.total_product_forecast)) * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.actuals ? formatNumber(((Number(entity.actuals) + Number(entity.total_product_actuals)) * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="c-table-overview__body-row--empty">There are no product families to show.</div>
  </div>
</template>

<script lang="ts" setup props>
  /* VUE IMPORTS */
  import { computed, defineProps, defineEmits } from "vue";
  import { useStore } from "vuex";
  /* INTERFACES */
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";
  /* HELPERS */
  import { formatNumber } from "@/utils/helpers/formatNumber";

  const props = defineProps({
    productlist: {
      type: Array<IProduct>,
      default: () => [],
    },
    focusedproductid: {
      type: String,
    },
    allProductFamilies: {
      type: Array<IProductFamily>,
      default: () => [],
    },
    isproductfamily: {
      type: Boolean,
      default: () => false,
    },
  });
  const emit = defineEmits(["selected-pf"]);

  const store = useStore();

  const users = computed(() => store.getters.getUsers);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const entitiesToShow = computed(() => props.allProductFamilies);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  function convertUsernameFromId(username: string) {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  }

  const handleOverviewClick = (entity: IProductFamily) => {
    emit("selected-pf", entity);
  };
</script>
