import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-cuoverview__wrapper" }
const _hoisted_2 = { class: "v-cuoverview__filter-wrapper" }
const _hoisted_3 = { class: "v-cuoverview__products-wrapper" }
const _hoisted_4 = { class: "v-cuoverview__summary-overview" }
const _hoisted_5 = { class: "v-cuoverview__summary-overview--title" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "c-budgetsummary__wrapper--empty"
}
const _hoisted_8 = { class: "v-cuoverview__product-cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_CostUnitStatus = _resolveComponent("CostUnitStatus")!
  const _component_BudgetSummary = _resolveComponent("BudgetSummary")!
  const _component_CostUnitCard = _resolveComponent("CostUnitCard")!
  const _component_CostUnitDetails = _resolveComponent("CostUnitDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.filtersLoaded)
        ? (_openBlock(), _createBlock(_component_FilterBar, {
            key: 0,
            header: _ctx.header,
            filterList: _ctx.filters,
            onSubmit: _ctx.handleFilterSubmit,
            "is-cost-unit": true
          }, null, 8, ["header", "filterList", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.selectedCostUnit) + " Cost Unit Overview ", 1),
          (_ctx.selectedCostUnit)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "v-cuoverview__summary-overview--btn cst-button cst-button-primary",
                type: "button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleManageClick && _ctx.handleManageClick(...args)))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("b", null, "Manage", -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        (_ctx.areCostUnitsFetched)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_CostUnitStatus, {
                status: _ctx.status,
                statusdate: _ctx.statusDate
              }, null, 8, ["status", "statusdate"]),
              _createVNode(_component_BudgetSummary, {
                size: "big",
                budget: _ctx.totals.totalBUDG,
                forecast: _ctx.totals.totalFCST,
                actuals: _ctx.totals.totalACT
              }, null, 8, ["budget", "forecast", "actuals"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, "Select a filter on the left side to see the overview."))
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.costUnitProducts, (unit) => {
          return (_openBlock(), _createBlock(_component_CostUnitCard, {
            key: unit.id,
            unit: unit,
            onShowUnitDetails: _ctx.showUnitDetails
          }, null, 8, ["unit", "onShowUnitDetails"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_CostUnitDetails, {
      selectedProductId: _ctx.selectedProductId,
      selectedProductName: _ctx.selectedProductName,
      selectedDetails: _ctx.selectedDetails,
      selectedCostUnit: _ctx.selectedCostUnit
    }, null, 8, ["selectedProductId", "selectedProductName", "selectedDetails", "selectedCostUnit"])
  ]))
}