import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-table-overview__header"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 1,
  style: {"display":"flex","align-items":"center","justify-content":"center"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "c-table-overview__body-row--expanded-container"
}
const _hoisted_10 = { class: "c-table-overview__header c-table-overview__header--expanded" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "c-table-overview__body-row c-table-overview__body-row--expanded" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 2,
  class: "c-table-overview__body-row--empty"
}

import { computed, ref } from "vue";
  import { useStore } from "vuex";
  /* INTERFACES */
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProjectApplication, IProduct } from "@/utils/interfaces/IProductTypes";
  /* HELPERS */
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { calculateProductPOForecast, calculateProductInvoiceActuals } from "@/utils/helpers/calculateProductForecastActuals";
  /* COMPONENTS */
  import ProjectAppModal from "../components/modals/C_ProjectAppModal.vue";
  import ProductModal from "../components/modals/C_ProdModal.vue";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_TableOverview',
  props: {
    productlist: {
      type: Array<IProduct>,
      default: () => [],
    },
    focusedproductid: {
      type: String,
    },
    isproductfocused: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ["show-product-details", "show-projapp-details"],
  setup(__props, { emit: __emit }) {

  /* VUE IMPORTS */
  const { CHANGE_PRODUCT_DETAILS_VIEW, FETCH_PROJECTS_IN_PRODUCT, FETCH_APP_IN_PRODUCT, FETCH_PRODUCT_PURCHASE_ORDERS } = VUEX_ACTIONS;

  const props = __props;
  const emit = __emit;

  const store = useStore();

  const users = computed(() => store.getters.getUsers);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const projectsInProduct = computed(() => store.getters.getProductProjects);
  const applicationsInProduct = computed(() => store.getters.getProductApplications);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  const isProjAppModalOpen = ref(false);
  const focusedEntity = ref();
  const isProdModalOpen = ref(false);
  const focusedProductName = ref("");
  const expandedRows = ref<number[]>([]);
  if (props.isproductfocused) {
    if (props.focusedproductid) {
      fetchProjectsAppsInProduct(props.focusedproductid);
    }
  }

  function fetchProjectsAppsInProduct(productId: string) {
    store.dispatch(FETCH_PROJECTS_IN_PRODUCT, productId);
    store.dispatch(FETCH_APP_IN_PRODUCT, productId);
  }

  const projectsApplicationsList = computed(() => {
    const projects = projectsInProduct.value;
    const applications = applicationsInProduct.value;

    let projectsAndApplications = [];

    for (const project in projects) {
      const modifiedProject = { ...projects[project], type: "project" };
      projectsAndApplications.push(modifiedProject);
    }

    for (const application in applications) {
      const modifiedApplication = {
        ...applications[application],
        type: "application",
      };
      projectsAndApplications.push(modifiedApplication);
    }
    return projectsAndApplications;
  });

  const entitiesToShow = computed(() => {
    return props.isproductfocused ? projectsApplicationsList.value : props.productlist;
  });

  const productPOForecast = computed(() => {
    return props.productlist.map((product) => {
      return calculateProductPOForecast(product);
    });
  });

  const prodInvoiceActuals = computed(() => {
    return props.productlist.map((product) => {
      return calculateProductInvoiceActuals(product);
    });
  });

  function convertUsernameFromId(username: string) {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  }

  function handleGraphClick(entityId: string, index: number) {
    store.dispatch(CHANGE_PRODUCT_DETAILS_VIEW, true);
    emit("show-product-details", entityId);
    // Check if the user clicked on "View" of another row than expanded, if he did, close the expanded row
    if (!expandedRows.value.includes(index)) {
      expandedRows.value = [];
    } else {
      expandedRows.value = [index];
      fetchProjectsAppsInProduct(entityId);
    }
  }

  const handleProductDetailsClick = (entity: IProduct) => {
    focusedProductName.value = entity.name;
    store.dispatch(FETCH_PRODUCT_PURCHASE_ORDERS, entity.id);
    isProdModalOpen.value = true;
  };

  function handleProjAppDetails(entity: IProjectApplication) {
    isProjAppModalOpen.value = true;
    focusedEntity.value = entity;
    emit("show-projapp-details", entity.id, entity.type);
  }

  function toggleProductRow(index: number, productid: string) {
    if (expandedRows.value.includes(index)) {
      expandedRows.value = [];
    } else {
      expandedRows.value = [index];
    }
    fetchProjectsAppsInProduct(productid);
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (entitiesToShow.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!__props.isproductfocused)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Overview"))
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "Name", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("div", null, "Type", -1)),
          _cache[4] || (_cache[4] = _createElementVNode("div", null, "Owner", -1)),
          _createElementVNode("div", null, _toDisplayString(financialGoalLabel.value), 1),
          _cache[5] || (_cache[5] = _createElementVNode("div", null, "Forecast", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("div", null, "Actuals", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("div", null, "Details", -1)),
          (!__props.isproductfocused)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Graph"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (entitiesToShow.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entitiesToShow.value, (entity, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", {
                class: _normalizeClass(["c-table-overview__body-row", {
            'c-table-overview__body-row--focused': expandedRows.value.includes(index),
          }])
              }, [
                (!__props.isproductfocused)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "c-table-overview__body-row--expand-icon",
                      onClick: ($event: any) => (toggleProductRow(index, entity.id))
                    }, _cache[8] || (_cache[8] = [
                      _createElementVNode("img", {
                        src: './arrow-down.svg',
                        alt: "expand down icon"
                      }, null, -1)
                    ]), 8, _hoisted_5))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, _toDisplayString(entity.name), 1),
                _createElementVNode("div", null, _toDisplayString(entity.platform_cost && entity.platform_cost !== null ? "Platform" : entity.type ? entity.type : "Product"), 1),
                _createElementVNode("div", null, _toDisplayString(convertUsernameFromId(entity.owner)), 1),
                _createElementVNode("div", null, _toDisplayString(entity.budget ? _unref(formatNumber)((entity.budget * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((entity.totalbudget * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                _createElementVNode("div", null, _toDisplayString(entity.forecast ? _unref(formatNumber)(((Number(entity.forecast) + Number(productPOForecast.value[index])) * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((Number(entity.totalforecast) * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                _createElementVNode("div", null, _toDisplayString(entity.actuals ? _unref(formatNumber)(((Number(entity.actuals) + Number(prodInvoiceActuals.value[index])) * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((Number(entity.totalactuals) * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (__props.isproductfocused ? handleProjAppDetails(entity) : handleProductDetailsClick(entity))
                  }, "View", 8, _hoisted_6)
                ]),
                (!__props.isproductfocused)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("img", {
                        src: './graph.svg',
                        alt: "graph icon",
                        style: {"height":"24px","width":"24px","cursor":"pointer"},
                        onClick: ($event: any) => (handleGraphClick(entity.id, index))
                      }, null, 8, _hoisted_8)
                    ]))
                  : _createCommentVNode("", true)
              ], 2),
              (expandedRows.value.includes(index))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("p", null, [
                      _cache[9] || (_cache[9] = _createTextVNode(" Projects and applications belonging to ")),
                      _createElementVNode("strong", null, _toDisplayString(entity.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", null, "Name", -1)),
                      _cache[11] || (_cache[11] = _createElementVNode("div", null, "Type", -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("div", null, "Owner", -1)),
                      _createElementVNode("div", null, _toDisplayString(financialGoalLabel.value), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("div", null, "Forecast", -1)),
                      _cache[14] || (_cache[14] = _createElementVNode("div", null, "Actuals", -1)),
                      _cache[15] || (_cache[15] = _createElementVNode("div", null, "Details", -1))
                    ]),
                    (projectsApplicationsList.value.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectsApplicationsList.value, (entity, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", null, _toDisplayString(entity.name), 1),
                                _createElementVNode("div", null, _toDisplayString(entity.platform_cost && entity.platform_cost !== null ? "Platform" : entity.type ? entity.type : "Product"), 1),
                                _createElementVNode("div", null, _toDisplayString(convertUsernameFromId(entity.owner)), 1),
                                _createElementVNode("div", null, _toDisplayString(entity.budget ? _unref(formatNumber)((entity.budget * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((entity.totalbudget * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                                _createElementVNode("div", null, _toDisplayString(entity.forecast ? _unref(formatNumber)((entity.forecast * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((entity.totalforecast * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                                _createElementVNode("div", null, _toDisplayString(entity.actuals ? _unref(formatNumber)((entity.actuals * currencyRate.value).toFixed(2)) + " " + currency.value : _unref(formatNumber)((entity.totalactuals * currencyRate.value).toFixed(2)) + " " + currency.value), 1),
                                _createElementVNode("div", null, [
                                  _createElementVNode("button", {
                                    onClick: ($event: any) => (handleProjAppDetails(entity))
                                  }, "View", 8, _hoisted_13)
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (isProjAppModalOpen.value)
                ? (_openBlock(), _createBlock(ProjectAppModal, {
                    key: 1,
                    projappName: focusedEntity.value.name,
                    type: focusedEntity.value.type,
                    "is-modal-enabled": isProjAppModalOpen.value,
                    onIsProjectAppModalVisible: _cache[0] || (_cache[0] = ($event: any) => (isProjAppModalOpen.value = false))
                  }, null, 8, ["projappName", "type", "is-modal-enabled"]))
                : _createCommentVNode("", true),
              (isProdModalOpen.value)
                ? (_openBlock(), _createBlock(ProductModal, {
                    key: 2,
                    prodName: focusedProductName.value,
                    "is-modal-enabled": isProdModalOpen.value,
                    onIsProdModalVisible: _cache[1] || (_cache[1] = ($event: any) => (isProdModalOpen.value = false))
                  }, null, 8, ["prodName", "is-modal-enabled"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(__props.isproductfocused ? 'There are no projects or applications in this product.' : 'There are no products or platforms in this product family.'), 1))
  ]))
}
}

})