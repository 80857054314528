import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-createcu" }
const _hoisted_2 = { class: "v-createcu--forms" }
const _hoisted_3 = {
  key: 0,
  class: "v-createcu--formwrapper",
  "data-testid": "createcu-wrapper"
}
const _hoisted_4 = { class: "v-createcu--formwrapper--body" }
const _hoisted_5 = { class: "v-createcu--formwrapper__form" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "cst-input-field" }
const _hoisted_12 = ["disabled", "min"]
const _hoisted_13 = { class: "v-createcu--formwrapper__buttonrow" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "v-createcu--formwrapper" }
const _hoisted_16 = {
  key: 0,
  class: "v-createcu--formwrapper--header v-createcu--formwrapper--allocating"
}
const _hoisted_17 = {
  key: 1,
  class: "v-createcu--formwrapper--body"
}
const _hoisted_18 = { class: "v-createcu--formwrapper__form" }
const _hoisted_19 = { class: "cst-input-field" }
const _hoisted_20 = ["value"]
const _hoisted_21 = { class: "cst-input-field" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = ["value"]
const _hoisted_24 = { class: "v-createcu--formwrapper__form--budget" }
const _hoisted_25 = { class: "cst-input-field" }
const _hoisted_26 = { class: "cst-input-field" }
const _hoisted_27 = { class: "v-createcu--formwrapper__buttonrow" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = {
  key: 0,
  class: "v-createcu--productswrapper"
}
const _hoisted_30 = { class: "v-createcu--products__header" }
const _hoisted_31 = { class: "v-createcu--products__header--text" }
const _hoisted_32 = { class: "v-createcu--products" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!
  const _component_CostUnitCard = _resolveComponent("CostUnitCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hasPermission)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "v-createcu--formwrapper--header" }, [
                _createElementVNode("div", { class: "v-createcu--formwrapper--header__title" }, "Create Cost Unit")
              ], -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("form", {
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", { for: "name" }, "Name:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        disabled: _ctx.inputFieldsDisabled,
                        class: "cst-input v-createcu--formwrapper--inputfield",
                        type: "text",
                        placeholder: "Example: AP",
                        id: "nameText",
                        "data-testid": "createcu-input-name",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nameText) = $event)),
                        required: ""
                      }, null, 8, _hoisted_7), [
                        [_vModelText, _ctx.nameText]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[17] || (_cache[17] = _createElementVNode("label", { for: "productfamily" }, "Select manager", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field v-createcu--formwrapper--inputfield",
                        required: "",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedManager) = $event)),
                        disabled: _ctx.inputFieldsDisabled,
                        "data-testid": "createcu-select-manager"
                      }, [
                        _cache[16] || (_cache[16] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a manager", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (manager) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: manager.id,
                            value: manager.id,
                            id: "selectedManager"
                          }, _toDisplayString(manager.username), 9, _hoisted_10))
                        }), 128))
                      ], 8, _hoisted_9), [
                        [_vModelSelect, _ctx.selectedManager]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", { for: "date" }, "Start Date:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        disabled: _ctx.inputFieldsDisabled,
                        class: "cst-input v-createcu--formwrapper--inputfield",
                        type: "date",
                        id: "date",
                        min: _ctx.fetchTodaysDate(),
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDate) = $event)),
                        "data-testid": "createcu-select-date"
                      }, null, 8, _hoisted_12), [
                        [_vModelText, _ctx.selectedDate]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      disabled: _ctx.disableCreateBtn,
                      type: "submit",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleCostUnitCreation(_ctx.nameText))),
                      "data-testid": "createcu-create-button"
                    }, "Create", 8, _hoisted_14),
                    (_ctx.showProductAllocation)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                          type: "submit",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleNew()))
                        }, "New"))
                      : _createCommentVNode("", true)
                  ])
                ], 32)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.showProductAllocation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[20] || (_cache[20] = [
                _createElementVNode("div", { class: "v-createcu--formwrapper--header__title" }, "Allocate a product", -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.showProductAllocation)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("form", {
                  onSubmit: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _cache[22] || (_cache[22] = _createElementVNode("label", { for: "productfamily" }, "Select product family", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        required: "",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedProductFamily) = $event)),
                        onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.fetchProductsFromPF && _ctx.fetchProductsFromPF(...args))),
                        "data-testid": "createcu-select-pf"
                      }, [
                        _cache[21] || (_cache[21] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a product family", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamily, (family) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: family.id,
                            value: family.id,
                            id: "selectedFamilyProduct"
                          }, _toDisplayString(family.name), 9, _hoisted_20))
                        }), 128))
                      ], 544), [
                        [_vModelSelect, _ctx.selectedProductFamily]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[24] || (_cache[24] = _createElementVNode("label", { for: "productfamily" }, "Select product", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        required: "",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedProduct) = $event)),
                        onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fetchRemainingPercentage(Number(_ctx.selectedProduct)))),
                        disabled: _ctx.isProductDropdownDisabled,
                        "data-testid": "createcu-select-product"
                      }, [
                        _cache[23] || (_cache[23] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a product", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: product.id,
                            value: product.id,
                            id: "selectedProduct"
                          }, _toDisplayString(product.name), 9, _hoisted_23))
                        }), 128))
                      ], 40, _hoisted_22), [
                        [_vModelSelect, _ctx.selectedProduct]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[25] || (_cache[25] = _createElementVNode("label", { for: "name" }, "Allocate budget (%):", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: _normalizeClass(["cst-input", { 'cst-input__warning': _ctx.isExceedingPercentage }]),
                          input: "number",
                          placeholder: "Example: 10%",
                          id: "budgetPercentage",
                          min: "1",
                          max: "100",
                          onInput: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.preventDotInInput && _ctx.preventDotInInput(...args))),
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.budgetPercentage) = $event)),
                          required: "",
                          "data-testid": "createcu-input-allocatebudget"
                        }, null, 34), [
                          [_vModelText, _ctx.budgetPercentage]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _cache[26] || (_cache[26] = _createElementVNode("label", { for: "name" }, "Available budget (%):", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          input: "number",
                          placeholder: "Example: 10%",
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.remainingBudgetPercentage) = $event)),
                          id: "remainingBudgetPercentage",
                          disabled: "",
                          "data-testid": "createcu-input-availablebudget"
                        }, null, 512), [
                          [_vModelText, _ctx.remainingBudgetPercentage]
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      type: "submit",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.handleProductAllocation())),
                      disabled: _ctx.disableAllocateBtn,
                      "data-testid": "createcu-allocatebutton"
                    }, "Allocate", 8, _hoisted_28)
                  ])
                ], 32)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.showProducts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("p", _hoisted_31, "Cost Unit: " + _toDisplayString(_ctx.nameText), 1)
            ]),
            _createElementVNode("div", _hoisted_32, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCUProducts[0].products, (unit) => {
                return (_openBlock(), _createBlock(_component_CostUnitCard, {
                  key: unit.id,
                  unit: unit,
                  hideButtons: true
                }, null, 8, ["unit"]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}