import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IProduct } from "@/utils/interfaces/IProductTypes";

interface CostUnitsState {
  costUnits: { id: number; name: string }[];
  filteredCostUnits: { id: number; name: string }[];
  newCostUnitProducts: { id: number; name: string }[];
  newCostUnit: { id: number }[];
  productAdded: { id: number }[];
  allCostUnitProducts: IProduct[];
  productPercentageRemaining: { remaining: number }[];
  costUnitProducts: IProduct[];
}

interface CostUnitsContext {
  commit: Commit;
  dispatch: Dispatch;
  state: CostUnitsState;
}

const state: CostUnitsState = {
  costUnits: [],
  filteredCostUnits: [],
  newCostUnitProducts: [],
  newCostUnit: [],
  productAdded: [],
  allCostUnitProducts: [],
  productPercentageRemaining: [],
  costUnitProducts: [],
};

interface ProductsContext {
  commit: Commit;
  dispatch: Dispatch;
}

const mutations = {
  setCostUnits(state: CostUnitsState, payload: { id: number; name: string }[]) {
    state.costUnits = payload;
  },
  setFilteredCostUnits(state: CostUnitsState, payload: { id: number; name: string }[]) {
    state.filteredCostUnits = payload;
  },
  setNewCostUnit(state: CostUnitsState, payload: { id: number }[]) {
    state.newCostUnit = payload;
  },
  setProductAdded(state: CostUnitsState, payload: { id: number }[]) {
    state.productAdded = payload;
  },
  setAllProductsForCostUnit(state: CostUnitsState, payload: []) {
    state.allCostUnitProducts = payload;
  },
  setNewCostUnitProducts(state: CostUnitsState, payload: { id: number; name: string }[]) {
    state.newCostUnitProducts = payload;
  },
  setProductPercentageRemaining(state: CostUnitsState, payload: { remaining: number }[]) {
    state.productPercentageRemaining = payload;
  },
  setCostUnitProducts(state: CostUnitsState, payload: []) {
    state.costUnitProducts = payload;
  },
};

const getters = {
  getCostUnits: (state: CostUnitsState) => state.costUnits,
  getFilteredCostUnits: (state: CostUnitsState) => state.filteredCostUnits,
  getNewCostUnitProducts: (state: CostUnitsState) => state.newCostUnitProducts,
  getNewCostUnit: (state: CostUnitsState) => state.newCostUnit,
  getProductAdded: (state: CostUnitsState) => state.productAdded,
  getAllProductsForCostUnit: (state: CostUnitsState) => state.allCostUnitProducts,
  getProductPercentageRemaining: (state: CostUnitsState) => state.productPercentageRemaining,
  getCostUnitProducts: (state: CostUnitsState) => state.costUnitProducts,
};

const actions = {
  async fetchCostUnits(context: CostUnitsContext) {
    await AXIOS_REQUEST("GET", "/costunits")
      .then((response) => {
        const costUnitsDO: { id: number; name: string }[] = [];
        for (const id in response.data) {
          costUnitsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setCostUnits", costUnitsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchFilteredCostUnits(context: CostUnitsContext, searchString?: string) {
    await AXIOS_REQUEST("GET", `/costunits${searchString}`)
      .then((response) => {
        const filteredCostUnitsDO: { id: number; name: string }[] = [];
        for (const id in response.data) {
          filteredCostUnitsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setFilteredCostUnits", filteredCostUnitsDO);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404") {
          context.commit("setFilteredCostUnits", []);
        } else {
          console.error(error);
        }
      });
  },

  clearFilteredCostUnits(context: CostUnitsContext) {
    const filteredCostUnitsDO: { id: number; name: string }[] = [];
    context.commit("setFilteredCostUnits", filteredCostUnitsDO);
  },

  async fetchNewCostUnitProducts(context: CostUnitsContext, searchString?: string) {
    await AXIOS_REQUEST("GET", `/costunits${searchString}`)
      .then((response) => {
        const newCostUnitProductsDO: { id: number; name: string }[] = [];
        for (const id in response.data) {
          newCostUnitProductsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setNewCostUnitProducts", newCostUnitProductsDO);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404") {
          context.commit("setNewCostUnitProducts", []);
        } else {
          console.error(error);
        }
      });
  },

  async updateCostUnit(
    context: CostUnitsContext,
    payload: {
      id: number;
      name: string;
      manager: number;
      status: string;
      statusdate: Date;
    }
  ) {
    await AXIOS_REQUEST("PUT", `/costunits/${payload.id}`, payload)
      .then(() => {
        context.dispatch("updateRequestMessage", `Successfully updated Cost Unit`);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error while updating Cost Unit`);
      });
  },

  async createNewCostUnit(
    context: CostUnitsContext,
    payload: {
      name: string;
      manager: number;
      status: string;
      statusdate: Date;
    }
  ) {
    await AXIOS_REQUEST("POST", `/costunits/`, payload)
      .then((response) => {
        context.commit("setNewCostUnit", response.data.id);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error creating new cost unit: ${error}`);
      });
  },

  async addProductToCostUnit(
    context: CostUnitsContext,
    payload: {
      product: number;
      costunit: number;
      percentage: number;
    }
  ) {
    await AXIOS_REQUEST("POST", `/costunits/${payload.costunit}/products`, payload)
      .then((response) => {
        context.commit("setProductAdded", response.data.product);
        context.dispatch("updateRequestMessage", `Product added successfully`);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error: ${error.response.data}`);
        context.commit("setProductAdded", 0);
      });
  },

  /* Fetch all products when creating a single cost unit*/
  async fetchAllProductsForCostUnit(context: ProductsContext) {
    await AXIOS_REQUEST("GET", "/products")
      .then((response) => {
        const allProductsDO: IProduct[] = [];
        for (const id in response.data) {
          allProductsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setAllProductsForCostUnit", allProductsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchRemainingProductPercentage(
    context: ProductsContext,
    payload: {
      productID: number;
      costUnitID: number;
    }
  ) {
    await AXIOS_REQUEST("GET", `/products/${payload.productID}/costunits/${payload.costUnitID}/percentageremaining`)
      .then((response) => {
        context.commit("setProductPercentageRemaining", response.data.remainingpercentage);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  updateProductList(context: ProductsContext, productid: number) {
    context.commit(
      "setAllProductsForCostUnit",
      state.allCostUnitProducts.filter((product) => product.id !== productid)
    );
  },

  async fetchCostUnitProducts(context: ProductsContext, cuname: string) {
    await AXIOS_REQUEST("GET", `/costunits?name=${cuname}`)
      .then((response) => {
        const costUnitProducts: IProduct[] = [];
        for (const id in response.data[0].products) {
          costUnitProducts.push({
            id: id,
            ...response.data[0].products[id],
          });
        }
        context.commit("setCostUnitProducts", costUnitProducts);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async deleteProductFromCostUnit(context: CostUnitsContext, payload: { costUnitId: number; productId: number }) {
    await AXIOS_REQUEST("DELETE", `/costunits/${payload.costUnitId}/product/${payload.productId}`).catch((error) => {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error deleting product: ${error}`);
    });
  },
};

const costUnitsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default costUnitsModule;
