import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-productcard__wrapper c-productcard__wrapper--family" }
const _hoisted_2 = { class: "c-productcard__info" }
const _hoisted_3 = {
  class: "c-productcard__product-name",
  "data-testid": "productfamily-name"
}
const _hoisted_4 = { class: "c-projapp__owner-wrapper" }
const _hoisted_5 = { class: "c-productcard__summary" }

import { computed, ref } from "vue";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProductFamily } from "@/utils/interfaces/IProductTypes";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_ProductFamilyCard',
  props: {
    productfamily: {}
  },
  emits: ["selected-pf"],
  setup(__props: any, { emit: __emit }) {

  const emit = __emit;
  const props = __props;

  const store = useStore();

  const ownerUsername = ref("");
  const users = computed(() => store.getters.getUsers);

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (user.id === props.productfamily.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  convertUsernameFromId();

  const handleOverviewClick = () => {
    emit("selected-pf", props.productfamily);
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.productfamily.name), 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "c-productcard__family-name-tag" }, "Family", -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "c-projapp__owner-label" }, "Owner:", -1)),
      _createElementVNode("p", null, _toDisplayString(ownerUsername.value), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(BudgetSummary, {
        size: "small",
        budget: Number(_ctx.productfamily.budget),
        forecast: Number(_ctx.productfamily.forecast) + Number(_ctx.productfamily.total_product_forecast),
        productforecast: Number(_ctx.productfamily.total_product_forecast),
        actuals: Number(_ctx.productfamily.actuals) + Number(_ctx.productfamily.total_product_actuals)
      }, null, 8, ["budget", "forecast", "productforecast", "actuals"]),
      _createElementVNode("div", { class: "c-productcard__button-wrapper" }, [
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: handleOverviewClick
        }, "Overview")
      ])
    ])
  ]))
}
}

})