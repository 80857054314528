import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";
import store from "@/store";

interface FiltersState {
  filters: { id: number; name: string }[];
  costUnitFilters: { id: number; name: string }[];
}

interface FiltersContext {
  commit: Commit;
  state: FiltersState;
}

const state: FiltersState = {
  filters: [],
  costUnitFilters: [],
};

const mutations = {
  setFilters(state: FiltersState, payload: { id: number; name: string }[]) {
    state.filters = payload;
  },
  setCostUnitFilters(state: FiltersState, payload: { id: number; name: string }[]) {
    state.costUnitFilters = payload;
  },
};

const getters = {
  getFilters: (state: FiltersState) => state.filters,
  getCostUnitFilters: (state: FiltersState) => state.costUnitFilters,
};

const actions = {
  fetchFilters(context: FiltersContext) {
    setTimeout(() => {
      AXIOS_REQUEST("GET", `/filters?username=${store.getters.getCurrentUser}`)
        .then((response) => {
          const filtersDO: { id: number; name: string }[] = [];
          for (const id in response.data) {
            filtersDO.push({
              id: id,
              ...response.data[id],
            });
          }
          context.commit("setFilters", filtersDO);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 100);
  },

  fetchCostUnitFilters(context: FiltersContext) {
    AXIOS_REQUEST("GET", "/costunitfilters")
      .then((response) => {
        const costunitfiltersDO: { id: number; name: string }[] = [];
        for (const id in response.data) {
          costunitfiltersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setCostUnitFilters", costunitfiltersDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const filtersModule = {
  state,
  getters,
  mutations,
  actions,
};

export default filtersModule;
