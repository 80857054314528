import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, vShow as _vShow, withModifiers as _withModifiers, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../public/newimage.png'


const _hoisted_1 = { class: "cst-inputbox" }
const _hoisted_2 = {
  class: "cst-inputbox--body",
  "data-testid": "newprod-input-box-body"
}
const _hoisted_3 = { class: "cst-inputbox__form" }
const _hoisted_4 = { class: "cst-input-field" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_13 = ["disabled", "placeholder", "max"]
const _hoisted_14 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 2,
  class: "cst-input-field"
}
const _hoisted_17 = ["disabled"]
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "v-filter-products__platform-row" }
const _hoisted_20 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_21 = ["required", "disabled"]
const _hoisted_22 = { class: "cst-input-field__checkbox" }
const _hoisted_23 = { class: "cst-inputbox__actions" }
const _hoisted_24 = { class: "thumbnail-dropdown" }
const _hoisted_25 = ["src"]
const _hoisted_26 = {
  key: 1,
  "data-testid": "newprod-selected-image-false"
}
const _hoisted_27 = {
  class: "thumbnail-dropdown--content",
  "data-testid": "thumbnail-dropdown"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = ["src", "alt"]
const _hoisted_30 = { class: "cst-inputbox__actions--row-wrapper" }
const _hoisted_31 = ["disabled"]
const _hoisted_32 = {
  key: 0,
  class: "cst-inputbox__added-content",
  "data-testid": "newprod-new-product-section"
}

import { computed, onBeforeUnmount, ref, Ref, watch } from "vue";
  import { useStore } from "vuex";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";
  import imageFilenames from "@/utils/assets/imageFilenames.json";
  import NewProductCard from "../cards/C_NewProductCard.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IImage } from "@/utils/interfaces/IImage";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_NewProductForm',
  props: {
    preSelectedProductFamily: {
      type: Boolean,
      default: false,
    },
    hasBudgetLimit: {
      type: Boolean,
      default: false,
    },
  },
  setup(__props) {

  const props = __props;

  const { CREATE_NEW_PRODUCT, FETCH_REGIONS, UPDATE_REQUEST_MESSAGE, FETCH_USERS, FETCH_OWNED_PRODUCT_FAMILIES, FETCH_ALL_PRODUCTS, FETCH_ALL_PRODUCT_IMAGES, CLEAR_UPLOADED_IMAGE, UPLOAD_NEW_PRODUCT_IMAGE, FETCH_PRODUCT_FAMILY_REMAINING_BUDGET } =
    VUEX_ACTIONS;
  const store = useStore();
  const dropdownOpen = ref(false);
  const imageNames = ref([""]);
  let isPlatformSelected = ref(false);
  let platformCost = ref();
  const newProduct: Ref<IProduct> = ref({
    image: null,
    name: "",
    owner: null,
    productfamily: null,
    region: null,
    platform_cost: null,
  });
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const createdProductFlag = ref(false);
  const regions = computed(() => {
    return [...(store.getters.getRegions || [])].sort((a, b) => a.name.localeCompare(b.name));
  });
  const users = computed(() => {
    return (store.getters.getUsers || []).slice().sort((a: IUser, b: IUser) => a.username.localeCompare(b.username));
  });

  const products = computed(() => store.getters.getAllProducts);
  const uploadedImage = computed(() => store.getters.getUploadedImage);
  const uploadedImageId = computed(() => store.getters.getUploadedImageId);
  const selectedImage = ref("");
  const allImages = computed(() => store.getters.getAllProductImages);
  const currencyRate = computed(() => store.getters.getCurrencyRate);

  const productFamilies = computed(() => {
    return (store.getters.getOwnedProductFamilies || []).slice().sort((a: IProductFamily, b: IProductFamily) => a.name.localeCompare(b.name));
  });
  const productAdded = ref(false);
  const submitBtnDisabled = ref(false);
  const inputDisabled = ref(false);
  const selectedFamily = ref("");
  const selectedRegion = ref("");
  const selectOwner = ref("");
  const availableBudget = computed(() => store.getters.getRemainingBudget);
  const createdProductFamily = computed(() => store.getters.getCreatedProductFamily);
  store.dispatch(FETCH_ALL_PRODUCT_IMAGES);

  if (props.preSelectedProductFamily && createdProductFamily.value) {
    selectedFamily.value = createdProductFamily.value.name;
  }

  store.dispatch(FETCH_REGIONS);
  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_ALL_PRODUCTS);
  store.dispatch(FETCH_OWNED_PRODUCT_FAMILIES);

  onBeforeUnmount(() => {
    store.dispatch(CLEAR_UPLOADED_IMAGE);
    store.commit("setRemainingBudget", null);
  });

  imageNames.value = imageFilenames.map((item) => item);

  const productData: Ref<IProduct> = ref({
    productfamily: null,
    name: "",
    region: null,
    owner: null,
    image: null,
    budget_limit: undefined,
    platform_cost: null,
  });

  const handleCurrentProdFamily = () => {
    const currentProdFamily = productFamilies.value ? productFamilies.value.filter((p: IProductFamily) => p.name === selectedFamily.value) : [];

    if (currentProdFamily.length > 0) {
      productData.value.productfamily = Number(currentProdFamily[0].id);
    }
  };

  const handleSelectFamily = async (event: Event) => {
    const selectedProductFamily = productFamilies.value ? productFamilies.value.filter((p: IProductFamily) => p.name === (event?.target as HTMLSelectElement).value) : [];
    if (selectedProductFamily.length > 0) {
      productData.value.productfamily = Number(selectedProductFamily[0].id);
      await store.dispatch(FETCH_PRODUCT_FAMILY_REMAINING_BUDGET, productData.value.productfamily);
      isPlatformSelected.value = selectedProductFamily[0].name.toLowerCase().includes("platform");
    }
  };

  const regionEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.regions_enabled;
    } else {
      return false;
    }
  });

  const handleSelectRegions = (event: Event) => {
    const region = regions.value.filter((p: { name: string }) => p.name == (event?.target as HTMLSelectElement).value);
    productData.value.region = Number(region[0].id);
  };

  const toggleDropdown = () => {
    dropdownOpen.value = !dropdownOpen.value;
  };

  const handleSelectImage = (image: IImage) => {
    productData.value.image = Number(image.id);
    selectedImage.value = image.image_data;
    dropdownOpen.value = false;
  };

  async function submitForm() {
    if (props.preSelectedProductFamily) {
      handleCurrentProdFamily();
    }
    const existingProduct = products.value.find((p: IProduct) => p.name.toLowerCase() === productData.value.name.toLowerCase());
    if (!existingProduct) {
      if (!availableBudget.value || availableBudget.value === null) {
        productData.value.budget_limit = undefined;
      }
      productData.value.owner = Number(selectOwner.value);

      if (isPlatformSelected.value) {
        productData.value.platform_cost = platformCost.value;
      } else {
        productData.value.platform_cost = null;
      }

      newProduct.value = productData.value;
      createdProductFlag.value = true;
      await store.dispatch(CREATE_NEW_PRODUCT, productData.value);
      if (selectedImage.value === "") {
        selectedImage.value = "https://placehold.co/240x140";
      }
      productAdded.value = true;
      submitBtnDisabled.value = true;
      inputDisabled.value = true;
    } else {
      store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Product with the name you provided already exists!");
      productData.value.name = "";
    }
  }

  const handleNew = () => {
    createdProductFlag.value = false;
    productAdded.value = false;
    submitBtnDisabled.value = false;
    inputDisabled.value = false;
    productData.value.owner = null;
    productData.value.image = null;
    productData.value.name = "";
    productData.value.productfamily = null;
    productData.value.budget_limit = null;
    productData.value.region = null;
    selectedFamily.value = "";
    selectedRegion.value = "";
    selectedImage.value = "";
    store.dispatch(FETCH_ALL_PRODUCTS);
  };

  const uploadNewImage = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = "none";

    fileInput.addEventListener("change", handleFileInputChange);

    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  };

  const handleFileInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const fileSizeInMb = file.size / 1024 / 1024;
      if (fileSizeInMb > 0.5) {
        store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Image size cannot exceed 500kb!");
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target) {
            const result = e.target.result;
            if (typeof result === "string") {
              store.dispatch(UPLOAD_NEW_PRODUCT_IMAGE, result);
            } else {
              console.error("Unexpected result type:", typeof result);
            }
            toggleDropdown();
          } else {
            console.error("Event target is null");
          }
        };
        reader.readAsDataURL(file);
      }
    } else {
      console.error("No file selected or file input element not found");
    }
  };

  watch([uploadedImage], () => {
    selectedImage.value = uploadedImage.value;
    store.dispatch(FETCH_ALL_PRODUCT_IMAGES);
  });

  watch([uploadedImageId], () => {
    productData.value.image = Number(uploadedImageId.value);
  });

  watch([productFamilies], () => {
    if (selectedFamily.value.length > 0) {
      isPlatformSelected.value = selectedFamily.value.toLowerCase().includes("platform");
    }
  });

  const placeholderText = ref(`Available: ${formatNumber((availableBudget.value * currencyRate.value).toFixed(2))}`);

  // Watch to update placeholder text when availableBudget changes
  watch(
    () => availableBudget.value,
    (newBudget) => {
      placeholderText.value = `Available: ${formatNumber((newBudget * currencyRate.value).toFixed(2))}`;
    }
  );

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
      _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Add a new Product")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _withModifiers(submitForm, ["prevent"]),
        "data-testid": "newprod-form"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[13] || (_cache[13] = _createElementVNode("label", {
              for: "productowner",
              "data-testid": "newprod-form-label-owner"
            }, [
              _createTextVNode("Owner:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectOwner).value = $event)),
              required: "",
              id: "productowner",
              disabled: inputDisabled.value,
              placeholder: "Select Owner",
              "data-testid": "newprod-select-own"
            }, [
              _cache[12] || (_cache[12] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select an owner", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (owner) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: owner.id,
                  value: owner.id,
                  "data-testid": "newprod-owner"
                }, _toDisplayString(owner.username), 9, _hoisted_6))
              }), 128))
            ], 8, _hoisted_5), [
              [_vModelSelect, selectOwner.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { for: "productfamily" }, [
              _createTextVNode("Product Family:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              required: "",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (handleSelectFamily($event))),
              disabled: inputDisabled.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedFamily).value = $event)),
              id: "productfamily",
              name: "productfamily",
              "data-testid": "newprod-select-family"
            }, [
              _cache[14] || (_cache[14] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select a Product Family", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productFamilies.value, (family) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: family.id,
                  value: family.name
                }, _toDisplayString(family.name), 9, _hoisted_9))
              }), 128))
            ], 40, _hoisted_8), [
              [_vModelSelect, selectedFamily.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { for: "name" }, [
              _createTextVNode("Name:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              autocomplete: "name",
              class: "cst-input",
              type: "text",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((productData.value.name) = $event)),
              id: "name",
              required: "",
              disabled: inputDisabled.value,
              maxlength: "32",
              "data-testid": "newprod-input-field",
              placeholder: "Enter Product Name"
            }, null, 8, _hoisted_11), [
              [_vModelText, productData.value.name]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["cst-input-field__indicator", {
                'cst-input-field__indicator--max': productData.value.name?.length == 32,
              }]),
              "data-testid": "newprod-input-name"
            }, _toDisplayString(productData.value.name?.length ? `${productData.value.name.length}` : `0`) + "/32", 3)
          ]),
          (__props.hasBudgetLimit || (availableBudget.value > 0 && availableBudget.value != null && availableBudget.value != undefined))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[17] || (_cache[17] = _createElementVNode("label", { for: "budgetLimit" }, [
                  _createTextVNode("Budget Limit:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "number",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((productData.value.budget_limit) = $event)),
                  id: "budgetLimit",
                  required: "",
                  disabled: inputDisabled.value,
                  "data-testid": "newprod-budgetlimitinput-field",
                  placeholder: placeholderText.value,
                  max: availableBudget.value
                }, null, 8, _hoisted_13), [
                  [_vModelText, productData.value.budget_limit]
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { for: "target" }, [
                  _createTextVNode("Target:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "number",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((productData.value.target) = $event)),
                  id: "target",
                  required: "",
                  disabled: inputDisabled.value,
                  "data-testid": "newprod-targetinput-field",
                  placeholder: "Enter Target Amount"
                }, null, 8, _hoisted_15), [
                  [_vModelText, productData.value.target]
                ])
              ])),
          (regionEnabled.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "productregion" }, [
                  _createTextVNode("Region:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "cst-select-field",
                  required: "",
                  disabled: inputDisabled.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((selectedRegion).value = $event)),
                  id: "productregion",
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (handleSelectRegions($event))),
                  "data-testid": "newprod-select-regions"
                }, [
                  _cache[19] || (_cache[19] = _createElementVNode("option", {
                    disabled: "",
                    selected: "",
                    value: ""
                  }, "Select a Region", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(regions.value, (region) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: region.id,
                      value: region.name
                    }, _toDisplayString(region.name), 9, _hoisted_18))
                  }), 128))
                ], 40, _hoisted_17), [
                  [_vModelSelect, selectedRegion.value]
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (__props.hasBudgetLimit && availableBudget.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["cst-input-field__indicator--available", availableBudget.value && Number(availableBudget.value) <= 0 ? 'cst-input-field__indicator--min' : '']),
              "data-testid": "newprod-input-budget-limit"
            }, [
              _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Available amount:", -1)),
              _createTextVNode(" " + _toDisplayString(_unref(formatNumber)((Number(availableBudget.value) * currencyRate.value).toFixed(2))), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          (_unref(isPlatformSelected))
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _cache[22] || (_cache[22] = _createElementVNode("label", { for: "platformCost" }, [
                  _createTextVNode("Enter platform cost value:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "cst-input",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(platformCost) ? (platformCost).value = $event : platformCost = $event)),
                  id: "platformCost",
                  placeholder: "Enter platform cost value",
                  required: _unref(isPlatformSelected),
                  disabled: inputDisabled.value
                }, null, 8, _hoisted_21), [
                  [_vModelText, _unref(platformCost)]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_22, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { for: "selectPlatform" }, "Platform?", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              id: "selectPlatform",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(isPlatformSelected) ? (isPlatformSelected).value = $event : isPlatformSelected = $event))
            }, null, 512), [
              [_vModelCheckbox, _unref(isPlatformSelected)]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            (!inputDisabled.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "thumbnail-dropdown--btn",
                  onClick: toggleDropdown,
                  "data-testid": "thumbnail-dropdown-btn"
                }, [
                  (selectedImage.value)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: selectedImage.value,
                        alt: "Product",
                        width: "60",
                        height: "40",
                        "data-testid": "newprod-selected-image"
                      }, null, 8, _hoisted_25))
                    : (_openBlock(), _createElementBlock("div", _hoisted_26, "Select an Image"))
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_27, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Add new",
                width: "80",
                height: "50",
                style: {"border":"1px solid #cfcfcf"},
                "data-testid": "newprod-upload-image",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (uploadNewImage()))
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allImages.value, (image) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: image,
                  "data-testid": "newprod-select-image",
                  onClick: ($event: any) => (handleSelectImage(image))
                }, [
                  _createElementVNode("img", {
                    src: image.image_data,
                    alt: image.name,
                    width: "80",
                    height: "50",
                    style: {"object-fit":"contain"}
                  }, null, 8, _hoisted_29)
                ], 8, _hoisted_28))
              }), 128))
            ], 512), [
              [_vShow, dropdownOpen.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("button", {
              class: "submit-button cst-button cst-button-primary",
              type: "submit",
              disabled: submitBtnDisabled.value || (__props.hasBudgetLimit && availableBudget.value && Number(availableBudget.value) <= 0),
              "data-testid": "newprod-submit-btn"
            }, "Submit", 8, _hoisted_31),
            (productAdded.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "submit-button cst-button cst-button-primary",
                  onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (handleNew()), ["prevent"])),
                  "data-testid": "newprod-new-btn"
                }, "New"))
              : _createCommentVNode("", true)
          ])
        ])
      ], 32)
    ]),
    (createdProductFlag.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _cache[24] || (_cache[24] = _createElementVNode("h4", null, "New Product has been added!", -1)),
          _cache[25] || (_cache[25] = _createElementVNode("h5", null, "You can add Project or Applications directly to it by clicking on the corresponding buttons.", -1)),
          _createVNode(NewProductCard, {
            product: newProduct.value,
            base64image: selectedImage.value
          }, null, 8, ["product", "base64image"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})