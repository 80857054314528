import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cst-spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "v-manageplatform__container"
}
const _hoisted_3 = { class: "v-manageplatform__box--wrapper" }
const _hoisted_4 = { class: "v-manageplatform__title" }
const _hoisted_5 = { class: "v-manageplatform__body" }
const _hoisted_6 = { class: "v-manageplatform__body--manage" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "v-manageplatform__body--allocate" }
const _hoisted_12 = { class: "cst-input-field" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "cst-input-field" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "cst-input-field" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "cst-input-field" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "cst-inputbox__actions--row-wrapper__spacebetween" }
const _hoisted_24 = { class: "cst-inputbox__actions--row-wrapper__smallgap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    (!_ctx.arePlatformsFetched)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_ctx.areProductsFetched)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, "Manage platform: " + _toDisplayString(_ctx.selectedPlatform), 1),
              _createElementVNode("form", {
                onSubmit: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "section__line-with-text" }, [
                    _createElementVNode("span", { class: "section__title" }, "Platform Details")
                  ], -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _cache[12] || (_cache[12] = _createElementVNode("strong", null, "Platform name:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.selectedPlatform), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[13] || (_cache[13] = _createElementVNode("strong", null, "Platform owner:", -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.platformOwner), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _cache[14] || (_cache[14] = _createElementVNode("label", { for: "platform-cost" }, "Platform cost:", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input v-createcu--formwrapper--inputfield",
                          type: "number",
                          placeholder: _ctx.formatNumber((_ctx.platformObject?.platform_cost * _ctx.currencyRate).toFixed(2)),
                          id: "platform-cost",
                          "data-testid": "manage-platform-platform-cost",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.platformCost) = $event))
                        }, null, 8, _hoisted_9), [
                          [_vModelText, _ctx.platformCost]
                        ])
                      ])
                    ]),
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleUpdatePlatformCost && _ctx.handleUpdatePlatformCost(...args))),
                      disabled: _ctx.platformCost == '' || Number(_ctx.platformCost) < 0,
                      type: "button"
                    }, "Update cost", 8, _hoisted_10)
                  ]),
                  _cache[24] || (_cache[24] = _createElementVNode("div", { class: "section__line-with-text" }, [
                    _createElementVNode("span", { class: "section__title" }, "Allocate Customer")
                  ], -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[16] || (_cache[16] = _createElementVNode("label", { for: "productfamily" }, "Select product family", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        required: "",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedProductFamily) = $event)),
                        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSelectUserFamily && _ctx.handleSelectUserFamily(...args)))
                      }, [
                        _cache[15] || (_cache[15] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a product family", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamily, (family) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: family.id,
                            value: family.id,
                            id: "selectedFamilyProduct"
                          }, _toDisplayString(family.name), 9, _hoisted_13))
                        }), 128))
                      ], 544), [
                        [_vModelSelect, _ctx.selectedProductFamily]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", { for: "product" }, "Select existing product", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        required: "",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedProduct) = $event)),
                        disabled: _ctx.isProductDropdownDisabled,
                        onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleSelectProduct && _ctx.handleSelectProduct(...args)))
                      }, [
                        _cache[17] || (_cache[17] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a product", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: product.id,
                            value: product.id,
                            id: "selectedProduct"
                          }, _toDisplayString(product.name), 9, _hoisted_16))
                        }), 128))
                      ], 40, _hoisted_15), [
                        [_vModelSelect, _ctx.selectedProduct]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", { for: "project" }, "Project:", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedProject) = $event)),
                        disabled: _ctx.projects.length === 0 || _ctx.selectedProduct == '',
                        onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleSelectProject && _ctx.handleSelectProject(...args))),
                        "data-testid": "create-request-project-dropdown"
                      }, [
                        _cache[19] || (_cache[19] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select a Project", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: project.id,
                            value: project.id
                          }, _toDisplayString(project.name), 9, _hoisted_19))
                        }), 128))
                      ], 40, _hoisted_18), [
                        [_vModelSelect, _ctx.selectedProject]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _cache[22] || (_cache[22] = _createElementVNode("label", { for: "application" }, "Application:", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "cst-select-field",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedApplication) = $event)),
                        disabled: _ctx.applications.length === 0 || _ctx.selectedProduct == '',
                        onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleSelectApplication && _ctx.handleSelectApplication(...args))),
                        "data-testid": "create-request-application-dropdown"
                      }, [
                        _cache[21] || (_cache[21] = _createElementVNode("option", {
                          disabled: "",
                          selected: "",
                          value: ""
                        }, "Select an Application", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (application) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: application.id,
                            value: application.id
                          }, _toDisplayString(application.name), 9, _hoisted_22))
                        }), 128))
                      ], 40, _hoisted_21), [
                        [_vModelSelect, _ctx.selectedApplication]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("button", {
                      class: "cst-button cst-button-primary v-createcu--formwrapper__createbtn",
                      type: "submit",
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleAddConsumer && _ctx.handleAddConsumer(...args)))
                    }, "Add Consumer")
                  ])
                ])
              ], 32)
            ])
          ]))
        : _createCommentVNode("", true)
  ], 64))
}