<template>
  <div v-bind:class="props.isModal && props.openModal ? 'cst-inputbox__modal-overlay' : ''">
    <div class="cst-inputbox c-createnew__dialog">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title" data-testid="cst-inputbox-title">
          Create a new
          {{ props.type.charAt(0).toUpperCase() + props.type.slice(1) }}
        </div>
        <div v-if="isModal" class="cst-inputbox--header__close cst-button-close" @click="closeModalInternal" data-testid="proj-app-close-modal">X</div>
      </div>
      <div class="cst-inputbox--body">
        <form @submit.prevent="submitForm">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="proj-app-owner">Owner:</label>
              <select class="cst-select-field" id="proj-app-owner" @change="handleSelectOwner($event)" required v-model="preSelectedOwner" data-testid="proj-app-select-own">
                <option disabled selected value="">Select Owner</option>
                <option v-for="owner in users" :key="owner.id" :value="owner.id" :selected="owner.username === selectedOwner">
                  {{ owner.username }}
                </option>
              </select>
            </div>

            <div class="cst-input-field" v-if="!props.isModal">
              <label for="selectproductfamily">Select product family</label>
              <select class="cst-select-field" id="selectproductfamily" required v-model="selectedProductFamily" @change="fetchProductsFromPF">
                <option disabled selected value="">Select a product family</option>
                <option v-for="family in productFamily" :key="family.id" :value="family.id" id="selectedFamilyProduct">
                  {{ family.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="proj-app-product">Product</label>
              <!-- The displayed select field depends whether the user is coming from newly created product or directly creating a new project/application -->
              <select class="cst-select-field" id="proj-app-product" @change="handleSelectProduct($event)" required :disabled="props.isModal" v-if="props.isModal">
                <option disabled selected value="">Select a Product</option>
                <option :value="preselectedProductId" :selected="newlyCreatedProduct.name">
                  {{ newlyCreatedProduct.name }}
                </option>
              </select>
              <select class="cst-select-field" id="proj-app-product" @change="handleSelectProduct($event)" required v-model="selectedProduct" :disabled="isProductSelectDisabled" v-else>
                <option disabled selected value="">Select a Product</option>
                <option v-for="product in filteredProducts" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>

            <div>
              <div class="cst-input-field">
                <label for="proj-app-name">Name:</label>
                <input class="cst-input" type="text" v-model="projectOrAppData.name" id="proj-app-name" required maxlength="32" data-testid="proj-app-name" />
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': projectOrAppData.name?.length == 32,
                  }"
                  data-testid="proj-app-indicator"
                  >{{ projectOrAppData.name?.length ? `${projectOrAppData.name.length}` : `0` }}/32</span
                >
              </div>
              <div class="cst-input-field">
                <label for="proj-app-currency">Currency</label>
                <select class="cst-select-field" id="proj-app-currency" @change="handleSelectCurrency($event)" v-model="projectOrAppData.currency" data-testid="proj-app-currency">
                  <option disabled selected value="">Select a Currency</option>
                  <option v-for="currency in currencies" :key="currency.id" :value="currency.id" :selected="currency.id === selectedCurrency">
                    {{ currency.id }}
                  </option>
                </select>
              </div>
            </div>

            <CostTypeInputs :available-budget="availableBudget" @emitCostValues="handleEmitCostValues" @emitSumTotal="handleEmitSumTotal"></CostTypeInputs>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" id="proj-app-submitbtn" data-testid="proj-app-submitbtn" :disabled="sumIsZero || (availableBudget !== null && Number(sumTotal) > Number(availableBudget))">Submit</button>
            </div>
          </div>
        </form>
        <!--Temporarily disable, needed later -->
        <!-- <hr
          v-if="isModal"
          class="hr-text"
          v-bind:data-content="
            props.type == 'project'
              ? 'OR Add an existing Project'
              : 'OR Add an existing Application'
          "
        />
        <form v-if="isModal" @submit.prevent="submitExistingForm">
          <div class="cst-input-field">
            <label for="proj-app-existing">{{
              props.type.charAt(0).toUpperCase() + props.type.slice(1)
            }}</label>
            <select
              class="cst-select-field"
              id="proj-app-existing"
              @change="handleSelectExisting($event)"
            >
              <option disabled selected value="">
                Select
                {{ props.type.charAt(0).toUpperCase() + props.type.slice(1) }}
              </option>
              <option
                v-for="existing in props.type == 'project'
                  ? projects
                  : applications"
                :key="existing.id"
                :value="existing.name"
              >
                {{ existing.name }}
              </option>
            </select>
          </div>
          <button
            class="submit-button cst-button cst-button-primary"
            id="proj-app-moveExisting"
            type="submit"
            :disabled="!existingProjectOrApplication"
          >
            Submit
          </button>
        </form> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, PropType, Ref, ref } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { IProduct, IProductFamily, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import { IUser } from "@/utils/interfaces/IUser";
  import CostTypeInputs from "../C_CostTypeInputs.vue";
  import { ICostTypeInputs } from "@/utils/interfaces/ICostTypeInputs";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "ProjectApplicationForm",
    components: {
      CostTypeInputs,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
      openModal: {
        type: Boolean,
        default: false,
      },
      closeModal: {
        type: Function as PropType<() => void>,
      },
      preselectProduct: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const { CREATE_NEW_APP, UPDATE_APP, FETCH_PROJECTS, CREATE_NEW_PROJECT, UPDATE_PROJECT, FETCH_APP, UPDATE_REQUEST_MESSAGE, FETCH_USERS, FETCH_PRODUCTS_IN_PROD_FAMILY, FETCH_RELATED_PRODUCT_FAMILIES, FETCH_PRODUCT_AVAILABLE_BUDGET, FETCH_ALL_PRODUCTS } =
        VUEX_ACTIONS;
      const store = useStore();
      const router = useRouter();
      const products = computed(() => store.getters.getAllProducts);
      const filteredProducts = computed(() => {
        const products = store.getters.getProducts || [];
        return products.slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name));
      });
      const applications = computed(() => store.getters.getApplications);
      const projects = computed(() => store.getters.getProjects);
      const users = computed(() => {
        return (store.getters.getUsers || []).slice().sort((a: IUser, b: IUser) => a.username.localeCompare(b.username));
      });
      const existingProjectOrApplication = ref();
      const selectedOwner = computed(() => store.getters.getCurrentUser);
      const productFamily = computed(() => {
        return (store.getters.getRelatedProductFamilies || []).slice().sort((a: IProductFamily, b: IProductFamily) => a.name.localeCompare(b.name));
      });
      const isProductSelectDisabled = ref(true);
      const selectedProductFamily = ref("");
      const preSelectedOwner = ref("");
      const newlyCreatedProduct = computed(() => store.getters.getCreatedProduct);
      const selectedProduct = ref("");
      const preselectedProductId = computed(() => store.getters.getCreatedProduct.id);
      const currencies = computed(() => store.getters.getAllCurrencies);
      const selectedCurrency = ref();
      const costTypeInputs = ref<ICostTypeInputs>({
        bci: null,
        bcm: null,
        bce: null,
        bei: null,
        bem: null,
        bee: null,
        undef: null,
      });
      const sumTotal = ref(0);
      const sumIsZero = ref(true);
      const availableBudget = computed(() => {
        if (products.value) {
          const product = products.value.find((product: { id: string; budget_limit: string }) => product.id === (!selectedProduct.value ? preselectedProductId.value : selectedProduct.value));

          return product && product.budget_limit !== null ? store.getters.getProductRemainingBudget : null;
        }
        return null;
      });

      onMounted(() => {
        if (!(store.getters.getCreatedProduct instanceof Array)) {
          // because createdProduct is initialized  as an Array but used like Object...
          store.dispatch(FETCH_PRODUCT_AVAILABLE_BUDGET, store.getters.getCreatedProduct.id);
        }
      });

      store.dispatch(FETCH_USERS);
      store.dispatch(FETCH_ALL_PRODUCTS);
      store.dispatch(FETCH_PROJECTS);
      store.dispatch(FETCH_APP);
      store.dispatch(FETCH_RELATED_PRODUCT_FAMILIES);

      const emitChanges = () => {
        context.emit("emitCostTypeInputs", costTypeInputs.value);
      };

      const emitExistingBudget = (existingBudget: number) => {
        context.emit("emitExistingBudget", existingBudget);
      };

      const projectOrAppData: Ref<IProjectApplication> = ref({
        owner: null,
        product: null,
        name: "",
        currency: "EUR",
        bci: null,
        bcm: null,
        bce: null,
        bei: null,
        bem: null,
        bee: null,
        undef: null,
        status: "",
      });

      function submitForm() {
        const existingProject = projects.value.find((proj: IProjectApplication) => proj.name.toLowerCase().trim() === projectOrAppData.value.name.toLowerCase().trim());
        const existingApplication = applications.value.find((app: IProjectApplication) => app.name.toLowerCase().trim() === projectOrAppData.value.name.toLowerCase().trim());
        if (props.preselectProduct) {
          handlePreSelectProduct();
        }
        if (!projectOrAppData.value.owner) {
          handleCurrentOwner();
        }

        async function handleCreation(dispatchAction: string, errorMessage: string) {
          try {
            await store.dispatch(dispatchAction, projectOrAppData.value);
            emitChanges();
            if (props.isModal) {
              setTimeout(() => {
                closeModalInternal();
              }, 1000);
            } else {
              setTimeout(() => {
                router.push("/");
              }, 1500);
            }
          } catch (error) {
            console.error(errorMessage, error);
          }
        }

        if (props.type === "application" && sumIsZero.value === false) {
          if (!existingApplication) {
            projectOrAppData.value.name = projectOrAppData.value.name.trim();
            handleCreation(CREATE_NEW_APP, "Error occurred while creating new application:");
          } else {
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Application with name you provided already exists!");
            projectOrAppData.value.name = "";
          }
        }
        if (props.type === "project" && sumIsZero.value === false) {
          if (!existingProject) {
            projectOrAppData.value.name = projectOrAppData.value.name.trim();
            handleCreation(CREATE_NEW_PROJECT, "Error occurred while creating new project:");
          } else {
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Project with name you provided already exists!");
            projectOrAppData.value.name = "";
          }
        }
      }

      const fetchProductsFromPF = async () => {
        await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedProductFamily.value.toString()).then(() => {
          isProductSelectDisabled.value = false;
          selectedProduct.value = "";
        });
      };

      const handleSelectCurrency = (event: Event) => {
        selectedCurrency.value = (event.target as HTMLSelectElement).value;
      };

      const handleSelectProduct = (event: Event) => {
        projectOrAppData.value.product = Number((event?.target as HTMLSelectElement).value);
        store.dispatch(FETCH_PRODUCT_AVAILABLE_BUDGET, selectedProduct.value);
      };

      const handlePreSelectProduct = () => {
        projectOrAppData.value.product = Number(preselectedProductId.value);
      };

      const handleSelectOwner = (event: Event) => {
        projectOrAppData.value.owner = Number((event?.target as HTMLSelectElement).value);
      };

      const handleCurrentOwner = () => {
        const user = users.value.filter((p: IUser) => p.username == selectedOwner.value);
        projectOrAppData.value.owner = Number(user[0].id);
      };

      const closeModalInternal = () => {
        if (props.closeModal) {
          props.closeModal();
        }
      };

      const handleEmitCostValues = (values: ICostTypeInputs) => {
        costTypeInputs.value = values;
        projectOrAppData.value.bci = costTypeInputs.value.bci;
        projectOrAppData.value.bcm = costTypeInputs.value.bcm;
        projectOrAppData.value.bce = costTypeInputs.value.bce;
        projectOrAppData.value.bei = costTypeInputs.value.bei;
        projectOrAppData.value.bem = costTypeInputs.value.bem;
        projectOrAppData.value.bee = costTypeInputs.value.bee;
        projectOrAppData.value.undef = costTypeInputs.value.undef;
      };

      const handleEmitSumTotal = (values: number) => {
        if (values > 0) {
          sumIsZero.value = false;
        } else {
          sumIsZero.value = true;
        }
        sumTotal.value = values;
      };

      const handleSelectExisting = (event: Event) => {
        if (props.type === "project") {
          const project = projects.value.filter((p: IProjectApplication) => p.name == (event.target as HTMLSelectElement).value);
          existingProjectOrApplication.value = project[0];
        } else {
          const application = applications.value.filter((a: IProjectApplication) => a.name == (event.target as HTMLSelectElement).value);
          existingProjectOrApplication.value = application[0];
        }
      };

      function submitExistingForm() {
        if (props.isModal) {
          handlePreSelectProduct();
        }

        const payload: IProjectApplication = {
          id: Number(existingProjectOrApplication.value.id),
          product: projectOrAppData.value.product,
          name: existingProjectOrApplication.value.name,
          owner: existingProjectOrApplication.value.owner,
          currency: existingProjectOrApplication.value.currency,
          bci: existingProjectOrApplication.value.bci,
          bcm: existingProjectOrApplication.value.bcm,
          bce: existingProjectOrApplication.value.bce,
          bei: existingProjectOrApplication.value.bei,
          bem: existingProjectOrApplication.value.bem,
          bee: existingProjectOrApplication.value.bee,
          undef: existingProjectOrApplication.value.undefined,
          status: existingProjectOrApplication.value.status,
        };
        if (props.type === "application") {
          store.dispatch(UPDATE_APP, payload);
          store.dispatch(UPDATE_REQUEST_MESSAGE, `Application added successfully!`);

          emitExistingBudget(existingProjectOrApplication.value.totalbudget);
          closeModalInternal();
        }
        if (props.type === "project") {
          store.dispatch(UPDATE_PROJECT, payload);
          store.dispatch(UPDATE_REQUEST_MESSAGE, `Project added successfully!`);

          emitExistingBudget(existingProjectOrApplication.value.totalbudget);
          closeModalInternal();
        }
      }

      return {
        props,
        projectOrAppData,
        applications,
        projects,
        submitForm,
        handleSelectProduct,
        products,
        closeModalInternal,
        handleSelectExisting,
        submitExistingForm,
        handleSelectOwner,
        handleSelectCurrency,
        handleEmitCostValues,
        handleEmitSumTotal,
        users,
        selectedOwner,
        selectedProduct,
        preselectedProductId,
        currencies,
        selectedCurrency,
        costTypeInputs,
        existingProjectOrApplication,
        preSelectedOwner,
        selectedProductFamily,
        fetchProductsFromPF,
        newlyCreatedProduct,
        productFamily,
        filteredProducts,
        isProductSelectDisabled,
        sumTotal,
        sumIsZero,
        availableBudget,
      };
    },
  });
</script>
