import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-testid": "app-wrapper"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_submenu = _resolveComponent("submenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return ($data.appLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header, {
          appName: $data.appName,
          logoUrl: $data.logoUrl
        }, null, 8, ["appName", "logoUrl"]),
        _createVNode(_component_submenu),
        _createVNode(_component_router_view),
        _createVNode(_component_Footer, {
          appName: $data.appName,
          "app-version": $data.appVersion
        }, null, 8, ["appName", "app-version"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
        _createElementVNode("div", {
          class: "cst-spinner",
          "data-testid": "app-spinner"
        }, null, -1)
      ])))
}