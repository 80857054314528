<template>
  <div v-if="appLoaded" data-testid="app-wrapper">
    <Header :appName="appName" :logoUrl="logoUrl"></Header>
    <submenu></submenu>
    <router-view />
    <Footer :appName="appName" :app-version="appVersion"></Footer>
  </div>
  <div v-else>
    <div class="cst-spinner" data-testid="app-spinner"></div>
  </div>
</template>

<script lang="ts">
  import Header from "./components/C_Header.vue";
  import Footer from "./components/C_Footer.vue";
  import KeyCloakService from "../src/security/KeycloakService";
  import Submenu from "./components/C_Submenu.vue";
  import { useStore } from "vuex";
  import { computed, ref, watch, watchEffect } from "vue";
  import { generateDefaultTheme, generateTheme } from "../src/utils/helpers/themeConfiguration";

  export default {
    data() {
      const appLoaded = ref(false);
      const store = useStore();
      const currentLoggedUser = computed(() => store.getters.getCurrentUser);
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const appVersion = computed(() => store.getters.getAppVersion);
      const appName = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.app_name;
        } else {
          return "App missing configuration";
        }
      });
      /* Generate HTML Style element and append all theme variables with the values from backend */
      watchEffect(() => {
        const styleElement = document.createElement("style");
        if (Object.keys(appConfiguration.value).length > 0) {
          styleElement.textContent = generateTheme(appConfiguration.value);
        } else {
          styleElement.textContent = generateDefaultTheme();
        }
        document.head.appendChild(styleElement);
      });

      const backendUrl = process.env.VUE_APP_CST_BACKEND_URL || "http://localhost:8081";
      const logoUrl = `${backendUrl}/configuration/logo`;
      const checkCurrentLoggedUser = () => {
        if (currentLoggedUser.value) {
          appLoaded.value = true;
        } else {
          appLoaded.value = false;
        }

        setTimeout(() => {
          if (!currentLoggedUser.value) {
            KeyCloakService.CallLogout();
          }
        }, 5000);
      };
      checkCurrentLoggedUser();

      if (Object.keys(appConfiguration.value).length > 0) {
        (document.querySelector("#page-title") as { innerHTML: string }).innerHTML = appConfiguration.value.app_name;
      } else {
        (document.querySelector("#page-title") as { innerHTML: string }).innerHTML = "Missing configuration";
      }

      watch([appConfiguration], () => {
        if (Object.keys(appConfiguration.value).length > 0) {
          (document.querySelector("#page-title") as { innerHTML: string }).innerHTML = appConfiguration.value.app_name;
        } else {
          (document.querySelector("#page-title") as { innerHTML: string }).innerHTML = "Missing configuration";
        }
      });

      watch([currentLoggedUser], () => {
        checkCurrentLoggedUser();
      });

      return {
        styles: JSON.stringify({
          ".header, .footer-container": {
            background: "var(--color-headers)",
          },
          ".header h1, .footer-container p,  .footer-container a, .footer-container": {
            color: "white",
          },
        }),
        logoUrl,
        appConfiguration,
        appName,
        backendUrl,
        appVersion,
        appLoaded,
      };
    },
    components: { Submenu, Header, Footer },
  };
</script>

<style lang="scss">
  @import "./styles/main.scss";
  body {
    margin: 0;
  }
</style>
