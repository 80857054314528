<template>
  <div class="v-createinvoice" data-testid="create-invoice" v-if="createInvoiceEnabled">
    <div class="cst-inputbox cst-inputbox__choose-items">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Choose list items</div>
      </div>

      <div class="cst-inputbox__invoice-item">
        <div>
          <label for="listItems" data-testid="list-item-label"><span class="cst-input-field--mandatory">* </span>List Item:</label>
          <select class="cst-select-field cst-select-field--list-item" id="listItems" v-model="selectedItemId" @change="handleSelectListItem" data-testid="list-items-select">
            <option v-for="listItem in localOrderListItems" :key="listItem.id" :value="listItem.id" data-testid="list-item-option">{{ listItem.name }} - {{ listItem.costtype }}</option>
          </select>
        </div>

        <form @submit.prevent="handleAddToInvoice" v-if="selectedItem && showSelectedItemBlock" data-testid="add-to-invoice-form">
          <div class="v-createinvoice__li-container">
            <p v-if="selectedItem.resourcename?.length > 0"><span>Resource Name: </span>{{ selectedItem.resourcename }}</p>
            <label v-if="selectedItem.resourcename?.length > 0" for="quantity"><span class="cst-input-field--mandatory">* </span>Person days:</label>
            <input v-if="selectedItem.resourcename?.length > 0" class="cst-input" type="number" id="quantity" data-testid="person-days-input" required v-model="invoiceListItemData.quantity" />
            <p v-if="selectedItem.daily_rate !== null" class=""><span>Daily Rate: </span>{{ selectedItem.daily_rate }}</p>
            <div v-if="selectedItem.daily_rate == null">
              <label for="amount"><span class="cst-input-field--mandatory">* </span>Amount:</label>
              <input class="cst-input" type="number" id="amount" data-testid="amount-input" required @input="preventDotInInput" v-model="invoiceListItemData.amount" />
            </div>
            <div v-if="totalListItemAmount" class="v-createinvoice__available-amount">
              <p>Total Amount:</p>
                <span>{{totalListItemAmount + " " + selectedItem.currency}}</span>
            </div>
            <div class="v-createinvoice__available-amount">
              <p>Available amount:</p>
              <span>{{ selectedItem.amount + " " + selectedItem.currency }}</span>
            </div>
            <div class="v-createinvoice__button-wrapper">
              <button type="submit" class="submit-button cst-button cst-button-primary" :disabled="isButtonDisabled" data-testid="add-to-invoice-button">Add to Invoice</button>
            </div>
          </div>
        </form>
        <div v-else>
          <p>Please choose the list item you want to add to the invoice.</p>
        </div>
      </div>
    </div>
    <form class="cst-inputbox cst-inputbox__create-invoice" @submit.prevent="createInvoice">
      <div>
        <div class="cst-inputbox--header">
          <div class="cst-inputbox--header__title">Create Invoice</div>
        </div>
        <div class="v-createinvoice__invoice-wrapper">
          <p><span>Order name: </span>{{ createInvoiceFromOrder.ordertext }}</p>
          <p><span>Customer contact: </span>{{ createInvoiceFromOrder.customercontact }}</p>
          <div class="v-createinvoice__invoice-title">
            <label for="title"><span class="cst-input-field--mandatory">* </span>Invoice Title:</label>
            <input class="cst-input" type="text" id="title" required @input="preventDotInInput" v-model="invoiceTitle" data-testid="invoice-title-input" />
          </div>

          <div v-if="dateRangeEnabled">
            <div class="v-createinvoice__invoice-date">
              <label for="start-date"><span class="cst-input-field--mandatory">* </span> Invoice Start Date:</label>
              <input class="cst-input" type="date" id="start-date" data-testid="invoice-date-input-start" required v-model="startDate" @input="endDate = ''" />
            </div>
            <div class="v-createinvoice__invoice-date">
              <label for="end-date"><span class="cst-input-field--mandatory">* </span> Invoice End Date:</label>
              <input class="cst-input" type="date" id="end-date" data-testid="invoice-date-input-end" :disabled="startDate === ''" required :min="startDate" :max="startDate ? getLastDayOfMonth(startDate) : ''" v-model="endDate" />
            </div>
          </div>
          <div class="v-createinvoice__invoice-date" v-else>
            <label for="date"><span class="cst-input-field--mandatory">* </span> Invoice Date:</label>
            <input class="cst-input" type="date" id="date" data-testid="invoice-date-input" required :min="currentDate" v-model="selectedDate" />
          </div>

          <div class="v-createinvoice__table-header">
            <p>No.</p>
            <p>Name</p>
            <p>Resource</p>
            <p>Person Days</p>
            <p>Amount</p>
            <p>Remove</p>
          </div>
          <div class="v-createinvoice__table-content">
            <div v-for="(item, index) in listItemsFromOrder" :key="item.li_name">
              <div class="v-createinvoice__table-row">
                <p>{{ index + 1 }}</p>
                <p>{{ item.li_name }}</p>
                <p>{{ item.resource_name }}</p>
                <p>{{ item.quantity }}</p>
                <p>{{ item.amount + " " + item.currency }}</p>
                <div>
                  <img src="../../public/remove.svg" alt="" data-testid="remove-invoice-item" @click="removeListItemFromInvoice(item.po_listitem_id)" />
                </div>
              </div>
            </div>
          </div>
          <div class="v-createinvoice__totalamount">
            <p><span>Total Amount:</span> {{ totalInvoiceAmount }}</p>
          </div>
        </div>
      </div>
      <div class="v-createinvoice__button-wrapper">
        <button type="submit" class="submit-button cst-button cst-button-primary" data-testid="create-invoice-btn" :disabled="listItemsFromOrder.length < 1">Create Invoice</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
  import { computed, ref, Ref, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { IListItem, INewInvoiceListItem } from "@/utils/interfaces/IListItem";
  import { IOrderListItem, IInvoiceListItem } from "@/utils/interfaces/IInvoice";
  import { fetchTodaysDate } from "@/utils/helpers/fetchTodaysDate";
  import { getLastDayOfMonth } from "@/utils/helpers/calculateLastDayOfMonth";
  import { VUEX_ACTIONS } from "@/utils/constants";
  const { FETCH_NOTIFICATIONS, CREATE_INVOICE } = VUEX_ACTIONS;

  const store = useStore();
  const router = useRouter();
  const listItemsFromOrder: Ref<IOrderListItem[]> = ref([]);
  const createInvoiceFromOrder = computed(() => store.getters.getInvoiceFromOrder);
  const selectedItemId = ref();
  const showSelectedItemBlock = ref(true);
  const currentDate = computed(() => fetchTodaysDate());
  const startDate = ref("");
  const endDate = ref("");
  const selectedDate = ref("");
  const isButtonDisabled = ref(true);
  const localOrderListItems = ref();
  const invoiceTitle = ref("");
  const selectedItem = computed(() => {
    if (localOrderListItems.value) {
      return localOrderListItems.value.find((item: IListItem) => item.id === selectedItemId.value);
    } else return null;
  });
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const createInvoiceEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.create_invoice_enabled;
    } else {
      return false;
    }
  });
  const dateRangeEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoice_has_date_range;
    } else {
      return false;
    }
  });

  const invoiceListItemData: Ref<IOrderListItem> = ref({
    amount: null,
    currency: "",
    resource_name: "",
    quantity: null,
    daily_rate: null,
    po_listitem_id: null,
    li_name: "",
  });

  const handleSelectListItem = () => {
    if (selectedItem.value) {
      invoiceListItemData.value.li_name = selectedItem.value.name;
      invoiceListItemData.value.po_listitem_id = selectedItem.value.id;
      invoiceListItemData.value.resource_name = selectedItem.value.resourcename;
      invoiceListItemData.value.quantity = selectedItem.value.quantity;
      invoiceListItemData.value.daily_rate = selectedItem.value.daily_rate;
      invoiceListItemData.value.currency = selectedItem.value.currency;
      showSelectedItemBlock.value = true;
      invoiceListItemData.value.amount = null;
    }
  };

  const preventDotInInput = () => {
    if (invoiceListItemData.value.amount !== null) {
      invoiceListItemData.value.amount = parseInt(invoiceListItemData.value.amount.toString().split(".").join(""));
    }
  };

  const handleAddToInvoice = () => {
    if (invoiceListItemData.value.amount && invoiceListItemData.value.amount > 0) {
        invoiceListItemData.value.quantity = null;
        listItemsFromOrder.value.push({
          ...invoiceListItemData.value,
        });
      } else if (totalListItemAmount.value > 0) {
        invoiceListItemData.value.amount = totalListItemAmount.value;
        listItemsFromOrder.value.push({
          ...invoiceListItemData.value,
        });
      }
      const filteredArray = localOrderListItems.value.filter((item: INewInvoiceListItem) => Number(item.id) !== Number(invoiceListItemData.value.po_listitem_id));

      localOrderListItems.value = filteredArray;
      showSelectedItemBlock.value = false;
      invoiceListItemData.value.amount = null;

  };

  const totalListItemAmount = computed(() => {
    if (invoiceListItemData.value.quantity && invoiceListItemData.value.daily_rate) {
      let listItemAmount = invoiceListItemData.value.quantity * invoiceListItemData.value.daily_rate;
      return listItemAmount;
    } else {
      return 0;
    }
  });

  const totalInvoiceAmount = computed(() => {
    let total = 0;
    for (const item of listItemsFromOrder.value) {
      if (item.amount !== null && item.amount !== undefined) {
        total += item.amount;
      }
    }
    return total + " EUR";
  });

  const createInvoice = () => {
    const invoiceListItems: IInvoiceListItem[] = listItemsFromOrder.value.map((item) => {
      // li_name was removed from the invoice because it doesn't match response on backend
      const { li_name, ...invoiceItem } = item; // eslint-disable-line @typescript-eslint/no-unused-vars
      return invoiceItem;
    });
    const invoice = {
      name: invoiceTitle.value,
      date: dateRangeEnabled.value ? currentDate.value : selectedDate.value,
      start_date: dateRangeEnabled.value ? startDate.value : null,
      end_date: dateRangeEnabled.value ? endDate.value : null,
      purchaseorder_id: Number(createInvoiceFromOrder.value.id),
      listitems: invoiceListItems,
    };
    store.dispatch(CREATE_INVOICE, invoice);
    setTimeout(() => {
      router.push("/invoices");
    }, 500);
  };

  watch([invoiceListItemData.value], () => {
    if (invoiceListItemData.value.amount == 0 || invoiceListItemData.value.amount == null || !invoiceListItemData.value.amount) {
      isButtonDisabled.value = true;
    } else {
      isButtonDisabled.value = false;
    }

    if (invoiceListItemData.value.amount && invoiceListItemData.value.amount > selectedItem.value.amount && invoiceListItemData.value.amount.toString().length > 0) {
      invoiceListItemData.value.amount = selectedItem.value.amount;
      isButtonDisabled.value = false;
    }
  });

  watch ([totalListItemAmount], () => {
    if (totalListItemAmount.value > selectedItem.value.amount) {
      isButtonDisabled.value = true;
    } else {
      isButtonDisabled.value = false;
    }
  })

  localOrderListItems.value = createInvoiceFromOrder?.value?.listitems;
  const removeListItemFromInvoice = (itemId: number | null) => {
    for (const item of createInvoiceFromOrder.value.listitems) {
      if (item.id === itemId) {
        localOrderListItems.value.push(item);
        const filteredArray = listItemsFromOrder.value.filter((item: IOrderListItem) => item.po_listitem_id !== itemId);
        listItemsFromOrder.value = filteredArray;
        selectedItemId.value = itemId;
        handleSelectListItem();
      }
    }
  };
</script>
