import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cst-new-product-type--wrapper" }
const _hoisted_2 = {
  class: "cst-inputbox",
  "data-testid": "newplatform-input-box"
}
const _hoisted_3 = { class: "cst-inputbox--body" }
const _hoisted_4 = { class: "cst-inputbox__form" }
const _hoisted_5 = { class: "cst-input-field" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = ["value"]
const _hoisted_9 = {
  key: 0,
  class: "cst-new-product-type--wrapper"
}
const _hoisted_10 = {
  class: "cst-inputbox",
  "data-testid": "newplatform-input-box"
}
const _hoisted_11 = { class: "cst-inputbox--body" }
const _hoisted_12 = { class: "cst-inputbox__form" }
const _hoisted_13 = { class: "cst-input-field" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "cst-input-field" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "cst-input-field" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = ["value"]

import { useStore } from "vuex";
  import RequestMessage from "@/components/C_RequestMessage.vue";

  import { computed, onUnmounted, ref } from "vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { VUEX_ACTIONS } from "@/utils/constants";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreatePlatform',
  setup(__props) {

  const { FETCH_PRODUCTS_IN_PROD_FAMILY, FETCH_RELATED_PRODUCT_FAMILIES, FETCH_USERS, CREATE_PLATFORM, ADD_PRODUCT_TO_PLATFORM } = VUEX_ACTIONS;

  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const productFamily = computed(() => store.getters.getRelatedProductFamilies);
  const filteredProducts = computed(() => store.getters.getProducts);
  const isPlatformCreated = computed(() => store.getters.getIsPlatformCreated);

  const platforms = ref<IPlatform[]>([
    { id: 1, name: "Platform 1", owner: 1, budget: 10 },
    { id: 2, name: "Platform 2", owner: 2, budget: 20 },
    { id: 3, name: "Platform 3", owner: 3, budget: 30 },
  ]);
  /* TO-DO: Uncomment these two lines once backend and store are ready, delete mocked ones. */
  /* const platforms = computed(() => store.getters.getPlatforms); */
  /* const isPlatformCreated = computed(() => store.getters.getIsPlatformCreated); */
  const selectedOwner = ref(`${currentUser.value}`);
  const selectedOwnerID = ref();
  const selectedProductFamily = ref("");
  const platformName = ref("");
  const platformBudget = ref("");
  const selectedProduct = ref<number | string>("");
  const selectedPlatform = ref<number | string>("");
  const selectedPlatformID = ref();
  const isProductSelectDisabled = ref(true);

  store.dispatch(FETCH_RELATED_PRODUCT_FAMILIES);
  store.dispatch(FETCH_USERS);

  const submitPlatformForm = async () => {
    if (!selectedOwnerID.value) {
      handleCurrentOwner();
    }
    const payload = {
      name: platformName.value,
      budget: platformBudget.value,
      owner: selectedOwnerID.value,
    };
    store.dispatch(CREATE_PLATFORM, payload);
  };

  const submitProductToPlatformForm = async () => {
    const payload = {
      selectedPlatform: selectedPlatformID.value,
      product: selectedProduct.value,
    };

    store.dispatch(ADD_PRODUCT_TO_PLATFORM, payload);
  };

  const handleSelectOwner = (event: Event) => {
    const user = users.value.filter((p: IUser) => p.username == (event?.target as HTMLSelectElement).value);
    selectedOwnerID.value = Number(user[0].id);
  };

  const handleSelectPlatform = (event: Event) => {
    const platform = platforms.value.filter((p: IPlatform) => p.name == (event?.target as HTMLSelectElement).value);
    selectedPlatformID.value = Number(platform[0].id);
  };

  // If the owner remains the default logged in user
  const handleCurrentOwner = () => {
    const user = users.value.filter((p: IUser) => p.username == selectedOwner.value);
    selectedOwnerID.value = Number(user[0].id);
  };

  const handleSelectProduct = (event: Event) => {
    selectedProduct.value = Number((event?.target as HTMLSelectElement).value);
  };

  const fetchProductsFromPF = async () => {
    await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedProductFamily.value.toString()).then(() => {
      isProductSelectDisabled.value = false;
      selectedProduct.value = "";
    });
  };

  onUnmounted(() => {
    store.commit("setIsPlatformCreated", false);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
          _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Create a platform")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(submitPlatformForm, ["prevent"]),
            "data-testid": "newplatform-submit-form"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { for: "platformname" }, "Name:", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "text",
                  id: "platformname",
                  required: "",
                  maxlength: "32",
                  "data-testid": "newplatform-name",
                  autocomplete: "name",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((platformName).value = $event))
                }, null, 512), [
                  [_vModelText, platformName.value]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': platformName.value.length == 32,
                }])
                }, _toDisplayString(platformName.value.length) + "/32", 3)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { for: "platformbudget" }, "Budget:", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "number",
                  id: "platformbudget",
                  min: "0",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((platformBudget).value = $event)),
                  required: "",
                  "data-testid": "newplatform-budget"
                }, null, 512), [
                  [_vModelText, platformBudget.value]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { for: "owner" }, "Owner:", -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "cst-select-field",
                  id: "owner",
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (handleSelectOwner($event))),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedOwner).value = $event)),
                  required: "",
                  "data-testid": "newplatform--owner"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (owner) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: owner.id,
                      value: owner.username
                    }, _toDisplayString(owner.username), 9, _hoisted_8))
                  }), 128))
                ], 544), [
                  [_vModelSelect, selectedOwner.value]
                ])
              ])
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "cst-inputbox__actions" }, [
              _createElementVNode("div", { class: "cst-inputbox--flex-wrapper" }, [
                _createElementVNode("button", {
                  class: "submit-button cst-button cst-button-primary",
                  type: "submit",
                  "data-testid": "newplatform-create-button"
                }, "Create")
              ])
            ], -1))
          ], 32)
        ])
      ])
    ]),
    (isPlatformCreated.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
              _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Add product to platform")
            ], -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("form", {
                onSubmit: _withModifiers(submitProductToPlatformForm, ["prevent"]),
                "data-testid": "newplatform-submit-product-platform"
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", { for: "platform" }, "Platform:", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "platform",
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (handleSelectPlatform($event))),
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectedPlatform).value = $event)),
                      required: "",
                      "data-testid": "select-platform-product"
                    }, [
                      _cache[14] || (_cache[14] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a platform:", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(platforms.value, (platform) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: platform.id,
                          value: platform.name
                        }, _toDisplayString(platform.name), 9, _hoisted_14))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, selectedPlatform.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", { for: "selectproductfamily" }, "Select product family:", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "selectproductfamily",
                      required: "",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((selectedProductFamily).value = $event)),
                      onChange: fetchProductsFromPF
                    }, [
                      _cache[16] || (_cache[16] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a product family", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productFamily.value, (family) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: family.id,
                          value: family.id,
                          id: "selectedFamilyProduct"
                        }, _toDisplayString(family.name), 9, _hoisted_16))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, selectedProductFamily.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[19] || (_cache[19] = _createElementVNode("label", { for: "platformproduct" }, "Product:", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "platformproduct",
                      onChange: _cache[7] || (_cache[7] = ($event: any) => (handleSelectProduct($event))),
                      required: "",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((selectedProduct).value = $event)),
                      disabled: isProductSelectDisabled.value
                    }, [
                      _cache[18] || (_cache[18] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a Product:", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredProducts.value, (product) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: product.id,
                          value: product.id
                        }, _toDisplayString(product.name), 9, _hoisted_19))
                      }), 128))
                    ], 40, _hoisted_18), [
                      [_vModelSelect, selectedProduct.value]
                    ])
                  ])
                ]),
                _cache[20] || (_cache[20] = _createElementVNode("div", { class: "cst-inputbox__actions" }, [
                  _createElementVNode("div", { class: "cst-inputbox--flex-wrapper" }, [
                    _createElementVNode("button", {
                      class: "submit-button cst-button cst-button-primary",
                      type: "submit",
                      "data-testid": "platform-add-product"
                    }, "Add")
                  ])
                ], -1))
              ], 32)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})