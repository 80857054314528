import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
import { AxiosResponse, isAxiosError } from "axios";

interface ArchiveState {
  archive: IPurchaseRequest[];
  isArchiveFetched: boolean;
}

interface ArchiveContext {
  commit: Commit;
  dispatch: Dispatch;
  state: ArchiveState;
}

const state: ArchiveState = {
  archive: [],
  isArchiveFetched: false,
};

const mutations = {
  setArchive(state: ArchiveState, payload: IPurchaseRequest[]) {
    state.archive = payload;
  },
  setIsArchiveFetched(state: ArchiveState, isFetched: boolean) {
    state.isArchiveFetched = isFetched;
  },
  removeRequest(state: ArchiveState, id: number) {
    const index = state.archive.findIndex((item: IPurchaseRequest) => item.id === id);
    if (index !== -1) {
      state.archive.splice(index, 1);
    }
  },
};

const getters = {
  getArchive: (state: ArchiveState) => state.archive,
  getIsArchiveFetched: (state: ArchiveState) => state.isArchiveFetched,
};

const saveArchiveToState = (context: ArchiveContext, response: AxiosResponse) => {
  const archiveDO: IPurchaseRequest[] = [];
  for (const id in response.data) {
    archiveDO.push({
      id: id,
      ...response.data[id],
    });
  }
  context.commit("setArchive", archiveDO);
  context.commit("setIsArchiveFetched", true);
};

const actions = {
  fetchArchive(context: ArchiveContext) {
    AXIOS_REQUEST("GET", "/archive")
      .then((response) => {
        context.commit("setIsArchiveFetched", false);
        saveArchiveToState(context, response);
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (error.message === "Request failed with status code 404") {
            context.commit("setArchive", []);
            context.commit("setIsArchiveFetched", true);
          } else {
            console.error(error);
            context.commit("setIsArchiveFetched", true);
          }
        } else {
          console.error(error);
          context.commit("setIsArchiveFetched", true);
        }
      });
  },

  deleteRequestFromArchive(context: ArchiveContext, id: number) {
    AXIOS_REQUEST("DELETE", `/archive/${id}`)
      .then(() => {
        context.commit("removeRequest", id);
        context.dispatch("fetchArchive");
        context.dispatch("updateRequestMessage", "Request deleted successfully!");
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error deleting request: ${error}`);
      });
  },

  restoreRequest(context: ArchiveContext, id: number) {
    AXIOS_REQUEST("GET", `/archive/${id}/restore`)
      .then(() => {
        context.commit("removeRequest", id);
        context.dispatch("fetchArchive");
        context.dispatch("updateRequestMessage", "Request restored successfully!");
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error restoring request: ${error}`);
      });
  },
};

const archiveModule = {
  state,
  getters,
  mutations,
  actions,
};

export default archiveModule;
