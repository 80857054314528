<template>
  <RequestMessage></RequestMessage>

  <div class="cst-new-product-type--wrapper" data-testid="createproject-wrapper" v-if="rolesFetched">
    <ProjectApplicationForm :type="'project'" data-testid="createproject-projectappform" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from "vue";
  import ProjectApplicationForm from "../components/forms/C_ProjectApplicationForm.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "NewProduct",
    components: {
      ProjectApplicationForm,
      RequestMessage,
    },
    setup() {
      const { FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
      const store = useStore();
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const router = useRouter();
      const rolesFetched = ref(false);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      if (!userRoles.value.includes("edit-project-application")) {
        rolesFetched.value = false;
        router.push("/");
      } else {
        rolesFetched.value = true;
      }

      return {
        rolesFetched,
      };
    },
  });
</script>
