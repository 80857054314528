import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-cucard__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "c-cucard__name" }
const _hoisted_4 = { class: "c-cucard__summary" }
const _hoisted_5 = {
  key: 0,
  class: "c-cucard__btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BudgetSummary = _resolveComponent("BudgetSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "c-cucard__image",
      src: _ctx.getImageUrl(),
      alt: "placeholder",
      "data-testid": "cucard-image"
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.unit.name + " (" + Number(_ctx.unit.percentage) * 100 + "%)"), 1),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BudgetSummary, {
        size: "small",
        budget: Number(_ctx.unit.totalbudget),
        forecast: Number(_ctx.unit.totalforecast),
        actuals: Number(_ctx.unit.totalactuals)
      }, null, 8, ["budget", "forecast", "actuals"]),
      (!_ctx.hideButtons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "cst-button cst-button-primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleUnitDetailsClick(`orders`))),
              "data-testid": "cucard-podetails-btn"
            }, "PO Details"),
            _createElementVNode("button", {
              type: "button",
              class: "cst-button cst-button-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleUnitDetailsClick(`requests`))),
              "data-testid": "cucard-prdetails-btn"
            }, "PR Details")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}