import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "cst-new-product-type--wrapper",
  "data-testid": "newapp-input-box"
}

import ProjectApplicationForm from "../components/forms/C_ProjectApplicationForm.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { computed } from "vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreateApplication',
  setup(__props) {

  const { FETCH_NOTIFICATIONS } = VUEX_ACTIONS;

  const store = useStore();
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const router = useRouter();
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  if (userRoles.value.length > 0 && !userRoles.value.includes("edit-project-application")) {
    router.push("/");
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ProjectApplicationForm, { type: 'application' })
    ])
  ], 64))
}
}

})