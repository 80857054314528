import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-costunitstatus__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["c-costunitstatus", [_ctx.color]]),
      "data-testid": "costunit-status"
    }, [
      _createTextVNode(_toDisplayString(_ctx.status), 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.formatDate(_ctx.statusdate)), 1)
    ], 2)
  ]))
}