import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cst-new-product-type--wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "cst-inputbox",
  "data-testid": "newprod-input-box"
}
const _hoisted_3 = { class: "cst-inputbox--body" }
const _hoisted_4 = { class: "cst-inputbox__form" }
const _hoisted_5 = { class: "cst-input-field" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "cst-inputbox__actions" }
const _hoisted_17 = { class: "cst-inputbox--flex-wrapper" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  key: 1,
  class: "cst-spinner",
  "data-testid": "newprod-spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!
  const _component_NewProductForm = _resolveComponent("NewProductForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.hasPermission)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
              _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Create a new Product Family")
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("form", {
                onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
                "data-testid": "newprod-submit-form"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", { for: "owner" }, [
                      _createTextVNode("Owner:"),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "owner",
                      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelectOwner($event))),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOwner) = $event)),
                      required: "",
                      disabled: _ctx.submitIsDisabled,
                      "data-testid": "newprod-select-owner"
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (owner) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: owner.id,
                          value: owner.username
                        }, _toDisplayString(owner.username), 9, _hoisted_7))
                      }), 128))
                    ], 40, _hoisted_6), [
                      [_vModelSelect, _ctx.selectedOwner]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", { for: "name" }, [
                      _createTextVNode("Name:"),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "text",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.productFamilyData.name) = $event)),
                      id: "name",
                      required: "",
                      maxlength: "32",
                      disabled: _ctx.submitIsDisabled,
                      "data-testid": "newprod-name",
                      autocomplete: "name"
                    }, null, 8, _hoisted_9), [
                      [_vModelText, _ctx.productFamilyData.name]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': _ctx.productFamilyData.name?.length == 32,
                }])
                    }, _toDisplayString(_ctx.productFamilyData.name?.length ? `${_ctx.productFamilyData.name.length}` : `0`) + "/32", 3)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", { for: "year" }, [
                      _createTextVNode("Year:"),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "number",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.productFamilyData.year) = $event)),
                      id: "year",
                      min: "1900",
                      max: "3000",
                      required: "",
                      disabled: _ctx.submitIsDisabled,
                      "data-testid": "newprod-pf-year"
                    }, null, 8, _hoisted_11), [
                      [_vModelText, _ctx.productFamilyData.year]
                    ])
                  ]),
                  (_ctx.budgetLimitEnabled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[11] || (_cache[11] = _createElementVNode("label", { for: "budgetLimit" }, [
                          _createTextVNode("Budget Limit:"),
                          _createElementVNode("span", { style: {"color":"red"} }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          type: "number",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.productFamilyData.budget_limit) = $event)),
                          id: "budgetLimit",
                          disabled: _ctx.submitIsDisabled,
                          "data-testid": "newprod-pf-budgetLimit",
                          required: true,
                          min: "1"
                        }, null, 8, _hoisted_13), [
                          [_vModelText, _ctx.productFamilyData.budget_limit]
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", { for: "target" }, [
                          _createTextVNode("Target:"),
                          _createElementVNode("span", { style: {"color":"red"} }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          type: "number",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.productFamilyData.target) = $event)),
                          id: "target",
                          disabled: _ctx.submitIsDisabled,
                          "data-testid": "newprod-pf-target",
                          required: true,
                          min: "1"
                        }, null, 8, _hoisted_15), [
                          [_vModelText, _ctx.productFamilyData.target]
                        ])
                      ]))
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("button", {
                      class: "submit-button cst-button cst-button-primary",
                      type: "submit",
                      "data-testid": "newprod-submit-button",
                      disabled: _ctx.submitIsDisabled
                    }, "Submit", 8, _hoisted_18),
                    (_ctx.createdProductFamily)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "submit-button cst-button cst-button-primary",
                          "data-testid": "newprod-new-pf-button",
                          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleNewProductFamily && _ctx.handleNewProductFamily(...args)))
                        }, "New Product Family"))
                      : _createCommentVNode("", true)
                  ])
                ])
              ], 32)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_19)),
      (_ctx.createdProductFamily && _ctx.canCreateProduct())
        ? (_openBlock(), _createBlock(_component_NewProductForm, {
            key: 2,
            preSelectedProductFamily: true,
            hasBudgetLimit: _ctx.productFamilyData.budget_limit !== null && _ctx.productFamilyData.budget_limit !== undefined
          }, null, 8, ["hasBudgetLimit"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}