import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-platformconsumers__wrapper" }
const _hoisted_2 = { class: "v-platformconsumers__products-wrapper" }
const _hoisted_3 = { class: "v-platformconsumers__summary-overview" }
const _hoisted_4 = { class: "v-platformconsumers__summary-overview--title" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "c-budgetsummary__wrapper--platform" }
const _hoisted_8 = { class: "v-platformconsumers__table--wrapper" }
const _hoisted_9 = {
  class: "v-platformconsumers__table--overview",
  "aria-describedby": "Table of platforms"
}
const _hoisted_10 = { colspan: "4" }
const _hoisted_11 = {
  key: 0,
  class: "v-showinvoices__li-table--wrapper"
}
const _hoisted_12 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_13 = { class: "v-showinvoices__li-table-row--first" }
const _hoisted_14 = {
  key: 1,
  class: "v-platformconsumers__no-data"
}
const _hoisted_15 = {
  key: 2,
  class: "v-platformconsumers__li-table--wrapper"
}
const _hoisted_16 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_17 = { class: "v-showinvoices__li-table-row--first" }
const _hoisted_18 = {
  key: 3,
  class: "v-platformconsumers__no-data"
}

import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct } from "@/utils/interfaces/IProductTypes";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_PlatformConsumers',
  setup(__props) {

  const { FETCH_NOTIFICATIONS, FETCH_USERS, FETCH_PROJECTS_IN_PLATFORM, FETCH_APPLICATIONS_IN_PLATFORM, FETCH_ALL_PRODUCTS } = VUEX_ACTIONS;
  const store = useStore();
  const route = useRoute();
  const { platform } = route.query;
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);

  const loggedUser = computed(() => store.getters.getCurrentUser);
  const users = computed(() => store.getters.getUsers);
  const allProducts = computed(() => store.getters.getAllProducts);
  const currencyRate = computed(() => store.getters.getCurrencyRate);

  const projectsInPlatform = computed(() => store.getters.getProjectsInPlatform);
  const applicationsInPlatform = computed(() => store.getters.getApplicationsInPlatform);

  const focusedPlatform = computed(() => {
    let platformObject: IProduct = {
      productfamily: null,
      name: "",
      region: null,
      owner: null,
      platform_cost: null,
    };
    allProducts.value?.filter((product: IProduct) => {
      if (Number(product.id) === Number(platform)) {
        platformObject = product;
      }
    });
    return platformObject;
  });

  const focusedPlatformName = computed(() => {
    let platformName = "Platform Overview";
    allProducts.value?.filter((product: IPlatform) => {
      if (Number(product.id) === Number(platform)) {
        platformName = product.name;
      }
    });
    return platformName;
  });

  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_PROJECTS_IN_PLATFORM, platform);
  store.dispatch(FETCH_APPLICATIONS_IN_PLATFORM, platform);
  store.dispatch(FETCH_ALL_PRODUCTS);

  const convertUsernameFromId = (id: number) => {
    let username = "Unknown";
    users.value.filter((user: IUser) => {
      if (Number(user.id) === id) {
        username = user.username;
      }
    });
    return username;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (focusedPlatformName.value !== 'Platform Overview')
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                  _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Platform:", -1)),
                  _createTextVNode(" " + _toDisplayString(focusedPlatformName.value), 1)
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(focusedPlatformName.value), 1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Platform Cost:", -1)),
              _createTextVNode(" " + _toDisplayString(focusedPlatform.value.platform_cost ? _unref(formatNumber)((focusedPlatform.value.platform_cost * currencyRate.value).toFixed(2)) : " -") + " " + _toDisplayString(selectedCurrency.value), 1)
            ]),
            _createElementVNode("div", null, [
              _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Owner:", -1)),
              _createTextVNode(" " + _toDisplayString(focusedPlatform.value.owner ? convertUsernameFromId(Number(focusedPlatform.value.owner)) : " -"), 1)
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("table", _hoisted_9, [
              _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { class: "v-platformconsumers__table--header" }, "Platform Consumers")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                _cache[7] || (_cache[7] = _createElementVNode("tr", { class: "v-platformconsumers__table-row" }, null, -1)),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", _hoisted_10, [
                    _cache[3] || (_cache[3] = _createElementVNode("strong", null, [
                      _createElementVNode("p", { class: "v-platformconsumers__label" }, "Projects:")
                    ], -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "v-platformconsumers__li-table-header" }, [
                      _createElementVNode("p", { class: "v-platformconsumers__li-table-header--first" }, "No."),
                      _createElementVNode("p", null, "Name"),
                      _createElementVNode("p", null, "Owner"),
                      _createElementVNode("p", null, "Platform Cost")
                    ], -1)),
                    (projectsInPlatform.value.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectsInPlatform.value, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                              (item && typeof item === 'object')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("p", _hoisted_13, _toDisplayString(index + 1), 1),
                                    _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                    _createElementVNode("p", null, _toDisplayString(convertUsernameFromId(Number(item.owner))), 1),
                                    _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.platform_cost * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_14, "There are no projects associated with this platform.")),
                    _cache[5] || (_cache[5] = _createElementVNode("strong", null, [
                      _createElementVNode("p", { class: "v-platformconsumers__label" }, "Applications:")
                    ], -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "v-platformconsumers__li-table-header" }, [
                      _createElementVNode("p", { class: "v-platformconsumers__li-table-header--first" }, "No."),
                      _createElementVNode("p", null, "Name"),
                      _createElementVNode("p", null, "Owner"),
                      _createElementVNode("p", null, "Platform Cost")
                    ], -1)),
                    (applicationsInPlatform.value.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicationsInPlatform.value, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                              (item && typeof item === 'object')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                    _createElementVNode("p", _hoisted_17, _toDisplayString(index + 1), 1),
                                    _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                    _createElementVNode("p", null, _toDisplayString(convertUsernameFromId(Number(item.owner))), 1),
                                    _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.platform_cost * currencyRate.value).toFixed(2)) + " " + item.currency), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_18, "There are no applications associated with this platform."))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})