import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "c-listpodetails__modal-overlay"
}
const _hoisted_2 = {
  class: "c-listpodetails__modal-container",
  "data-testid": "list-po-details-container"
}
const _hoisted_3 = { class: "c-listpodetails__modal-header" }
const _hoisted_4 = {
  class: "c-listpodetails__modal-title",
  "data-testid": "listpo-title"
}
const _hoisted_5 = { class: "c-listpodetails__modal-header-closebtn" }
const _hoisted_6 = { class: "c-listpodetails__modal--details" }
const _hoisted_7 = { class: "c-listpodetails__modal--details-column" }
const _hoisted_8 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_9 = {
  key: 0,
  class: "c-listpodetails__modal--details-item"
}
const _hoisted_10 = { class: "c-listpodetails__modal--details-column" }
const _hoisted_11 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_12 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_13 = { class: "c-listpodetails__modal--details-column" }
const _hoisted_14 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_15 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_16 = { class: "c-listpodetails__modal--details-column" }
const _hoisted_17 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_18 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_19 = { class: "c-listpodetails__modal--details-column" }
const _hoisted_20 = { class: "c-listpodetails__modal--details-item" }
const _hoisted_21 = { class: "c-listpodetails__modal--details" }
const _hoisted_22 = { class: "c-listpodetails__modal--details-comment" }
const _hoisted_23 = { class: "c-requestdetails__container__listitems" }
const _hoisted_24 = { class: "c-requestdetails__container__listitems__listpo-wrapper" }
const _hoisted_25 = { class: "c-requestdetails__container__listitems-table" }
const _hoisted_26 = { "data-testid": "list-po-name" }
const _hoisted_27 = { "data-testid": "list-po-application" }
const _hoisted_28 = { "data-testid": "list-po-quantity" }
const _hoisted_29 = {
  class: "c-listpodetails__modal--details-list-po-amount",
  "data-testid": "list-po-amount"
}
const _hoisted_30 = {
  class: "c-listpodetails__modal--details-list-po-amount",
  "data-testid": "list-po-currentYear"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPODetails)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.selectedPOName) + " - Details", 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Remove list item button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                "data-testid": "list-po-closemodal"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[1] || (_cache[1] = _createElementVNode("h3", null, "PR Owner:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.prownername ? _ctx.selectedItem.prownername : "-"), 1)
              ]),
              (_ctx.supplierNameEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Supplier Name:", -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.suppliername ? _ctx.selectedItem.suppliername : "-"), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Start Date:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.podate ? _ctx.formatDate(_ctx.selectedItem.podate) : "-"), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Delivery Date:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.deliverydate ? _ctx.formatDate(_ctx.selectedItem.deliverydate) : "-"), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Order Amount:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber((_ctx.selectedItem.orderamount ? _ctx.selectedItem.orderamount * _ctx.currencyRate : 0).toFixed(2))), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Currency:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.currency ? _ctx.selectedItem.currency : "-"), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Current Year:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber(Number(_ctx.selectedItem.amountCurrentYear ? _ctx.selectedItem.amountCurrentYear * _ctx.currencyRate : _ctx.selectedItem.orderamount).toFixed(2))), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Next Year:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber(Number(_ctx.selectedItem.amountNextYear ? _ctx.selectedItem.amountNextYear * _ctx.currencyRate : 0).toFixed(2))), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Customer Contact:", -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.customercontact ? _ctx.selectedItem.customercontact : "-"), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Comments:", -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem?.comments ? _ctx.selectedItem.comments : "-"), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("table", _hoisted_25, [
                _cache[11] || (_cache[11] = _createElementVNode("caption", null, [
                  _createElementVNode("h3", { class: "c-requestdetails__container__listitems-title" }, "List Items:")
                ], -1)),
                _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", { class: "c-requestdetails__container__listitems-table-head--listpo" }, [
                    _createElementVNode("th", null, "Position:"),
                    _createElementVNode("th", null, "Name:"),
                    _createElementVNode("th", null, "Product Family:"),
                    _createElementVNode("th", null, "Product:"),
                    _createElementVNode("th", null, "Application:"),
                    _createElementVNode("th", null, "Project:"),
                    _createElementVNode("th", null, "Cost Category:"),
                    _createElementVNode("th", null, "Cost Type:"),
                    _createElementVNode("th", null, "Resource name:"),
                    _createElementVNode("th", null, "Person days:"),
                    _createElementVNode("th", null, "Daily rate:"),
                    _createElementVNode("th", null, "Amount:"),
                    _createElementVNode("th", null, "Current Year:")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedListItems, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      class: "c-requestdetails__container__listitems-table-row",
                      key: index
                    }, [
                      _createElementVNode("td", null, _toDisplayString(item.position), 1),
                      _createElementVNode("td", _hoisted_26, [
                        _createElementVNode("b", null, _toDisplayString(item.name), 1)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(item.productfamilyname), 1),
                      _createElementVNode("td", null, _toDisplayString(item.productname), 1),
                      _createElementVNode("td", _hoisted_27, _toDisplayString(item.applicationname ? item.applicationname : "-"), 1),
                      _createElementVNode("td", null, _toDisplayString(item.projectname ? item.projectname : "-"), 1),
                      _createElementVNode("td", null, _toDisplayString(item.costtype.split(" ")[0]), 1),
                      _createElementVNode("td", null, _toDisplayString(item.costtype.split(" ")[1]), 1),
                      _createElementVNode("td", null, _toDisplayString(item.resourcename), 1),
                      _createElementVNode("td", _hoisted_28, _toDisplayString(item?.quantity && item.quantity > 0 ? item.quantity : ""), 1),
                      _createElementVNode("td", null, _toDisplayString(item.daily_rate), 1),
                      _createElementVNode("td", _hoisted_29, _toDisplayString(_ctx.formatNumber((item.amount ? item.amount * _ctx.currencyRate : 0).toFixed(2)) + " " + _ctx.currency), 1),
                      _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.amount * _ctx.currencyRate).toFixed(2)) + " " + _ctx.currency), 1)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}