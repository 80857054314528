import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "c-costtype-inputs",
  "data-testid": "costtype-inputs-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_3 = {
  class: "cst-input-field",
  "data-testid": "costtype-inputs"
}
const _hoisted_4 = { class: "cst-input-field" }
const _hoisted_5 = { class: "cst-input-field" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = { class: "cst-input-field" }
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = { class: "cst-input-field" }
const _hoisted_12 = { class: "cst-input-field" }
const _hoisted_13 = { class: "cst-input-field" }
const _hoisted_14 = { class: "cst-input-field" }
const _hoisted_15 = {
  key: 0,
  class: "cst-input-field__indicator c-costtype-inputs-max",
  "data-testid": "newprod-input-budget-limit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.undefinedEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[20] || (_cache[20] = _createElementVNode("label", { for: "budget" }, "Undefined:", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "cst-input",
              type: "number",
              id: "undefined",
              placeholder: "Enter Amount",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.costTypeInputs.undef) = $event)),
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
              min: "1",
              step: "any",
              "data-testid": "costtype-input-undef"
            }, null, 544), [
              [_vModelText, _ctx.costTypeInputs.undef]
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _cache[21] || (_cache[21] = _createElementVNode("label", { for: "capitaltotal" }, "Capital:", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "capital-infra",
          placeholder: "Enter Infa. Cost",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.costTypeInputs.bci) = $event)),
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bci"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bci]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "capital-maint",
          placeholder: "Enter Maint. Cost",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.costTypeInputs.bcm) = $event)),
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bcm"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bcm]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "capital-enhanc",
          placeholder: "Enter Enhanc. Cost",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.costTypeInputs.bce) = $event)),
          onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bce"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bce]
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          id: "capitaltotal",
          class: "cst-input",
          type: "number",
          disabled: "",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.sumCapital) = $event))
        }, null, 512), [
          [_vModelText, _ctx.sumCapital]
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_7, [
        _cache[22] || (_cache[22] = _createElementVNode("label", { for: "expensestotal" }, "Expenses:", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "expense-infra",
          placeholder: "Enter Infa. Cost",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.costTypeInputs.bei) = $event)),
          onChange: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bei"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bei]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "expense-maint",
          placeholder: "Enter Maint. Cost",
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.costTypeInputs.bem) = $event)),
          onChange: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bem"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bem]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          type: "number",
          id: "expense-enhanc",
          placeholder: "Enter Enhanc. Cost",
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.costTypeInputs.bee) = $event)),
          onChange: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.emitChange && _ctx.emitChange(...args))),
          min: "1",
          step: "any",
          "data-testid": "costtype-input-bee"
        }, null, 544), [
          [_vModelText, _ctx.costTypeInputs.bee]
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("input", {
          id: "expensestotal",
          class: "cst-input",
          type: "number",
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.sumExpenses) = $event)),
          disabled: ""
        }, null, 512), [
          [_vModelText, _ctx.sumExpenses]
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_11, [
        _cache[23] || (_cache[23] = _createElementVNode("label", { for: "total" }, "Total:", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          id: "total-infra",
          type: "number",
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.sumInfra) = $event)),
          "data-testid": "costtype-suminfra",
          disabled: ""
        }, null, 512), [
          [_vModelText, _ctx.sumInfra]
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          id: "total-maint",
          type: "number",
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.sumMaint) = $event)),
          "data-testid": "costtype-summaint",
          disabled: ""
        }, null, 512), [
          [_vModelText, _ctx.sumMaint]
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          id: "total-enhanc",
          type: "number",
          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.sumEnhanc) = $event)),
          "data-testid": "costtype-sumenhanc",
          disabled: ""
        }, null, 512), [
          [_vModelText, _ctx.sumEnhanc]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _withDirectives(_createElementVNode("input", {
          class: "cst-input",
          id: "total",
          type: "number",
          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.sumTotal) = $event)),
          disabled: ""
        }, null, 512), [
          [_vModelText, _ctx.sumTotal]
        ]),
        (_ctx.availableBudget && Number(_ctx.availableBudget) >= 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
              _cache[24] || (_cache[24] = _createElementVNode("strong", null, "Available amount:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.formatNumber((Number(_ctx.availableBudget) * _ctx.currencyRate).toFixed(2))), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}