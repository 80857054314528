<template>
  <div class="cst-dropdown" data-testid="user-dropdown">
    <div class="cst-dropdown--label" @click="toggleDropdown" data-testid="toggle-dropdown-button">User</div>
    <div class="cst-dropdown--information" v-if="getUserDropdown">
      <div class="cst-dropdown--information--values">
        <div class="cst-dropdown--information--data">
          <div class="cst-dropdown--name">User:</div>
          <div class="cst-dropdown--value" data-testid="user-dropdown-username">
            {{ currentUser }}
          </div>
        </div>
        <div class="cst-dropdown--information--data">
          <div class="cst-dropdown--name">Roles:</div>
          <div class="cst-dropdown--value">
            <div v-for="(value, index) in userGroups" :key="index">
              {{ value }}
            </div>
          </div>
        </div>
      </div>
      <div class="cst-dropdown-logout-btn-wrapper" v-if="canAccessActivityLog">
        <button type="button" class="cst-button cst-button-primary" data-testid="activity-logs-button" @click="handleDownloadLogs">Activity Logs</button>
      </div>
      <div class="cst-dropdown-logout-btn-wrapper">
        <button type="button" class="cst-button cst-button-primary" data-testid="logout-button" @click="logout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted, onUnmounted } from "vue";
  import KeyCloakService from "../security/KeycloakService";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  type EventListener = (event: Event) => void;
  export default defineComponent({
    name: "DropdownComponent",
    setup(props) {
      const { SHOW_NOTIFICATIONS_DROPDOWN, CHANGE_USER_DROPDOWN, DOWNLOAD_LOGS } = VUEX_ACTIONS;
      const store = useStore();
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const userGroups = computed(() => store.getters.getCurrentUserGroups);
      const currentUser = computed(() => store.getters.getCurrentUser);
      const getUserDropdown = computed(() => {
        return store.getters.getShowUserDropdown;
      });

      const canAccessActivityLog = computed(() => {
        return userRoles.value?.includes("edit_archive_history_logdata") ?? false;
      });

      const toggleDropdown = () => {
        store.dispatch(SHOW_NOTIFICATIONS_DROPDOWN, false);
        store.dispatch(CHANGE_USER_DROPDOWN, !getUserDropdown.value);
      };

      const logout = () => {
        KeyCloakService.CallLogout();
      };

      const handleDownloadLogs = () => {
        store.dispatch(DOWNLOAD_LOGS);
      };

      const handleClickOutside = (event: PointerEvent) => {
        const dropdown = document.querySelector(".cst-dropdown");
        const isClickedOutsideDropdown = dropdown && !(event.target as Element).matches(".cst-dropdown--label") && !(event.target as Element).closest(".cst-dropdown--information");

        if (isClickedOutsideDropdown) {
          store.dispatch(CHANGE_USER_DROPDOWN, false);
        }
      };

      onMounted(() => {
        document.addEventListener("click", handleClickOutside as EventListener);
      });

      onUnmounted(() => {
        document.removeEventListener("click", handleClickOutside as EventListener);
      });

      return {
        props,
        toggleDropdown,
        handleDownloadLogs,
        logout,
        currentUser,
        userRoles,
        getUserDropdown,
        userGroups,
        canAccessActivityLog,
      };
    },
  });
</script>
