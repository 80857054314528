import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IProjectApplication } from "@/utils/interfaces/IProductTypes";

//TO-DO: Define projects/application in platform interface
interface PlatformState {
  projectsInPlatform: Array<IProjectApplication>;
  applicationsInPlatform: Array<IProjectApplication>;
}

interface ProductsContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: PlatformState = {
  projectsInPlatform: [],
  applicationsInPlatform: [],
};

const mutations = {
  setProjectsInPlatform(state: PlatformState, payload: IProjectApplication[]) {
    state.projectsInPlatform = payload;
  },
  setApplicationsInPlatform(state: PlatformState, payload: IProjectApplication[]) {
    state.applicationsInPlatform = payload;
  },
};

const getters = {
  getProjectsInPlatform: (state: PlatformState) => state.projectsInPlatform,
  getApplicationsInPlatform: (state: PlatformState) => state.applicationsInPlatform,
};

const actions = {
  async addProjectToPlatform(
    context: ProductsContext,
    payload: {
      platformId: number;
      projectId: number;
    }
  ) {
    AXIOS_REQUEST("POST", `/products/platformProj`, {
      platform: payload.platformId,
      project: payload.projectId,
    })
      .then((response) => {
        context.dispatch("updateRequestMessage", `Successfully added consumer to platform`);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.message === "The project is already in this platform.") {
          context.dispatch("updateRequestMessage", `Error: Project has already been added!`);
        }
      });
  },

  async fetchProjectsInPlatform(context: ProductsContext, platformId: number) {
    const projectsInPlatform: IProjectApplication[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/products/platforms/${platformId}/projects`);
      for (const id in response.data) {
        projectsInPlatform.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setProjectsInPlatform", projectsInPlatform);
    } catch (error) {
      context.commit("setProjectsInPlatform", projectsInPlatform);
      console.error(error);
    }
  },
  async fetchApplicationsInPlatform(context: ProductsContext, platformId: number) {
    const applicationsInPlatform: IProjectApplication[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/products/platforms/${platformId}/applications`);
      for (const id in response.data) {
        applicationsInPlatform.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setApplicationsInPlatform", applicationsInPlatform);
    } catch (error) {
      context.commit("setApplicationsInPlatform", applicationsInPlatform);
      console.error(error);
    }
  },
  async addApplicationToPlatform(
    context: ProductsContext,
    payload: {
      platformId: number;
      applicationId: number;
    }
  ) {
    AXIOS_REQUEST("POST", `/products/platformApp`, {
      platform: payload.platformId,
      application: payload.applicationId,
    })
      .then((response) => {
        context.dispatch("updateRequestMessage", `Successfully added consumer to platform`);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.message === "The application is already in this platform.") {
          context.dispatch("updateRequestMessage", `Error: Application has already been added!`);
        }
      });
  },
  async updatePlatformCost(
    context: ProductsContext,
    payload: {
      platformId: number;
      cost: number;
    }
  ) {
    AXIOS_REQUEST("PUT", `/products/${payload.platformId}`, {
      platform_cost: payload.cost,
    })
      .then((response) => {
        context.dispatch("updateRequestMessage", `Successfully modified platform`);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.message) {
          context.dispatch("updateRequestMessage", `${error.response.data.message}`);
        }
      });
  },
};

const productsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default productsModule;
