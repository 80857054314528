import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-productnextyearchart__container"
}
const _hoisted_2 = { class: "c-productnextyearchart__wrapper" }
const _hoisted_3 = { class: "c-productnextyearchart__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.chartData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Bar, {
              id: "p-nextyear-chart",
              options: _ctx.chartOptions,
              data: _ctx.chartData
            }, null, 8, ["options", "data"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, "Proposed " + _toDisplayString(_ctx.financialGoalLabel), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Proposed Forecast", -1)),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "PO Next Year", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}