import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
import { calculateRemainingAmountPO } from "./calculateRemainingAmountPO";

const sorting = {
  column: "",
  order: "asc",
};

export const sortByColumn = (column: string, orders: IUserOrders[]) => {
  if (column === "orderamount") {
    if (sorting.column === column) {
      sorting.order = sorting.order === "asc" ? "desc" : "asc";
    } else {
      sorting.column = column;
      sorting.order = "asc";
    }
    orders.sort((a: IUserOrders, b: IUserOrders) => {
      const aValue = a.orderamount;
      const bValue = b.orderamount;
      return sorting.order === "asc" ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
    });
  } else if (column === "2023capital") {
    if (sorting.column === column) {
      sorting.order = sorting.order === "asc" ? "desc" : "asc";
    } else {
      sorting.column = column;
      sorting.order = "asc";
    }
    orders.sort((a: IUserOrders, b: IUserOrders) => {
      const aValue = a.application === null ? a.projectcapital : a.applicationcapital;
      const bValue = b.application === null ? b.projectcapital : b.applicationcapital;
      return sorting.order === "asc" ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
    });
  } else if (column === "2023expense") {
    if (sorting.column === column) {
      sorting.order = sorting.order === "asc" ? "desc" : "asc";
    } else {
      sorting.column = column;
      sorting.order = "asc";
    }
    orders.sort((a: IUserOrders, b: IUserOrders) => {
      const aValue = a.application === null ? a.projectexpenses : a.applicationexpenses;
      const bValue = b.application === null ? b.projectexpenses : b.applicationexpenses;
      return sorting.order === "asc" ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
    });
  } else if (column === "remainingamount") {
    if (sorting.column === column) {
      sorting.order = sorting.order === "asc" ? "desc" : "asc";
    } else {
      sorting.column = column;
      sorting.order = "asc";
    }
    orders.sort((a: IUserOrders, b: IUserOrders) => {
      const aValue = calculateRemainingAmountPO(a);
      const bValue = calculateRemainingAmountPO(b);
      return sorting.order === "asc" ? aValue - bValue : bValue - aValue;
    });
  } else {
    // Default sorting logic for other columns
    if (sorting.column === column) {
      sorting.order = sorting.order === "asc" ? "desc" : "asc";
    } else {
      sorting.column = column;
      sorting.order = "asc";
    }
    // Sort the orders array
    orders.sort((firstVal, secondVal) => {
      const aValue = firstVal[column as keyof IUserOrders];
      const bValue = secondVal[column as keyof IUserOrders];
      if (aValue && bValue) {
        return sorting.order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  }
};
