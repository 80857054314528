<template>
  <RequestMessage></RequestMessage>
  <div class="cst-new-product-type--wrapper" data-testid="newapp-input-box">
    <ProjectApplicationForm :type="'application'" />
  </div>
</template>

<script setup lang="ts">
  import ProjectApplicationForm from "../components/forms/C_ProjectApplicationForm.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { computed } from "vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  const { FETCH_NOTIFICATIONS } = VUEX_ACTIONS;

  const store = useStore();
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const router = useRouter();
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  if (userRoles.value.length > 0 && !userRoles.value.includes("edit-project-application")) {
    router.push("/");
  }
</script>
