<template>
  <RequestMessage></RequestMessage>
  <div v-if="!arePlatformsFetched" class="cst-spinner"></div>
  <div v-else-if="areProductsFetched" class="v-manageplatform__container">
    <div class="v-manageplatform__box--wrapper">
      <div class="v-manageplatform__title">Manage platform: {{ selectedPlatform }}</div>
      <form @submit.prevent>
        <div class="v-manageplatform__body">
          <div class="section__line-with-text">
            <span class="section__title">Platform Details</span>
          </div>
          <div class="v-manageplatform__body--manage">
            <div><strong>Platform name:</strong> {{ selectedPlatform }}</div>
            <div><strong>Platform owner:</strong> {{ platformOwner }}</div>
            <div class="cst-input-field">
              <div class="cst-input-field">
                <label for="platform-cost">Platform cost:</label>
                <input
                  class="cst-input v-createcu--formwrapper--inputfield"
                  type="number"
                  :placeholder="formatNumber((platformObject?.platform_cost * currencyRate).toFixed(2))"
                  id="platform-cost"
                  data-testid="manage-platform-platform-cost"
                  v-model="platformCost"
                />
              </div>
            </div>
            <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" @click="handleUpdatePlatformCost" :disabled="platformCost == '' || Number(platformCost) < 0" type="button">Update cost</button>
          </div>
          <div class="section__line-with-text">
            <span class="section__title">Allocate Customer</span>
          </div>
          <div class="v-manageplatform__body--allocate">
            <div class="cst-input-field">
              <label for="productfamily">Select product family</label>
              <select class="cst-select-field" required v-model="selectedProductFamily" @change="handleSelectUserFamily">
                <option disabled selected value="">Select a product family</option>
                <option v-for="family in productFamily" :key="family.id" :value="family.id" id="selectedFamilyProduct">
                  {{ family.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="product">Select existing product</label>
              <select class="cst-select-field" required v-model="selectedProduct" :disabled="isProductDropdownDisabled" @change="handleSelectProduct">
                <option disabled selected value="">Select a product</option>
                <option v-for="product in products" :key="product.id" :value="product.id" id="selectedProduct">
                  {{ product.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="project">Project:</label>
              <select class="cst-select-field" v-model="selectedProject" :disabled="projects.length === 0 || selectedProduct == ''" @change="handleSelectProject" data-testid="create-request-project-dropdown">
                <option disabled selected value="">Select a Project</option>
                <option v-for="project in projects" :key="project.id" :value="project.id">
                  {{ project.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="application">Application:</label>
              <select class="cst-select-field" v-model="selectedApplication" :disabled="applications.length === 0 || selectedProduct == ''" @change="handleSelectApplication" data-testid="create-request-application-dropdown">
                <option disabled selected value="">Select an Application</option>
                <option v-for="application in applications" :key="application.id" :value="application.id">
                  {{ application.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="cst-inputbox__actions--row-wrapper__spacebetween">
          <div class="cst-inputbox__actions--row-wrapper__smallgap">
            <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" type="submit" @click="handleAddConsumer">Add Consumer</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { computed, defineComponent, ref, watch } from "vue";
  import { useRoute, useRouter, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
  import { useStore } from "vuex";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";

  export default defineComponent({
    name: "ManagePlatform",
    components: {
      RequestMessage,
    },
    beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      if (to.query.platform) {
        next();
      } else {
        next("/");
      }
    },
    setup() {
      const { FETCH_ALL_PRODUCT_FAMILIES, FETCH_PRODUCTS_IN_PROD_FAMILY, FETCH_NOTIFICATIONS, FETCH_APP_IN_PRODUCT, FETCH_PROJECTS_IN_PRODUCT, ADD_PROJECT_TO_PLATFORM, ADD_APPLICATION_TO_PLATFORM, FETCH_ALL_PRODUCTS, FETCH_USERS, UPDATE_PLATFORM_COST } =
        VUEX_ACTIONS;
      const route = useRoute();
      const router = useRouter();
      const { platform } = route.query;
      const store = useStore();
      const users = computed(() => store.getters.getUsers);
      const currencyRate = computed(() => store.getters.getCurrencyRate);
      const productFamily = computed(() =>
        (store.getters.getAllProductFamilies || [])
          .filter((item: IProductFamily) => !item.name.toLowerCase().includes("platform"))
          .slice()
          .sort((a: IProductFamily, b: IProductFamily) => a.name.localeCompare(b.name))
      );
      const products = computed(() => (store.getters.getProducts || []).slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));
      const allProducts = computed(() => store.getters.getAllProducts);
      const platformProductId = computed(() => {
        const matchingProduct = allProducts?.value.find((product: IProduct) => {
          return product.name === selectedPlatform.value;
        });

        return matchingProduct ? matchingProduct.id : null;
      });
      const applications = computed(() => (store.getters.getProductApplications || []).slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));
      const projects = computed(() => (store.getters.getProductProjects || []).slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));
      const selectedProductFamily = ref("");
      const selectedUserFamily = ref("");
      const selectedProduct = ref("");
      const selectedNewProduct = ref("");
      const fetchedSuccessfully = ref(false);
      const saveProductBtnDisabled = ref(true);
      const allocateProductBtnDisabled = ref(true);
      const isProductDropdownDisabled = ref(true);
      const deleteProductBtnDisabled = ref(true);
      const changeBtnDisabled = ref(true);
      const isProjectSelected = ref(false);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      const selectedApplication = ref("");
      const selectedProject = ref("");
      const arePlatformsFetched = ref(false);
      const selectedPlatform = ref(platform || "");
      const areProductsFetched = computed(() => store.getters.getAreProductsFetched);
      const platformObject = computed(() => {
        const matchingProduct = allProducts.value?.find((product: IProduct) => {
          return product.name === selectedPlatform.value;
        });

        return matchingProduct || null;
      });
      const platformOwner = computed(() => {
        const matchingUser = users?.value.find((user: IUser) => {
          return user.id === platformObject.value?.owner;
        });
        return matchingUser?.username || null;
      });

      const platformCost = ref("");
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      store.dispatch(FETCH_ALL_PRODUCT_FAMILIES, `?name=${platform}`);
      store.dispatch(FETCH_ALL_PRODUCTS);
      store.dispatch(FETCH_USERS);

      if (allProducts.value && (platformObject.value == null || platformObject.value == undefined)) {
        router.push("/");
      } else {
        arePlatformsFetched.value = true;
      }

      const handleAddConsumer = async () => {
        if (Number(selectedProject.value) !== 0) {
          await store.dispatch(ADD_PROJECT_TO_PLATFORM, {
            platformId: Number(platformProductId.value),
            projectId: Number(selectedProject.value),
          });
        } else {
          await store.dispatch(ADD_APPLICATION_TO_PLATFORM, {
            platformId: Number(platformProductId.value),
            applicationId: Number(selectedApplication.value),
          });
        }
      };

      const handleSelectUserFamily = async (event: Event) => {
        selectedUserFamily.value = (event.target as HTMLSelectElement).value;
        await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedUserFamily.value);
        selectedProduct.value = "";
        selectedProject.value = "";
        selectedApplication.value = "";
      };

      const handleSelectProduct = async (event: Event) => {
        selectedProduct.value = (event.target as HTMLSelectElement).value;
        await store.dispatch(FETCH_APP_IN_PRODUCT, selectedProduct.value);
        await store.dispatch(FETCH_PROJECTS_IN_PRODUCT, selectedProduct.value);
        selectedApplication.value = "";
        selectedProject.value = "";
      };

      const handleSelectProject = (event: Event) => {
        selectedProject.value = (event.target as HTMLSelectElement).value;
        selectedApplication.value = "";
      };

      const handleSelectApplication = (event: Event) => {
        selectedApplication.value = (event.target as HTMLSelectElement).value;
        selectedProject.value = "";
      };

      const handleUpdatePlatformCost = async () => {
        const payload = {
          platformId: platformProductId.value,
          cost: platformCost.value,
        };
        await store.dispatch(UPDATE_PLATFORM_COST, payload);
        setTimeout(() => store.dispatch(FETCH_ALL_PRODUCTS), 1000);
      };

      watch([selectedProductFamily], () => {
        isProductDropdownDisabled.value = selectedProductFamily.value == "";
      });

      watch([selectedProject, selectedApplication], () => {
        if (selectedProject.value !== "" && selectedApplication.value === "") {
          isProjectSelected.value = true;
          selectedApplication.value = "";
        } else if (selectedProject.value === "" && selectedApplication.value !== "") {
          isProjectSelected.value = false;
        }
      });

      watch([allProducts], () => {
        if (allProducts.value && (platformObject.value == null || platformObject.value == undefined)) {
          router.push("/");
        }
      });

      return {
        platform,
        users,
        products,
        changeBtnDisabled,
        fetchedSuccessfully,
        selectedProduct,
        selectedNewProduct,
        saveProductBtnDisabled,
        allocateProductBtnDisabled,
        deleteProductBtnDisabled,
        productFamily,
        selectedProductFamily,
        handleSelectUserFamily,
        handleSelectProduct,
        handleSelectApplication,
        handleSelectProject,
        isProductDropdownDisabled,
        handleAddConsumer,
        applications,
        projects,
        selectedApplication,
        selectedProject,
        isProjectSelected,
        selectedPlatform,
        platformProductId,
        platformObject,
        handleUpdatePlatformCost,
        platformCost,
        platformOwner,
        arePlatformsFetched,
        areProductsFetched,
        currencyRate,
        formatNumber,
      };
    },
  });
</script>
