export const getLastDayOfMonth = (dateString: string) => {
  const inputDate = new Date(dateString);

  const year = inputDate.getFullYear();
  const month = inputDate.getMonth();

  /* We pass next month and then use 0 to get the last day of previous month which is the one we need */
  const lastDay = new Date(year, month + 1, 0);

  const formattedDate = `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;

  return formattedDate;
};
