interface ConfigurationFile {
  app_name: string;
  logo: string;
  cost_units_enabled: true;
  css_color_text_primary: string;
  css_color_text_secondary: string;
  css_color_text_tertiary: string;
  css_color_text_link: string;
  css_color_text_disabled: string;
  css_color_text_header: string;
  css_color_text_footer: string;
  css_color_header: string;
  css_color_footer: string;
  css_color_subheader: string;
  css_color_body: string;
  css_color_primary: string;
  id: number;
  invoice_importer_enabled: boolean;
  purchase_request_archive_enabled: boolean;
}

export const generateTheme = (configuration: ConfigurationFile): string => {
  return `
  :root {
    /*************** COLORS *****************/

    /* text colors */
    --color-text-primary: ${configuration.css_color_text_primary};
    --color-text-secondary: ${configuration.css_color_text_secondary};
    --color-text-tertiary: ${configuration.css_color_text_tertiary};
    --color-text-links: ${configuration.css_color_text_link};
    --color-text-disabled: ${configuration.css_color_text_disabled};
    --color-text-header: ${configuration.css_color_text_header};
    --color-text-footer: ${configuration.css_color_text_footer};

    /* primary colors */
    --color-headers: ${configuration.css_color_header};
    --color-footer: ${configuration.css_color_footer};
    --color-subheaders: ${configuration.css_color_subheader};
    --color-body: ${configuration.css_color_body};
    --color-primary: ${configuration.css_color_primary};

    /* primary charts colors */
    --color-chart--green: #65ac1e;
    --color-chart--yellow: #f3d700;
    --color-chart--orange: #f39500;
    --color-chart--red: #d0021b;
    --color-chart--blue: #21a0d2;
    --color-chart--darkgray: #7c7c7c;
    --color-chart--lightgray: #dcdcdc;

    /* primary conditional colors */
    --color-positive: #63ac1e;
    --color-negative: #d0021b;
    --color-warning: #f39500;

    /* primary modals colors */
    --color-modal--white: #f1f1f1;
    --color-modal--lightgray: #e9e9e9;

    /* card colors */
    --color-card-light-green: #f5f8f1;
    --color-card-mid-green: #e6e9df;

    /* COLOR PALLETE'S (from lighter to darker) */

    /* red color pallete */
    --color-red-1: #d0021b;
    --color-red-2: #8c0b0b;

    /* yellow color pallete */
    --color-yellow-1: #fef18f;
    --color-yellow-2: #f3d700;

    /* orange color pallete */
    --color-orange-1: #f9ca7f;
    --color-orange-2: #f3b800;
    --color-orange-3: #f39500;
    --color-orange-4: #b16427;

    /* blue color pallete */
    --color-blue-1: #d2ecf6;
    --color-blue-2: #90cfe8;
    --color-blue-3: #21a0d2;
    --color-blue-4: #004a96;

    /* green color pallete */
    --color-green-1: #84bd4b;
    --color-green-2: #65ac1e;
    --color-green-3: #00793a;

    /* gray color pallete */
    --color-gray-1: #f0f0f0;
    --color-gray-2: #dcdcdc;
    --color-gray-3: #a3a3a3;
    --color-gray-4: #7c7c7c;
    --color-gray-5: #333333;

    /*************** FONT SIZES *****************/

    --font-xxs:  10px;
    --font-xs:  12px;
    --font-sm:  14px;
    --font-md:  16px;
    --font-lg:  18px;
    --font-xl:  20px;
    --font-2xl:  22px;
    --font-3xl:  30px;

    /*************** SHADOWS *****************/

    --shadow-light: 2px 4px 6px rgba(0, 0, 0, 0: 2);

    /*************** BORDER RADIUS *****************/
    --border-radius-small:  6px;

    /*************** MARGINS & PADDING *****************/
    --margin-padding-small: 6px;
    --margin-padding-medium: 20px;
    --margin-padding-large: 50px;

  `;
};

export const generateDefaultTheme = (): string => {
  return `
  :root {
    /*************** COLORS *****************/

    /* text colors */
    --color-text-primary: #212121;
    --color-text-secondary: #606060;
    --color-text-tertiary: #949494;
    --color-text-links: #3367d6;
    --color-text-disabled: #aaaaaa;
    --color-text-header: #000000;
    --color-text-footer: #ffffff;

    /* primary colors */
    --color-headers: #ffffff;
    --color-footer: #d3021a;
    --color-subheaders: #5d9fc7;
    --color-body: #333333;
    --color-primary: #d3021a;

    /* primary charts colors */
    --color-chart--green: #65ac1e;
    --color-chart--yellow: #f3d700;
    --color-chart--orange: #f39500;
    --color-chart--red: #d0021b;
    --color-chart--blue: #21a0d2;
    --color-chart--darkgray: #7c7c7c;
    --color-chart--lightgray: #dcdcdc;

    /* primary conditional colors */
    --color-positive: #63ac1e;
    --color-negative: #d0021b;
    --color-warning: #f39500;

    /* primary modals colors */
    --color-modal--white: #f1f1f1;
    --color-modal--lightgray: #e9e9e9;

    /* card colors */
    --color-card-light-green: #f5f8f1;
    --color-card-mid-green: #e6e9df;

    /* COLOR PALLETE'S (from lighter to darker) */

    /* red color pallete */
    --color-red-1: #d0021b;
    --color-red-2: #8c0b0b;

    /* yellow color pallete */
    --color-yellow-1: #fef18f;
    --color-yellow-2: #f3d700;

    /* orange color pallete */
    --color-orange-1: #f9ca7f;
    --color-orange-2: #f3b800;
    --color-orange-3: #f39500;
    --color-orange-4: #b16427;

    /* blue color pallete */
    --color-blue-1: #d2ecf6;
    --color-blue-2: #90cfe8;
    --color-blue-3: #21a0d2;
    --color-blue-4: #004a96;

    /* green color pallete */
    --color-green-1: #84bd4b;
    --color-green-2: #65ac1e;
    --color-green-3: #00793a;

    /* gray color pallete */
    --color-gray-1: #f0f0f0;
    --color-gray-2: #dcdcdc;
    --color-gray-3: #a3a3a3;
    --color-gray-4: #7c7c7c;
    --color-gray-5: #333333;

    /*************** FONT SIZES *****************/

    --font-xxs:  10px;
    --font-xs:  12px;
    --font-sm:  14px;
    --font-md:  16px;
    --font-lg:  18px;
    --font-xl:  20px;
    --font-2xl:  22px;
    --font-3xl:  30px;

    /*************** SHADOWS *****************/

    --shadow-light: 2px 4px 6px rgba(0, 0, 0, 0: 2);

    /*************** BORDER RADIUS *****************/
    --border-radius-small:  6px;

    /*************** MARGINS & PADDING *****************/
    --margin-padding-small: 6px;
    --margin-padding-medium: 20px;
    --margin-padding-large: 50px;
  `;
};
