<template>
  <div v-if="isModalOpened" class="c-notificationmodal__overlay">
    <div class="c-notificationmodal__container">
      <button @click="closeModal" class="c-notificationmodal__container-button-close cst-button-close" data-testid="close-modal">X</button>

      <p class="c-notificationmodal__container-title" data-testid="notification-modal-title">
        {{ getCurrentNotification.title }}
      </p>
      <div class="c-notificationmodal__container-content">
        <div class="c-notificationmodal__container-content-message" data-testid="notification-modal-message">
          {{ getCurrentNotification.message }}
          <div v-if="getCurrentNotification.message.includes('Please sign the purchase request')">
            You can view the list of open purchase requests
            <a href="/requests" class="c-notificationmodal__container-content-message--link">here</a>.
          </div>
        </div>
      </div>

      <div class="c-notificationmodal__container-action-row">
        <p class="c-notificationmodal__container-date" data-testid="notification-modal-date">
          {{ formatDate(getCurrentNotification.date) }}
        </p>
        <button class="c-notificationmodal__container-button-delete cst-button-primary" @click="deleteNotification(getCurrentNotification.id)" data-testid="notification-modal-delete">Delete</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, computed } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatDate } from "@/utils/helpers/formatDate";

  export default defineComponent({
    name: "notificationmodal",
    components: {},
    setup() {
      const { SHOW_NOTIFICATIONS_MODAL, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
      const store = useStore();
      const isModalOpened = computed(() => store.getters.getShowNotificationModal);
      const loggedUser = computed(() => store.getters.getCurrentUser);

      const closeModal = () => {
        store.dispatch(SHOW_NOTIFICATIONS_MODAL, false);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      const getCurrentNotification = computed(() => {
        return store.getters.getCurrentNotification;
      });

      const deleteNotification = (id: number) => {
        store.dispatch("deleteNotification", id);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
        closeModal();
      };

      return {
        isModalOpened,
        closeModal,
        getCurrentNotification,
        deleteNotification,
        formatDate,
      };
    },
  });
</script>
