import KeyCloakService from "@/security/KeycloakService";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";

const CST_BACKEND_URL = process.env.VUE_APP_CST_BACKEND_URL || "http://localhost:8081";

// Intercept CORS and network error and logout user when status = 0
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.request.status === 401 || error.request.status === 0) {
      KeyCloakService.CallLogout();
    }
    return Promise.reject(error);
  }
);

const AXIOS_REQUEST = (
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  searchString: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any
): Promise<AxiosResponse> => {
  const token = store.getters.getToken;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (method === "GET") {
    return axios.get(CST_BACKEND_URL + searchString, config);
  }
  if (method === "POST") {
    return axios.post(CST_BACKEND_URL + searchString, body || {}, config);
  }
  if (method === "PUT") {
    return axios.put(CST_BACKEND_URL + searchString, body || {}, config);
  }
  if (method === "DELETE") {
    config.data = body || {};
    return axios.delete(CST_BACKEND_URL + searchString, config);
  }
  if (method === "PATCH") {
    return axios.patch(CST_BACKEND_URL + searchString, body || {}, config);
  }
  return Promise.reject(new Error("Unsupported method"));
};

export { AXIOS_REQUEST, CST_BACKEND_URL };
