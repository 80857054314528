import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface NotificationsState {
  notifications: string[];
  currentNotification: [];
  showNotifications: boolean;
}

const state: NotificationsState = {
  notifications: [],
  currentNotification: [],
  showNotifications: false,
};

interface NotificationsContext {
  commit: Commit;
  dispatch: Dispatch;
  state: NotificationsState;
}

const mutations = {
  setNotifications(state: NotificationsState, payload: string[]) {
    state.notifications = payload;
  },
  setCurrentNotification(state: NotificationsState, payload: []) {
    state.currentNotification = payload;
  },
  setShowNotifications(state: NotificationsState, payload: boolean) {
    state.showNotifications = payload;
  },
};

const getters = {
  getNotifications: (state: NotificationsState) => state.notifications,
  getCurrentNotification: (state: NotificationsState) => state.currentNotification,
  getShowNotifications: (state: NotificationsState) => state.showNotifications,
};

/* TO:DO MAYBE Username should be sent instead of ID */
const actions = {
  fetchNotifications(context: NotificationsContext, usernameid: number) {
    const notifications: string[] = [];
    if (usernameid) {
      AXIOS_REQUEST("GET", `/notifications/${usernameid}`)
        .then((response) => {
          if (response.data.length > 0) {
            for (const notification in response.data) {
              notifications.push({
                notification,
                ...response.data[notification],
              });
            }
          }
          context.commit("setNotifications", notifications);
        })
        .catch((error) => {
          console.error(error);
          context.commit("setNotifications", notifications);
        });
    }
  },

  markNotificationAsRead(context: NotificationsContext, payload: { id: number; usernameid: number }) {
    AXIOS_REQUEST("POST", `/notifications/seen/${payload.id}`)
      .then(() => context.dispatch("fetchNotifications", payload.usernameid))
      .catch((error) => {
        console.error(error);
      });
  },

  deleteNotification(context: NotificationsContext, payload: { id: number; usernameid: number }) {
    AXIOS_REQUEST("DELETE", `/notifications/${payload.id}`)
      .then(() => context.dispatch("fetchNotifications", payload.usernameid))
      .catch((error) => {
        console.error(error);
      });
  },

  defineCurrentNotification(context: NotificationsContext, payload: [id: number, title: string, message: string, date: Date]) {
    context.commit("setCurrentNotification", payload);
  },

  showNotificationsDropdown(context: NotificationsContext, show: boolean) {
    context.commit("setShowNotifications", show);
  },
};

const notificationsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default notificationsModule;
