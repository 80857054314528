import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search__wrapper" }
const _hoisted_2 = ["placeholder"]


  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_Search',
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(__props) {

  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "search__input",
      type: "text",
      id: "search-input",
      required: "",
      maxlength: "40",
      "data-testid": "search",
      placeholder: props.placeholder
    }, null, 8, _hoisted_2)
  ]))
}
}

})