<template>
  <footer>
    <div class="c-footer" data-testid="footer-wrapper">
      <div class="c-footer__wrapper">
        <div class="c-footer__wrapper__app-name">
          <h2 data-testid="footer-application-name">{{ applicationName }}</h2>
        </div>

        <div class="c-footer__wrapper__version">
          <p data-testid="footer-application-version">Version | {{ applicationVersion }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
  import { computed, defineComponent } from "vue";
  export default defineComponent({
    props: ["appName", "appVersion"],
    setup(props) {
      const applicationName = computed(() => {
        if (props.appName) {
          return props.appName;
        } else {
          return "Missing Configuration";
        }
      });
      const applicationVersion = computed(() => {
        if (props.appVersion) {
          return props.appVersion;
        } else {
          return "1.0.0";
        }
      });
      return { applicationName, applicationVersion };
    },
  });
</script>
