import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";

export const calculateRemainingAmountPO = (item: IUserOrders): number => {
  const poAmount = item.orderamount;
  const capitalCommitted = parseFloat(item.application === null ? item.projectcapital : item.applicationcapital);
  const expenseCommitted = parseFloat(item.application === null ? item.projectexpenses : item.applicationexpenses);

  const remainingAmount = poAmount - (capitalCommitted + expenseCommitted);

  return remainingAmount;
};
