import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-header" }
const _hoisted_2 = {
  class: "c-header__wrapper",
  "data-testid": "header-wrapper"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-header__app-name" }
const _hoisted_5 = { "data-testid": "application-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "c-header__logo",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateHome && _ctx.navigateHome(...args))),
          "data-testid": "header-logo"
        }, [
          _createElementVNode("img", {
            src: _ctx.applicationLogo,
            alt: "Company logo",
            "data-testid": "application-logo"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.applicationName), 1)
        ])
      ])
    ])
  ]))
}