import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "c-footer",
  "data-testid": "footer-wrapper"
}
const _hoisted_2 = { class: "c-footer__wrapper" }
const _hoisted_3 = { class: "c-footer__wrapper__app-name" }
const _hoisted_4 = { "data-testid": "footer-application-name" }
const _hoisted_5 = { class: "c-footer__wrapper__version" }
const _hoisted_6 = { "data-testid": "footer-application-version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.applicationName), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, "Version | " + _toDisplayString(_ctx.applicationVersion), 1)
        ])
      ])
    ])
  ]))
}