import { IProduct, IProductCUAllocation } from "@/utils/interfaces/IProductTypes";
import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface ProductsState {
  product: IProduct[];
  products: IProduct[];
  createdProduct: IProduct[];
  allProducts: IProduct[] | null;
  ownedProducts: IProduct[];
  remainingBudget: number | null;
  productCUAllocation: IProductCUAllocation;
  productCuAllocationFetched: boolean;
}

interface ProductsContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: ProductsState = {
  product: [],
  products: [],
  createdProduct: [],
  allProducts: null,
  ownedProducts: [],
  remainingBudget: null,
  productCUAllocation: {
    costUnits: [],
    totalPercentageSum: 0,
  },
  productCuAllocationFetched: false,
};

const mutations = {
  setProduct(state: ProductsState, payload: IProduct[]) {
    state.product = payload;
  },
  setProducts(state: ProductsState, payload: IProduct[]) {
    state.products = payload;
  },
  setAllProducts(state: ProductsState, payload: IProduct[]) {
    state.allProducts = payload;
  },
  updateAllProducts(state: ProductsState, payload: IProduct) {
    state.allProducts?.push(payload);
  },
  setCreatedProduct(state: ProductsState, payload: IProduct[]) {
    state.createdProduct = payload;
  },
  setOwnedProducts(state: ProductsState, payload: IProduct[]) {
    state.ownedProducts = payload;
  },
  setProductRemainingBudget(state: ProductsState, payload: number) {
    state.remainingBudget = payload;
  },
  setProductCuAllocation(state: ProductsState, payload: IProductCUAllocation) {
    state.productCUAllocation = payload;
  },
  setProductCuAllocationFetched(state: ProductsState, payload: boolean) {
    state.productCuAllocationFetched = payload;
  },
};

const getters = {
  getProduct: (state: ProductsState) => state.product,
  getProducts: (state: ProductsState) => state.products,
  getCreatedProduct: (state: ProductsState) => state.createdProduct,
  getAllProducts: (state: ProductsState) => state.allProducts,
  getOwnedProducts: (state: ProductsState) => state.ownedProducts,
  getProductRemainingBudget: (state: ProductsState) => state.remainingBudget,
  getProductCuAllocation: (state: ProductsState) => state.productCUAllocation,
  getProductCuAllocationFetched: (state: ProductsState) => state.productCuAllocationFetched,
};

/* TO:DO Refactor argument name "filterId" */
const actions = {
  async fetchProductsInProductFamily(context: ProductsContext, filterId: string) {
    const productsDO: IProduct[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/productfamilies/${filterId}/products`);
      for (const id in response.data) {
        productsDO.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setProducts", productsDO);
      context.commit("setProductCuAllocationFetched", true);
    } catch (error) {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error: This product family has no products`);
      context.commit("setProducts", productsDO);
    }
  },

  fetchProductAvailableBudget(context: ProductsContext, productId: number) {
    AXIOS_REQUEST("GET", `/products/${productId}/remainingBudget`)
      .then((response) => {
        const remainingBudget = response.data.availableBudget;
        context.commit("setProductRemainingBudget", remainingBudget);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  fetchAllProducts(context: ProductsContext) {
    AXIOS_REQUEST("GET", "/products")
      .then((response) => {
        const allProductsDO: IProduct[] = [];
        for (const id in response.data) {
          allProductsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setAllProducts", allProductsDO);
        context.commit("setAreProductsFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  fetchOwnedProducts(
    context: ProductsContext,
    payload: {
      username: string;
    }
  ) {
    AXIOS_REQUEST("GET", `/ownership/${payload.username}/ownedproducts`)
      .then((response) => {
        const allProductsDO: IProduct[] = [];
        for (const id in response.data) {
          allProductsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setOwnedProducts", allProductsDO);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  fetchProductCuAllocation(context: ProductsContext, productId: number) {
    AXIOS_REQUEST("GET", `/products/${productId}/costunits`)
      .then((response) => {
        const cuAllocation = response.data;
        context.commit("setProductCuAllocation", cuAllocation);
        context.commit("setProductCuAllocationFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },

  changeProductCUAllocationState(context: ProductsContext) {
    context.commit("setProductCuAllocationFetched", false);
  },

  async fetchSingleProduct(context: ProductsContext, productID: string) {
    try {
      const response = await AXIOS_REQUEST("GET", `/products/${productID}`);
      const singleProductDO: IProduct[] = response.data;
      context.commit("setProduct", singleProductDO);
    } catch (error) {
      console.error(error);
    }
  },

  createNewProduct(context: ProductsContext, payload: IProduct) {
    if (payload.image == null) {
      delete payload.image;
    }
    AXIOS_REQUEST("POST", "/products", payload)
      .then((response) => {
        const productDO: IProduct = response.data;
        context.commit("setCreatedProduct", productDO);
        context.commit("updateAllProducts", productDO);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error: ${error.message}`);
      });
  },

  updateProductBudgetInCostUnit(
    context: ProductsContext,
    payload: {
      costunitid: number;
      productid: number;
      percentage: number;
    }
  ) {
    const bodyData = {
      percentage: payload.percentage,
    };
    AXIOS_REQUEST("PUT", `/costunits/${payload.costunitid}/product/${payload.productid}/percentage`, bodyData)
      .then(() => {
        context.dispatch("updateRequestMessage", `Successfully updated the budget`);
      })
      .catch((error) => {
        console.error(error);
        context.dispatch("updateRequestMessage", `Error while updating the budget`);
      });
  },
};

const productsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default productsModule;
