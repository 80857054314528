<template>
  <div class="c-cudetails__modal-overlay" v-if="showPODetails">
    <div class="c-cudetails__modal-container" data-testid="cudetails-modal">
      <div>
        <div class="c-cudetails__modal-header">
          <h2 class="c-cudetails__modal-title" data-testid="cudetails-title">
            {{ selectedProductName + " (" + selectedCostUnit + ")" }}
          </h2>
          <div class="c-cudetails__modal-header-closebtn">
            <img src="../../../public/remove.svg" alt="Remove list item button" data-testid="cudetails-closemodal-button" @click="closeModal" />
          </div>
        </div>
      </div>
      <div class="c-cudetails__table-container" v-if="selectedDetails === `orders` ? relatedOrders.length > 0 : relatedRequests.length > 0" data-testid="cudetails-table">
        <div class="c-cudetails__table-wrapper">
          <table class="c-cudetails__table c-cudetails__list-table" aria-describedby="Cost Unit Details">
            <thead>
              <tr class="c-cudetails__table-head-row">
                <th>ID</th>
                <th>Owner</th>
                <th>Status</th>
                <th>Supplier</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Delivery Date (End Date)</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in selectedDetails === `orders` ? relatedOrders : relatedRequests" :key="index" class="c-cudetails__table-row">
                <td data-testid="cudetails-id">{{ item.id }}</td>
                <td data-testid="cudetails-prownername">
                  {{ item.prownername }}
                </td>
                <td data-testid="cudetails-status">
                  {{ item.status === "" ? "-" : item.status }}
                </td>
                <td data-testid="cudetails-suppliername">
                  {{ item.suppliername }}
                </td>
                <td data-testid="cudetails-orderamount">
                  {{ formatNumber(item.orderamount) }}
                </td>

                <td data-testid="cudetails-currency">{{ item.currency }}</td>
                <td data-testid="cudetails-deliverydate">
                  {{ item.deliverydate ? formatDate(item.deliverydate) : "" }}
                </td>
                <td>
                  <button @click="showLIDetails(item.id)" data-testid="cudetails-button-show">Show</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="selectedDetailsID !== undefined" class="c-cudetails__listitems">
          <div class="c-cudetails__listitems-header">
            <h4 data-testid="cudetails-listitems-title">List items for order ID: {{ selectedDetailsID }}</h4>
            <button class="cst-button-close c-cudetails__listitems-closebtn" @click="closeListItems" data-testid="cudetails-button-closelistitems">X</button>
          </div>
          <div class="c-cudetails__listitems-container" data-testid="cudetails-listitems-container">
            <ListItemCard v-for="item in listItems" :isDetails="true" :data="item" :key="item.id"></ListItemCard>
          </div>
        </div>
      </div>
      <h2 v-else class="c-cudetails__empty-msg" data-testid="cudetails-listitems-empty">List is empty!</h2>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { IPurchaseOrder } from "@/utils/interfaces/IPurchaseOrder";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { ICostUnit } from "@/utils/interfaces/ICostUnit";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import ListItemCard from "../cards/C_ListItemCard.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "CostUnitDetails",
    components: {
      ListItemCard,
    },
    props: {
      selectedProductId: {
        type: String,
        required: true,
      },
      selectedProductName: {
        type: String,
        required: true,
      },
      selectedDetails: {
        type: String,
        required: true,
      },
      selectedCostUnit: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { CHANGE_PO_DETAILS_VIEW, FETCH_PURCHASE_ORDERS, FETCH_PURCHASE_REQUESTS } = VUEX_ACTIONS;
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const allOrders = computed(() => store.getters.getPurchaseOrders);
      const allRequests = computed(() => store.getters.getPurchaseRequests);
      const allCostUnits = computed(() => store.getters.getCostUnits);
      const showPODetails = computed(() => store.getters.getShowPODetails);
      const selectedDetailsID = ref<number | undefined>(undefined);
      const listItems = ref();

      store.dispatch(FETCH_PURCHASE_ORDERS);
      store.dispatch(FETCH_PURCHASE_REQUESTS);

      const percentage = computed(() => {
        const costUnit = allCostUnits.value.find((unit: ICostUnit) => unit.name === props.selectedCostUnit);
        if (costUnit) {
          const product = costUnit.products.find((product: IProduct) => Number(product.id) === Number(props.selectedProductId));
          if (product) {
            return product.percentage;
          }
        }
        return null;
      });

      const relatedOrders = computed(() => {
        return allOrders.value.filter((req: IPurchaseOrder) => req.listitems.some((listItem: IListItem) => Number(listItem.product) === Number(props.selectedProductId)));
      });

      const relatedRequests = computed(() => {
        return allRequests.value.filter((req: IPurchaseRequest) => req.listitems.some((listItem: IListItem) => Number(listItem.product) === Number(props.selectedProductId)));
      });

      const showLIDetails = (id: number) => {
        selectedDetailsID.value = id;
        const related = props.selectedDetails === "orders" ? relatedOrders.value : relatedRequests.value;
        const selected = related.filter((req: IPurchaseRequest) => req.id === selectedDetailsID.value);
        listItems.value = selected[0].listitems;
      };

      const closeModal = () => {
        store.dispatch(CHANGE_PO_DETAILS_VIEW, false);
        selectedDetailsID.value = undefined;
      };

      const closeListItems = () => {
        selectedDetailsID.value = undefined;
      };

      return {
        showPODetails,
        closeModal,
        closeListItems,
        currency,
        formatNumber,
        formatDate,
        relatedOrders,
        relatedRequests,
        percentage,
        showLIDetails,
        selectedDetailsID,
        listItems,
      };
    },
  });
</script>
