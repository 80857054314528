<template>
  <RequestMessage></RequestMessage>
  <div class="v-showinvoices__wrapper">
    <h1 class="v-showinvoices__text-center v-showinvoices__title">List of Invoices</h1>
    <div v-if="!areInvoicesFetched" class="cst-spinner" data-testid="v-invoices-spinner"></div>
    <div class="v-showinvoices__table-container" v-else-if="invoices.length > 0" data-testid="vshowinvoices__table">
      <C_Search :placeholder="'Search by PO Name...'" @input="filterInvoices($event)" />
      <div v-if="filteredInvoices.length > 0" class="v-showinvoices__table-wrapper">
        <table class="v-showinvoices__table" aria-describedby="Table of invoices">
          <thead>
            <tr class="v-showinvoices__table-head-row">
              <th v-for="(item, index) in tableHeaders" :key="index">
                {{ item }}
              </th>
              <th v-if="invoiceExportEnabled && hasExportPermission" data-testid="vshowinvoices-documentcolumn">Document</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in filteredInvoices" :key="index">
            <tr class="v-showinvoices__table-row">
              <td v-if="!dateRangeEnabled">{{ formatDate(item.date) }}</td>
              <td v-if="dateRangeEnabled">{{ item.start_date ? formatDate(item.start_date) : "-" }}</td>
              <td v-if="dateRangeEnabled">{{ item.end_date ? formatDate(item.end_date) : "-" }}</td>

              <td>{{ item.name }}</td>
              <td>{{ item.customercontact }}</td>
              <td>{{ item.po_name }}</td>
              <td>
                {{ formatNumber((Number(item.total_amount) * currencyRate).toFixed(2)) + " " + selectedCurrency }}
              </td>
              <td>
                <button title="Show" class="c-requestbtns c-requestbtns-secondary-show" data-testid="vshowinvoices-detailsbtn" @click="toggleDetails(item, index)">
                  <p>{{ expandedIndex === index ? "Hide" : "Show" }}</p>
                  <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                    <path
                      d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                    />
                  </svg>
                </button>
              </td>
              <td v-if="hasExportPermission && invoiceExportEnabled">
                <button title="Export" class="c-requestbtns c-requestbtns-secondary-show" data-testid="vshowinvoices-exportbtn" @click="exportInvoice(item)">
                  Export
                  <svg fill="#000000" viewBox="0 0 50 32" version="1.1" preserveAspectRatio="xMidYMid meet">
                    <path d="M6,13.61h7.61V6H24v8.38h2V6a2,2,0,0,0-2-2H10.87L4,10.87V30a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2H6Zm0-1.92L11.69,6H12v6H6Z" class="clr-i-outline clr-i-outline-path-1"></path>
                    <path d="M28.32,16.35a1,1,0,0,0-1.41,1.41L30.16,21H18a1,1,0,0,0,0,2H30.19l-3.28,3.28a1,1,0,1,0,1.41,1.41L34,22Z" class="clr-i-outline clr-i-outline-path-2"></path>
                    <rect x="0" y="0" width="32" height="32" fill-opacity="0"></rect>
                  </svg>
                </button>
              </td>
            </tr>
            <tr v-if="expandedIndex !== null && expandedIndex === index">
              <td :colspan="hasExportPermission ? 8 : 6">
                <div v-if="expandedItem.listitems && expandedItem.listitems.length > 0">
                  <h4 class="v-showinvoices__listitems">List Items:</h4>
                  <div class="v-showinvoices__li-table-header" data-testid="vshowinvoices-listitems">
                    <p>No.</p>
                    <p>Name</p>
                    <p>Resource</p>
                    <p>Person Days</p>
                    <p>Cost type</p>
                    <p>Daily rate</p>
                    <p>Amount</p>
                  </div>
                  <div class="v-showinvoices__li-table-row-wrapper">
                    <div v-for="(item, index) in expandedItem.listitems" :key="index">
                      <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                        <p>{{ index + 1 }}</p>
                        <p>{{ (item as IInvoiceShowLI).list_item_name }}</p>
                        <p>{{ (item as IInvoiceShowLI).resource_name }}</p>
                        <p>{{ (item as IInvoiceShowLI).quantity }}</p>
                        <p>{{ (item as IInvoiceShowLI).cost_type }}</p>
                        <p>{{ (item as IInvoiceShowLI).daily_rate }}</p>
                        <p>
                          {{ formatNumber(Number((item as IInvoiceShowLI).amount).toFixed(2)) + " " + (item as IInvoiceShowLI).currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="v-showinvoices--empty">No such invoices found</p>
      </div>
      <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="invoices" @paginateList="paginateList" data-testid="pages-indicator" />
    </div>
    <div v-else>
      <h3 v-if="isInvoiceListEmpty" class="v-showinvoices__text-center v-showinvoices__title" data-testid="vshowinvoices-noinvoices">No invoices found</h3>
    </div>
    <ExportDocumentModal v-if="isModalOpen" :invoiceID="invoiceID"></ExportDocumentModal>
  </div>
</template>

<script setup lang="ts">
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { computed, ref, Ref, ComputedRef, watch } from "vue";
  import { useStore } from "vuex";
  import { IInvoice, IInvoiceShowLI } from "@/utils/interfaces/IInvoice";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import ExportDocumentModal from "@/components/modals/C_ExportDocumentModal.vue";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  const { FETCH_INVOICES, SHOW_EXPORT_DOCUMENT_MODAL, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const isModalOpen = computed(() => store.getters.getShowExportDocumentModal);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const invoiceID = ref();
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const invoices: ComputedRef<IInvoice[]> = computed(() => store.getters.getInvoices);
  const filteredInvoices = ref(invoices.value?.slice(0, 10));
  const paginatedList = ref(invoices.value?.slice(0, 10));

  const areInvoicesFetched = computed(() => store.getters.getAreInvoicesFetched);

  const isInvoiceListEmpty = computed(() => {
    if (invoices.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const totalPages = computed(() => {
    if (invoices.value.length > 10 && invoices.value.length % 10 != 0) {
      return Math.floor(invoices.value.length / 10) + 1;
    } else {
      return invoices.value.length / 10;
    }
  });

  const expandedItem = ref();
  const expandedIndex = ref<number | null>(null);

  const invoiceExportEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoices_export_enabled;
    } else {
      return false;
    }
  });

  const hasExportPermission = computed(() => {
    if (userRoles.value.includes("edit-invoice")) {
      return true;
    } else {
      return false;
    }
  });

  const dateRangeEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.invoice_has_date_range;
    } else {
      return false;
    }
  });

  const tableHeaders: Ref<string[]> = dateRangeEnabled.value ? ref(["Start Date", "End Date", "Title", "Customer Contact", "PO Name", "Amount", "Details"]) : ref(["Date", "Title", "Customer Contact", "PO Name", "Amount", "Details"]);

  const fetchInvoices = () => {
    store.dispatch(FETCH_INVOICES);
  };

  fetchInvoices();

  const toggleDetails = (item: IInvoice, index: number) => {
    if (expandedIndex.value === index) {
      expandedIndex.value = null;
    } else {
      expandedIndex.value = index;
      expandedItem.value = item;
    }
  };

  const exportInvoice = (invoice: IInvoice) => {
    store.dispatch(SHOW_EXPORT_DOCUMENT_MODAL, true);
    invoiceID.value = invoice.id;
  };

  const filterInvoices = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredInvoices.value = invoices.value.filter((invoice: IInvoice) => {
      if (invoice.po_name) return invoice.po_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });

    if (searchTerm === "") {
      filteredInvoices.value = paginatedList.value;
    }
  };

  watch([invoices], () => {
    paginatedList.value = invoices.value?.slice(0, 10);
    filteredInvoices.value = invoices.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IInvoice>) => {
    filteredInvoices.value = filteredList;
    paginatedList.value = filteredList;
    expandedIndex.value = null;
  };
</script>
