import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "c-pflastnextyear-chart__container"
}
const _hoisted_3 = { class: "c-pflastnextyear-chart__note" }

import { Bar } from "vue-chartjs";
  import { ref, watch, computed } from "vue";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { useStore } from "vuex";

  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_PFLastNextYearChart',
  props: {
    products: {
      type: Array as () => IProduct[],
      required: true,
    },
  },
  setup(__props) {

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  const props = __props;
  const dataLoaded = ref(false);
  const store = useStore();
  const chartData = ref<IChartData>();
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const currencyRate = computed(() => store.getters.getCurrencyRate);

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });
  const currency = computed(() => store.getters.getSelectedCurrency);

  const updateChartData = () => {
    if (props.products && props.products.length > 0) {
      const datasets = [];

      for (let productIndex = 0; productIndex < props.products.length; productIndex++) {
        const product = props.products[productIndex];
        datasets.push({
          label: product.name,
          data: [
            Number(product.lastyearbudget ? (product.lastyearbudget * currencyRate.value).toFixed(2) : product.lastyearbudget),
            Number(product.budget ? (product.budget * currencyRate.value).toFixed(2) : product.budget),
            Number(product.totalnextyearbudget ? (product.totalnextyearbudget * currencyRate.value).toFixed(2) : product.totalnextyearbudget),
          ] as number[],
          backgroundColor: [productIndex % 2 === 0 ? "#65ac1e" : "#00793a", productIndex % 2 === 0 ? "#21a0d2" : "#004a96", productIndex % 2 === 0 ? "#FFA41B" : "#FC5404"],
        });
      }

      chartData.value = {
        labels: ["Last Year", "Current Year", "Next Year"],
        datasets: datasets,
      };

      dataLoaded.value = true;
    }
  };

  watch([() => props.products], () => {
    updateChartData();
  });

  watch([currencyRate], () => {
    updateChartData();
  });

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: financialGoalLabel.value ? `${financialGoalLabel.value} summary` : "Budget summary",
        font: {
          family: "customFont",
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            family: "customFont",
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          font: {
            family: "customFont",
          },
        },
      },
    },
    indexAxis: "y" as const,
  } as BarChartOptions;

return (_ctx: any,_cache: any) => {
  return (dataLoaded.value && __props.products.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (chartData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(Bar), {
                id: "pf-currentyear-chart",
                options: chartOptions,
                data: chartData.value
              }, null, 8, ["data"]),
              _createElementVNode("div", _hoisted_3, "*Amounts are shown in " + _toDisplayString(currency.value), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})