<template>
  <RequestMessage></RequestMessage>
  <div v-if="rolesFetched" class="cst-new-product-type--wrapper" data-testid="createprod-wrapper">
    <NewProductForm data-testid="createprod-newprodform"></NewProductForm>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from "vue";
  import NewProductForm from "../components/forms/C_NewProductForm.vue";
  import RequestMessage from "../components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "NewProduct",
    components: {
      NewProductForm,
      RequestMessage,
    },
    setup() {
      const { FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
      const store = useStore();
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const router = useRouter();
      const rolesFetched = ref(false);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      if (!userRoles.value.includes("edit-product-hierarchy")) {
        rolesFetched.value = false;
        router.push("/");
      } else {
        rolesFetched.value = true;
      }

      return {
        rolesFetched,
      };
    },
  });
</script>
