import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-notificationmodal__overlay"
}
const _hoisted_2 = { class: "c-notificationmodal__container" }
const _hoisted_3 = {
  class: "c-notificationmodal__container-title",
  "data-testid": "notification-modal-title"
}
const _hoisted_4 = { class: "c-notificationmodal__container-content" }
const _hoisted_5 = {
  class: "c-notificationmodal__container-content-message",
  "data-testid": "notification-modal-message"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "c-notificationmodal__container-action-row" }
const _hoisted_8 = {
  class: "c-notificationmodal__container-date",
  "data-testid": "notification-modal-date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isModalOpened)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
            class: "c-notificationmodal__container-button-close cst-button-close",
            "data-testid": "close-modal"
          }, "X"),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getCurrentNotification.title), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.getCurrentNotification.message) + " ", 1),
              (_ctx.getCurrentNotification.message.includes('Please sign the purchase request'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
                    _createTextVNode(" You can view the list of open purchase requests "),
                    _createElementVNode("a", {
                      href: "/requests",
                      class: "c-notificationmodal__container-content-message--link"
                    }, "here", -1),
                    _createTextVNode(". ")
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.formatDate(_ctx.getCurrentNotification.date)), 1),
            _createElementVNode("button", {
              class: "c-notificationmodal__container-button-delete cst-button-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteNotification(_ctx.getCurrentNotification.id))),
              "data-testid": "notification-modal-delete"
            }, "Delete")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}