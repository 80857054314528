<template>
  <RequestMessage></RequestMessage>
  <div class="v-managecostunit__container">
    <div class="v-managecostunit__box--column">
      <div class="v-managecostunit__box--wrapper">
        <div class="v-managecostunit__title">Manage Cost Unit {{ unit }}</div>
        <div class="v-managecostunit__body">
          <div class="cst-input-field">
            <label for="owner">Select new manager:</label>
            <select class="cst-select-field" v-model="selectedManager" @change="handleSelectManager">
              <option v-for="user in users" :key="user.id" :value="user.id" id="selectedManager">
                {{ user.username }}
              </option>
            </select>
          </div>

          <div class="cst-input-field">
            <label for="owner">Cost Unit Status</label>
            <select class="cst-select-field" @change="handleSelectStatus" v-model="selectedStatus">
              <option v-for="(status, index) in costUnitStatus" :key="index" :value="status">
                {{ status }}
              </option>
            </select>
          </div>
        </div>
        <div class="cst-inputbox__actions--row-wrapper__spacebetween">
          <button type="button" class="cst-button cst-button-primary v-managecostunit__button" :disabled="changeBtnDisabled" @click="handleUpdateCostUnit">Save</button>
        </div>
      </div>
    </div>

    <div class="v-managecostunit__box--column">
      <div class="v-managecostunit__box">
        <div v-if="fetchedSuccessfully" class="v-managecostunit__box--wrapper">
          <div class="v-managecostunit__title">Manage Products in {{ unit }}</div>
          <div class="v-managecostunit__body">
            <div class="cst-input-field">
              <label for="owner">Select product:</label>
              <select class="cst-select-field" v-model="selectedProduct" :disabled="isAddingNewProduct" @change="fetchRemainingPercentage(Number(selectedProduct), Number(costUnitID))">
                <option disabled selected value="">Select a product</option>
                <option v-for="product in fetchedProducts" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="name">Allocate budget (%):</label>
              <input
                class="cst-input"
                :class="{ 'cst-input__warning': isExceedingPercentage }"
                input="number"
                placeholder="Example: 10%"
                id="percentage"
                min="1"
                max="100"
                @input="preventDotInInput"
                v-model="percentage"
                required
                :disabled="percentageDisabled"
              />
            </div>
            <div class="cst-input-field">
              <label for="name">Available budget (%):</label>
              <input class="cst-input" input="number" placeholder="Example: 10%" v-model="remainingAmount" id="remainingAmount" disabled />
            </div>
          </div>
          <div class="cst-inputbox__actions--row-wrapper__spacebetween">
            <div class="cst-inputbox__actions--row-wrapper__smallgap">
              <button type="button" class="cst-button cst-button-primary v-managecostunit__button" @click="handleUpdateProductBudget" :disabled="saveProductBtnDisabled">Update</button>

              <button type="button" class="cst-button v-managecostunit__button v-managecostunit__button--danger" @click="deleteProduct" :disabled="deleteProductBtnDisabled">Delete</button>
            </div>
            <button type="button" class="cst-button cst-button-primary v-managecostunit__button" @click="handleAddingNewProduct" :disabled="isAddingNewProduct">Add New</button>
          </div>
        </div>
      </div>

      <div class="v-managecostunit__box--wrapper" v-if="isAddingNewProduct">
        <div class="v-managecostunit__title">
          <b>Allocate Products to {{ unit }}</b>
        </div>
        <form @submit.prevent>
          <div class="v-managecostunit__body">
            <div class="cst-input-field">
              <label for="productfamily">Select product family</label>
              <select class="cst-select-field" required v-model="selectedProductFamily" @change="fetchProductsFromPF">
                <option disabled selected value="">Select a product family</option>
                <option v-for="family in productFamilies" :key="family.id" :value="family.id" id="selectedFamilyProduct">
                  {{ family.name }}
                </option>
              </select>
            </div>
            <div class="cst-input-field">
              <label for="product">Select existing product</label>
              <select class="cst-select-field" required v-model="selectedNewProduct" :disabled="isProductDropdownDisabled" @change="fetchRemainingPercentage(Number(selectedNewProduct), Number(costUnitID))">
                <option disabled selected value="">Select a product</option>
                <option v-for="product in products" :key="product.id" :value="product.id" id="selectedProduct">
                  {{ product.name }}
                </option>
              </select>
            </div>
            <div class="v-createcu--formwrapper__form--budget">
              <div class="cst-input-field">
                <label for="name">Allocate budget (%):</label>
                <input
                  class="cst-input"
                  :class="{
                    'cst-input__warning': isExceedingPercentageNewProduct,
                  }"
                  input="number"
                  placeholder="Example: 10%"
                  id="percentage"
                  min="1"
                  max="100"
                  @input="preventDotInInput"
                  v-model="percentageNewProduct"
                  required
                  :disabled="percentageDisabledNew"
                />
              </div>
              <div class="cst-input-field">
                <label for="name">Available budget (%):</label>
                <input class="cst-input" input="number" placeholder="Example: 10%" v-model="remainingAmountNewProduct" id="remainingAmount" disabled />
              </div>
            </div>
          </div>

          <div class="cst-inputbox__actions--row-wrapper__spacebetween">
            <div class="cst-inputbox__actions--row-wrapper__smallgap">
              <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" type="submit" :disabled="allocateProductBtnDisabled" @click="handleAllocateProduct">Allocate</button>
              <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" type="submit" @click="handleFinish">Finish</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, watch, onBeforeUnmount } from "vue";
  import { useRoute, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
  import { useStore } from "vuex";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";
  import { IUser } from "@/utils/interfaces/IUser";

  export default defineComponent({
    name: "ManageCostUnit",
    components: {
      RequestMessage,
    },
    beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      // This will check if there are parameters in the url and if there aren't it won't allow access to this route.
      if (to.query.unit) {
        next();
      } else {
        next("/");
      }
    },
    setup() {
      const {
        FETCH_FILTERED_COST_UNITS,
        CLEAR_FILTERED_COST_UNITS,
        UPDATE_COST_UNIT,
        ADD_PRODUCT_TO_COST_UNIT,
        FETCH_REMAINING_PRODUCT_PERCENTAGE,
        FETCH_COST_UNIT_PRODUCTS,
        DELETE_PRODUCT_FROM_COST_UNIT,
        FETCH_ALL_PRODUCT_FAMILIES,
        FETCH_PRODUCTS_IN_PROD_FAMILY,
        UPDATE_PRODUCT_PERCENTAGE_IN_CU,
        UPDATE_REQUEST_MESSAGE,
        FETCH_USERS,
        FETCH_NOTIFICATIONS,
      } = VUEX_ACTIONS;
      const route = useRoute();
      const { unit } = route.query;
      const store = useStore();
      const filteredUnit = computed(() => store.getters.getFilteredCostUnits);

      const fetchedProducts = computed(() => (store.getters.getCostUnitProducts || []).slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));

      const fetchProductRemainingPercentage = ref();

      const users = computed(() => {
        return (store.getters.getUsers || []).slice().sort((a: IUser, b: IUser) => a.username.localeCompare(b.username));
      });

      const productFamilies = computed(() =>
        (store.getters.getAllProductFamilies || [])
          .filter((item: IProductFamily) => !item.name.toLowerCase().includes("platform"))
          .slice()
          .sort((a: IProductFamily, b: IProductFamily) => a.name.localeCompare(b.name))
      );

      const products = computed(() => (store.getters.getProducts || []).slice().sort((a: IProduct, b: IProduct) => a.name.localeCompare(b.name)));

      const costUnitStatus = ["Active", "Proposed", "Retired"];
      const initialManager = ref("");
      const selectedProductFamily = ref("");
      const initialStatus = ref("");
      const selectedStatus = ref("");
      const selectedManager = ref("");
      const selectedProduct = ref("");
      const selectedNewProduct = ref("");
      const fetchedSuccessfully = ref(false);
      const percentageDisabled = ref(true);
      const percentageDisabledNew = ref(true);
      const saveProductBtnDisabled = ref(true);
      const allocateProductBtnDisabled = ref(true);
      const isProductDropdownDisabled = ref(true);
      const deleteProductBtnDisabled = ref(true);
      const isAddingNewProduct = ref(false);
      const changeBtnDisabled = ref(true);
      const payload = ref({});
      const percentage = ref(0);
      const percentageNewProduct = ref(0);
      const remainingAmount = ref(0);
      const remainingAmountNewProduct = ref(0);
      const isExceedingPercentage = ref(false);
      const isExceedingPercentageNewProduct = ref(false);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      const costUnitID = computed(() => {
        return filteredUnit.value.length > 0 ? filteredUnit.value[0].id : null;
      });

      const setInitialValues = async () => {
        await store.dispatch(FETCH_FILTERED_COST_UNITS, `?name=${unit}`).then(() => {
          store.dispatch(FETCH_COST_UNIT_PRODUCTS, unit);
          store.dispatch(FETCH_ALL_PRODUCT_FAMILIES);
          store.dispatch(FETCH_USERS);
          if (filteredUnit.value !== null && filteredUnit.value !== undefined && filteredUnit.value.length != 0) {
            fetchedSuccessfully.value = true;
            initialManager.value = filteredUnit.value[0].manager;
            initialStatus.value = filteredUnit.value[0].status;
            selectedStatus.value = initialStatus.value;
            selectedManager.value = initialManager.value;
            payload.value = {
              id: filteredUnit.value[0].id,
              name: unit,
              manager: selectedManager.value,
              status: selectedStatus.value,
              statusdate: filteredUnit.value[0].statusdate,
            };
            changeBtnDisabled.value = true;
            selectedProductFamily.value = "";
            selectedProduct.value = "";
            selectedNewProduct.value = "";
            percentageNewProduct.value = 0;
            percentage.value = 0;
            remainingAmount.value = 0;
            remainingAmountNewProduct.value = 0;
          } else {
            fetchedSuccessfully.value = false;
          }
        });
        await store.dispatch(FETCH_COST_UNIT_PRODUCTS, `${unit}`);
      };

      setInitialValues();

      const handleSelectManager = () => {
        payload.value = {
          id: filteredUnit.value[0].id,
          name: unit,
          manager: Number(selectedManager.value),
          status: selectedStatus.value,
          statusdate: filteredUnit.value[0].statusdate,
        };
      };

      const handleSelectStatus = () => {
        payload.value = {
          id: filteredUnit.value[0].id,
          name: unit,
          manager: Number(selectedManager.value),
          status: selectedStatus.value,
          statusdate: filteredUnit.value[0].statusdate,
        };
      };

      const handleUpdateCostUnit = async () => {
        await store.dispatch(UPDATE_COST_UNIT, payload.value).then(() => setInitialValues());
      };

      const handleAddingNewProduct = () => {
        isAddingNewProduct.value = true;
        percentageDisabled.value = true;
        setInitialValues();
      };

      const handleFinish = () => {
        isAddingNewProduct.value = false;
        setInitialValues();
      };

      const preventDotInInput = () => {
        percentage.value = parseInt(percentage.value.toString().split(".").join(""));
        percentageNewProduct.value = parseInt(percentageNewProduct.value.toString().split(".").join(""));
      };

      const fetchRemainingPercentage = async (product: number, costUnitID: number) => {
        fetchProductRemainingPercentage.value = 0;
        await store
          .dispatch(FETCH_REMAINING_PRODUCT_PERCENTAGE, {
            productID: product,
            costUnitID: costUnitID,
          })
          .then(() => {
            fetchProductRemainingPercentage.value = store.getters.getProductPercentageRemaining;
          });
      };

      const deleteProduct = async () => {
        const payload = {
          costUnitId: filteredUnit.value[0].id,
          productId: selectedProduct.value,
        };
        await store
          .dispatch(DELETE_PRODUCT_FROM_COST_UNIT, payload)
          .then(() => {
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Product Removed From Cost Unit Sucessfully!");
            setInitialValues();
            selectedProduct.value = "";
          })
          .catch(() => store.dispatch(UPDATE_REQUEST_MESSAGE, "Error while removing the product"));
      };

      const fetchProductsFromPF = async () => {
        remainingAmountNewProduct.value = 0;
        percentageNewProduct.value = 0;
        await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedProductFamily.value.toString()).then(() => {
          selectedNewProduct.value = "";
        });
      };

      const handleAllocateProduct = async () => {
        if (selectedNewProduct.value != "" && percentageNewProduct.value >= 1 && percentageNewProduct.value <= remainingAmountNewProduct.value) {
          const payload = {
            product: Number(selectedNewProduct.value),
            costunit: Number(filteredUnit.value[0].id),
            percentage: percentageNewProduct.value,
          };
          await store.dispatch(ADD_PRODUCT_TO_COST_UNIT, payload).then(async () => {
            setInitialValues();
          });
        } else {
          store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Cannot allocate product");
        }
      };

      const handleUpdateProductBudget = async () => {
        if (percentage.value <= remainingAmount.value && percentage.value > 0) {
          const payload = {
            costunitid: filteredUnit.value[0].id,
            productid: selectedProduct.value,
            percentage: percentage.value,
          };
          await store.dispatch(UPDATE_PRODUCT_PERCENTAGE_IN_CU, payload).then(() => {
            setInitialValues();
          });
        } else {
          store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Cannot allocate this budget");
        }
      };

      onBeforeUnmount(() => {
        store.dispatch(CLEAR_FILTERED_COST_UNITS);
      });

      watch([selectedManager, selectedStatus], () => {
        if (selectedManager.value != "" && selectedStatus.value != "" && (selectedStatus.value != initialStatus.value || selectedManager.value != initialManager.value)) {
          changeBtnDisabled.value = false;
        } else {
          changeBtnDisabled.value = true;
        }
      });

      watch([fetchProductRemainingPercentage], () => {
        if (!isAddingNewProduct.value) {
          remainingAmount.value = fetchProductRemainingPercentage.value;
        } else {
          remainingAmountNewProduct.value = fetchProductRemainingPercentage.value;
        }
      });

      watch([percentage, selectedProduct], () => {
        if (selectedProduct.value != "") {
          deleteProductBtnDisabled.value = false;
          percentageDisabled.value = false;
          saveProductBtnDisabled.value = false;
          if (percentage.value > remainingAmount.value) {
            isExceedingPercentage.value = true;
            saveProductBtnDisabled.value = true;
          } else {
            isExceedingPercentage.value = false;
          }
          if (percentage.value == 0) {
            saveProductBtnDisabled.value = true;
          }
        } else {
          deleteProductBtnDisabled.value = true;
          percentageDisabled.value = true;
          saveProductBtnDisabled.value = true;
          isExceedingPercentage.value = false;
        }
        if (percentage.value > 100) {
          percentage.value = 100;
        }
        if (isNaN(percentage.value)) {
          percentage.value = 0;
        }
      });

      watch([selectedProductFamily], () => {
        if (selectedProductFamily.value != "") {
          isProductDropdownDisabled.value = false;
        } else {
          isProductDropdownDisabled.value = true;
        }
      });

      watch([percentageNewProduct, selectedNewProduct], () => {
        if (selectedNewProduct.value != "") {
          percentageDisabledNew.value = false;
          allocateProductBtnDisabled.value = false;
          if (percentageNewProduct.value > remainingAmountNewProduct.value) {
            isExceedingPercentageNewProduct.value = true;
            allocateProductBtnDisabled.value = true;
          } else {
            isExceedingPercentageNewProduct.value = false;
          }
          if (percentageNewProduct.value == 0) {
            allocateProductBtnDisabled.value = true;
          }
        } else {
          percentageDisabledNew.value = true;
          allocateProductBtnDisabled.value = true;
        }
        if (percentageNewProduct.value > 100) {
          percentageNewProduct.value = 100;
        }
        if (isNaN(percentageNewProduct.value)) {
          percentageNewProduct.value = 0;
        }
      });

      watch([selectedProduct, filteredUnit], ([newProduct, newUnits]) => {
        if (newProduct && newUnits.length > 0) {
          const costUnitID = Number(newUnits[0]?.id);
          const productID = Number(newProduct);
          if (productID !== undefined && costUnitID !== undefined) {
            fetchRemainingPercentage(productID, costUnitID);
          }
        }
      });

      return {
        unit,
        users,
        selectedManager,
        products,
        handleSelectManager,
        handleSelectStatus,
        handleUpdateCostUnit,
        handleAllocateProduct,
        handleUpdateProductBudget,
        costUnitStatus,
        selectedStatus,
        changeBtnDisabled,
        fetchedSuccessfully,
        preventDotInInput,
        percentage,
        percentageNewProduct,
        isExceedingPercentage,
        isExceedingPercentageNewProduct,
        remainingAmount,
        remainingAmountNewProduct,
        fetchedProducts,
        selectedProduct,
        selectedNewProduct,
        fetchRemainingPercentage,
        percentageDisabled,
        percentageDisabledNew,
        saveProductBtnDisabled,
        allocateProductBtnDisabled,
        deleteProductBtnDisabled,
        deleteProduct,
        productFamilies,
        selectedProductFamily,
        fetchProductsFromPF,
        isProductDropdownDisabled,
        handleAddingNewProduct,
        handleFinish,
        isAddingNewProduct,
        costUnitID,
      };
    },
  });
</script>
