export function formatNumber(value: string) {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return value;
  }
  return number.toLocaleString("hr-HR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function formatNumberValue(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return "";
  }

  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
