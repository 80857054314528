<template>
  <div class="v-cuoverview__wrapper">
    <div class="v-cuoverview__filter-wrapper">
      <FilterBar :header="header" :filterList="filters" @submit="handleFilterSubmit" v-if="filtersLoaded" :is-cost-unit="true" />
    </div>
    <div class="v-cuoverview__products-wrapper">
      <div class="v-cuoverview__summary-overview">
        <div class="v-cuoverview__summary-overview--title">
          {{ selectedCostUnit }} Cost Unit Overview
          <button v-if="selectedCostUnit" class="v-cuoverview__summary-overview--btn cst-button cst-button-primary" type="button" @click="handleManageClick">
            <b>Manage</b>
          </button>
        </div>
        <div v-if="areCostUnitsFetched">
          <CostUnitStatus :status="status" :statusdate="statusDate"></CostUnitStatus>
          <BudgetSummary size="big" :budget="totals.totalBUDG" :forecast="totals.totalFCST" :actuals="totals.totalACT" />
        </div>
        <div v-else class="c-budgetsummary__wrapper--empty">Select a filter on the left side to see the overview.</div>
      </div>
      <div class="v-cuoverview__product-cards">
        <CostUnitCard v-for="unit in costUnitProducts" :key="unit.id" :unit="unit" @show-unit-details="showUnitDetails" />
      </div>
    </div>
    <CostUnitDetails :selectedProductId="selectedProductId" :selectedProductName="selectedProductName" :selectedDetails="selectedDetails" :selectedCostUnit="selectedCostUnit"></CostUnitDetails>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeMount, watch } from "vue";
  import FilterBar from "../components/C_FilterBar.vue";
  import BudgetSummary from "../components/C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { ISelectedFilter } from "@/utils/interfaces/ISelectedFilter";
  import CostUnitCard from "@/components/cards/C_CostUnitCard.vue";
  import CostUnitStatus from "@/components/C_CostUnitStatus.vue";
  import CostUnitDetails from "@/components/modals/C_CostUnitDetails.vue";
  import { useRouter, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import store from "@/store";

  export default defineComponent({
    name: "CostUnitOverview",
    components: {
      FilterBar,
      BudgetSummary,
      CostUnitCard,
      CostUnitStatus,
      CostUnitDetails,
    },
    beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
      const appConfiguration = computed(() => store.getters.getAppConfiguration);

      const costUnitsEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.cost_units_enabled;
        } else {
          return true;
        }
      });

      if (costUnitsEnabled.value) {
        next();
      } else {
        next("/");
      }
    },
    setup() {
      const { FETCH_COST_UNITS, FETCH_FILTERED_COST_UNITS, CLEAR_FILTERED_COST_UNITS, FETCH_COST_UNIT_FILTERS, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
      const header = "Filter";
      const selectedFilters = ref<ISelectedFilter[]>([]);
      const filtersLoaded = ref(false);
      const isFiltersLoading = ref(false);
      const areCostUnitsFetched = ref(false);
      const store = useStore();
      const router = useRouter();
      const selectedProductId = ref("0");
      const selectedProductName = ref("");
      const selectedDetails = ref("");
      const selectedCostUnit = ref("");
      const selectedCostUnitID = ref("");
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      store.dispatch(CLEAR_FILTERED_COST_UNITS);

      onBeforeMount(async () => {
        isFiltersLoading.value = true;
        await store.dispatch(FETCH_COST_UNIT_FILTERS);
        await store.dispatch(FETCH_COST_UNITS);
      });

      const showUnitDetails = (productId: string, productName: string, option: string) => {
        selectedProductId.value = productId;
        selectedProductName.value = productName;
        selectedDetails.value = option;
      };

      const filters = computed(() => store.getters.getCostUnitFilters);
      const allCostUnits = computed(() => store.getters.getCostUnits);
      const filteredCostUnits = computed(() => store.getters.getFilteredCostUnits);

      const costUnitProducts = computed(() => {
        let unitProducts = [];
        for (let unit of filteredCostUnits.value) {
          unitProducts.push(...unit.products);
        }
        return unitProducts.sort((a, b) => a.name.localeCompare(b.name));
      });

      const status = computed(() => {
        if (filteredCostUnits.value !== undefined && filteredCostUnits.value.length > 0) {
          return filteredCostUnits.value[0].status;
        } else {
          return "Status";
        }
      });

      const statusDate = computed(() => {
        if (filteredCostUnits.value !== undefined && filteredCostUnits.value.length > 0) {
          return filteredCostUnits.value[0].statusdate;
        } else {
          return "Status Date";
        }
      });

      const totals = computed(() => {
        let totalBUDG = 0;
        let totalFCST = 0;
        let totalACT = 0;
        for (const element of costUnitProducts.value) {
          totalBUDG += Number(element.totalbudget);
          totalFCST += Number(element.totalforecast);
          totalACT += Number(element.totalactuals);
        }

        return {
          totalBUDG,
          totalFCST,
          totalACT,
        };
      });

      const handleFilterSubmit = (selectedFilter: ISelectedFilter[]) => {
        if (selectedFilter.length > 0) {
          selectedFilters.value = selectedFilter;
          let str = "";
          for (const id in selectedFilter) {
            const flag = str === "" ? "?" : "&";
            let name = selectedFilter[id].name;

            if (selectedFilter[id].label === "Cost Unit") {
              selectedCostUnit.value = name;
              selectedCostUnitID.value = selectedFilter[id].option;
            }
            if (name.includes("&")) {
              name = name.replace(/&/g, "%26");
            }
            if (selectedFilter[id].label === "Cost Unit") {
              str = str + `${flag}name=${name}`;
            }
            if (selectedFilter[id].label === "Product Family") {
              if (selectedFilter[id].name.includes("&")) {
                selectedFilter[id].name = selectedFilter[id].name.replace(/&/g, "%26");
              }
              str = str + `${flag}productfamily=${selectedFilter[id].name}`;
            }
          }
          store.dispatch(FETCH_FILTERED_COST_UNITS, str);
          areCostUnitsFetched.value = true;
        }
      };

      const handleManageClick = () => {
        router.push({
          path: "/manage-cost-unit",
          query: {
            unit: selectedCostUnit.value,
          },
        });
      };

      watch(
        () => filters.value,
        (newFilters) => {
          if (newFilters.length > 0) {
            filtersLoaded.value = true;
          }
        }
      );

      if (userRoles.value.length > 0 && !userRoles.value.includes("edit-cost-unit")) {
        router.push("/");
      }

      watch([userRoles], () => {
        if (!userRoles.value.includes("edit-cost-unit")) {
          router.push("/");
        }
      });

      return {
        header,
        filters,
        handleFilterSubmit,
        handleManageClick,
        filtersLoaded,
        isFiltersLoading,
        showUnitDetails,
        selectedProductId,
        selectedProductName,
        selectedDetails,
        filteredCostUnits,
        allCostUnits,
        costUnitProducts,
        totals,
        selectedCostUnit,
        selectedCostUnitID,
        status,
        statusDate,
        areCostUnitsFetched,
      };
    },
  });
</script>
