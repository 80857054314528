<template>
  <div class="c-productcard__wrapper">
    <img class="c-productcard__product-image" :src="productImage" alt="placeholder" @click="handleOverviewClick(product.id, product.name, product_po_forecast, product_invoice_actuals)" />
    <div class="c-productcard__info">
      <div>
        <p class="c-productcard__product-name" data-testid="product-name">{{ product.name }}</p>

        <span v-if="product.platform_cost != null" class="c-productcard__family-name-tag"> Platform </span>
        <p v-else class="c-productcard__type">Product</p>
      </div>
      <img @click="handleGraphClick" :src="'./graph.svg'" width="26" height="26" alt="product details icon" />
    </div>

    <div class="c-projapp__owner-wrapper">
      <p class="c-projapp__owner-label">Owner:</p>
      <p>{{ ownerUsername }}</p>
    </div>
    <div class="c-productcard__summary">
      <BudgetSummary
        size="small"
        :budget="Number(product.budget)"
        :target="Number(product.target)"
        :forecast="Number(product.capitalforecast) + Number(product.expensesforecast) + Number(product_po_forecast)"
        :actuals="Number(product.capitalactuals) + Number(product.expensesactuals) + Number(product_invoice_actuals)"
        :budgetlimit="Number(product.budget_limit)"
        :productforecast="Number(product_po_forecast)"
        :platformforecast="Number(product.total_consumers_forecast)"
      ></BudgetSummary>
      <div class="c-productcard__button-wrapper">
        <button type="button" class="cst-button-primary c-productcard__button" @click="handleOverviewClick(product.id, product.name, product_po_forecast, product_invoice_actuals)" data-testid="productcard-overviewbtn">Overview</button>
        <button type="button" class="cst-button-primary c-productcard__button" @click="handleDetailsClick" data-testid="productcard-detailsbtn">Details</button>
      </div>
    </div>
    <ProductModal v-if="isProdModalOpen" :prodName="product.name" :is-modal-enabled="isProdModalOpen" @isProdModalVisible="isProdModalOpen = false"></ProductModal>
  </div>
</template>

<script lang="ts">
  import ProductModal from "../modals/C_ProdModal.vue";
  import { computed, defineComponent, PropType, ref, toRefs } from "vue";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IUser } from "@/utils/interfaces/IUser";

  export default defineComponent({
    name: "ProductCard",
    components: {
      BudgetSummary,
      ProductModal,
    },
    props: {
      product: {
        type: Object as PropType<IProduct>,
        required: true,
      },
    },
    emits: ["show-product-details", "selected-product-id-name"],
    setup(props, { emit }) {
      const store = useStore();
      const { CHANGE_PRODUCT_DETAILS_VIEW, FETCH_PRODUCT_PURCHASE_ORDERS } = VUEX_ACTIONS;

      const productImage = ref("");
      const ownerUsername = ref("");
      const users = computed(() => store.getters.getUsers);
      const isProdModalOpen = ref(false);

      const { product } = toRefs(props);
      if (product.value.image_data != null) {
        productImage.value = product.value.image_data;
      } else {
        productImage.value = "https://placehold.co/240x140";
      }

      const handleGraphClick = () => {
        store.dispatch(CHANGE_PRODUCT_DETAILS_VIEW, true);
        emit("show-product-details", props.product.id);
      };

      const handleDetailsClick = () => {
        store.dispatch(FETCH_PRODUCT_PURCHASE_ORDERS, props.product.id);

        isProdModalOpen.value = true;
      };

      function convertUsernameFromId() {
        if (users.value.length > 0) {
          users.value.filter((user: IUser) => {
            if (user.id === props.product.owner) {
              ownerUsername.value = user.username;
            }
          });
        }
      }

      convertUsernameFromId();

      const product_po_forecast =
        Number(product.value.product_PR_fce) + Number(product.value.product_PR_fci) + Number(product.value.product_PR_fcm) + Number(product.value.product_PR_fee) + Number(product.value.product_PR_fei) + Number(product.value.product_PR_fem);

      const product_invoice_actuals =
        Number(product.value.prod_invoice_aci) + Number(product.value.prod_invoice_acm) + Number(product.value.prod_invoice_ace) + Number(product.value.prod_invoice_aei) + Number(product.value.prod_invoice_aem) + Number(product.value.prod_invoice_aee);

      const handleOverviewClick = (productId: number | undefined, productName: string | undefined, productPOForecast: number | undefined, prodInvoiceActuals: number | null) => {
        emit("selected-product-id-name", productId, productName, productPOForecast, prodInvoiceActuals);
      };

      return {
        handleGraphClick,
        productImage,
        handleOverviewClick,
        convertUsernameFromId,
        ownerUsername,
        handleDetailsClick,
        isProdModalOpen,
        product_po_forecast,
        product_invoice_actuals,
      };
    },
  });
</script>
