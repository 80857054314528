import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "cst-inputbox c-createnew__dialog" }
const _hoisted_2 = { class: "cst-inputbox--header" }
const _hoisted_3 = {
  class: "cst-inputbox--header__title",
  "data-testid": "cst-inputbox-title"
}
const _hoisted_4 = { class: "cst-inputbox--body" }
const _hoisted_5 = { class: "cst-inputbox__form" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = ["value", "selected"]
const _hoisted_8 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["value", "selected"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "cst-input-field" }
const _hoisted_16 = { class: "cst-input-field" }
const _hoisted_17 = ["value", "selected"]
const _hoisted_18 = { class: "cst-inputbox__actions" }
const _hoisted_19 = { class: "cst-inputbox--flex-wrapper" }
const _hoisted_20 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CostTypeInputs = _resolveComponent("CostTypeInputs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.props.isModal && _ctx.props.openModal ? 'cst-inputbox__modal-overlay' : '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, " Create a new " + _toDisplayString(_ctx.props.type.charAt(0).toUpperCase() + _ctx.props.type.slice(1)), 1),
        (_ctx.isModal)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cst-inputbox--header__close cst-button-close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModalInternal && _ctx.closeModalInternal(...args))),
              "data-testid": "proj-app-close-modal"
            }, "X"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[13] || (_cache[13] = _createElementVNode("label", { for: "proj-app-owner" }, "Owner:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                id: "proj-app-owner",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectOwner($event))),
                required: "",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preSelectedOwner) = $event)),
                "data-testid": "proj-app-select-own"
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("option", {
                  disabled: "",
                  selected: "",
                  value: ""
                }, "Select Owner", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (owner) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: owner.id,
                    value: owner.id,
                    selected: owner.username === _ctx.selectedOwner
                  }, _toDisplayString(owner.username), 9, _hoisted_7))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.preSelectedOwner]
              ])
            ]),
            (!_ctx.props.isModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", { for: "selectproductfamily" }, "Select product family", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    id: "selectproductfamily",
                    required: "",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedProductFamily) = $event)),
                    onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fetchProductsFromPF && _ctx.fetchProductsFromPF(...args)))
                  }, [
                    _cache[14] || (_cache[14] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a product family", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamily, (family) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: family.id,
                        value: family.id,
                        id: "selectedFamilyProduct"
                      }, _toDisplayString(family.name), 9, _hoisted_9))
                    }), 128))
                  ], 544), [
                    [_vModelSelect, _ctx.selectedProductFamily]
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _cache[18] || (_cache[18] = _createElementVNode("label", { for: "proj-app-product" }, "Product", -1)),
              (_ctx.props.isModal)
                ? (_openBlock(), _createElementBlock("select", {
                    key: 0,
                    class: "cst-select-field",
                    id: "proj-app-product",
                    onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleSelectProduct($event))),
                    required: "",
                    disabled: _ctx.props.isModal
                  }, [
                    _cache[16] || (_cache[16] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a Product", -1)),
                    _createElementVNode("option", {
                      value: _ctx.preselectedProductId,
                      selected: _ctx.newlyCreatedProduct.name
                    }, _toDisplayString(_ctx.newlyCreatedProduct.name), 9, _hoisted_12)
                  ], 40, _hoisted_11))
                : _withDirectives((_openBlock(), _createElementBlock("select", {
                    key: 1,
                    class: "cst-select-field",
                    id: "proj-app-product",
                    onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleSelectProduct($event))),
                    required: "",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedProduct) = $event)),
                    disabled: _ctx.isProductSelectDisabled
                  }, [
                    _cache[17] || (_cache[17] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a Product", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProducts, (product) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: product.id,
                        value: product.id
                      }, _toDisplayString(product.name), 9, _hoisted_14))
                    }), 128))
                  ], 40, _hoisted_13)), [
                    [_vModelSelect, _ctx.selectedProduct]
                  ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_15, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "proj-app-name" }, "Name:", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "text",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.projectOrAppData.name) = $event)),
                  id: "proj-app-name",
                  required: "",
                  maxlength: "32",
                  "data-testid": "proj-app-name"
                }, null, 512), [
                  [_vModelText, _ctx.projectOrAppData.name]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': _ctx.projectOrAppData.name?.length == 32,
                  }]),
                  "data-testid": "proj-app-indicator"
                }, _toDisplayString(_ctx.projectOrAppData.name?.length ? `${_ctx.projectOrAppData.name.length}` : `0`) + "/32", 3)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _cache[21] || (_cache[21] = _createElementVNode("label", { for: "proj-app-currency" }, "Currency", -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "cst-select-field",
                  id: "proj-app-currency",
                  onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleSelectCurrency($event))),
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.projectOrAppData.currency) = $event)),
                  "data-testid": "proj-app-currency"
                }, [
                  _cache[20] || (_cache[20] = _createElementVNode("option", {
                    disabled: "",
                    selected: "",
                    value: ""
                  }, "Select a Currency", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (currency) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: currency.id,
                      value: currency.id,
                      selected: currency.id === _ctx.selectedCurrency
                    }, _toDisplayString(currency.id), 9, _hoisted_17))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.projectOrAppData.currency]
                ])
              ])
            ]),
            _createVNode(_component_CostTypeInputs, {
              "available-budget": _ctx.availableBudget,
              onEmitCostValues: _ctx.handleEmitCostValues,
              onEmitSumTotal: _ctx.handleEmitSumTotal
            }, null, 8, ["available-budget", "onEmitCostValues", "onEmitSumTotal"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                class: "submit-button cst-button cst-button-primary",
                type: "submit",
                id: "proj-app-submitbtn",
                "data-testid": "proj-app-submitbtn",
                disabled: _ctx.sumIsZero || (_ctx.availableBudget !== null && Number(_ctx.sumTotal) > Number(_ctx.availableBudget))
              }, "Submit", 8, _hoisted_20)
            ])
          ])
        ], 32)
      ])
    ])
  ], 2))
}