export const fetchTodaysDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const day = String(currentDate.getDate());
  const month = currentDate.getMonth() + 1;
  const refactoredDay = () => {
    if (day.toString().length < 2) {
      return `0${day}`;
    } else {
      return day;
    }
  };
  const refactoredMonth = () => {
    if (month.toString().length < 2) {
      return `0${month}`;
    } else {
      return month;
    }
  };
  // Returns YYYY-MM-DD format
  const formattedDate = `${year}-${refactoredMonth()}-${refactoredDay()}`;
  return formattedDate;
};
