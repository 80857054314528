import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "cst-dropdown",
  "data-testid": "user-dropdown"
}
const _hoisted_2 = {
  key: 0,
  class: "cst-dropdown--information"
}
const _hoisted_3 = { class: "cst-dropdown--information--values" }
const _hoisted_4 = { class: "cst-dropdown--information--data" }
const _hoisted_5 = {
  class: "cst-dropdown--value",
  "data-testid": "user-dropdown-username"
}
const _hoisted_6 = { class: "cst-dropdown--information--data" }
const _hoisted_7 = { class: "cst-dropdown--value" }
const _hoisted_8 = {
  key: 0,
  class: "cst-dropdown-logout-btn-wrapper"
}
const _hoisted_9 = { class: "cst-dropdown-logout-btn-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "cst-dropdown--label",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      "data-testid": "toggle-dropdown-button"
    }, "User"),
    (_ctx.getUserDropdown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "cst-dropdown--name" }, "User:", -1)),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.currentUser), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "cst-dropdown--name" }, "Roles:", -1)),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userGroups, (value, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(value), 1))
                }), 128))
              ])
            ])
          ]),
          (_ctx.canAccessActivityLog)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "cst-button cst-button-primary",
                  "data-testid": "activity-logs-button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDownloadLogs && _ctx.handleDownloadLogs(...args)))
                }, "Activity Logs")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              type: "button",
              class: "cst-button cst-button-primary",
              "data-testid": "logout-button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
            }, "Logout")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}