import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "c-newproduct__wrapper",
  "data-testid": "newprod-wrapper"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "c-newproduct__name" }
const _hoisted_4 = { class: "c-newproduct__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BudgetSummary = _resolveComponent("BudgetSummary")!
  const _component_ProjectApplicationForm = _resolveComponent("ProjectApplicationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "c-newproduct__image",
      src: _ctx.product.image === '' ? 'https://placehold.co/240x140' : _ctx.base64image,
      alt: "Product",
      "data-testid": "newprod-image"
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.product.name), 1),
    _createVNode(_component_BudgetSummary, {
      size: "small",
      budget: _ctx.totalBudget,
      forecast: _ctx.product.forecast,
      actuals: _ctx.product.actuals
    }, null, 8, ["budget", "forecast", "actuals"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        class: "cst-button cst-button-primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAddProject && _ctx.handleAddProject(...args))),
        "data-testid": "newprod-addprojectbtn"
      }, "Add Project"),
      _createElementVNode("button", {
        type: "button",
        class: "cst-button cst-button-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAddApplication && _ctx.handleAddApplication(...args))),
        "data-testid": "newprod-addapplicationbtn"
      }, "Add Application")
    ]),
    (_ctx.projectModalState)
      ? (_openBlock(), _createBlock(_component_ProjectApplicationForm, {
          key: 0,
          type: 'project',
          isModal: true,
          openModal: _ctx.projectModalState,
          preselectProduct: true,
          closeModal: _ctx.closeProjectModal,
          onEmitCostTypeInputs: _ctx.handleEmitCostValues,
          onEmitExistingBudget: _ctx.handleExistingBudget,
          "data-testid": "newprod-projectapplicationform"
        }, null, 8, ["openModal", "closeModal", "onEmitCostTypeInputs", "onEmitExistingBudget"]))
      : _createCommentVNode("", true),
    (_ctx.appModalState)
      ? (_openBlock(), _createBlock(_component_ProjectApplicationForm, {
          key: 1,
          type: 'application',
          isModal: true,
          openModal: _ctx.appModalState,
          preselectProduct: true,
          closeModal: _ctx.closeAppModal,
          onEmitCostTypeInputs: _ctx.handleEmitCostValues,
          onEmitExistingBudget: _ctx.handleExistingBudget
        }, null, 8, ["openModal", "closeModal", "onEmitCostTypeInputs", "onEmitExistingBudget"]))
      : _createCommentVNode("", true)
  ]))
}