import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cst-new-product-type--wrapper",
  "data-testid": "budgetrequestreview-wrapper"
}
const _hoisted_2 = { class: "cst-inputbox--header" }
const _hoisted_3 = {
  class: "cst-inputbox--header__title",
  "data-testid": "budgetrequestreview-inputbox-header"
}
const _hoisted_4 = {
  key: 0,
  class: "c-budgetrequestreview",
  "data-testid": "budgetrequestreview-no-such-entities"
}
const _hoisted_5 = {
  key: 1,
  class: "c-budgetrequestreview--empty",
  "data-testid": "budgetrequestreview-empty"
}
const _hoisted_6 = {
  key: 2,
  "data-testid": "budgetrequestreview-spinner",
  class: "cst-spinner"
}

import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import C_BudgetCard from "./cards/C_BudgetCard.vue";
  import C_FutureBudgetModal from "./modals/C_FutureBudgetModal.vue";
  import C_FutureBudgetDecisionModal from "./modals/C_FutureBudgetDecisionModal.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import C_Search from "./C_Search.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_BudgetRequestReview',
  props: {
    isoverview: Boolean,
    financialGoalLabel: String,
  },
  setup(__props) {

  const { FETCH_FUTURE_BUDGETS, FETCH_SPECIFIC_FUTURE_BUDGET, FETCH_FUTURE_PROPOSAL_FILTER } = VUEX_ACTIONS;
  const props = __props;
  const store = useStore();
  const futureBudget = computed(() => {
    const budget = store.getters.getFutureBudget;
    if (props.isoverview) {
      return budget.filter((item: IBudgetPlan) => item.status == "approved");
    } else {
      return budget.filter((item: IBudgetPlan) => item.status !== "approved");
    }
  });
  const filteredBudget = ref(futureBudget.value);
  const isBudgetFetched = computed(() => store.getters.getFutureBudgetFetched);
  const selectedEntity = ref<IBudgetPlan>({});
  const showDetailsModal = ref(false);
  const showDecisionModal = ref(false);
  const isApproval = ref(false);
  store.dispatch(FETCH_FUTURE_BUDGETS);

  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);

  const handleFutureBudgetDetails = (entity: IBudgetPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_BUDGET, selectedEntity.value.id);
  };

  const handleFutureBudgetApprove = (entity: IBudgetPlan) => {
    isApproval.value = true;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const handleFutureBudgetDecline = (entity: IBudgetPlan) => {
    isApproval.value = false;
    selectedEntity.value = entity;
    showDecisionModal.value = !showDecisionModal.value;
  };

  const filterFutureBudgetPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredBudget.value = futureBudget.value.filter((budget: IBudgetPlan) => {
      if (budget?.entity_name) return budget?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  watch([futureBudget], () => {
    filteredBudget.value = futureBudget.value;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (futureBudget.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["cst-inputbox", { 'c-budgetrequestreview--overview': props.isoverview }]),
            "data-testid": "budgetrequestreview-inputbox"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(props.isoverview ? `Approved Future ${__props.financialGoalLabel} Entities` : "Requests In Review"), 1)
            ]),
            _createVNode(C_Search, {
              onInput: _cache[0] || (_cache[0] = ($event: any) => (filterFutureBudgetPlans($event))),
              placeholder: `Search future ${__props.financialGoalLabel}...`
            }, null, 8, ["placeholder"]),
            _createElementVNode("div", {
              class: _normalizeClass(["c-budgetrequestreview--wrapper", {
          'c-budgetrequestreview--wrapper--overview': props.isoverview,
        }]),
              "data-testid": "budgetrequestreview-card-wrapper"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredBudget.value, (entity) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "c-budgetrequestreview",
                  key: entity.id
                }, [
                  _createVNode(C_BudgetCard, {
                    onFutureBudgetDetails: handleFutureBudgetDetails,
                    onFutureBudgetApprove: handleFutureBudgetApprove,
                    onFutureBudgetDecline: handleFutureBudgetDecline,
                    entity: entity,
                    isoverview: props.isoverview,
                    financialGoalLabel: __props.financialGoalLabel
                  }, null, 8, ["entity", "isoverview", "financialGoalLabel"])
                ]))
              }), 128)),
              (filteredBudget.value.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, "There are no such entities."))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ]))
      : (__props.isoverview && isBudgetFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "No approved future budget requests available."))
        : (!isBudgetFetched.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6))
          : _createCommentVNode("", true),
    (showDetailsModal.value)
      ? (_openBlock(), _createBlock(C_FutureBudgetModal, {
          key: 3,
          onCloseDetailsModal: _cache[1] || (_cache[1] = ($event: any) => (showDetailsModal.value = !showDetailsModal.value)),
          entity: selectedEntity.value,
          financialGoalLabel: __props.financialGoalLabel
        }, null, 8, ["entity", "financialGoalLabel"]))
      : _createCommentVNode("", true),
    (showDecisionModal.value)
      ? (_openBlock(), _createBlock(C_FutureBudgetDecisionModal, {
          key: 4,
          entity: selectedEntity.value,
          onCloseDecisionModal: _cache[2] || (_cache[2] = ($event: any) => (showDecisionModal.value = !showDecisionModal.value)),
          "is-approval": isApproval.value,
          financialGoalLabel: __props.financialGoalLabel
        }, null, 8, ["entity", "is-approval", "financialGoalLabel"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})