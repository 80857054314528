<template>
  <div class="v-productcuallocation">
    <h1 class="v-polist__title-all">Product Cost Unit Allocation</h1>
    <div v-if="productCuAllocationFetched && productCuAllocation.costUnits.length > 0" class="v-productcuallocation__table-wrapper">
      <table class="v-polist__table v-polist__list-table" aria-describedby="Table of cost units the product is allocated in">
        <thead>
          <tr class="v-polist__table-head-row">
            <th>
              <strong>Product</strong>
            </th>
            <th v-for="unit in costUnits" :key="unit.id">
              {{ unit.name }}
            </th>
            <th>
              <strong>Total</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="v-polist__table-row">
            <td>
              <strong>{{ product.name }}</strong>
            </td>
            <td v-for="unit in costUnits" :key="unit.id">
              <div v-if="productCuAllocation.costUnits.find((cu: any) => cu.costunitname === unit.name)">
                {{ Number(productCuAllocation.costUnits.find((cu: any) => cu.costunitname === unit.name).percentage) * 100 + " %" }}
              </div>
              <div v-else>0</div>
            </td>

            <td>
              <strong> {{ Number(productCuAllocation.totalPercentageSum) * 100 + " %" }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="productCuAllocationFetched && productCuAllocation.costUnits.length == 0" class="v-productcuallocation__label-empty">
      <p>This product is not allocated to any cost units.</p>
    </div>
    <div v-else class="cst-spinner"></div>
  </div>
</template>

<script setup lang="ts">
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";

  const { FETCH_COST_UNITS, FETCH_PRODUCT_CU_ALLOCATION, FETCH_SINGLE_PRODUCT } = VUEX_ACTIONS;

  const route = useRoute();
  const router = useRouter();
  const checkUnitQuery = () => {
    // If there is no product in the query, redirect to the home page
    if (!route.query.product) {
      router.push("/");
    }
  };
  checkUnitQuery();

  const productId = route.query.product;

  const store = useStore();
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const costUnits = computed(() => store.getters.getCostUnits);
  const productCuAllocation = computed(() => store.getters.getProductCuAllocation);
  const productCuAllocationFetched = computed(() => store.getters.getProductCuAllocationFetched);
  const product = computed(() => store.getters.getProduct);

  store.dispatch(FETCH_COST_UNITS);
  store.dispatch(FETCH_PRODUCT_CU_ALLOCATION, productId);
  store.dispatch(FETCH_SINGLE_PRODUCT, productId);

  if (userRoles.value.length > 0 && !(userRoles.value.includes("edit-product-hierarchy") || userRoles.value.includes("view-product-hierarchy") || userRoles.value.includes("view-all-product"))) {
    router.push("/");
  }
</script>
