import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import KeyCloakService from "./security/KeycloakService";

KeyCloakService.CallLogin(() => {
  const app = createApp(App);
  app.use(store).use(router).mount("#app");
});
