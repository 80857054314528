import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";

interface SuppliersState {
  suppliers: string[];
}

const state: SuppliersState = {
  suppliers: [],
};

interface SuppliersContext {
  commit: Commit;
  state: SuppliersState;
}

const mutations = {
  setSuppliers(state: SuppliersState, payload: string[]) {
    state.suppliers = payload;
  },
};

const getters = {
  getSuppliers: (state: SuppliersState) => state.suppliers,
};

const actions = {
  fetchSuppliers(context: SuppliersContext) {
    AXIOS_REQUEST("GET", "/suppliers")
      .then((response) => {
        const suppliersDO: SuppliersState[] = [];
        for (const id in response.data) {
          suppliersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setSuppliers", suppliersDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const suppliersModule = {
  state,
  getters,
  mutations,
  actions,
};

export default suppliersModule;
