<template>
  <div class="c-entitycard__card">
    <div class="c-entitycard__action-row">
      <button type="button" class="cst-button-close c-entitycard__btn" @click="removeEntity" data-testid="entity-removeentity-btn" :disabled="isprcreated && !isediting">X</button>
    </div>

    <div class="c-entitycard__entity-info" data-testid="entity-info">
      {{ data.application !== null && data.application !== undefined ? findApplication() : data.project !== null && data.project !== undefined ? findProject() : findProduct() }}
    </div>

    <div class="c-entitycard__entity-type" data-testid="entity-entitytype">
      {{ entityType }}
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType, ref } from "vue";
  import { IEntityData } from "@/utils/interfaces/IEntityData";
  import { useStore } from "vuex";

  export default defineComponent({
    name: "EntityCard",
    props: {
      data: {
        type: Object as PropType<IEntityData>,
        required: true,
      },
      isprcreated: {
        type: Boolean,
        required: true,
      },
      isediting: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      removeEntity() {
        let entityToRemove;

        if (this.data.application !== null) {
          entityToRemove = this.data.application;
        } else if (this.data.project !== null) {
          entityToRemove = this.data.project;
        } else {
          entityToRemove = this.data.product;
        }
        this.$emit("removeEntity", entityToRemove);
      },
    },
    setup(props) {
      const store = useStore();
      const products = computed(() => store.getters.getAllProducts);
      const projects = computed(() => store.getters.getProjects);
      const applications = computed(() => store.getters.getApplications);
      const entityType = ref("product");
      const findProduct = () => {
        const product = products.value.filter((p: { id: string }) => Number(p.id) == props.data.product);
        entityType.value = "product";
        return product[0]?.name;
      };

      const findProject = () => {
        const project = projects.value.filter((pr: { id: string }) => Number(pr.id) == props.data.project);
        entityType.value = "project";
        return project[0]?.name;
      };

      const findApplication = () => {
        const app = applications.value.filter((a: { id: string }) => Number(a.id) == props.data.application);
        entityType.value = "application";
        return app[0]?.name;
      };

      return {
        findProduct,
        findProject,
        findApplication,
        entityType,
      };
    },
  });
</script>
