import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IPurchaseOrder, IUserOrders } from "@/utils/interfaces/IPurchaseOrder";

interface PurchaseOrdersState {
  purchaseOrders: IPurchaseOrder[];
  renewOrderItem: IUserOrders | null;
  applicationPurchaseOrders: IPurchaseOrder[];
  projectPurchaseOrders: IPurchaseOrder[];
  areOrdersFetched: boolean;
  isProjectPOListFetched: boolean;
  isApplicationPOListFetched: boolean;
  productPurchaseOrders: IPurchaseOrder[];
  isProductPOListFetched: boolean;
}

interface PurchaseOrdersContext {
  commit: Commit;
  state: PurchaseOrdersState;
  dispatch: Dispatch;
}

const state: PurchaseOrdersState = {
  purchaseOrders: [],
  renewOrderItem: null,
  applicationPurchaseOrders: [],
  projectPurchaseOrders: [],
  areOrdersFetched: false,
  isProjectPOListFetched: false,
  isApplicationPOListFetched: false,
  productPurchaseOrders: [],
  isProductPOListFetched: false,
};

const mutations = {
  setPurchaseOrders(state: PurchaseOrdersState, payload: IPurchaseOrder[]) {
    state.purchaseOrders = payload;
  },
  setRenewOrderItem(state: PurchaseOrdersState, payload: IUserOrders) {
    state.renewOrderItem = payload;
  },
  setApplicationPurchaseOrders(state: PurchaseOrdersState, payload: IPurchaseOrder[]) {
    state.applicationPurchaseOrders = payload;
  },
  setProjectPurchaseOrders(state: PurchaseOrdersState, payload: IPurchaseOrder[]) {
    state.projectPurchaseOrders = payload;
  },
  setAreOrdersFetched(state: PurchaseOrdersState, areFetched: boolean) {
    state.areOrdersFetched = areFetched;
  },
  setIsProjectPOListFetched(state: PurchaseOrdersState, isFetched: boolean) {
    state.isProjectPOListFetched = isFetched;
  },
  setIsApplicationPOListFetched(state: PurchaseOrdersState, isFetched: boolean) {
    state.isApplicationPOListFetched = isFetched;
  },
  setProductPurchaseOrders(state: PurchaseOrdersState, payload: IPurchaseOrder[]) {
    state.productPurchaseOrders = payload;
  },
  setIsProductPOListFetched(state: PurchaseOrdersState, isFetched: boolean) {
    state.isProductPOListFetched = isFetched;
  },
};

const getters = {
  getPurchaseOrders: (state: PurchaseOrdersState) => state.purchaseOrders,
  getRenewOrderItem: (state: PurchaseOrdersState) => state.renewOrderItem,
  getApplicationPurchaseOrders: (state: PurchaseOrdersState) => state.applicationPurchaseOrders,
  getProjectPurchaseOrders: (state: PurchaseOrdersState) => state.projectPurchaseOrders,
  getAreOrdersFetched: (state: PurchaseOrdersState) => state.areOrdersFetched,
  getIsApplicationPOListFetched: (state: PurchaseOrdersState) => state.isApplicationPOListFetched,
  getIsProjectPOListFetched: (state: PurchaseOrdersState) => state.isProjectPOListFetched,
  getProductPurchaseOrders: (state: PurchaseOrdersState) => state.productPurchaseOrders,
  getIsProductPOListFetched: (state: PurchaseOrdersState) => state.isProductPOListFetched,
};

const actions = {
  fetchPurchaseOrders(context: PurchaseOrdersContext) {
    AXIOS_REQUEST("GET", "/purchaseorders")
      .then((response) => {
        const purchaseOrdersDO: IPurchaseOrder[] = [];
        for (const id in response.data) {
          purchaseOrdersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setPurchaseOrders", purchaseOrdersDO);
        context.commit("setAreOrdersFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },
  fetchProjectPurchaseOrders(context: PurchaseOrdersContext, projectID: number) {
    AXIOS_REQUEST("GET", `/projects/${projectID}/purchaseorders`)
      .then((response) => {
        const purchaseOrdersDO: IPurchaseOrder[] = [];
        for (const id in response.data) {
          purchaseOrdersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setProjectPurchaseOrders", purchaseOrdersDO);
        context.commit("setIsProjectPOListFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.commit("setIsProjectPOListFetched", true);
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },
  fetchApplicationPurchaseOrders(context: PurchaseOrdersContext, applicationID: number) {
    AXIOS_REQUEST("GET", `/applications/${applicationID}/purchaseorders`)
      .then((response) => {
        const purchaseOrdersDO: IPurchaseOrder[] = [];
        for (const id in response.data) {
          purchaseOrdersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setApplicationPurchaseOrders", purchaseOrdersDO);
        context.commit("setIsApplicationPOListFetched", true);
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 403) {
          context.commit("setIsApplicationPOListFetched", true);
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to use this feature.`);
        }
      });
  },
  fetchProductPurchaseOrders(context: PurchaseOrdersContext, productID: number) {
    AXIOS_REQUEST("GET", `/products/${productID}/purchaseorders/`)
      .then((response) => {
        const purchaseOrdersDO: IPurchaseOrder[] = [];
        for (const id in response.data) {
          purchaseOrdersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setProductPurchaseOrders", purchaseOrdersDO);
        context.commit("setIsProductPOListFetched", true);
      })
      .catch((error) => {
        context.commit("setProductPurchaseOrders", []);
        console.error(error);
        if (error.response.status === 403) {
          context.commit("setIsProductPOListFetched", true);
          context.dispatch("updateRequestMessage", `ERROR: You don't have permission to see details!`);
        }
      });
  },
  changeIsPOListFetchedState(context: PurchaseOrdersContext) {
    context.commit("setIsProjectPOListFetched", false);
    context.commit("setIsApplicationPOListFetched", false);
    context.commit("setIsProductPOListFetched", false);
  },
};

const purchaseOrdersModule = {
  state,
  getters,
  mutations,
  actions,
};

export default purchaseOrdersModule;
