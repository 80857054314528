import { AXIOS_REQUEST } from "../../config";
import { IListItem } from "@/utils/interfaces/IListItem";
import { Commit, Dispatch } from "vuex";

interface ListItemsState {
  listItems: IListItem[];
}

interface ListItemsContext {
  commit: Commit;
  state: ListItemsState;
  dispatch: Dispatch;
}

const state: ListItemsState = {
  listItems: [],
};

const mutations = {
  setListItems(state: ListItemsState, payload: IListItem[]) {
    state.listItems = payload;
  },
};

const getters = {
  getListItems: (state: ListItemsState) => state.listItems,
};

const actions = {
  async fetchListItems(context: ListItemsContext, requestID: number) {
    await AXIOS_REQUEST("GET", `/purchaserequests/${requestID}/listitems`)
      .then((response) => {
        const listItemsDO: IListItem[] = [];
        for (const id in response.data) {
          listItemsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setListItems", listItemsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  createListItem(context: ListItemsContext, data: { requestID: number; payload: IListItem }) {
    AXIOS_REQUEST("POST", `/purchaserequests/${data.requestID}/listitems`, data.payload)
      .then((response) => {
        const updatedListItems = [...context.state.listItems];
        updatedListItems.push(response.data[0]);
        context.commit("setListItems", updatedListItems);
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", `Error when adding list item: ${error}`);
      });
  },

  assignCostType(context: ListItemsContext, data: { itemID: number; newType: string }) {
    AXIOS_REQUEST("POST", `/purchaserequests/assigntocosttype/${data.itemID}`, {
      costtype: data.newType,
    })
      .then(() => {
        const updatedListItems = [...context.state.listItems];
        const indexToUpdate = updatedListItems.findIndex((item) => item.id === data.itemID);
        if (indexToUpdate !== -1) {
          updatedListItems[indexToUpdate].costtype = data.newType;
        }
        context.commit("setListItems", updatedListItems);
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", `Error when updating list item: ${error}`);
      });
  },

  deleteListItem(context: ListItemsContext, data: { requestID: number; position: number }) {
    AXIOS_REQUEST("DELETE", `/purchaserequests/${data.requestID}/listitems/${data.position}`)
      .then((response) => {
        const listItemsDO: IListItem[] = [];
        for (const id in response.data) {
          listItemsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setListItems", listItemsDO);
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", `Error when deleting list item: ${error}`);
      });
  },
};

const listItemsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default listItemsModule;
