<template>
  <div class="c-dcdataentry__wrapper">
    <div class="c-dcdataentry__header">
      <div class="c-dcdataentry__header-wrapper">
        <h2 class="c-dcdataentry__header-title">IT Digital Cost Data Entry</h2>
        <h3 class="c-dcdataentry__header-subtitle">Update your Product Forecast and Budget in 3 simple steps</h3>
      </div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="c-dcdataentry__content">
        <div class="c-dcdataentry__content-steps">
          <p>Step 1 - Select your Product Family</p>
          <p>Step 2 - Select your Product</p>
          <p>Step 3 - Select your Project or Application</p>
          <p>Step 4 - Select Forecast or Budget</p>
          <span class="c-dcdataentry__content-budgetinfo">*Budget input option only available during budgeting periods</span>
        </div>
        <div class="c-dcdataentry__content-selects">
          <div class="cst-input-field">
            <label for="productfamily">Product Family</label>
            <select class="cst-select-field" id="select-prodfamily" required v-model="selectedData.productfamily">
              <option disabled selected value="">Select a Product Family</option>
              <option v-for="family in productFamilies" :key="family.id" :value="family.id" id="selectedFamily">
                {{ family.name }}
              </option>
            </select>
          </div>
          <div class="c-dcdataentry__content-selectpair">
            <div class="cst-input-field">
              <label for="product">Product:</label>
              <select class="cst-select-field" id="select-product" v-model="selectedData.product">
                <option disabled selected value="">Select a Product</option>
                <option v-for="product in userProducts" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="c-dcdataentry__content-selectpair">
            <div class="cst-input-field">
              <label for="project">Project:</label>
              <select class="cst-select-field" id="select-project" v-model="selectedData.project">
                <option disabled selected value="">Select a Project</option>
                <option v-for="project in userProjects" :key="project.id" :value="project.id">
                  {{ project.name }}
                </option>
              </select>
            </div>
            <p>or</p>
            <div class="cst-input-field">
              <label for="application">Application:</label>
              <select class="cst-select-field" id="select-application" v-model="selectedData.application">
                <option disabled selected value="">Select an Application</option>
                <option v-for="application in userApplications" :key="application.id" :value="application.id">
                  {{ application.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="c-dcdataentry__content-selectpair c-dcdataentry__content-forecast">
            <div class="c-dcdataentry__content-radio">
              <input type="radio" id="Forecast" name="forecast" value="Forecast" class="cst-radio" v-model="selectedData.option" checked />
              <label for="forecast">Forecast</label>
            </div>
            <p>or</p>
            <div class="c-dcdataentry__content-radio">
              <input type="radio" id="Budget" name="budget" value="Budget" class="cst-radio" v-model="selectedData.option" />
              <label for="budget">Budget</label>
            </div>
          </div>
        </div>
      </div>
      <div class="c-dcdataentry__enter-btn-container">
        <div class="c-dcdataentry__enter-btn-wrapper">
          <button type="submit" class="cst-button cst-button-primary">Enter</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "DigitalCostDataEntry",
    setup() {
      const { FETCH_APP, FETCH_ALL_PRODUCTS, FETCH_PROJECTS } = VUEX_ACTIONS;
      const store = useStore();
      const userProducts = computed(() => store.getters.getAllProducts);
      const userProjects = computed(() => store.getters.getProjects);
      const productFamilies = computed(() => store.getters.getAllProductFamilies);
      const userApplications = computed(() => store.getters.getApplications);
      store.dispatch(FETCH_ALL_PRODUCTS);
      store.dispatch(FETCH_PROJECTS);
      store.dispatch(FETCH_APP);

      const selectedData = ref({
        productfamily: null,
        product: null,
        project: null,
        application: null,
        option: "Forecast",
      });

      const handleSubmit = () => {
        console.log("selectedData.value");
      };

      return {
        userProducts,
        userProjects,
        userApplications,
        selectedData,
        handleSubmit,
        productFamilies,
      };
    },
  });
</script>
