import { AXIOS_REQUEST } from "../../config";
import { IUser } from "@/utils/interfaces/IUser";
import { Commit } from "vuex";

interface UsersState {
  users: IUser[];
  userOrders: IUser[];
  showUserDropdown: boolean;
}

const state: UsersState = {
  users: [],
  userOrders: [],
  showUserDropdown: false,
};

interface UsersContext {
  commit: Commit;
  state: UsersState;
}

const mutations = {
  setUsers(state: UsersState, payload: IUser[]) {
    state.users = payload;
  },
  setShowUserDropdown(state: UsersState, payload: boolean) {
    state.showUserDropdown = payload;
  },
};

const getters = {
  getUsers: (state: UsersState) => state.users,
  getShowUserDropdown: (state: UsersState) => state.showUserDropdown,
};

const actions = {
  fetchUsers(context: UsersContext) {
    AXIOS_REQUEST("GET", "/users")
      .then((response) => {
        const usersDO: IUser[] = [];
        for (const id in response.data) {
          usersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setUsers", usersDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  changeUserDropdownView(context: UsersContext, show: boolean) {
    context.commit("setShowUserDropdown", show);
  },
};

const usersModule = {
  state,
  getters,
  mutations,
  actions,
};

export default usersModule;
