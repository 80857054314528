import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "v-archive__main-container" }
const _hoisted_2 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "v-archive-spinner"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  class: "v-polist__table v-polist__list-table",
  "aria-describedby": "Table of purchase orders"
}
const _hoisted_7 = { class: "v-polist__table-head-row" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "vpolist" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { key: 2 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]

import { ref, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import { formatDate } from "@/utils/helpers/formatDate";
  import RequestDetails from "../components/modals/C_RequestDetails.vue";
  import RequestMessage from "../components/C_RequestMessage.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import C_Pagination from "@/components/C_Pagination.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_Archive',
  setup(__props) {

  const archiveEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.purchase_request_archive_enabled;
    } else {
      return true;
    }
  });

  const { FETCH_ARCHIVE, DELETE_FROM_ARCHIVE, RESTORE_FROM_ARCHIVE, SHOW_PR_DETAILS_VIEW, FETCH_NOTIFICATIONS, FETCH_ALL_PRODUCTS } = VUEX_ACTIONS;
  const store = useStore();

  const archiveList = computed(() => store.getters.getArchive);
  const currentUserRoles = computed(() => store.getters.getCurrentUserRoles);
  const canEdit = computed(() => currentUserRoles.value?.includes("edit-purchase-request"));
  const isArchiveFetched = computed(() => store.getters.getIsArchiveFetched);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedItemId = ref<number | undefined>(undefined);
  const selectedPRListItems = ref([]);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_ALL_PRODUCTS);
  const filteredArchiveList = ref(archiveList.value.slice(0, 10));
  const router = useRouter();
  const totalPages = computed(() => {
    if (archiveList.value.length > 10 && archiveList.value.length % 10 != 0) {
      return Math.floor(archiveList.value.length / 10) + 1;
    } else {
      return archiveList.value.length / 10;
    }
  });
  const paginatedList = ref(archiveList.value?.slice(0, 10));

  const isArchiveListEmpty = computed(() => {
    if (archiveList.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const handleDetails = (item: IPurchaseRequest) => {
    store.dispatch(SHOW_PR_DETAILS_VIEW, true);
    selectedItemId.value = Number(item.id);
    selectedPRListItems.value = item.listitems;
  };

  const handleEdit = (item: IPurchaseRequest) => {
    const stringifiedData = JSON.stringify(item);
    localStorage.setItem("archiveItemEdit", stringifiedData);
    store.commit("setIsEditingPr", true);
    router.push("/edit-purchase-request");
  };

  const fetchArchive = async () => {
    await store.dispatch(FETCH_ARCHIVE);
  };

  fetchArchive();

  const handleRestore = (itemId: number) => {
    store.dispatch(RESTORE_FROM_ARCHIVE, itemId);
  };

  const handleDelete = (itemId: number) => {
    store.dispatch(DELETE_FROM_ARCHIVE, itemId);
  };

  if (!archiveEnabled.value) {
    router.push("/");
  }

  watch([archiveList], () => {
    paginatedList.value = archiveList.value?.slice(0, 10);
    filteredArchiveList.value = archiveList.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IPurchaseRequest>) => {
    filteredArchiveList.value = filteredList;
    paginatedList.value = filteredList;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _cache[15] || (_cache[15] = _createElementVNode("h1", { class: "v-archive__requests-title" }, "Archived Requests", -1)),
      (!isArchiveFetched.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (isArchiveListEmpty.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
                  _createElementVNode("div", {
                    class: "v-archive__empty",
                    "data-testid": "v-archive-empty"
                  }, "Archive is empty.", -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", null, [
                    _createElementVNode("table", _hoisted_6, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", _hoisted_7, [
                          _cache[1] || (_cache[1] = _createElementVNode("th", null, "Description", -1)),
                          _cache[2] || (_cache[2] = _createElementVNode("th", null, "PR Owner", -1)),
                          (sbuEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_8, "SBU"))
                            : _createCommentVNode("", true),
                          (supplierEnabled.value)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_9, "Supplier"))
                            : _createCommentVNode("", true),
                          _cache[3] || (_cache[3] = _createElementVNode("th", null, "Order Amount", -1)),
                          _cache[4] || (_cache[4] = _createElementVNode("th", null, "Current Year", -1)),
                          _cache[5] || (_cache[5] = _createElementVNode("th", null, "Next Year", -1)),
                          _cache[6] || (_cache[6] = _createElementVNode("th", null, "Start Date", -1)),
                          _cache[7] || (_cache[7] = _createElementVNode("th", null, "Delivery Date", -1)),
                          _cache[8] || (_cache[8] = _createElementVNode("th", null, "Details", -1)),
                          (canEdit.value)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_10, "Edit"))
                            : _createCommentVNode("", true),
                          _cache[9] || (_cache[9] = _createElementVNode("th", null, "Restore", -1)),
                          _cache[10] || (_cache[10] = _createElementVNode("th", null, "Delete", -1))
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredArchiveList.value, (item) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: item.id,
                            class: "v-polist__table-row"
                          }, [
                            _createElementVNode("td", null, _toDisplayString(item.ordertext.length > 38 ? item.ordertext.slice(0, 36) + "..." : item.ordertext), 1),
                            _createElementVNode("td", null, _toDisplayString(item.prownername), 1),
                            (sbuEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_11, _toDisplayString(item?.sbuname), 1))
                              : _createCommentVNode("", true),
                            (supplierEnabled.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_12, _toDisplayString(item.suppliername), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)((Number(item.orderamount) * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                            _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                            _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                            _createElementVNode("td", null, _toDisplayString(_unref(formatDate)(item.podate)), 1),
                            _createElementVNode("td", _hoisted_13, _toDisplayString(_unref(formatDate)(item.deliverydate)), 1),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                type: "button",
                                title: "Details",
                                class: "c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin",
                                "data-testid": "v-archive-detailsbtn",
                                onClick: ($event: any) => (handleDetails(item))
                              }, _cache[11] || (_cache[11] = [
                                _createElementVNode("p", null, "Details", -1),
                                _createElementVNode("svg", {
                                  fill: "#000000",
                                  width: "15px",
                                  height: "15px",
                                  viewBox: "-1 0 19 19",
                                  class: "cf-icon-svg"
                                }, [
                                  _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                ], -1)
                              ]), 8, _hoisted_14)
                            ]),
                            (canEdit.value)
                              ? (_openBlock(), _createElementBlock("td", _hoisted_15, [
                                  _createElementVNode("button", {
                                    type: "button",
                                    class: "c-requestbtns c-requestbtns-secondary-edit c-requestbtns--no-margin",
                                    onClick: ($event: any) => (handleEdit(item)),
                                    alt: "edit button icon",
                                    title: "Edit Archived Request"
                                  }, _cache[12] || (_cache[12] = [
                                    _createStaticVNode(" Edit <svg width=\"18px\" height=\"18px\" viewBox=\"0 0 24 24\" fill=\"none\" class=\"c-requestbtns-edit\"><g id=\"SVGRepo_bgCarrier\" stroke-width=\"0\"></g><g id=\"SVGRepo_tracerCarrier\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></g><g id=\"SVGRepo_iconCarrier\"><path d=\"M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4M9.35443 12.4346L15.9429 5.92003C16.4684 5.40046 17.3049 5.3718 17.8647 5.85418C18.4812 6.38542 18.5247 7.32554 17.96 7.91149L11.481 14.6335L9 15L9.35443 12.4346Z\" stroke=\"#000000\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></g></svg>", 2)
                                  ]), 8, _hoisted_16)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                title: "Restore to Open Requests",
                                class: "c-requestbtns c-requestbtns-secondary-edit c-requestbtns--no-margin",
                                "data-testid": "v-archive-restorebtn",
                                onClick: ($event: any) => (handleRestore(item.id))
                              }, _cache[13] || (_cache[13] = [
                                _createElementVNode("p", null, "Restore", -1),
                                _createElementVNode("svg", {
                                  width: "15px",
                                  height: "15px",
                                  viewBox: "0 0 24 24",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M12 20.5001C16.6944 20.5001 20.5 16.6945 20.5 12.0001C20.5 9.17456 19.1213 6.67103 17 5.1255M13 22.4001L11 20.4001L13 18.4001M12 3.5001C7.30558 3.5001 3.5 7.30568 3.5 12.0001C3.5 14.8256 4.87867 17.3292 7 18.8747M11 5.6001L13 3.6001L11 1.6001",
                                    stroke: "#000000",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 8, _hoisted_17)
                            ]),
                            _createElementVNode("td", null, [
                              _createElementVNode("button", {
                                type: "button",
                                title: "Delete Permanently",
                                class: "c-requestbtns cst-button-danger c-requestbtns--no-margin",
                                "data-testid": "v-archive-deletebtn",
                                onClick: ($event: any) => (handleDelete(item.id))
                              }, _cache[14] || (_cache[14] = [
                                _createElementVNode("p", null, "Delete", -1),
                                _createElementVNode("img", {
                                  src: './trash.svg',
                                  alt: "delete button icon"
                                }, null, -1)
                              ]), 8, _hoisted_18)
                            ])
                          ]))
                        }), 128))
                      ])
                    ]),
                    (totalPages.value > 1)
                      ? (_openBlock(), _createBlock(C_Pagination, {
                          key: 0,
                          totalPages: totalPages.value,
                          itemList: archiveList.value,
                          onPaginateList: paginateList,
                          "data-testid": "pages-indicator"
                        }, null, 8, ["totalPages", "itemList"]))
                      : _createCommentVNode("", true)
                  ])
                ]))
          ])),
      _createVNode(RequestDetails, {
        selectedItemId: selectedItemId.value,
        selectedPRListItems: selectedPRListItems.value,
        showCTA: false
      }, null, 8, ["selectedItemId", "selectedPRListItems"])
    ])
  ], 64))
}
}

})