<template>
  <div class="c-future-budget-modal--background">
    <div class="c-future-budget-modal">
      <div class="c-future-budget-modal__header">
        <h2>
          {{ props.isApproval ? `Approve future ${financialGoalLabel}?` : `Decline future ${financialGoalLabel}` }}
        </h2>
        <img src="../../../public/remove-white.svg" alt="close modal button" @click="closeModal()" data-testid="future-budget-closemodal-button" />
      </div>
      <div class="c-future-budget-modal__container">
        <div class="c-future-budget-modal__container__messages c-future-budget-modal--budget-approval-data">
          <div class="c-future-budget-modal__container-budget">
            <p class="c-future-budget-modal__container-budget--label">
              Proposed <span>{{ financialGoalLabel }}</span
              >:
            </p>
            <p class="c-future-budget-modal__container-budget--value" data-testid="budget-approval-data-value">
              {{ entity.proposed_budget ? formatNumber((Number(entity.proposed_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
            </p>
          </div>
          <div class="c-future-budget-modal__container-budget">
            <p class="c-future-budget-modal__container-budget--label">
              Demanded <span>{{ financialGoalLabel }}</span
              >:
            </p>
            <p class="c-future-budget-modal__container-budget--value">
              {{ entity.demanded_budget ? formatNumber((Number(entity.demanded_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
            </p>
          </div>
        </div>
        <div class="c-future-budget-modal__approved-budget--wrapper" v-if="isApproval">
          <div class="cst-input-field">
            <label for="approved-budget"> Enter approved {{ financialGoalLabel }}<span style="color: red">*</span></label>
            <input class="cst-input" type="number" id="approved-budget" min="1" required :placeholder="'0'" v-model="approvedBudget" />
          </div>
        </div>
        <div class="c-future-budget-modal__decline-reason" v-else>
          <div class="cst-input-field">
            <label for="comment">Decline reason</label>
            <textarea class="cst-input c-future-budget-modal__messagebox" type="textarea" id="comment" maxlength="240" v-model="message"></textarea>
            <span
              class="cst-input-field__indicator"
              :class="{
                'cst-input-field__indicator--max': message?.length == 240,
              }"
              >{{ message?.length ? `${message.length}` : `0` }}/240</span
            >
          </div>
        </div>
      </div>
      <div class="c-future-budget-modal__footer c-future-budget-modal__footer--button-wrapper">
        <button type="button" class="cst-button-primary c-productcard__button" @click="handleSubmit()">
          {{ props.isApproval ? "Approve" : "Decline" }}
        </button>
        <button type="button" class="cst-button-primary c-productcard__button" @click="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { defineProps, onBeforeUnmount, onMounted, defineEmits, ref, computed } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  const { APPROVE_FUTURE_BUDGET_PLAN, DECLINE_FUTURE_BUDGET_PLAN } = VUEX_ACTIONS;
  const props = defineProps<{
    entity: IBudgetPlan;
    isApproval: boolean;
    financialGoalLabel?: string;
  }>();
  const emit = defineEmits(["close-decision-modal"]);
  const store = useStore();
  const message = ref("");
  const users = computed(() => store.getters.getUsers);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const approvedBudget = ref<number>();
  const currentUserId = computed(() => {
    const userId = users.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const closeModal = () => {
    store.commit("setSpecificFutureBudget", null);
    emit("close-decision-modal");
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const handleSubmit = () => {
    if (props.isApproval) {
      const payload = {
        id: props.entity.id,
        approved_budget: approvedBudget.value,
        status: "approved",
      };
      store.dispatch(APPROVE_FUTURE_BUDGET_PLAN, payload);
      closeModal();
    } else {
      const payload = {
        id: props.entity.id,
        sender_id: currentUserId.value,
        description: message.value,
        status: "declined",
      };
      store.dispatch(DECLINE_FUTURE_BUDGET_PLAN, payload);
      closeModal();
    }
  };

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });
</script>
