<template>
  <header>
    <div class="c-header">
      <div class="c-header__wrapper" data-testid="header-wrapper">
        <div class="c-header__logo" @click="navigateHome" data-testid="header-logo">
          <img :src="applicationLogo" alt="Company logo" data-testid="application-logo" />
        </div>

        <div class="c-header__app-name">
          <h1 data-testid="application-name">{{ applicationName }}</h1>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts">
  import { useRouter } from "vue-router";
  import { computed, defineComponent } from "vue";
  export default defineComponent({
    props: ["appName", "logoUrl"],
    setup(props) {
      const router = useRouter();
      const navigateHome = () => {
        router.push("/");
      };
      const applicationName = computed(() => {
        if (props.appName) {
          return props.appName;
        } else {
          return "Missing Configuration";
        }
      });
      const applicationLogo = computed(() => {
        if (props.logoUrl) {
          return props.logoUrl;
        } else {
          return "";
        }
      });
      return { applicationName, applicationLogo, navigateHome };
    },
  });
</script>
