import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "c-productchart__container",
  "data-testid": "product-chart"
}
const _hoisted_2 = { class: "c-productchart__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Bar, {
        id: "pf-currentyear-chart",
        options: _ctx.chartOptions,
        data: _ctx.chartData
      }, null, 8, ["options", "data"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, _toDisplayString(_ctx.financialGoalLabel), 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "Forecast", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", null, "Actuals", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "PO's", -1))
      ])
    ])
  ]))
}