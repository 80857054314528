import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cst-spinner",
  "data-testid": "vrequests-spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "v-requests"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "v-requests__table-container",
  "data-testid": "v-requests__table"
}
const _hoisted_5 = { class: "v-requests__table-container--search" }
const _hoisted_6 = {
  key: 0,
  class: "v-requests__table-wrapper"
}
const _hoisted_7 = {
  class: "v-polist__table v-polist__list-table",
  "aria-describedby": "Table of open requests"
}
const _hoisted_8 = { class: "v-polist__table-head-row" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 4 }
const _hoisted_14 = { key: 5 }
const _hoisted_15 = { class: "vpolist" }
const _hoisted_16 = {
  key: 0,
  class: "vpolist"
}
const _hoisted_17 = {
  key: 1,
  class: "vpolist"
}
const _hoisted_18 = { class: "vpolist" }
const _hoisted_19 = { class: "vpolist" }
const _hoisted_20 = { class: "vpolist" }
const _hoisted_21 = { class: "vpolist" }
const _hoisted_22 = { class: "vpolist" }
const _hoisted_23 = { class: "vpolist" }
const _hoisted_24 = { class: "vpolist" }
const _hoisted_25 = {
  key: 2,
  title: "This PR will expire in less than 8 weeks!"
}
const _hoisted_26 = {
  key: 3,
  title: "",
  class: "v-requests__table"
}
const _hoisted_27 = { key: 4 }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { key: 5 }
const _hoisted_30 = ["onClick", "disabled", "title"]
const _hoisted_31 = { key: 6 }
const _hoisted_32 = ["onClick", "disabled", "title"]
const _hoisted_33 = { key: 7 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = { key: 1 }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { key: 1 }

import { ref, onMounted, computed, watch } from "vue";
  import tableLabels from "@/utils/assets/requestTable.json";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import RequestConfirmModal from "../components/modals/C_RequestConfirmModal.vue";
  import RequestDetails from "../components/modals/C_RequestDetails.vue";
  import RequestMessage from "../components/C_RequestMessage.vue";
  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { addWeeks, isBefore } from "date-fns";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import C_JustificationModal from "@/components/modals/C_JustificationModal.vue";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_Requests',
  setup(__props) {

  const { FETCH_LIST_ITEMS, SHOW_PR_DETAILS_VIEW, CHANGE_CONFIRM_MODAL_VIEW, FETCH_PURCHASE_REQUESTS, DECLINE_PURCHASE_REQUEST, SWITCH_TO_PURCHASE_ORDER, UPDATE_REQUEST_MESSAGE, FETCH_SBU, FETCH_NOTIFICATIONS, FETCH_ARCHIVE, CHANGE_JUSTIFICATION_MODAL_VIEW } =
    VUEX_ACTIONS;
  const labels = ref<string[]>([]);
  const selectedItemId = ref<number | undefined>(undefined);
  const requestID = ref<number | undefined>(undefined);
  const focusedRequestAmount = ref<number>(0);
  const store = useStore();
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currentUserRoles = computed(() => store.getters.getCurrentUserRoles);
  const currentUserGroups = computed(() => store.getters.getCurrentUserGroups);
  const proces = ref("");
  const showManagementBtns = ref();
  const selectedPRListItems = ref([]);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  const isFamilyManager = computed(() => {
    return currentUserGroups.value?.includes("Product Family Manager");
  });
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const prJustificationEnabled = computed(()=> {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_justification_enabled;
    } else {
      return false;
    }
  })

  store.dispatch(FETCH_SBU);
  store.dispatch(FETCH_ARCHIVE);

  const requests = computed(() => store.getters.getPurchaseRequests);
  const filteredRequests = ref(requests.value?.slice(0, 10));
  const paginatedList = ref(requests.value?.slice(0, 10));
  const areRequestsFetched = computed(() => store.getters.getAreRequestsFetched);

  const totalPages = computed(() => {
    if (requests.value.length > 10 && requests.value.length % 10 != 0) {
      return Math.floor(requests.value.length / 10) + 1;
    } else {
      return requests.value.length / 10;
    }
  });
  const isRequestListEmpty = computed(() => {
    if (requests.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const showCTA = computed(() => {
    if (currentUserRoles.value?.includes("approve-purchase-request")) {
      return true;
    } else {
      return false;
    }
  });

  onMounted(() => {
    labels.value = tableLabels.map((item: { label: string }) => item.label);
    if (supplierEnabled.value) {
      labels.value.splice(1, 0, "Supplier");
    }
    if (sbuEnabled.value) {
      labels.value.splice(2, 0, "SBU");
    }
    if (currentUserRoles.value?.includes("approve-purchase-request")) {
      showManagementBtns.value = true;
    } else {
      showManagementBtns.value = false;
    }
  });

  const expirationDate = computed(() => {
    return addWeeks(new Date(), 8);
  });

  const isExpired = (deliveryDate: string) => {
    const deliveryDateObj = new Date(deliveryDate);
    return isBefore(deliveryDateObj, expirationDate.value);
  };

  const showConfirmModal = async (itemId: number, action: string) => {
    requestID.value = Number(itemId);
    store.dispatch(SHOW_PR_DETAILS_VIEW, false);
    await store.dispatch(FETCH_LIST_ITEMS, itemId);
    proces.value = action;
    store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, true);
  };

  const showJustificationModal = async (itemId: number, itemAmount: number, action: string) => {
    requestID.value = Number(itemId);
    focusedRequestAmount.value = itemAmount;
    proces.value = action;
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, true);
  };

  const handleDecline = async (data: { itemId: number; message: string }) => {
    if (data.itemId !== null) {
      if (!currentUserRoles.value?.includes("approve-purchase-request")) {
        store.dispatch(UPDATE_REQUEST_MESSAGE, "Request deleted successfully");
      } else {
        store.dispatch(UPDATE_REQUEST_MESSAGE, "Request declined successfully");
      }
      await store.dispatch(DECLINE_PURCHASE_REQUEST, {
        itemId: data.itemId,
        message: data.message,
      });
      store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, false);
      requestID.value = undefined;
      selectedItemId.value = undefined;
    }
  };

  const handleAccept = async (data: { itemId: number; orderNumber: string; customerContact: string }) => {
    if (data.itemId !== undefined && data.orderNumber) {
      store.dispatch(SWITCH_TO_PURCHASE_ORDER, {
        itemId: data.itemId,
        orderNumber: data.orderNumber,
        customerContact: data.customerContact,
      });
      store.dispatch(UPDATE_REQUEST_MESSAGE, "Request accepted successfully!");

      requestID.value = undefined;
      selectedItemId.value = undefined;
    } else {
      store.dispatch(UPDATE_REQUEST_MESSAGE, "ERROR: Order number cannot be empty!");
    }
  };

  const handleDetails = (item: IPurchaseRequest) => {
    store.dispatch(SHOW_PR_DETAILS_VIEW, true);
    selectedItemId.value = Number(item.id);
    selectedPRListItems.value = item.listitems;
  };

  const fetchRequests = () => {
    store.dispatch(FETCH_PURCHASE_REQUESTS);

    labels.value = tableLabels.map((item: { label: string }) => item.label);
    if (sbuEnabled.value) {
      labels.value.splice(2, 0, "SBU");
    }
    if (currentUserRoles.value.includes("approve-purchase-request")) {
      showManagementBtns.value = true;
    } else {
      showManagementBtns.value = false;
    }
  };
  fetchRequests();

  const filterOpenRequests = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredRequests.value = requests.value.filter((request: IPurchaseRequest) => {
      if (request?.ordertext) return request?.ordertext.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });

    if (searchTerm === "") {
      filteredRequests.value = paginatedList.value;
    }
  };

  watch([requests], () => {
    paginatedList.value = requests.value?.slice(0, 10);
    filteredRequests.value = requests.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IPurchaseRequest>) => {
    filteredRequests.value = filteredList;
    paginatedList.value = filteredList;
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    (!areRequestsFetched.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[21] || (_cache[21] = _createElementVNode("h1", {
            class: "v-requests__title",
            "data-testid": "vrequests-open-requests-label"
          }, "Open requests", -1)),
          (isRequestListEmpty.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
                _createElementVNode("h1", {
                  class: "v-requests__title",
                  "data-testid": "vrequests-norequests"
                }, "You have no Requests pending...", -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(C_Search, {
                    placeholder: 'Search by description...',
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (filterOpenRequests($event)))
                  })
                ]),
                (filteredRequests.value.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("table", _hoisted_7, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("tr", _hoisted_8, [
                            _cache[4] || (_cache[4] = _createElementVNode("th", null, "Request ID", -1)),
                            (supplierEnabled.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_9, "Supplier"))
                              : _createCommentVNode("", true),
                            (sbuEnabled.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_10, "SBU"))
                              : _createCommentVNode("", true),
                            _cache[5] || (_cache[5] = _createElementVNode("th", null, "Description", -1)),
                            _cache[6] || (_cache[6] = _createElementVNode("th", null, "PR Owner", -1)),
                            _cache[7] || (_cache[7] = _createElementVNode("th", null, "Amount", -1)),
                            _cache[8] || (_cache[8] = _createElementVNode("th", null, "Current Year", -1)),
                            _cache[9] || (_cache[9] = _createElementVNode("th", null, "Next Year", -1)),
                            _cache[10] || (_cache[10] = _createElementVNode("th", null, "Start Date", -1)),
                            _cache[11] || (_cache[11] = _createElementVNode("th", null, "Delivery Date", -1)),
                            _cache[12] || (_cache[12] = _createElementVNode("th", null, "Status", -1)),
                            (!showManagementBtns.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_11, "Delete"))
                              : _createCommentVNode("", true),
                            (showManagementBtns.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_12, "Approve"))
                              : _createCommentVNode("", true),
                            (showManagementBtns.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_13, "Decline"))
                              : _createCommentVNode("", true),
                            (prJustificationEnabled.value && isFamilyManager.value)
                              ? (_openBlock(), _createElementBlock("th", _hoisted_14, "Justify"))
                              : _createCommentVNode("", true),
                            _cache[13] || (_cache[13] = _createElementVNode("th", null, "Details", -1))
                          ])
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredRequests.value, (item) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: item.id,
                              class: "v-polist__table-row",
                              "data-testid": "vrequests-request"
                            }, [
                              _createElementVNode("td", _hoisted_15, _toDisplayString(item.id), 1),
                              (supplierEnabled.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_16, _toDisplayString(item.suppliername), 1))
                                : _createCommentVNode("", true),
                              (sbuEnabled.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_17, _toDisplayString(item.sbuname), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("td", _hoisted_18, _toDisplayString(item.ordertext), 1),
                              _createElementVNode("td", _hoisted_19, _toDisplayString(item.prownername), 1),
                              _createElementVNode("td", _hoisted_20, _toDisplayString(_unref(formatNumber)((Number(item.orderamount) * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                              _createElementVNode("td", _hoisted_21, _toDisplayString(_unref(formatNumber)(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                              _createElementVNode("td", _hoisted_22, _toDisplayString(_unref(formatNumber)(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1),
                              _createElementVNode("td", _hoisted_23, _toDisplayString(_unref(formatDate)(item.podate)), 1),
                              _createElementVNode("td", _hoisted_24, _toDisplayString(_unref(formatDate)(item.deliverydate)), 1),
                              (isExpired(item.deliverydate))
                                ? (_openBlock(), _createElementBlock("td", _hoisted_25, _cache[14] || (_cache[14] = [
                                    _createElementVNode("img", {
                                      src: './warning.png',
                                      alt: "warning button icon",
                                      class: "v-requests__table-data-warning"
                                    }, null, -1)
                                  ])))
                                : _createCommentVNode("", true),
                              (!isExpired(item.deliverydate))
                                ? (_openBlock(), _createElementBlock("td", _hoisted_26))
                                : _createCommentVNode("", true),
                              (!showManagementBtns.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_27, [
                                    _createElementVNode("button", {
                                      onClick: ($event: any) => (handleDecline({ itemId: item.id, message: item.message })),
                                      type: "button",
                                      class: "c-requestbtns cst-button-danger c-requestbtns--no-margin",
                                      "data-testid": "deletepr-btn"
                                    }, _cache[15] || (_cache[15] = [
                                      _createTextVNode(" Delete "),
                                      _createElementVNode("img", {
                                        src: './trash.svg',
                                        alt: "delete button icon"
                                      }, null, -1)
                                    ]), 8, _hoisted_28)
                                  ]))
                                : _createCommentVNode("", true),
                              (showManagementBtns.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_29, [
                                    _createElementVNode("button", {
                                      onClick: ($event: any) => (showConfirmModal(item.id, 'Approve')),
                                      type: "button",
                                      class: _normalizeClass(["c-requestbtns cst-button-primary c-requestbtns--no-margin", {
                    'c-requestbtns--disabled': item?.justification_status == 'Proposed',
                  }]),
                                      disabled: item?.justification_status == 'Proposed',
                                      title: item?.justification_status == 'Proposed' ? 'Must be signed by a Product Family Manager' : '',
                                      "data-testid": "approvepr-btn"
                                    }, [
                                      _cache[16] || (_cache[16] = _createTextVNode(" Approve ")),
                                      _createElementVNode("img", {
                                        src: './accept.svg',
                                        alt: "accept button icon",
                                        style: _normalizeStyle({
                      filter: item?.justification_status == 'Proposed' ? 'invert()' : '',
                    })
                                      }, null, 4)
                                    ], 10, _hoisted_30)
                                  ]))
                                : _createCommentVNode("", true),
                              (showManagementBtns.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_31, [
                                    _createElementVNode("button", {
                                      onClick: ($event: any) => (showConfirmModal(item.id, 'Do you want to decline the request?')),
                                      type: "button",
                                      class: _normalizeClass(["c-requestbtns cst-button-danger c-requestbtns--no-margin", {
                    'c-requestbtns--disabled': item?.justification_status == 'Proposed',
                  }]),
                                      "data-testid": "declinepr-btn",
                                      disabled: item?.justification_status == 'Proposed',
                                      title: item?.justification_status == 'Proposed' ? 'Must be signed by a Product Family Manager' : ''
                                    }, [
                                      _cache[17] || (_cache[17] = _createTextVNode(" Decline ")),
                                      _createElementVNode("img", {
                                        src: './decline.svg',
                                        alt: "decline button icon",
                                        style: _normalizeStyle({
                      filter: item?.justification_status == 'Proposed' ? 'grayscale(100%)' : '',
                    })
                                      }, null, 4)
                                    ], 10, _hoisted_32)
                                  ]))
                                : _createCommentVNode("", true),
                              (prJustificationEnabled.value && isFamilyManager.value)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_33, [
                                    (item.justification_status == 'Proposed')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                          _createElementVNode("button", {
                                            onClick: ($event: any) => (showJustificationModal(item.id, item.orderamount, 'Review')),
                                            type: "button",
                                            class: "c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin",
                                            "data-testid": "review-justification-btn"
                                          }, _cache[18] || (_cache[18] = [
                                            _createTextVNode(" Review "),
                                            _createElementVNode("svg", {
                                              fill: "#000000",
                                              width: "16px",
                                              height: "16px",
                                              viewBox: "-1 0 19 19",
                                              class: "cf-icon-svg"
                                            }, [
                                              _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                            ], -1)
                                          ]), 8, _hoisted_35)
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_36, "-"))
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("td", null, [
                                _createElementVNode("button", {
                                  onClick: ($event: any) => (handleDetails(item)),
                                  class: "c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin",
                                  alt: "details button icon",
                                  "data-testid": "detailspr-btn"
                                }, _cache[19] || (_cache[19] = [
                                  _createTextVNode(" Show "),
                                  _createElementVNode("svg", {
                                    fill: "#000000",
                                    width: "16px",
                                    height: "16px",
                                    viewBox: "-1 0 19 19",
                                    class: "cf-icon-svg"
                                  }, [
                                    _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                  ], -1)
                                ]), 8, _hoisted_37)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_38, _cache[20] || (_cache[20] = [
                      _createElementVNode("p", null, "No such request has been found.", -1)
                    ]))),
                (totalPages.value > 1)
                  ? (_openBlock(), _createBlock(C_Pagination, {
                      key: 2,
                      totalPages: totalPages.value,
                      itemList: requests.value,
                      onPaginateList: paginateList,
                      "data-testid": "pages-indicator"
                    }, null, 8, ["totalPages", "itemList"]))
                  : _createCommentVNode("", true)
              ])),
          (selectedItemId.value !== undefined)
            ? (_openBlock(), _createBlock(RequestDetails, {
                key: 2,
                selectedPRListItems: selectedPRListItems.value,
                selectedItemId: selectedItemId.value,
                onDecline: _cache[1] || (_cache[1] = ($event: any) => (showConfirmModal(selectedItemId.value, 'Do you want to decline the request?'))),
                onApprove: _cache[2] || (_cache[2] = ($event: any) => (showConfirmModal(selectedItemId.value, 'Approve'))),
                "show-c-t-a": showCTA.value,
                "data-testid": "vrequests-request-details"
              }, null, 8, ["selectedPRListItems", "selectedItemId", "show-c-t-a"]))
            : _createCommentVNode("", true),
          _createVNode(RequestConfirmModal, {
            itemId: requestID.value,
            action: proces.value,
            onDecline: handleDecline,
            onApprove: handleAccept
          }, null, 8, ["itemId", "action"]),
          _createVNode(C_JustificationModal, {
            itemId: requestID.value,
            pramount: Number(focusedRequestAmount.value)
          }, null, 8, ["itemId", "pramount"])
        ]))
  ], 64))
}
}

})