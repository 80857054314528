import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = { class: "c-exportdocumentmodal__wrapper" }
const _hoisted_2 = { class: "c-exportdocumentmodal__container" }
const _hoisted_3 = { class: "c-exportdocumentmodal__content" }
const _hoisted_4 = { class: "c-exportdocumentmodal__data" }
const _hoisted_5 = { class: "c-exportdocumentmodal__data" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "c-exportdocumentmodal__data" }
const _hoisted_8 = ["disabled"]

import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { computed, ref } from "vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_ExportDocumentModal',
  props: {
    invoiceID: {
      type: String,
      required: true,
    },
  },
  setup(__props) {

  const { SHOW_EXPORT_DOCUMENT_MODAL, DOWNLOAD_PDF, GENERATE_PDF } = VUEX_ACTIONS;
  const store = useStore();
  const props = __props;
  const selectedLanguage = ref("english");
  const isPdfGenerated = computed(() => store.getters.getIsPdfGenerated);
  const generatePDF = () => store.dispatch(GENERATE_PDF, { invoiceID: props.invoiceID, language: selectedLanguage.value });
  const downloadPDF = () => {
    store.dispatch(DOWNLOAD_PDF);
  };
  const closeModal = () => {
    store.dispatch(SHOW_EXPORT_DOCUMENT_MODAL, false);
    store.commit("setIsPdfGenerated", false);
    store.commit("setPDF", null);
    store.commit("setPDFId", null);
  };
  const handleLanguageChange = () => {
    store.commit("setIsPdfGenerated", false);
    store.commit("setPDF", null);
    store.commit("setPDFId", null);
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "c-exportdocumentmodal__headline" }, [
        _cache[1] || (_cache[1] = _createElementVNode("p", {
          class: "c-exportdocumentmodal__title",
          "data-testid": "projectapp-modal-title"
        }, "Invoice Export", -1)),
        _createElementVNode("img", {
          src: _imports_0,
          height: "30",
          width: "30",
          alt: "Close modal icon",
          onClick: closeModal
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { for: "language" }, "Choose a language", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "cst-select-field",
            name: "language",
            id: "language",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedLanguage).value = $event)),
            onChange: handleLanguageChange
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("option", { value: "english" }, "English", -1),
            _createElementVNode("option", { value: "german" }, "German", -1)
          ]), 544), [
            [_vModelSelect, selectedLanguage.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[4] || (_cache[4] = _createElementVNode("p", null, "Generate PDF", -1)),
          _createElementVNode("button", {
            class: "c-requestbtns cst-button-primary",
            onClick: generatePDF,
            disabled: isPdfGenerated.value
          }, "Generate", 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[6] || (_cache[6] = _createElementVNode("p", null, "Download Invoice", -1)),
          _createElementVNode("button", {
            class: "c-requestbtns cst-button-primary",
            disabled: !isPdfGenerated.value,
            onClick: downloadPDF
          }, _cache[5] || (_cache[5] = [
            _createTextVNode(" Export as PDF "),
            _createElementVNode("svg", {
              fill: "#FFFFFF",
              width: "18px",
              height: "18px",
              viewBox: "0 0 36 36",
              version: "1.1",
              preserveAspectRatio: "xMidYMid meet"
            }, [
              _createElementVNode("path", {
                d: "M6,13.61h7.61V6H24v8.38h2V6a2,2,0,0,0-2-2H10.87L4,10.87V30a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2H6Zm0-1.92L11.69,6H12v6H6Z",
                class: "clr-i-outline clr-i-outline-path-1"
              }),
              _createElementVNode("path", {
                d: "M28.32,16.35a1,1,0,0,0-1.41,1.41L30.16,21H18a1,1,0,0,0,0,2H30.19l-3.28,3.28a1,1,0,1,0,1.41,1.41L34,22Z",
                class: "clr-i-outline clr-i-outline-path-2"
              }),
              _createElementVNode("rect", {
                x: "0",
                y: "0",
                width: "36",
                height: "36",
                "fill-opacity": "0"
              })
            ], -1)
          ]), 8, _hoisted_8)
        ])
      ])
    ])
  ]))
}
}

})