import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-table-overview__header"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "c-table-overview__body-row" }
const _hoisted_4 = { class: "c-table-overview__body-row--expand-icon" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 2,
  class: "c-table-overview__body-row--empty"
}

import { computed } from "vue";
  import { useStore } from "vuex";
  /* INTERFACES */
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";
  /* HELPERS */
  import { formatNumber } from "@/utils/helpers/formatNumber";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_ProductFamilyTableOverview',
  props: {
    productlist: {
      type: Array<IProduct>,
      default: () => [],
    },
    focusedproductid: {
      type: String,
    },
    allProductFamilies: {
      type: Array<IProductFamily>,
      default: () => [],
    },
    isproductfamily: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ["selected-pf"],
  setup(__props, { emit: __emit }) {

  /* VUE IMPORTS */
  const props = __props;
  const emit = __emit;

  const store = useStore();

  const users = computed(() => store.getters.getUsers);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const entitiesToShow = computed(() => props.allProductFamilies);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  function convertUsernameFromId(username: string) {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  }

  const handleOverviewClick = (entity: IProductFamily) => {
    emit("selected-pf", entity);
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (entitiesToShow.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[0] || (_cache[0] = _createElementVNode("div", null, "Overview", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", null, "Name", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "Type", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("div", null, "Owner", -1)),
          _createElementVNode("div", null, _toDisplayString(financialGoalLabel.value), 1),
          _cache[4] || (_cache[4] = _createElementVNode("div", null, "Forecast", -1)),
          _cache[5] || (_cache[5] = _createElementVNode("div", null, "Actuals", -1))
        ]))
      : _createCommentVNode("", true),
    (entitiesToShow.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entitiesToShow.value, (entity, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: './arrow-down.svg',
                    alt: "expand down icon",
                    onClick: ($event: any) => (handleOverviewClick(entity))
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", null, _toDisplayString(entity.name), 1),
                _cache[6] || (_cache[6] = _createElementVNode("div", null, "Family", -1)),
                _createElementVNode("div", null, _toDisplayString(entity.owner ? convertUsernameFromId(entity.owner.toString()) : "-"), 1),
                _createElementVNode("div", null, _toDisplayString(entity.budget ? _unref(formatNumber)(Number(entity.budget).toFixed(2)) + " " + currency.value : "-"), 1),
                _createElementVNode("div", null, _toDisplayString(entity.forecast ? _unref(formatNumber)(((Number(entity.forecast) + Number(entity.total_product_forecast)) * currencyRate.value).toFixed(2)) + " " + currency.value : "-"), 1),
                _createElementVNode("div", null, _toDisplayString(entity.actuals ? _unref(formatNumber)(((Number(entity.actuals) + Number(entity.total_product_actuals)) * currencyRate.value).toFixed(2)) + " " + currency.value : "-"), 1)
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, "There are no product families to show."))
  ]))
}
}

})