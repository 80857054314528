import { AXIOS_REQUEST } from "../../config";
import { IProductFamily } from "@/utils/interfaces/IProductTypes";
import { Commit, Dispatch } from "vuex";

interface ProductFamilyState {
  productFamily: IProductFamily[];
  allProductFamilies: IProductFamily[];
  createdProductFamily: IProductFamily | null;
  ownedProductFamilies: IProductFamily[];
  relatedProductFamilies: IProductFamily[];
  remainingBudget: number | null;
  ownedFamiliesBudgetPlan: IProductFamily[];
}

interface ProductFamilyContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: ProductFamilyState = {
  productFamily: [],
  allProductFamilies: [],
  createdProductFamily: null,
  ownedProductFamilies: [],
  relatedProductFamilies: [],
  remainingBudget: null,
  ownedFamiliesBudgetPlan: [],
};

const mutations = {
  setProductFamily(state: ProductFamilyState, payload: IProductFamily[]) {
    state.productFamily = payload;
  },
  setAllProductFamilies(state: ProductFamilyState, payload: IProductFamily[]) {
    state.allProductFamilies = payload;
  },
  setCreatedProductFamily(state: ProductFamilyState, payload: IProductFamily | null) {
    state.createdProductFamily = payload;
  },
  setOwnedProductFamilies(state: ProductFamilyState, payload: IProductFamily[]) {
    state.ownedProductFamilies = payload;
  },
  setRelatedProductFamilies(state: ProductFamilyState, payload: IProductFamily[]) {
    state.relatedProductFamilies = payload;
  },
  setRemainingBudget(state: ProductFamilyState, payload: number) {
    state.remainingBudget = payload;
  },
  setOwnedFamiliesBudgetPlan(state: ProductFamilyState, payload: IProductFamily[]) {
    state.ownedFamiliesBudgetPlan = payload;
  },
};

const getters = {
  getProductFamily: (state: ProductFamilyState) => state.productFamily,
  getAllProductFamilies: (state: ProductFamilyState) => state.allProductFamilies,
  getCreatedProductFamily: (state: ProductFamilyState) => state.createdProductFamily,
  getOwnedProductFamilies: (state: ProductFamilyState) => state.ownedProductFamilies,
  getRelatedProductFamilies: (state: ProductFamilyState) => state.relatedProductFamilies,
  getRemainingBudget: (state: ProductFamilyState) => state.remainingBudget,
  getOwnedFamiliesBudgetPlan: (state: ProductFamilyState) => state.ownedFamiliesBudgetPlan,
};

const actions = {
  async fetchProductFamily(context: ProductFamilyContext, familyId: string) {
    try {
      const response = await AXIOS_REQUEST("GET", `/productfamilies/${familyId}`);
      const productFamilyDO: IProductFamily[] = response.data;
      context.commit("setProductFamily", productFamilyDO);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchProductFamilyRemainingBudget(context: ProductFamilyContext, familyId: string) {
    await AXIOS_REQUEST("GET", `/productfamilies/${familyId}/remainingBudget`)
      .then((response) => {
        const remainingBudget = response.data.availableBudget;
        context.commit("setRemainingBudget", remainingBudget);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchAllProductFamilies(context: ProductFamilyContext) {
    await AXIOS_REQUEST("GET", "/productfamilies")
      .then((response) => {
        const productFamiliesDO: IProductFamily[] = [];
        for (const id in response.data) {
          productFamiliesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setAllProductFamilies", productFamiliesDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchOwnedProductFamilies(context: ProductFamilyContext) {
    await AXIOS_REQUEST("GET", "/productfamilies/owned")
      .then((response) => {
        const productFamiliesDO: IProductFamily[] = [];
        for (const id in response.data) {
          productFamiliesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setOwnedProductFamilies", productFamiliesDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async fetchOwnedProductFamiliesBudgetPlanning(context: ProductFamilyContext, payload: { username: string }) {
    await AXIOS_REQUEST("GET", `/ownership/${payload.username}/ownedproductfamilies`)
      .then((response) => {
        const productFamiliesDO: IProductFamily[] = [];
        for (const id in response.data) {
          productFamiliesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setOwnedFamiliesBudgetPlan", productFamiliesDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async createNewProductFamily(context: ProductFamilyContext, payload: IProductFamily) {
    await AXIOS_REQUEST("POST", "/productfamilies", payload)
      .then((response) => {
        context.commit("setCreatedProductFamily", response.data);
        context.dispatch("updateRequestMessage", "Successfully created a product family!");
      })
      .catch((error) => {
        if (error.response.data.message === "Product Family with given name already exists.") {
          context.dispatch("updateRequestMessage", "Error: Product Family with name you provided already exists!");
        } else {
          console.error(error);
        }
      });
  },

  async fetchRelatedProductFamilies(context: ProductFamilyContext) {
    await AXIOS_REQUEST("GET", "/productfamilies/related")
      .then((response) => {
        const productFamiliesDO: IProductFamily[] = [];
        for (const id in response.data) {
          productFamiliesDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setRelatedProductFamilies", productFamiliesDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const productFamilyModule = {
  state,
  getters,
  mutations,
  actions,
};

export default productFamilyModule;
