<template>
  <div>
    <div v-if="entitiesToShow.length > 0" class="c-table-overview__header">
      <div v-if="!isproductfocused">Overview</div>
      <div>Name</div>
      <div>Type</div>
      <div>Owner</div>
      <div>{{ financialGoalLabel }}</div>
      <div>Forecast</div>
      <div>Actuals</div>
      <div>Details</div>
      <div v-if="!isproductfocused">Graph</div>
    </div>
    <div v-if="entitiesToShow.length > 0">
      <div v-for="(entity, index) in entitiesToShow" :key="index">
        <div
          class="c-table-overview__body-row"
          :class="{
            'c-table-overview__body-row--focused': expandedRows.includes(index),
          }"
        >
          <div class="c-table-overview__body-row--expand-icon" v-if="!isproductfocused" @click="toggleProductRow(index, entity.id)">
            <img :src="'./arrow-down.svg'" alt="expand down icon" />
          </div>
          <div>
            {{ entity.name }}
          </div>
          <div>
            {{ entity.platform_cost && entity.platform_cost !== null ? "Platform" : entity.type ? entity.type : "Product" }}
          </div>
          <div>{{ convertUsernameFromId(entity.owner) }}</div>
          <div>
            {{ entity.budget ? formatNumber((entity.budget * currencyRate).toFixed(2)) + " " + currency : formatNumber((entity.totalbudget * currencyRate).toFixed(2)) + " " + currency }}
          </div>
          <div>
            {{ entity.forecast ? formatNumber(((Number(entity.forecast) + Number(productPOForecast[index])) * currencyRate).toFixed(2)) + " " + currency : formatNumber((Number(entity.totalforecast) * currencyRate).toFixed(2)) + " " + currency }}
          </div>
          <div>
            {{ entity.actuals ? formatNumber(((Number(entity.actuals) + Number(prodInvoiceActuals[index])) * currencyRate).toFixed(2)) + " " + currency : formatNumber((Number(entity.totalactuals) * currencyRate).toFixed(2)) + " " + currency }}
          </div>
          <div>
            <button @click="isproductfocused ? handleProjAppDetails(entity) : handleProductDetailsClick(entity)">View</button>
          </div>
          <div v-if="!isproductfocused" style="display: flex; align-items: center; justify-content: center">
            <img :src="'./graph.svg'" alt="graph icon" style="height: 24px; width: 24px; cursor: pointer" @click="handleGraphClick(entity.id, index)" />
          </div>
        </div>
        <div v-if="expandedRows.includes(index)" class="c-table-overview__body-row--expanded-container">
          <p>
            Projects and applications belonging to
            <strong> {{ entity.name }}</strong>
          </p>

          <div class="c-table-overview__header c-table-overview__header--expanded">
            <div>Name</div>
            <div>Type</div>
            <div>Owner</div>
            <div>{{ financialGoalLabel }}</div>
            <div>Forecast</div>
            <div>Actuals</div>
            <div>Details</div>
          </div>

          <div v-if="projectsApplicationsList.length > 0">
            <div v-for="(entity, index) in projectsApplicationsList" :key="index">
              <div class="c-table-overview__body-row c-table-overview__body-row--expanded">
                <div>
                  {{ entity.name }}
                </div>
                <div>
                  {{ entity.platform_cost && entity.platform_cost !== null ? "Platform" : entity.type ? entity.type : "Product" }}
                </div>
                <div>{{ convertUsernameFromId(entity.owner) }}</div>
                <div>
                  {{ entity.budget ? formatNumber((entity.budget * currencyRate).toFixed(2)) + " " + currency : formatNumber((entity.totalbudget * currencyRate).toFixed(2)) + " " + currency }}
                </div>
                <div>
                  {{ entity.forecast ? formatNumber((entity.forecast * currencyRate).toFixed(2)) + " " + currency : formatNumber((entity.totalforecast * currencyRate).toFixed(2)) + " " + currency }}
                </div>
                <div>
                  {{ entity.actuals ? formatNumber((entity.actuals * currencyRate).toFixed(2)) + " " + currency : formatNumber((entity.totalactuals * currencyRate).toFixed(2)) + " " + currency }}
                </div>
                <div>
                  <button @click="handleProjAppDetails(entity)">View</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProjectAppModal v-if="isProjAppModalOpen" :projappName="focusedEntity.name" :type="focusedEntity.type" :is-modal-enabled="isProjAppModalOpen" @isProjectAppModalVisible="isProjAppModalOpen = false"></ProjectAppModal>
        <ProductModal v-if="isProdModalOpen" :prodName="focusedProductName" :is-modal-enabled="isProdModalOpen" @isProdModalVisible="isProdModalOpen = false"></ProductModal>
      </div>
    </div>

    <div v-else class="c-table-overview__body-row--empty">
    {{ isproductfocused ? 'There are no projects or applications in this product.' : 'There are no products or platforms in this product family.' }}
    </div>
  </div>
</template>

<script lang="ts" setup props>
  /* VUE IMPORTS */
  import { computed, defineProps, defineEmits, ref } from "vue";
  import { useStore } from "vuex";
  /* INTERFACES */
  import { IUser } from "@/utils/interfaces/IUser";
  import { IProjectApplication, IProduct } from "@/utils/interfaces/IProductTypes";
  /* HELPERS */
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { calculateProductPOForecast, calculateProductInvoiceActuals } from "@/utils/helpers/calculateProductForecastActuals";
  /* COMPONENTS */
  import ProjectAppModal from "../components/modals/C_ProjectAppModal.vue";
  import ProductModal from "../components/modals/C_ProdModal.vue";
  const { CHANGE_PRODUCT_DETAILS_VIEW, FETCH_PROJECTS_IN_PRODUCT, FETCH_APP_IN_PRODUCT, FETCH_PRODUCT_PURCHASE_ORDERS } = VUEX_ACTIONS;

  const props = defineProps({
    productlist: {
      type: Array<IProduct>,
      default: () => [],
    },
    focusedproductid: {
      type: String,
    },
    isproductfocused: {
      type: Boolean,
      default: () => false,
    },
  });
  const emit = defineEmits(["show-product-details", "show-projapp-details"]);

  const store = useStore();

  const users = computed(() => store.getters.getUsers);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const projectsInProduct = computed(() => store.getters.getProductProjects);
  const applicationsInProduct = computed(() => store.getters.getProductApplications);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  const isProjAppModalOpen = ref(false);
  const focusedEntity = ref();
  const isProdModalOpen = ref(false);
  const focusedProductName = ref("");
  const expandedRows = ref<number[]>([]);
  if (props.isproductfocused) {
    if (props.focusedproductid) {
      fetchProjectsAppsInProduct(props.focusedproductid);
    }
  }

  function fetchProjectsAppsInProduct(productId: string) {
    store.dispatch(FETCH_PROJECTS_IN_PRODUCT, productId);
    store.dispatch(FETCH_APP_IN_PRODUCT, productId);
  }

  const projectsApplicationsList = computed(() => {
    const projects = projectsInProduct.value;
    const applications = applicationsInProduct.value;

    let projectsAndApplications = [];

    for (const project in projects) {
      const modifiedProject = { ...projects[project], type: "project" };
      projectsAndApplications.push(modifiedProject);
    }

    for (const application in applications) {
      const modifiedApplication = {
        ...applications[application],
        type: "application",
      };
      projectsAndApplications.push(modifiedApplication);
    }
    return projectsAndApplications;
  });

  const entitiesToShow = computed(() => {
    return props.isproductfocused ? projectsApplicationsList.value : props.productlist;
  });

  const productPOForecast = computed(() => {
    return props.productlist.map((product) => {
      return calculateProductPOForecast(product);
    });
  });

  const prodInvoiceActuals = computed(() => {
    return props.productlist.map((product) => {
      return calculateProductInvoiceActuals(product);
    });
  });

  function convertUsernameFromId(username: string) {
    if (users.value.length > 0) {
      const user = users.value.find((user: IUser) => Number(user.id) === Number(username));
      if (user) {
        return user.username;
      }
    }
    return "-";
  }

  function handleGraphClick(entityId: string, index: number) {
    store.dispatch(CHANGE_PRODUCT_DETAILS_VIEW, true);
    emit("show-product-details", entityId);
    // Check if the user clicked on "View" of another row than expanded, if he did, close the expanded row
    if (!expandedRows.value.includes(index)) {
      expandedRows.value = [];
    } else {
      expandedRows.value = [index];
      fetchProjectsAppsInProduct(entityId);
    }
  }

  const handleProductDetailsClick = (entity: IProduct) => {
    focusedProductName.value = entity.name;
    store.dispatch(FETCH_PRODUCT_PURCHASE_ORDERS, entity.id);
    isProdModalOpen.value = true;
  };

  function handleProjAppDetails(entity: IProjectApplication) {
    isProjAppModalOpen.value = true;
    focusedEntity.value = entity;
    emit("show-projapp-details", entity.id, entity.type);
  }

  function toggleProductRow(index: number, productid: string) {
    if (expandedRows.value.includes(index)) {
      expandedRows.value = [];
    } else {
      expandedRows.value = [index];
    }
    fetchProjectsAppsInProduct(productid);
  }
</script>
