import { Commit } from "vuex";

interface ModalsState {
  showProductDetails: boolean;
  showPODetails: boolean;
  showPRDetails: boolean;
  showConfirmModal: boolean;
  showNotificationModal: boolean;
  showExportDocumentModal: boolean;
  showJustificationModal: boolean;
}

interface ModalsContext {
  commit: Commit;
}

const state: ModalsState = {
  showProductDetails: false,
  showPODetails: false,
  showPRDetails: false,
  showConfirmModal: false,
  showNotificationModal: false,
  showExportDocumentModal: false,
  showJustificationModal: false,
};

const mutations = {
  setShowProductDetails(state: ModalsState, payload: boolean) {
    state.showProductDetails = payload;
  },
  setShowPODetails(state: ModalsState, payload: boolean) {
    state.showPODetails = payload;
  },
  setShowPRDetails(state: ModalsState, payload: boolean) {
    state.showPRDetails = payload;
  },
  setShowConfirmModal(state: ModalsState, payload: boolean) {
    state.showConfirmModal = payload;
  },
  setShowNotificationModal(state: ModalsState, payload: boolean) {
    state.showNotificationModal = payload;
  },
  setShowExportDocumentModal(state: ModalsState, payload: boolean) {
    state.showExportDocumentModal = payload;
  },
  setShowJustificationModal(state: ModalsState, payload: boolean) {
    state.showJustificationModal = payload;
  },
};

const getters = {
  getShowProductDetails: (state: ModalsState) => state.showProductDetails,
  getShowPODetails: (state: ModalsState) => state.showPODetails,
  getShowPRDetails: (state: ModalsState) => state.showPRDetails,
  getShowConfirmModal: (state: ModalsState) => state.showConfirmModal,
  getShowNotificationModal: (state: ModalsState) => state.showNotificationModal,
  getShowExportDocumentModal: (state: ModalsState) => state.showExportDocumentModal,
  getShowJustificationModal: (state: ModalsState) => state.showJustificationModal,
};

const actions = {
  changeProductDetailsView(context: ModalsContext, show: boolean) {
    context.commit("setShowProductDetails", show);
  },
  changePODetailsView(context: ModalsContext, show: boolean) {
    context.commit("setShowPODetails", show);
  },
  changePRDetailsView(context: ModalsContext, show: boolean) {
    context.commit("setShowPRDetails", show);
  },
  changeConfirmModalView(context: ModalsContext, show: boolean) {
    context.commit("setShowConfirmModal", show);
  },
  showNotificationModalView(context: ModalsContext, show: boolean) {
    context.commit("setShowNotificationModal", show);
  },
  showExportDocumentModal(context: ModalsContext, show: boolean) {
    context.commit("setShowExportDocumentModal", show);
  },
  changeJustificationModalView(context: ModalsContext, show: boolean) {
    context.commit("setShowJustificationModal", show);
  },
};

const modalsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default modalsModule;
