<template>
  <RequestMessage></RequestMessage>
  <div class="cst-new-product-type--wrapper">
    <div class="cst-inputbox" data-testid="newplatform-input-box">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Create a platform</div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="submitPlatformForm" data-testid="newplatform-submit-form">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="platformname">Name:</label>
              <input class="cst-input" type="text" id="platformname" required maxlength="32" data-testid="newplatform-name" autocomplete="name" v-model="platformName" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': platformName.length == 32,
                }"
                >{{ platformName.length }}/32</span
              >
            </div>
            <div class="cst-input-field">
              <label for="platformbudget">Budget:</label>
              <input class="cst-input" type="number" id="platformbudget" min="0" v-model="platformBudget" required data-testid="newplatform-budget" />
            </div>
            <div class="cst-input-field">
              <label for="owner">Owner:</label>
              <select class="cst-select-field" id="owner" @change="handleSelectOwner($event)" v-model="selectedOwner" required data-testid="newplatform--owner">
                <option v-for="owner in users" :key="owner.id" :value="owner.username">
                  {{ owner.username }}
                </option>
              </select>
            </div>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="newplatform-create-button">Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="cst-new-product-type--wrapper" v-if="isPlatformCreated">
    <div class="cst-inputbox" data-testid="newplatform-input-box">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Add product to platform</div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="submitProductToPlatformForm" data-testid="newplatform-submit-product-platform">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="platform">Platform:</label>
              <select class="cst-select-field" id="platform" @change="handleSelectPlatform($event)" v-model="selectedPlatform" required data-testid="select-platform-product">
                <option disabled selected value="">Select a platform:</option>
                <option v-for="platform in platforms" :key="platform.id" :value="platform.name">
                  {{ platform.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="selectproductfamily">Select product family:</label>
              <select class="cst-select-field" id="selectproductfamily" required v-model="selectedProductFamily" @change="fetchProductsFromPF">
                <option disabled selected value="">Select a product family</option>
                <option v-for="family in productFamily" :key="family.id" :value="family.id" id="selectedFamilyProduct">
                  {{ family.name }}
                </option>
              </select>
            </div>
            <div class="cst-input-field">
              <label for="platformproduct">Product:</label>

              <select class="cst-select-field" id="platformproduct" @change="handleSelectProduct($event)" required v-model="selectedProduct" :disabled="isProductSelectDisabled">
                <option disabled selected value="">Select a Product:</option>
                <option v-for="product in filteredProducts" :key="product.id" :value="product.id">
                  {{ product.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="platform-add-product">Add</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useStore } from "vuex";
  import RequestMessage from "@/components/C_RequestMessage.vue";

  import { computed, onUnmounted, ref } from "vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { VUEX_ACTIONS } from "@/utils/constants";

  const { FETCH_PRODUCTS_IN_PROD_FAMILY, FETCH_RELATED_PRODUCT_FAMILIES, FETCH_USERS, CREATE_PLATFORM, ADD_PRODUCT_TO_PLATFORM } = VUEX_ACTIONS;

  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const productFamily = computed(() => store.getters.getRelatedProductFamilies);
  const filteredProducts = computed(() => store.getters.getProducts);
  const isPlatformCreated = computed(() => store.getters.getIsPlatformCreated);

  const platforms = ref<IPlatform[]>([
    { id: 1, name: "Platform 1", owner: 1, budget: 10 },
    { id: 2, name: "Platform 2", owner: 2, budget: 20 },
    { id: 3, name: "Platform 3", owner: 3, budget: 30 },
  ]);
  /* TO-DO: Uncomment these two lines once backend and store are ready, delete mocked ones. */
  /* const platforms = computed(() => store.getters.getPlatforms); */
  /* const isPlatformCreated = computed(() => store.getters.getIsPlatformCreated); */
  const selectedOwner = ref(`${currentUser.value}`);
  const selectedOwnerID = ref();
  const selectedProductFamily = ref("");
  const platformName = ref("");
  const platformBudget = ref("");
  const selectedProduct = ref<number | string>("");
  const selectedPlatform = ref<number | string>("");
  const selectedPlatformID = ref();
  const isProductSelectDisabled = ref(true);

  store.dispatch(FETCH_RELATED_PRODUCT_FAMILIES);
  store.dispatch(FETCH_USERS);

  const submitPlatformForm = async () => {
    if (!selectedOwnerID.value) {
      handleCurrentOwner();
    }
    const payload = {
      name: platformName.value,
      budget: platformBudget.value,
      owner: selectedOwnerID.value,
    };
    store.dispatch(CREATE_PLATFORM, payload);
  };

  const submitProductToPlatformForm = async () => {
    const payload = {
      selectedPlatform: selectedPlatformID.value,
      product: selectedProduct.value,
    };

    store.dispatch(ADD_PRODUCT_TO_PLATFORM, payload);
  };

  const handleSelectOwner = (event: Event) => {
    const user = users.value.filter((p: IUser) => p.username == (event?.target as HTMLSelectElement).value);
    selectedOwnerID.value = Number(user[0].id);
  };

  const handleSelectPlatform = (event: Event) => {
    const platform = platforms.value.filter((p: IPlatform) => p.name == (event?.target as HTMLSelectElement).value);
    selectedPlatformID.value = Number(platform[0].id);
  };

  // If the owner remains the default logged in user
  const handleCurrentOwner = () => {
    const user = users.value.filter((p: IUser) => p.username == selectedOwner.value);
    selectedOwnerID.value = Number(user[0].id);
  };

  const handleSelectProduct = (event: Event) => {
    selectedProduct.value = Number((event?.target as HTMLSelectElement).value);
  };

  const fetchProductsFromPF = async () => {
    await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedProductFamily.value.toString()).then(() => {
      isProductSelectDisabled.value = false;
      selectedProduct.value = "";
    });
  };

  onUnmounted(() => {
    store.commit("setIsPlatformCreated", false);
  });
</script>
