<template>
  <div>
    <div class="c-productnextyearchart__container" v-if="chartData">
      <div class="c-productnextyearchart__wrapper">
        <Bar id="p-nextyear-chart" :options="chartOptions" :data="chartData" />
      </div>
      <div class="c-productnextyearchart__labels">
        <div>Proposed {{ financialGoalLabel }}</div>
        <div>Proposed Forecast</div>
        <div>PO Next Year</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Bar } from "vue-chartjs";
  import { computed, defineComponent, ref } from "vue";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { useStore } from "vuex";
  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  export default defineComponent({
    name: "ProductNextYearChart",
    components: { Bar },
    props: {
      product: {
        type: Object as () => IProduct,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const appConfig = computed(() => store.getters.getAppConfiguration);
      const dataLoaded = ref(false);
      const chartData = ref<IChartData>();
      const financialGoalLabel = computed(() => {
        if (Object.keys(appConfig.value).length > 0) {
          return appConfig.value.financial_goal_label;
        } else {
          return "Budget";
        }
      });
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      chartData.value = {
        labels: [""],
        datasets: [
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetcapitalinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetcapitalmaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetcapitalenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#00793a"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetexpenseinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetexpensemaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.nextyear?.nextyearbudgetexpenseenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#004a96"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [(Number(props.product.nextyear?.nextyearforecastcapitalinfrastructure ?? 0) + Number(props.product.nextyear_product_PO_fci ?? 0)) * currencyRate.value],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [(Number(props.product.nextyear?.nextyearforecastcapitalmaintenance ?? 0) + Number(props.product.nextyear_product_PO_fcm ?? 0)) * currencyRate.value],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [(Number(props.product.nextyear?.nextyearforecastcapitalenhancement ?? 0) + Number(props.product.nextyear_product_PO_fce ?? 0)) * currencyRate.value],
            backgroundColor: ["#00793a"],
            stack: "Stack 1",
          },
          {
            label: "Infrastructure",
            data: [(Number(props.product.nextyear?.nextyearforecastexpenseinfrastructure ?? 0) + Number(props.product.nextyear_product_PO_fei ?? 0)) * currencyRate.value],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [(Number(props.product.nextyear?.nextyearforecastexpensemaintenance ?? 0) + Number(props.product.nextyear_product_PO_fem ?? 0)) * currencyRate.value],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [(Number(props.product.nextyear?.nextyearforecastexpenseenhancement ?? 0) + Number(props.product.nextyear_product_PO_fee ?? 0)) * currencyRate.value],
            backgroundColor: ["#004a96"],
            stack: "Stack 1",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0]?.totals.totalCI) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 2",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0].totals?.totalCM) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 2",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0].totals?.totalCE) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#00793a"],
            stack: "Stack 2",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0].totals?.totalEI) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 2",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0].totals?.totalEM) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 2",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.purchaseordersnextyear?.[0].totals?.totalEE) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#004a96"],
            stack: "Stack 2",
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Next Year",
            font: {
              family: "customFont",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
        },
        indexAxis: "x" as const,
      } as BarChartOptions;

      return {
        dataLoaded,
        chartData,
        chartOptions,
        currency,
        currencyRate,
        financialGoalLabel,
      };
    },
  });
</script>
