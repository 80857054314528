<template><DigitalCostDataEntry></DigitalCostDataEntry></template>

<script lang="ts">
  import { defineComponent } from "vue";
  import DigitalCostDataEntry from "../components/C_DigitalCostDataEntry.vue";

  export default defineComponent({
    name: "DataEntry",
    components: {
      DigitalCostDataEntry,
    },
  });
</script>
