<template>
  <div>
    <div class="c-productlastyearchart__container" v-if="chartData">
      <Bar id="p-lastyear-chart" :options="chartOptions" :data="chartData" />
      <div class="c-productlastyearchart__labels">
        <div>{{ financialGoalLabel }}</div>
        <div>Actuals</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Bar } from "vue-chartjs";
  import { computed, defineComponent, ref } from "vue";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { useStore } from "vuex";
  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  export default defineComponent({
    name: "ProductLastYearChart",
    components: { Bar },
    props: {
      product: {
        type: Object as () => IProduct,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const dataLoaded = ref(false);
      const chartData = ref<IChartData>();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      const financialGoalLabel = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.financial_goal_label;
        } else {
          return "Budget";
        }
      });

      chartData.value = {
        labels: [""],
        datasets: [
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetcapitalinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetcapitalmaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetcapitalenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#00793a"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetexpenseinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetexpensemaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.lastyear?.lastyearbudgetexpenseenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#004a96"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualscapitalinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualscapitalmaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualscapitalenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#00793a"],
            stack: "Stack 1",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualsexpenseinfrastructure) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualsexpensemaintenance) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.lastyear?.lastyearactualsexpenseenhancement) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#004a96"],
            stack: "Stack 1",
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Last Year",
            font: {
              family: "customFont",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
        },
        indexAxis: "x" as const,
      } as BarChartOptions;

      return {
        dataLoaded,
        chartData,
        chartOptions,
        currency,
        currencyRate,
        financialGoalLabel,
      };
    },
  });
</script>
