import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelRadio as _vModelRadio, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "c-dcdataentry__wrapper" }
const _hoisted_2 = { class: "c-dcdataentry__content" }
const _hoisted_3 = { class: "c-dcdataentry__content-selects" }
const _hoisted_4 = { class: "cst-input-field" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "c-dcdataentry__content-selectpair" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "c-dcdataentry__content-selectpair" }
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "cst-input-field" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "c-dcdataentry__content-selectpair c-dcdataentry__content-forecast" }
const _hoisted_15 = { class: "c-dcdataentry__content-radio" }
const _hoisted_16 = { class: "c-dcdataentry__content-radio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "c-dcdataentry__header" }, [
      _createElementVNode("div", { class: "c-dcdataentry__header-wrapper" }, [
        _createElementVNode("h2", { class: "c-dcdataentry__header-title" }, "IT Digital Cost Data Entry"),
        _createElementVNode("h3", { class: "c-dcdataentry__header-subtitle" }, "Update your Product Forecast and Budget in 3 simple steps")
      ])
    ], -1)),
    _createElementVNode("form", {
      onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "c-dcdataentry__content-steps" }, [
          _createElementVNode("p", null, "Step 1 - Select your Product Family"),
          _createElementVNode("p", null, "Step 2 - Select your Product"),
          _createElementVNode("p", null, "Step 3 - Select your Project or Application"),
          _createElementVNode("p", null, "Step 4 - Select Forecast or Budget"),
          _createElementVNode("span", { class: "c-dcdataentry__content-budgetinfo" }, "*Budget input option only available during budgeting periods")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "productfamily" }, "Product Family", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              id: "select-prodfamily",
              required: "",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedData.productfamily) = $event))
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select a Product Family", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamilies, (family) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: family.id,
                  value: family.id,
                  id: "selectedFamily"
                }, _toDisplayString(family.name), 9, _hoisted_5))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.selectedData.productfamily]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[10] || (_cache[10] = _createElementVNode("label", { for: "product" }, "Product:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                id: "select-product",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedData.product) = $event))
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("option", {
                  disabled: "",
                  selected: "",
                  value: ""
                }, "Select a Product", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userProducts, (product) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: product.id,
                    value: product.id
                  }, _toDisplayString(product.name), 9, _hoisted_8))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedData.product]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[12] || (_cache[12] = _createElementVNode("label", { for: "project" }, "Project:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                id: "select-project",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedData.project) = $event))
              }, [
                _cache[11] || (_cache[11] = _createElementVNode("option", {
                  disabled: "",
                  selected: "",
                  value: ""
                }, "Select a Project", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userProjects, (project) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: project.id,
                    value: project.id
                  }, _toDisplayString(project.name), 9, _hoisted_11))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedData.project]
              ])
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("p", null, "or", -1)),
            _createElementVNode("div", _hoisted_12, [
              _cache[14] || (_cache[14] = _createElementVNode("label", { for: "application" }, "Application:", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field",
                id: "select-application",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedData.application) = $event))
              }, [
                _cache[13] || (_cache[13] = _createElementVNode("option", {
                  disabled: "",
                  selected: "",
                  value: ""
                }, "Select an Application", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userApplications, (application) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: application.id,
                    value: application.id
                  }, _toDisplayString(application.name), 9, _hoisted_13))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedData.application]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                id: "Forecast",
                name: "forecast",
                value: "Forecast",
                class: "cst-radio",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedData.option) = $event)),
                checked: ""
              }, null, 512), [
                [_vModelRadio, _ctx.selectedData.option]
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("label", { for: "forecast" }, "Forecast", -1))
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("p", null, "or", -1)),
            _createElementVNode("div", _hoisted_16, [
              _withDirectives(_createElementVNode("input", {
                type: "radio",
                id: "Budget",
                name: "budget",
                value: "Budget",
                class: "cst-radio",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedData.option) = $event))
              }, null, 512), [
                [_vModelRadio, _ctx.selectedData.option]
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("label", { for: "budget" }, "Budget", -1))
            ])
          ])
        ])
      ]),
      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "c-dcdataentry__enter-btn-container" }, [
        _createElementVNode("div", { class: "c-dcdataentry__enter-btn-wrapper" }, [
          _createElementVNode("button", {
            type: "submit",
            class: "cst-button cst-button-primary"
          }, "Enter")
        ])
      ], -1))
    ], 32)
  ]))
}