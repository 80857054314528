<template>
  <RequestMessage></RequestMessage>
  <div class="cst-new-product-type--wrapper" data-testid="budgetplanning-wrapper">
    <div v-if="true" class="cst-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">
          {{ router.currentRoute.value.path.toLowerCase() == "/proposal" ? `New ${financialGoalLabel} Proposal` : `New ${financialGoalLabel} Demand` }}
        </div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="handleBudgetRequest">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="entity-type">Entity Type:<span style="color: red">*</span></label>
              <select v-model="selectedOption" @change="onOptionSelected" id="filter-select" data-testid="budget-planning-select-entity-type">
                <option disabled value="">Select entity type</option>
                <option v-for="(filter, index) in filteredFilters" :key="index" :value="filter.label" data-testid="budget-planning-entity-type-option">
                  {{ filter.label }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="entity">Entity:<span style="color: red">*</span></label>
              <select :key="selectedOption" class="cst-select-field" id="entity" required v-model="selectedEntity" @change="handleChangeEntity" data-testid="budget-planning-select-entity">
                <option disabled value="">Select an entity</option>
                <option v-for="entity in entities" :key="entity.id" :value="entity.id" data-testid="budget-planning-entity">
                  {{ entity.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() != '/proposal'">
              <label for="demand-title">
                Demand Title
                <span style="color: red">*</span></label
              >
              <input class="cst-input" type="text" id="demand-title" required placeholder="Enter demand title" v-model="demandTitle" maxlength="32" data-testid="budget-planning-input-demand-title" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': demandTitle?.length == 32,
                }"
                >{{ demandTitle?.length ? `${demandTitle.length}` : `0` }}/32</span
              >
            </div>

            <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() != '/proposal'">
              <label for="demand-id">
                Demand ID
                <span style="color: red">*</span></label
              >
              <input class="cst-input" type="text" id="demand-id" required placeholder="Enter demand ID" v-model="demandId" maxlength="12" data-testid="budget-planning-input-demand-id" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': demandId?.length == 12,
                }"
                >{{ demandId?.length ? `${demandId.length}` : `0` }}/12</span
              >
            </div>
            <div>
              <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() == '/proposal'">
                <label for="last-year-actuals"> Last Year Actuals </label>
                <input class="cst-input" type="text" id="last-year-actuals" disabled v-model="lastYearActuals" placeholder="0,00 EUR" data-testid="budget-planning-input-last-year-actuals" />
              </div>

              <div class="cst-input-field">
                <label for="proposed-demanded-budget"> {{ router.currentRoute.value.path.toLowerCase() == "/proposal" ? `Proposed ${financialGoalLabel}` : "Demand Estimate" }}<span style="color: red">*</span></label>
                <input
                  class="cst-input"
                  type="number"
                  id="budget"
                  min="1"
                  required
                  :placeholder="router.currentRoute.value.path == '/proposal' ? `Enter proposed ${financialGoalLabel}` : 'Enter demand estimate'"
                  v-model="budget"
                  data-testid="budget-planning-budget"
                />
              </div>
            </div>
            <div>
              <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() == '/proposal'">
                <label for="current-year-forecast"> Current Year Forecast </label>
                <input class="cst-input" type="text" id="current-year-forecast" v-model="currentYearForecast" disabled placeholder="0,00 EUR" data-testid="budget-planning-input-current-year-forecast" />
              </div>

              <div class="cst-input-field">
                <label for="description">Description:</label>
                <textarea class="cst-input cst-input__description" type="textarea" v-model="description" id="description" maxlength="240" data-testid="budget-planning-input-description"></textarea>
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': description?.length == 240,
                  }"
                  >{{ description?.length ? `${description.length}` : `0` }}/240</span
                >
              </div>
            </div>
          </div>

          <div v-if="router.currentRoute.value.path.toLowerCase() == '/demand'" class="label-row-wrapper">
            <p class="label">Note:</p>
            <p class="label-message">{{ financialGoalLabel }} demand can only be created by the entity owner.</p>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="budget-planning-submit-button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <C_BudgetRequestReview :financialGoalLabel="financialGoalLabel"></C_BudgetRequestReview>
</template>
<script lang="ts" setup>
  import { computed, ref } from "vue";
  import { onBeforeRouteLeave, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct, IProductFamily, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { IUser } from "@/utils/interfaces/IUser";
  import C_BudgetRequestReview from "@/components/C_BudgetRequestReview.vue";

  interface BudgetPlanningFilter {
    id: string;
    label: string;
    options: { id: string; name: string }[];
  }

  const { FETCH_FUTURE_PROPOSAL_FILTER, FETCH_FUTURE_DEMAND_FILTER, FETCH_SINGLE_PRODUCT, FETCH_SINGLE_PROJECT, FETCH_SINGLE_APP, FETCH_PRODUCT_FAMILY, CREATE_FUTURE_BUDGET_PLAN, FETCH_USERS } = VUEX_ACTIONS;

  const entities = ref<{ id: string; name: string }[]>([]);
  const selectedOption = ref<string>("");

  const router = useRouter();
  const store = useStore();
  const currentUser = computed(() => store.getters.getCurrentUser);
  const allUsers = computed(() => store.getters.getUsers);

  const currentUserId = computed(() => {
    const userId = allUsers.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);

  const entityType = ref("");
  const selectedEntity = ref("");
  const description = ref("");
  const currentYearForecast = ref("");
  const lastYearActuals = ref("");
  const budget = ref<number>();
  const demandTitle = ref("");
  const demandId = ref("");

  const financialGoalLabel = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.financial_goal_label;
    } else {
      return "Budget";
    }
  });

  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_FUTURE_PROPOSAL_FILTER);
  store.dispatch(FETCH_FUTURE_DEMAND_FILTER);

  const budgetFilter = computed(() => {
    if (router.currentRoute.value.path.toLowerCase() === "/proposal") {
      return store.getters.getProposalFilters;
    } else if (router.currentRoute.value.path.toLowerCase() === "/demand") {
      return store.getters.getDemandFilters;
    } else {
      return [];
    }
  });

  const filteredFilters = computed(() => {
    if (budgetFilter?.value.length > 0) {
      return budgetFilter.value.filter((filter: BudgetPlanningFilter) => filter.options.length > 0);
    } else {
      return [];
    }
  });

  const onOptionSelected = () => {
    selectedEntity.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    entities.value = [];
    store.commit("setProductFamily", []);
    store.commit("setProduct", []);
    store.commit("setSingleProject", []);
    store.commit("setSingleApplication", []);
    const selectedFilter = filteredFilters.value.find((filter: BudgetPlanningFilter) => filter.label === selectedOption.value);
    if (selectedFilter) {
      entities.value = selectedFilter.options;
      entityType.value = selectedFilter.label;
    }
  };

  const handleChangeEntity = async () => {
    store.commit("setProductFamily", []);
    store.commit("setProduct", []);
    store.commit("setSingleProject", []);
    store.commit("setSingleApplication", []);

    try {
      switch (entityType.value.toLowerCase()) {
        case "product family":
          await store.dispatch(FETCH_PRODUCT_FAMILY, selectedEntity.value);
          handleChangeData(store.getters.getProductFamily);
          break;
        case "product":
          await store.dispatch(FETCH_SINGLE_PRODUCT, selectedEntity.value);
          handleChangeData(store.getters.getProduct);
          break;
        case "project":
          await store.dispatch(FETCH_SINGLE_PROJECT, selectedEntity.value);
          handleChangeData(store.getters.getSingleProject);
          break;
        case "application":
          await store.dispatch(FETCH_SINGLE_APP, selectedEntity.value);
          handleChangeData(store.getters.getSingleApplication);
          break;
      }
    } catch (error) {
      console.error("Error fetching data for selected entity:", error);
    }
  };

  const handleChangeData = (entityData: IProduct | IProductFamily | IProjectApplication) => {
    if (entityData.lastyeartotalactuals) {
      lastYearActuals.value = formatNumber((Number(entityData.lastyeartotalactuals) * currencyRate.value).toFixed(2)) + " " + currency.value;
    }
    if ("forecast" in entityData && entityData.forecast !== undefined) {
      currentYearForecast.value = formatNumber((Number(entityData.forecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
    } else if ("totalforecast" in entityData && entityData.totalforecast !== undefined) {
      currentYearForecast.value = formatNumber((Number(entityData.totalforecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
    } else {
      currentYearForecast.value = "";
    }
  };

  // Submit button and POST new data
  const handleBudgetRequest = () => {
    const payload = {
      description: description.value,
      entity_type: entityType.value.toLowerCase().split(" ").join(""),
      status: "proposed",
      sender_id: currentUserId.value,
    };

    if (router.currentRoute.value.path.toLowerCase() == "/proposal") {
      (payload as IBudgetPlan).proposed_budget = Number(budget.value);
      (payload as IBudgetPlan).proposed_by = currentUser.value;
    } else {
      (payload as IBudgetPlan).demand_title = demandTitle.value;
      (payload as IBudgetPlan).demand_id = demandId.value;
      (payload as IBudgetPlan).demanded_budget = budget.value;
    }

    switch (entityType.value.toLowerCase().split(" ").join("")) {
      case "productfamily":
        (payload as IBudgetPlan).entity_productfamily_id = Number(selectedEntity.value);
        break;
      case "product":
        (payload as IBudgetPlan).entity_product_id = Number(selectedEntity.value);
        break;
      case "project":
        (payload as IBudgetPlan).entity_project_id = Number(selectedEntity.value);
        break;
      case "application":
        (payload as IBudgetPlan).entity_application_id = Number(selectedEntity.value);
        break;
      default:
        console.error("Invalid entity type:", entityType.value);
    }

    store.dispatch(CREATE_FUTURE_BUDGET_PLAN, payload);
    demandTitle.value = "";
    demandId.value = "";
    budget.value = 0;
    description.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    selectedEntity.value = "";
    entityType.value = "";
  };

  onBeforeRouteLeave(() => {
    entityType.value = "";
    selectedEntity.value = "";
    selectedOption.value = "";
    lastYearActuals.value = "";
    currentYearForecast.value = "";
    entities.value = [];
  });
</script>
