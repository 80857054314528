<template>
  <div class="c-costtype-inputs" data-testid="costtype-inputs-wrapper">
    <div>
      <div class="cst-input-field" v-if="undefinedEnabled">
        <label for="budget">Undefined:</label>
        <input class="cst-input" type="number" id="undefined" placeholder="Enter Amount" v-model="costTypeInputs.undef" @change="emitChange" min="1" step="any" data-testid="costtype-input-undef" />
      </div>
    </div>
    <div>
      <div class="cst-input-field" data-testid="costtype-inputs">
        <label for="capitaltotal">Capital:</label>
        <input class="cst-input" type="number" id="capital-infra" placeholder="Enter Infa. Cost" v-model="costTypeInputs.bci" @change="emitChange" min="1" step="any" data-testid="costtype-input-bci" />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" type="number" id="capital-maint" placeholder="Enter Maint. Cost" v-model="costTypeInputs.bcm" @change="emitChange" min="1" step="any" data-testid="costtype-input-bcm" />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" type="number" id="capital-enhanc" placeholder="Enter Enhanc. Cost" v-model="costTypeInputs.bce" @change="emitChange" min="1" step="any" data-testid="costtype-input-bce" />
      </div>
      <div class="cst-input-field">
        <input id="capitaltotal" class="cst-input" type="number" disabled v-model="sumCapital" />
      </div>
    </div>
    <div>
      <div class="cst-input-field">
        <label for="expensestotal">Expenses:</label>
        <input class="cst-input" type="number" id="expense-infra" placeholder="Enter Infa. Cost" v-model="costTypeInputs.bei" @change="emitChange" min="1" step="any" data-testid="costtype-input-bei" />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" type="number" id="expense-maint" placeholder="Enter Maint. Cost" v-model="costTypeInputs.bem" @change="emitChange" min="1" step="any" data-testid="costtype-input-bem" />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" type="number" id="expense-enhanc" placeholder="Enter Enhanc. Cost" v-model="costTypeInputs.bee" @change="emitChange" min="1" step="any" data-testid="costtype-input-bee" />
      </div>
      <div class="cst-input-field">
        <input id="expensestotal" class="cst-input" type="number" v-model="sumExpenses" disabled />
      </div>
    </div>

    <div>
      <div class="cst-input-field">
        <label for="total">Total:</label>
        <input class="cst-input" id="total-infra" type="number" v-model="sumInfra" data-testid="costtype-suminfra" disabled />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" id="total-maint" type="number" v-model="sumMaint" data-testid="costtype-summaint" disabled />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" id="total-enhanc" type="number" v-model="sumEnhanc" data-testid="costtype-sumenhanc" disabled />
      </div>
      <div class="cst-input-field">
        <input class="cst-input" id="total" type="number" v-model="sumTotal" disabled />
        <span class="cst-input-field__indicator c-costtype-inputs-max" data-testid="newprod-input-budget-limit" v-if="availableBudget && Number(availableBudget) >= 0"
          ><strong>Available amount:</strong> {{ formatNumber((Number(availableBudget) * currencyRate).toFixed(2)) }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { formatNumber } from "@/utils/helpers/formatNumber";

  export default defineComponent({
    name: "CostTypeInputs",
    props: {
      requiredEmitProp: {
        type: String,
        required: false,
      },
      availableBudget: {
        type: String,
        required: false,
      },
    },
    methods: {
      emitChange() {
        this.$emit("emitCostValues", this.costTypeInputs);
      },
      emitSumTotal() {
        this.$emit("emitSumTotal", this.sumTotal);
      },
    },
    setup(props, context) {
      const costTypeInputs = ref({
        bci: null,
        bcm: null,
        bce: null,
        bei: null,
        bem: null,
        bee: null,
        undef: null,
      });

      const sumCapital = computed(() => {
        return (costTypeInputs.value.bci ? costTypeInputs.value.bci : 0) + (costTypeInputs.value.bcm ? costTypeInputs.value.bcm : 0) + (costTypeInputs.value.bce ? costTypeInputs.value.bce : 0);
      });

      const sumExpenses = computed(() => {
        return (costTypeInputs.value.bei ? costTypeInputs.value.bei : 0) + (costTypeInputs.value.bem ? costTypeInputs.value.bem : 0) + (costTypeInputs.value.bee ? costTypeInputs.value.bee : 0);
      });

      const sumInfra = computed(() => {
        return (costTypeInputs.value.bei ? costTypeInputs.value.bei : 0) + (costTypeInputs.value.bci ? costTypeInputs.value.bci : 0);
      });

      const sumMaint = computed(() => {
        return (costTypeInputs.value.bem ? costTypeInputs.value.bem : 0) + (costTypeInputs.value.bcm ? costTypeInputs.value.bcm : 0);
      });

      const sumEnhanc = computed(() => {
        return (costTypeInputs.value.bee ? costTypeInputs.value.bee : 0) + (costTypeInputs.value.bce ? costTypeInputs.value.bce : 0);
      });

      const sumTotal = computed(() => {
        return sumCapital.value + sumExpenses.value + Number(costTypeInputs.value.undef);
      });
      const store = useStore();
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const undefinedEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.undefined_enabled;
        } else {
          return false;
        }
      });
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      watch([sumTotal], () => {
        context.emit("emitSumTotal", sumTotal.value);
      });

      watch([costTypeInputs.value], () => {
        if (costTypeInputs.value.bci === "") {
          costTypeInputs.value.bci = null;
        }
        if (costTypeInputs.value.bcm === "") {
          costTypeInputs.value.bcm = null;
        }
        if (costTypeInputs.value.bce === "") {
          costTypeInputs.value.bce = null;
        }
        if (costTypeInputs.value.bei === "") {
          costTypeInputs.value.bei = null;
        }
        if (costTypeInputs.value.bem === "") {
          costTypeInputs.value.bem = null;
        }
        if (costTypeInputs.value.bee === "") {
          costTypeInputs.value.bee = null;
        }
        if (costTypeInputs.value.undef === "") {
          costTypeInputs.value.undef = null;
        }
      });

      return {
        costTypeInputs,
        sumCapital,
        sumExpenses,
        sumInfra,
        sumMaint,
        sumEnhanc,
        sumTotal,
        undefinedEnabled,
        currencyRate,
        formatNumber,
      };
    },
  });
</script>
