import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "v-notifications__wrapper",
  "data-testid": "notifications-wrapper"
}
const _hoisted_2 = {
  key: 0,
  "data-testid": "no-messages"
}
const _hoisted_3 = { class: "v-notifications__content-action-row" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "v-notifications__content-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_C_Pagination = _resolveComponent("C_Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Notifications", -1)),
    (_ctx.notifications == null || (_ctx.notifications && !_ctx.notifications.length))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "No new notifications..."))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredNotifications, (notification) => {
          return (_openBlock(), _createElementBlock("div", {
            key: notification.id,
            class: "v-notifications__content"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", {
                class: _normalizeClass(["v-notifications__content-title", {
            'v-notifications__content-title__read': notification.status !== null,
          }]),
                onClick: ($event: any) => (_ctx.openModal(notification.id, notification.title, notification.message, notification.created)),
                "data-testid": "notification-title"
              }, _toDisplayString(notification.title.length > 20 ? notification.title.slice(0, 20) + "..." : notification.title), 11, _hoisted_4),
              _createElementVNode("img", {
                class: _normalizeClass(["v-notifications__content-action-row-status", notification.status !== null ? 'v-notifications__content-action-row-status' : 'v-notifications__content-action-row-status-new']),
                src: notification.status !== null ? '/mail-open.svg' : '/mail-new.svg',
                alt: "Notification button read status",
                onClick: ($event: any) => (_ctx.markNotificationAsSeen(notification.id)),
                "data-testid": "notification-read-button"
              }, null, 10, _hoisted_5),
              _createElementVNode("img", {
                src: "/delete.jpg",
                class: "v-notifications__content-action-row-delete",
                alt: "Delete notification button",
                onClick: ($event: any) => (_ctx.deleteNotification(notification.id)),
                "data-testid": "notification-delete-button"
              }, null, 8, _hoisted_6)
            ]),
            _createElementVNode("p", {
              class: "v-notifications__content-message",
              onClick: ($event: any) => (_ctx.openModal(notification.id, notification.title, notification.message, notification.created)),
              "data-testid": "notification-message"
            }, _toDisplayString(notification.message.length > 70 ? notification.message.slice(0, 70) + "..." : notification.message), 9, _hoisted_7),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.formatDate(notification.created)), 1)
          ]))
        }), 128)),
    (_ctx.totalPages > 1)
      ? (_openBlock(), _createBlock(_component_C_Pagination, {
          key: 2,
          totalPages: _ctx.totalPages,
          itemList: _ctx.notifications,
          onPaginateList: _ctx.paginateList,
          "data-testid": "pages-indicator"
        }, null, 8, ["totalPages", "itemList", "onPaginateList"]))
      : _createCommentVNode("", true)
  ]))
}