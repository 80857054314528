import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { class: "c-table-overview--budget" }
const _hoisted_2 = { class: "c-table-overview__header" }
const _hoisted_3 = {
  key: 1,
  class: "c-table-overview__body-row--wrapper"
}
const _hoisted_4 = { class: "c-table-overview__body-row" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 2,
  class: "c-table-overview__body-row--empty"
}

import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { computed, ref } from "vue";
  import { useStore } from "vuex";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import C_FutureBudgetModal from "./modals/C_FutureBudgetModal.vue";
  import C_Search from "./C_Search.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_FutureBudgetTableOverview',
  props: {
    financialGoalLabel: {}
  },
  setup(__props: any) {

  const { FETCH_SPECIFIC_FUTURE_BUDGET } = VUEX_ACTIONS;
  const props = __props;
  const store = useStore();
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const showDetailsModal = ref(false);
  const selectedEntity = ref<IBudgetPlan>({});
  const futureBudget = computed(() => {
    const budget = store.getters.getFutureBudget;
    return budget.filter((item: IBudgetPlan) => item.status == "approved");
  });
  const filteredBudget = ref(futureBudget.value);
  const handleFutureBudgetDetails = (entity: IBudgetPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_BUDGET, selectedEntity.value.id);
  };

  const filterFutureBudgetPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredBudget.value = futureBudget.value.filter((budget: IBudgetPlan) => {
      if (budget?.entity_name) return budget?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (futureBudget.value.length > 0)
        ? (_openBlock(), _createBlock(C_Search, {
            key: 0,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (filterFutureBudgetPlans($event))),
            placeholder: 'Search by entity name...'
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "Entity", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", null, "Owner", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", null, "Status", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", null, "Demand ID", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("div", null, "Demand Title", -1)),
        _createElementVNode("div", null, "Demanded " + _toDisplayString(_ctx.financialGoalLabel), 1),
        _createElementVNode("div", null, "Proposed " + _toDisplayString(_ctx.financialGoalLabel), 1),
        _createElementVNode("div", null, "Approved " + _toDisplayString(_ctx.financialGoalLabel), 1),
        _cache[7] || (_cache[7] = _createElementVNode("div", null, "Details", -1))
      ]),
      (futureBudget.value.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredBudget.value, (entity, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, _toDisplayString(entity.entity_name), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.owner), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.status), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.demand_id ? entity.demand_id : "-"), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.demand_title ? entity.demand_title : "-"), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.demanded_budget ? _unref(formatNumber)((entity.demanded_budget * currencyRate.value).toFixed(2)) + " " + currency.value : "-"), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.proposed_budget ? _unref(formatNumber)((entity.proposed_budget * currencyRate.value).toFixed(2)) + " " + currency.value : "-"), 1),
                  _createElementVNode("div", null, _toDisplayString(entity.approved_budget ? _unref(formatNumber)((entity.approved_budget * currencyRate.value).toFixed(2)) + " " + currency.value : "-"), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (handleFutureBudgetDetails(entity))
                    }, "View", 8, _hoisted_5)
                  ])
                ])
              ]))
            }), 128)),
            (filteredBudget.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "There are no such entities."))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, "There are no entities with planned future budget."))
    ]),
    (showDetailsModal.value)
      ? (_openBlock(), _createBlock(C_FutureBudgetModal, {
          key: 0,
          onCloseDetailsModal: _cache[1] || (_cache[1] = ($event: any) => (showDetailsModal.value = !showDetailsModal.value)),
          entity: selectedEntity.value,
          financialGoalLabel: _ctx.financialGoalLabel
        }, null, 8, ["entity", "financialGoalLabel"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})