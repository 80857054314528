import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '../../public/notification.svg'
import _imports_1 from '../../public/delete.jpg'


const _hoisted_1 = { class: "c-notification__container" }
const _hoisted_2 = {
  key: 0,
  class: "c-notification__img-alert",
  alt: "notification number indicator",
  "data-testid": "notification-number"
}
const _hoisted_3 = { class: "c-notification__container" }
const _hoisted_4 = {
  key: 0,
  class: "c-notification__dropdown",
  "data-testid": "notification-dropdown"
}
const _hoisted_5 = {
  key: 0,
  class: "c-notification__dropdown--no-notifications"
}
const _hoisted_6 = { class: "c-notification__dropdown-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "c-notification__dropdown-status"
}
const _hoisted_8 = { class: "c-notification__dropdown-content" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "c-notification__dropdown-content-action-row" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["src", "onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "c-notification__dropdown-content-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationModal = _resolveComponent("NotificationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "c-notification__img-wrapper",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleNotifications && _ctx.handleNotifications(...args))),
      "data-testid": "notification-icon"
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        class: "c-notification__img",
        src: _imports_0,
        alt: "notification icon"
      }, null, -1)),
      (_ctx.totalUnreadNotifications > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.totalUnreadNotifications), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showNotifications)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.notifications == null || (_ctx.notifications && !_ctx.notifications.length))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, "No new notifications..."))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.notifications.slice(0, 5), (notification) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: notification.id,
                    style: {"padding":"5px"}
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      (notification.status === null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["c-notification__dropdown-content-title", {
                  'c-notification__dropdown-content-title__read': notification.status != null,
                }]),
                          onClick: ($event: any) => (_ctx.openModal(notification.id, notification.title, notification.message, notification.created))
                        }, _toDisplayString(notification.title.length > 20 ? notification.title.slice(0, 20) + "..." : notification.title), 11, _hoisted_9),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("p", {
                            class: "c-notification__dropdown-content-action-row-message",
                            onClick: ($event: any) => (_ctx.openModal(notification.id, notification.title, notification.message, notification.created)),
                            "data-testid": "open-modal"
                          }, _toDisplayString(notification.message.length > 25 ? notification.message.slice(0, 20) + " ..." : notification.message), 9, _hoisted_11),
                          _createElementVNode("img", {
                            class: _normalizeClass(["c-notification__dropdown-content-action-row-status", notification.status !== null ? 'c-notification__dropdown-content-action-row-status' : 'c-notification__dropdown-content-action-row-status-new']),
                            src: notification.status !== null ? '/mail-open.svg' : '/mail-new.svg',
                            alt: "Notification button read status",
                            "data-testid": "read-notification",
                            onClick: ($event: any) => (_ctx.markNotificationAsSeen(notification.id))
                          }, null, 10, _hoisted_12),
                          _createElementVNode("img", {
                            src: _imports_1,
                            class: "c-notification__dropdown-content-action-row-delete",
                            alt: "Delete notification button",
                            "data-testid": "delete-notification",
                            onClick: ($event: any) => (_ctx.deleteNotification(notification.id))
                          }, null, 8, _hoisted_13)
                        ]),
                        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.formatDate(notification.created)), 1)
                      ])
                    ]),
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-notification__dropdown-spacer" }, null, -1))
                  ]))
                }), 128)),
            _createElementVNode("div", {
              class: "c-notification__dropdown-viewall",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleViewAll && _ctx.handleViewAll(...args))),
              "data-testid": "view-all-notifications"
            }, "View all notifications")
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_NotificationModal)
  ]))
}