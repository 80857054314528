import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";

interface CurrencyState {
  selectedCurrency: string;
  currencyRate: number;
  allCurrencies: { id: string; rate: number }[];
}

interface CurrencyContext {
  commit: Commit;
}

const state: CurrencyState = {
  selectedCurrency: "EUR",
  allCurrencies: [],
  currencyRate: 1,
};

const mutations = {
  setSelectedCurrency(state: CurrencyState, currency: string) {
    state.selectedCurrency = currency;
  },
  setAllCurrencies(state: CurrencyState, currencies: { id: string; rate: number }[]) {
    state.allCurrencies = currencies;
  },
  setCurrencyRate(state: CurrencyState, currencyRate: number) {
    state.currencyRate = currencyRate;
  },
};

const getters = {
  getSelectedCurrency: (state: CurrencyState) => {
    return state.selectedCurrency;
  },
  getAllCurrencies: (state: CurrencyState) => {
    return state.allCurrencies;
  },
  getCurrencyRate: (state: CurrencyState) => {
    return state.currencyRate;
  },
};

const actions = {
  updateSelectedCurrency(context: CurrencyContext, currency: string) {
    context.commit("setSelectedCurrency", currency);
    for (const item of state.allCurrencies) {
      if (item.id == currency) {
        context.commit("setCurrencyRate", item.rate);
      }
    }
  },

  fetchCurrencies(context: CurrencyContext) {
    AXIOS_REQUEST("GET", "/currencies")
      .then((response) => {
        const currenciesDO: { id: string; rate: number }[] = [];
        for (const id in response.data) {
          currenciesDO.push({
            id: id,
            rate: response.data[id],
          });
        }
        context.commit("setAllCurrencies", currenciesDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const currencyModule = {
  state,
  getters,
  mutations,
  actions,
};

export default currencyModule;
