import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-productlastyearchart__container"
}
const _hoisted_2 = { class: "c-productlastyearchart__labels" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.chartData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Bar, {
            id: "p-lastyear-chart",
            options: _ctx.chartOptions,
            data: _ctx.chartData
          }, null, 8, ["options", "data"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, _toDisplayString(_ctx.financialGoalLabel), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Actuals", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}