import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-platformoverview__wrapper" }
const _hoisted_2 = { class: "v-platformoverview__products-wrapper" }
const _hoisted_3 = { class: "v-platformoverview__summary-overview" }
const _hoisted_4 = { class: "v-platformoverview__summary-overview--title" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "c-budgetsummary__wrapper--empty"
}
const _hoisted_8 = {
  key: 1,
  class: "c-budgetsummary__wrapper--empty"
}
const _hoisted_9 = {
  key: 0,
  class: "v-platformoverview__search"
}
const _hoisted_10 = {
  key: 1,
  class: "v-platformoverview__no-data"
}
const _hoisted_11 = {
  key: 2,
  class: "v-platformoverview__no-data"
}
const _hoisted_12 = {
  key: 3,
  class: "v-platformoverview__product-cards"
}
const _hoisted_13 = { key: 4 }
const _hoisted_14 = { class: "v-platformoverview__table--wrapper" }
const _hoisted_15 = {
  class: "v-platformoverview__table--overview",
  "aria-describedby": "Table of platforms"
}
const _hoisted_16 = {
  colsp: "",
  class: "v-platformoverview__table-row"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { colspan: "4" }
const _hoisted_20 = {
  key: 0,
  class: "v-showinvoices__li-table--wrapper"
}
const _hoisted_21 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_22 = { class: "v-showinvoices__li-table-row--first" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 1,
  class: "cst-spinner"
}
const _hoisted_25 = { key: 2 }
const _hoisted_26 = {
  key: 3,
  class: "v-platformoverview__li-table--wrapper"
}
const _hoisted_27 = {
  key: 0,
  class: "v-showinvoices__li-table-row"
}
const _hoisted_28 = { class: "v-showinvoices__li-table-row--first" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 4,
  class: "cst-spinner"
}
const _hoisted_31 = { key: 5 }
const _hoisted_32 = {
  key: 5,
  class: "cst-spinner"
}

import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import C_PlatformCard from "@/components/cards/C_PlatformCard.vue";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { IProduct, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import ProjectAppModal from "@/components/modals/C_ProjectAppModal.vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_PlatformOverview',
  setup(__props) {

  const { FETCH_NOTIFICATIONS, FETCH_USERS, FETCH_ALL_PLATFORMS, FETCH_PRODUCTS_IN_PLATFORM, FETCH_PROJECTS_IN_PRODUCT, FETCH_APP_IN_PRODUCT, FETCH_PROJECT_PURCHASE_ORDERS, FETCH_APPLICATION_PURCHASE_ORDERS } = VUEX_ACTIONS;
  const store = useStore();
  const platforms = computed(() => store.getters.getPlatforms);
  const selectedPlatform = ref<IPlatform>();
  const focusedPlatform = ref("Platform Overview");
  const platformDescription = ref("Select a platform from the list to see the details.");

  const filteredPlatforms = ref<IPlatform[]>(platforms.value);
  const isProjAppModalOpen = ref(false);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  const users = computed(() => store.getters.getUsers);
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const productsInPlatform = computed(() => store.getters.getProductsInPlatform);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const focusedProjApp = ref({
    name: "",
    type: "",
  });
  const arePlatformDetailsFetched = computed(() => store.getters.getArePlatformDetailsFetched);
  const projectsInProduct = computed(() => store.getters.getProductProjects);
  const applicationsInProduct = computed(() => store.getters.getProductApplications);
  const areApplicationsFromProductFetched = computed(() => store.getters.getApplicationsInProductFetched);
  const areProjectsFromProductFetched = computed(() => store.getters.getProjectsInProductFetched);

  const isPlatformFocused = ref(false);
  const expandedIndex = ref<number | null>(null);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_ALL_PLATFORMS);

  const filterPlatforms = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredPlatforms.value = platforms.value.filter((platform: IPlatform) => {
      return platform.name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  const handlePlatformDetails = (platform: IPlatform) => {
    selectedPlatform.value = platform;
    focusedPlatform.value = `${platform.name}`;
    isPlatformFocused.value = true;
    filteredPlatforms.value = [];
    store.dispatch(FETCH_PRODUCTS_IN_PLATFORM, platform.id);
  };

  const handleProductDetailsClick = (product: IProduct, index: number) => {
    store.commit("setProjectsInProductFetched", false);
    store.commit("setApplicationsInProductFetched", false);

    if (expandedIndex.value === index) {
      expandedIndex.value = null;
    } else {
      expandedIndex.value = index;
      store.dispatch(FETCH_PROJECTS_IN_PRODUCT, product.id);
      store.dispatch(FETCH_APP_IN_PRODUCT, product.id);
    }
  };

  const handleProjectAppDetailsClick = (projapp: IProjectApplication, type: string) => {
    focusedProjApp.value.name = projapp.name;
    focusedProjApp.value.type = type;
    isProjAppModalOpen.value = true;

    if (type === "project") {
      store.dispatch(FETCH_PROJECT_PURCHASE_ORDERS, projapp.id);
    } else {
      store.dispatch(FETCH_APPLICATION_PURCHASE_ORDERS, projapp.id);
    }
  };

  const convertUsernameFromId = (id: number) => {
    let username = "Unknown";
    users.value.filter((user: IUser) => {
      if (Number(user.id) === id) {
        username = user.username;
      }
    });
    return username;
  };

  watch([platforms], () => {
    filteredPlatforms.value = platforms.value;
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (focusedPlatform.value !== 'Platform Overview')
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Platform:", -1)),
                  _createTextVNode(" " + _toDisplayString(focusedPlatform.value), 1)
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(focusedPlatform.value), 1)),
            (focusedPlatform.value != 'Platform Overview')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "v-platformoverview__summary-overview--btn cst-button cst-button-primary",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (console.log('Manage platform')))
                }, _cache[4] || (_cache[4] = [
                  _createElementVNode("b", null, "Manage", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          (selectedPlatform.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Total budget:", -1)),
                  _createTextVNode(" " + _toDisplayString(selectedPlatform.value.budget) + " " + _toDisplayString(selectedCurrency.value.value), 1)
                ]),
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Platform owner:", -1)),
                  _createTextVNode(" " + _toDisplayString(convertUsernameFromId(selectedPlatform.value.owner)), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(platformDescription.value), 1))
        ]),
        (focusedPlatform.value == 'Platform Overview')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("input", {
                class: "v-platformoverview__search-input",
                type: "text",
                id: "platformname",
                required: "",
                maxlength: "40",
                "data-testid": "platform-search",
                placeholder: "Search platform...",
                onInput: _cache[1] || (_cache[1] = ($event: any) => (filterPlatforms($event)))
              }, null, 32)
            ]))
          : _createCommentVNode("", true),
        (filteredPlatforms.value.length === 0 && !isPlatformFocused.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[7] || (_cache[7] = [
              _createElementVNode("h2", null, "No platforms found.", -1)
            ])))
          : _createCommentVNode("", true),
        (productsInPlatform.value.length === 0 && isPlatformFocused.value && arePlatformDetailsFetched.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createTextVNode(_toDisplayString(filteredPlatforms.value.length) + " ", 1),
              _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Platform has no associated products.", -1))
            ]))
          : _createCommentVNode("", true),
        (!isPlatformFocused.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPlatforms.value, (platform) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: platform.id
                }, [
                  _createVNode(C_PlatformCard, {
                    unit: platform,
                    onShowPlatformDetails: handlePlatformDetails
                  }, null, 8, ["unit"])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (isPlatformFocused.value && arePlatformDetailsFetched.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _cache[19] || (_cache[19] = _createElementVNode("h2", null, [
                _createElementVNode("strong", null, "Products in platform:")
              ], -1)),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("table", _hoisted_15, [
                  _cache[18] || (_cache[18] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", { class: "v-platformoverview__table-head-row" }, [
                      _createElementVNode("th", null, "Name"),
                      _createElementVNode("th", null, "Owner"),
                      _createElementVNode("th", null, "Budget"),
                      _createElementVNode("th", null, "Details")
                    ])
                  ], -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productsInPlatform.value, (product, index) => {
                    return (_openBlock(), _createElementBlock("tbody", { key: index }, [
                      _createElementVNode("tr", _hoisted_16, [
                        _createElementVNode("td", null, _toDisplayString(product.name), 1),
                        _createElementVNode("td", null, _toDisplayString(convertUsernameFromId(product.owner)), 1),
                        _createElementVNode("td", null, _toDisplayString(_unref(formatNumber)((product.budget * currencyRate.value).toFixed(2)) + " " + selectedCurrency.value), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("button", {
                            onClick: ($event: any) => (handleProductDetailsClick(product, index)),
                            class: "c-requestbtns c-requestbtns-secondary-show"
                          }, [
                            _createElementVNode("p", null, _toDisplayString(expandedIndex.value === index ? "Hide" : "Show"), 1),
                            _cache[9] || (_cache[9] = _createElementVNode("svg", {
                              fill: "#000000",
                              width: "15px",
                              height: "15px",
                              viewBox: "-1 0 19 19",
                              class: "cf-icon-svg"
                            }, [
                              _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                            ], -1))
                          ], 8, _hoisted_17)
                        ])
                      ]),
                      (expandedIndex.value !== null && expandedIndex.value === index)
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                            _createElementVNode("td", _hoisted_19, [
                              _cache[14] || (_cache[14] = _createElementVNode("strong", null, [
                                _createElementVNode("p", { class: "v-platformoverview__label" }, "Projects:")
                              ], -1)),
                              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "v-platformoverview__li-table-header" }, [
                                _createElementVNode("p", { class: "v-platformoverview__li-table-header--first" }, "No."),
                                _createElementVNode("p", null, "Name"),
                                _createElementVNode("p", null, "Owner"),
                                _createElementVNode("p", null, "Budget"),
                                _createElementVNode("p", null, "Details")
                              ], -1)),
                              (areProjectsFromProductFetched.value && projectsInProduct.value.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectsInProduct.value, (item, index) => {
                                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                                        (item && typeof item === 'object')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                              _createElementVNode("p", _hoisted_22, _toDisplayString(index + 1), 1),
                                              _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                              _createElementVNode("p", null, _toDisplayString(convertUsernameFromId(Number(item.owner))), 1),
                                              _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.totalbudget * currencyRate.value).toFixed(2)) + " " + item.currency), 1),
                                              _createElementVNode("div", null, [
                                                _createElementVNode("button", {
                                                  onClick: ($event: any) => (handleProjectAppDetailsClick(item, 'project')),
                                                  class: "c-requestbtns c-requestbtns-secondary-show"
                                                }, _cache[10] || (_cache[10] = [
                                                  _createElementVNode("p", null, "Show", -1),
                                                  _createElementVNode("svg", {
                                                    fill: "#000000",
                                                    width: "15px",
                                                    height: "15px",
                                                    viewBox: "-1 0 19 19",
                                                    class: "cf-icon-svg"
                                                  }, [
                                                    _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                                  ], -1)
                                                ]), 8, _hoisted_23)
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]))
                                : (!areApplicationsFromProductFetched.value)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[11] || (_cache[11] = [
                                      _createElementVNode("p", { class: "v-platformoverview__label" }, "No projects found.", -1)
                                    ]))),
                              _cache[16] || (_cache[16] = _createElementVNode("strong", null, [
                                _createElementVNode("p", { class: "v-platformoverview__label" }, "Applications:")
                              ], -1)),
                              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "v-platformoverview__li-table-header" }, [
                                _createElementVNode("p", { class: "v-platformoverview__li-table-header--first" }, "No."),
                                _createElementVNode("p", null, "Name"),
                                _createElementVNode("p", null, "Owner"),
                                _createElementVNode("p", null, "Budget"),
                                _createElementVNode("p", null, "Details")
                              ], -1)),
                              (areApplicationsFromProductFetched.value && applicationsInProduct.value.length > 0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applicationsInProduct.value, (item, index) => {
                                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                                        (item && typeof item === 'object')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                              _createElementVNode("p", _hoisted_28, _toDisplayString(index + 1), 1),
                                              _createElementVNode("p", null, _toDisplayString(item.name), 1),
                                              _createElementVNode("p", null, _toDisplayString(convertUsernameFromId(Number(item.owner))), 1),
                                              _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((item.totalbudget * currencyRate.value).toFixed(2)) + " " + item.currency), 1),
                                              _createElementVNode("div", null, [
                                                _createElementVNode("button", {
                                                  onClick: ($event: any) => (handleProjectAppDetailsClick(item, 'application')),
                                                  class: "c-requestbtns c-requestbtns-secondary-show"
                                                }, _cache[12] || (_cache[12] = [
                                                  _createElementVNode("p", null, "Show", -1),
                                                  _createElementVNode("svg", {
                                                    fill: "#000000",
                                                    width: "15px",
                                                    height: "15px",
                                                    viewBox: "-1 0 19 19",
                                                    class: "cf-icon-svg"
                                                  }, [
                                                    _createElementVNode("path", { d: "M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z" })
                                                  ], -1)
                                                ]), 8, _hoisted_29)
                                              ])
                                            ]))
                                          : _createCommentVNode("", true)
                                      ]))
                                    }), 128))
                                  ]))
                                : (!areApplicationsFromProductFetched.value)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_30))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_31, _cache[13] || (_cache[13] = [
                                      _createElementVNode("p", { class: "v-platformoverview__label" }, "No applications found.", -1)
                                    ])))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : (!arePlatformDetailsFetched.value && isPlatformFocused.value && productsInPlatform.value.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_32))
            : _createCommentVNode("", true)
      ])
    ]),
    (isProjAppModalOpen.value)
      ? (_openBlock(), _createBlock(ProjectAppModal, {
          key: 0,
          projappName: focusedProjApp.value.name,
          type: focusedProjApp.value.type,
          "is-modal-enabled": isProjAppModalOpen.value,
          onIsProjectAppModalVisible: _cache[2] || (_cache[2] = ($event: any) => (isProjAppModalOpen.value = false))
        }, null, 8, ["projappName", "type", "is-modal-enabled"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})