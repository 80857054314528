import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-entitycard__card" }
const _hoisted_2 = { class: "c-entitycard__action-row" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  class: "c-entitycard__entity-info",
  "data-testid": "entity-info"
}
const _hoisted_5 = {
  class: "c-entitycard__entity-type",
  "data-testid": "entity-entitytype"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: "cst-button-close c-entitycard__btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeEntity && _ctx.removeEntity(...args))),
        "data-testid": "entity-removeentity-btn",
        disabled: _ctx.isprcreated && !_ctx.isediting
      }, "X", 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.application !== null && _ctx.data.application !== undefined ? _ctx.findApplication() : _ctx.data.project !== null && _ctx.data.project !== undefined ? _ctx.findProject() : _ctx.findProduct()), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.entityType), 1)
  ]))
}