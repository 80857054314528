import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-submenu__nav" }
const _hoisted_2 = { class: "c-submenu__nav-wrapper" }
const _hoisted_3 = {
  class: "c-submenu__nav-links",
  "data-testid": "submenu-wrapper"
}
const _hoisted_4 = {
  key: 0,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-overviews-items"
}
const _hoisted_5 = {
  key: 0,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-createnew-items"
}
const _hoisted_6 = {
  key: 0,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-request-items"
}
const _hoisted_7 = {
  key: 0,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-orders-items"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 3,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-orders-items"
}
const _hoisted_10 = {
  key: 0,
  class: "c-submenu__nav-subnav",
  "data-testid": "submenu-createnew-items"
}
const _hoisted_11 = { class: "c-submenu__nav--right" }
const _hoisted_12 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserDropdown = _resolveComponent("UserDropdown")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", null, [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", {
            class: "c-submenu__nav-link",
            onMouseover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSubmenu = true)),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSubmenu = false)),
            "data-testid": "submenu-item-overviews"
          }, [
            _cache[29] || (_cache[29] = _createElementVNode("p", null, "Overviews", -1)),
            _createElementVNode("div", {
              class: _normalizeClass({
                active: _ctx.isRouteActive('/') || _ctx.isRouteActive('/cost-units'),
              })
            }, null, 2),
            (_ctx.showSubmenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    "data-testid": "submenu-item-productoverview",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleProductClick && _ctx.handleProductClick(...args)))
                  }, "Product Overview"),
                  (_ctx.canCreateCU && _ctx.showCostUnits)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCUOverviewClick && _ctx.handleCUOverviewClick(...args))),
                        "data-testid": "submenu-item-costunits"
                      }, "Cost Units Overview"))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 32),
          (_ctx.canCreatePF || _ctx.canCreateProd || _ctx.canCreateProjOrApp)
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: "c-submenu__nav-link",
                onMouseover: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showCreateSubmenu = true)),
                onMouseleave: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showCreateSubmenu = false)),
                "data-testid": "submenu-item-createnew"
              }, [
                _cache[30] || (_cache[30] = _createElementVNode("p", null, "Create New", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass({
                active: _ctx.isRouteActive('/create-prodfamily') || _ctx.isRouteActive('/create-product') || _ctx.isRouteActive('/create-project') || _ctx.isRouteActive('/create-app') || _ctx.isRouteActive('/create-cost-unit'),
              })
                }, null, 2),
                (_ctx.showCreateSubmenu)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_ctx.canCreatePF)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleProductFamilyClick && _ctx.handleProductFamilyClick(...args))),
                            "data-testid": "submenu-item-createproductfamily"
                          }, "Product Family"))
                        : _createCommentVNode("", true),
                      (_ctx.canCreateProd)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleCreateProductClick && _ctx.handleCreateProductClick(...args))),
                            "data-testid": "submenu-item-createproduct"
                          }, "Product"))
                        : _createCommentVNode("", true),
                      (_ctx.canCreateProjOrApp)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleProjectClick && _ctx.handleProjectClick(...args))),
                            "data-testid": "submenu-item-createproject"
                          }, "Project"))
                        : _createCommentVNode("", true),
                      (_ctx.canCreateProjOrApp)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 3,
                            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleAppClick && _ctx.handleAppClick(...args))),
                            "data-testid": "submenu-item-createapp"
                          }, "Application"))
                        : _createCommentVNode("", true),
                      (_ctx.canCreateCU && _ctx.showCostUnits)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleCuCreateClick && _ctx.handleCuCreateClick(...args))),
                            "data-testid": "submenu-item-createcu"
                          }, "Cost Unit"))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ], 32))
            : _createCommentVNode("", true),
          _createElementVNode("li", {
            class: "c-submenu__nav-link",
            onMouseover: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showPRSubmenu = true)),
            onMouseleave: _cache[15] || (_cache[15] = ($event: any) => (_ctx.showPRSubmenu = false)),
            "data-testid": "submenu-item-requests"
          }, [
            _cache[31] || (_cache[31] = _createElementVNode("p", null, "Requests", -1)),
            _createElementVNode("div", {
              class: _normalizeClass({
                active: _ctx.isRouteActive('/requests') || _ctx.isRouteActive('/create-request') || _ctx.isRouteActive('/archive'),
              })
            }, null, 2),
            (_ctx.showPRSubmenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", {
                    to: "/requests",
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleOpenPRClick && _ctx.handleOpenPRClick(...args))),
                    "data-testid": "submenu-item-openrequests"
                  }, "Open Requests"),
                  (_ctx.canCreatePR)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleCreatePRClick && _ctx.handleCreatePRClick(...args))),
                        "data-testid": "submenu-item-createpr"
                      }, "Create Request"))
                    : _createCommentVNode("", true),
                  (_ctx.showArchive)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.handleArchiveClick && _ctx.handleArchiveClick(...args))),
                        "data-testid": "submenu-item-archive"
                      }, "Archive"))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 32),
          _createElementVNode("li", {
            class: "c-submenu__nav-link",
            onMouseover: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showPOSubmenu = true)),
            onMouseleave: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showPOSubmenu = false)),
            "data-testid": "submenu-item-orders"
          }, [
            _cache[32] || (_cache[32] = _createElementVNode("p", null, "Orders", -1)),
            _createElementVNode("div", {
              class: _normalizeClass({
                active: _ctx.isRouteActive('/orders-expiration') || _ctx.isRouteActive('/all-orders'),
              })
            }, null, 2),
            (_ctx.showPOSubmenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.handleExpirationClick && _ctx.handleExpirationClick(...args))),
                    "data-testid": "submenu-item-expirationlist"
                  }, "Expiration list"),
                  _createElementVNode("div", {
                    onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.handleAllOrders && _ctx.handleAllOrders(...args))),
                    "data-testid": "submenu-item-allorders"
                  }, "All orders")
                ]))
              : _createCommentVNode("", true)
          ], 32),
          _createElementVNode("li", {
            class: "c-submenu__nav-link",
            "data-testid": "submenu-item-invoices",
            onMouseover: _cache[20] || (_cache[20] = ($event: any) => (_ctx.showInvoiceSubmenu = true)),
            onMouseleave: _cache[21] || (_cache[21] = ($event: any) => (_ctx.showInvoiceSubmenu = false))
          }, [
            (!_ctx.showCSVUpload)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/invoices"
                }, {
                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                    _createElementVNode("p", null, "Invoices", -1)
                  ])),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("p", _hoisted_8, "Invoices")),
            (_ctx.showInvoiceSubmenu)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass({ active: _ctx.isRouteActive('/invoices') })
                }, null, 2))
              : _createCommentVNode("", true),
            (_ctx.showInvoiceSubmenu && _ctx.showCSVUpload)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_router_link, { to: "/invoices" }, {
                    default: _withCtx(() => _cache[34] || (_cache[34] = [
                      _createElementVNode("div", null, "Invoice list", -1)
                    ])),
                    _: 1
                  }),
                  (_ctx.showCSVUpload)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: "/import-csv"
                      }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createElementVNode("div", { "data-testid": "submenu-item-csv" }, "Upload CSV", -1)
                        ])),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 32),
          _createElementVNode("li", {
            class: "c-submenu__nav-link",
            onMouseover: _cache[25] || (_cache[25] = ($event: any) => (_ctx.showBudgetSubmenu = true)),
            onMouseleave: _cache[26] || (_cache[26] = ($event: any) => (_ctx.showBudgetSubmenu = false))
          }, [
            _createElementVNode("p", null, _toDisplayString(_ctx.financialGoalLabel) + " Planning", 1),
            (_ctx.showBudgetSubmenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", {
                    onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.handleBudgetOverviewClick && _ctx.handleBudgetOverviewClick(...args))),
                    "data-testid": "submenu-item-createproductfamily"
                  }, "Overview"),
                  _createElementVNode("div", {
                    onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.handleBudgetProposalClick && _ctx.handleBudgetProposalClick(...args))),
                    "data-testid": "submenu-item-createproduct"
                  }, "Proposal"),
                  _createElementVNode("div", {
                    onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.handleBudgetDemandClick && _ctx.handleBudgetDemandClick(...args))),
                    "data-testid": "submenu-item-createproject"
                  }, "Demand")
                ]))
              : _createCommentVNode("", true)
          ], 32)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _withDirectives(_createElementVNode("select", {
          title: "currency",
          class: "cst-select cst-select--small",
          id: "currency-select",
          onChange: _cache[27] || (_cache[27] = 
//@ts-ignore
(...args) => (_ctx.updateCurrency && _ctx.updateCurrency(...args))),
          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.selectedCurrency) = $event)),
          "data-testid": "submenu-currency-dropdown"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (currency) => {
            return (_openBlock(), _createElementBlock("option", {
              key: currency.id,
              value: currency.id,
              "data-testid": "submenu-list-currencies"
            }, _toDisplayString(currency.id), 9, _hoisted_12))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.selectedCurrency]
        ]),
        _createVNode(_component_UserDropdown),
        _createVNode(_component_Notification)
      ])
    ])
  ]))
}