import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-productcuallocation" }
const _hoisted_2 = {
  key: 0,
  class: "v-productcuallocation__table-wrapper"
}
const _hoisted_3 = {
  class: "v-polist__table v-polist__list-table",
  "aria-describedby": "Table of cost units the product is allocated in"
}
const _hoisted_4 = { class: "v-polist__table-head-row" }
const _hoisted_5 = { class: "v-polist__table-row" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "v-productcuallocation__label-empty"
}
const _hoisted_9 = {
  key: 2,
  class: "cst-spinner"
}

import { VUEX_ACTIONS } from "@/utils/constants";
  import { computed } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_ProductCUAllocation',
  setup(__props) {

  const { FETCH_COST_UNITS, FETCH_PRODUCT_CU_ALLOCATION, FETCH_SINGLE_PRODUCT } = VUEX_ACTIONS;

  const route = useRoute();
  const router = useRouter();
  const checkUnitQuery = () => {
    // If there is no product in the query, redirect to the home page
    if (!route.query.product) {
      router.push("/");
    }
  };
  checkUnitQuery();

  const productId = route.query.product;

  const store = useStore();
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const costUnits = computed(() => store.getters.getCostUnits);
  const productCuAllocation = computed(() => store.getters.getProductCuAllocation);
  const productCuAllocationFetched = computed(() => store.getters.getProductCuAllocationFetched);
  const product = computed(() => store.getters.getProduct);

  store.dispatch(FETCH_COST_UNITS);
  store.dispatch(FETCH_PRODUCT_CU_ALLOCATION, productId);
  store.dispatch(FETCH_SINGLE_PRODUCT, productId);

  if (userRoles.value.length > 0 && !(userRoles.value.includes("edit-product-hierarchy") || userRoles.value.includes("view-product-hierarchy") || userRoles.value.includes("view-all-product"))) {
    router.push("/");
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "v-polist__title-all" }, "Product Cost Unit Allocation", -1)),
    (productCuAllocationFetched.value && productCuAllocation.value.costUnits.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_4, [
                _cache[0] || (_cache[0] = _createElementVNode("th", null, [
                  _createElementVNode("strong", null, "Product")
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(costUnits.value, (unit) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: unit.id
                  }, _toDisplayString(unit.name), 1))
                }), 128)),
                _cache[1] || (_cache[1] = _createElementVNode("th", null, [
                  _createElementVNode("strong", null, "Total")
                ], -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_5, [
                _createElementVNode("td", null, [
                  _createElementVNode("strong", null, _toDisplayString(product.value.name), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(costUnits.value, (unit) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: unit.id
                  }, [
                    (productCuAllocation.value.costUnits.find((cu) => cu.costunitname === unit.name))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(Number(productCuAllocation.value.costUnits.find((cu) => cu.costunitname === unit.name).percentage) * 100 + " %"), 1))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, "0"))
                  ]))
                }), 128)),
                _createElementVNode("td", null, [
                  _createElementVNode("strong", null, _toDisplayString(Number(productCuAllocation.value.totalPercentageSum) * 100 + " %"), 1)
                ])
              ])
            ])
          ])
        ]))
      : (productCuAllocationFetched.value && productCuAllocation.value.costUnits.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[2] || (_cache[2] = [
            _createElementVNode("p", null, "This product is not allocated to any cost units.", -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_9))
  ]))
}
}

})