<template>
  <div class="c-cucard__wrapper">
    <img class="c-cucard__image" :src="getImageUrl()" alt="placeholder" data-testid="cucard-image" />
    <p class="c-cucard__name">
      {{ unit.name + " (" + Number(unit.percentage) * 100 + "%)" }}
    </p>
    <div class="c-cucard__summary">
      <BudgetSummary size="small" :budget="Number(unit.totalbudget)" :forecast="Number(unit.totalforecast)" :actuals="Number(unit.totalactuals)"></BudgetSummary>
      <div class="c-cucard__btns" v-if="!hideButtons">
        <button type="button" class="cst-button cst-button-primary" @click="handleUnitDetailsClick(`orders`)" data-testid="cucard-podetails-btn">PO Details</button>
        <button type="button" class="cst-button cst-button-primary" @click="handleUnitDetailsClick(`requests`)" data-testid="cucard-prdetails-btn">PR Details</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from "vue";
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { ICostUnitProducts } from "@/utils/interfaces/ICostUnit";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "ProductCard",
    components: {
      BudgetSummary,
    },
    props: {
      unit: {
        type: Object as PropType<ICostUnitProducts>,
        required: true,
      },
      hideButtons: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["show-unit-details"],
    setup(props, { emit }) {
      const { CHANGE_PO_DETAILS_VIEW } = VUEX_ACTIONS;
      const store = useStore();

      const handleUnitDetailsClick = (id: string) => {
        store.dispatch(CHANGE_PO_DETAILS_VIEW, true);
        emit("show-unit-details", props.unit.id, props.unit.name, id);
      };

      const getImageUrl = () => {
        if (props.unit.image_data === "" || props.unit.image_data === null || !props.unit.image_data) {
          return "https://placehold.co/240x140";
        }
        return props.unit.image_data;
      };

      return {
        handleUnitDetailsClick,
        getImageUrl,
      };
    },
  });
</script>
