<template>
  <RequestMessage></RequestMessage>
  <div class="v-createcu">
    <div class="v-createcu--forms">
      <div class="v-createcu--formwrapper" v-if="hasPermission" data-testid="createcu-wrapper">
        <div class="v-createcu--formwrapper--header">
          <div class="v-createcu--formwrapper--header__title">Create Cost Unit</div>
        </div>

        <div class="v-createcu--formwrapper--body">
          <form @submit.prevent>
            <div class="v-createcu--formwrapper__form">
              <div class="cst-input-field">
                <label for="name">Name:</label>
                <input :disabled="inputFieldsDisabled" class="cst-input v-createcu--formwrapper--inputfield" type="text" placeholder="Example: AP" id="nameText" data-testid="createcu-input-name" v-model="nameText" required />
              </div>

              <div class="cst-input-field">
                <label for="productfamily">Select manager</label>
                <select class="cst-select-field v-createcu--formwrapper--inputfield" required v-model="selectedManager" :disabled="inputFieldsDisabled" data-testid="createcu-select-manager">
                  <option disabled selected value="">Select a manager</option>
                  <option v-for="manager in users" :key="manager.id" :value="manager.id" id="selectedManager">
                    {{ manager.username }}
                  </option>
                </select>
              </div>
              <div class="cst-input-field">
                <label for="date">Start Date:</label>
                <input :disabled="inputFieldsDisabled" class="cst-input v-createcu--formwrapper--inputfield" type="date" id="date" :min="fetchTodaysDate()" v-model="selectedDate" data-testid="createcu-select-date" />
              </div>
            </div>
            <div class="v-createcu--formwrapper__buttonrow">
              <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" :disabled="disableCreateBtn" type="submit" @click="handleCostUnitCreation(nameText)" data-testid="createcu-create-button">Create</button>

              <button v-if="showProductAllocation" class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" type="submit" @click="handleNew()">New</button>
            </div>
          </form>
        </div>
      </div>

      <div class="v-createcu--formwrapper">
        <div class="v-createcu--formwrapper--header v-createcu--formwrapper--allocating" v-if="showProductAllocation">
          <div class="v-createcu--formwrapper--header__title">Allocate a product</div>
        </div>

        <div class="v-createcu--formwrapper--body" v-if="showProductAllocation">
          <form @submit.prevent>
            <div class="v-createcu--formwrapper__form">
              <div class="cst-input-field">
                <label for="productfamily">Select product family</label>
                <select class="cst-select-field" required v-model="selectedProductFamily" @change="fetchProductsFromPF" data-testid="createcu-select-pf">
                  <option disabled selected value="">Select a product family</option>
                  <option v-for="family in productFamily" :key="family.id" :value="family.id" id="selectedFamilyProduct">
                    {{ family.name }}
                  </option>
                </select>
              </div>
              <div class="cst-input-field">
                <label for="productfamily">Select product</label>
                <select class="cst-select-field" required v-model="selectedProduct" @change="fetchRemainingPercentage(Number(selectedProduct))" :disabled="isProductDropdownDisabled" data-testid="createcu-select-product">
                  <option disabled selected value="">Select a product</option>
                  <option v-for="product in products" :key="product.id" :value="product.id" id="selectedProduct">
                    {{ product.name }}
                  </option>
                </select>
              </div>
              <div class="v-createcu--formwrapper__form--budget">
                <div class="cst-input-field">
                  <label for="name">Allocate budget (%):</label>
                  <input
                    class="cst-input"
                    :class="{ 'cst-input__warning': isExceedingPercentage }"
                    input="number"
                    placeholder="Example: 10%"
                    id="budgetPercentage"
                    min="1"
                    max="100"
                    @input="preventDotInInput"
                    v-model="budgetPercentage"
                    required
                    data-testid="createcu-input-allocatebudget"
                  />
                </div>
                <div class="cst-input-field">
                  <label for="name">Available budget (%):</label>
                  <input class="cst-input" input="number" placeholder="Example: 10%" v-model="remainingBudgetPercentage" id="remainingBudgetPercentage" disabled data-testid="createcu-input-availablebudget" />
                </div>
              </div>
            </div>
            <div class="v-createcu--formwrapper__buttonrow">
              <button class="cst-button cst-button-primary v-createcu--formwrapper__createbtn" type="submit" @click="handleProductAllocation()" :disabled="disableAllocateBtn" data-testid="createcu-allocatebutton">Allocate</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="v-createcu--productswrapper" v-if="showProducts">
      <div class="v-createcu--products__header">
        <p class="v-createcu--products__header--text">Cost Unit: {{ nameText }}</p>
      </div>
      <div class="v-createcu--products">
        <CostUnitCard v-for="unit in filteredCUProducts[0].products" :key="unit.id" :unit="unit" :hideButtons="true" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { fetchTodaysDate } from "@/utils/helpers/fetchTodaysDate";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import CostUnitCard from "@/components/cards/C_CostUnitCard.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "CreateCostUnit",
    components: {
      RequestMessage,
      CostUnitCard,
    },
    setup() {
      const {
        CREATE_NEW_COST_UNIT,
        ADD_PRODUCT_TO_COST_UNIT,
        FETCH_ALL_PRODUCTS_IN_COST_UNIT,
        FETCH_REMAINING_PRODUCT_PERCENTAGE,
        UPDATE_PRODUCT_LIST,
        FETCH_ALL_PRODUCT_FAMILIES,
        FETCH_PRODUCTS_IN_PROD_FAMILY,
        UPDATE_REQUEST_MESSAGE,
        FETCH_USERS,
        FETCH_NOTIFICATIONS,
      } = VUEX_ACTIONS;
      const router = useRouter();
      const hasPermission = ref(false);
      const store = useStore();
      const disableCreateBtn = ref(false);
      const disableAllocateBtn = ref(true);
      const showProducts = ref(false);
      const inputFieldsDisabled = ref(false);
      const showProductAllocation = ref(false);
      const isExceedingPercentage = ref(false);
      const isProductDropdownDisabled = ref(true);
      const selectedDate = ref("");
      const nameText = ref("");
      const selectedManager = ref("");
      const selectedProductFamily = ref("");
      const selectedProduct = ref("");
      const encodedName = ref("");
      const budgetPercentage = ref(0);
      const remainingBudgetPercentage = ref(0);
      const newCostUnitId = ref(0);
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const getCostUnits = computed(() => store.getters.getCostUnits);
      const users = computed(() => store.getters.getUsers);
      const productFamily = computed(() => store.getters.getAllProductFamilies);
      const products = computed(() => store.getters.getProducts);
      const localCostUnits = ref(getCostUnits.value);
      const fetchAddedProduct = computed(() => store.getters.getProductAdded);
      const filteredCUProducts = computed(() => store.getters.getNewCostUnitProducts);
      const fetchProductRemainingPercentage = computed(() => store.getters.getProductPercentageRemaining);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      store.dispatch(FETCH_ALL_PRODUCTS_IN_COST_UNIT);
      store.dispatch(FETCH_USERS);
      store.dispatch(FETCH_ALL_PRODUCT_FAMILIES);

      if (userRoles.value.includes("edit-cost-unit")) {
        hasPermission.value = true;
      }

      const isValidDate = (dateStr: string): boolean => {
        if (!dateStr) return false;
        const dateObj = new Date(dateStr);
        return !isNaN(dateObj.getTime());
      };

      const isDateValid = computed(() => {
        return isValidDate(selectedDate.value);
      });

      const checkInputs = (): void => {
        disableCreateBtn.value = !(nameText.value.trim() !== "" && isDateValid.value && selectedManager.value.trim() !== "");
        if (nameText.value.includes("%") || nameText.value.includes("#")) {
          nameText.value = nameText.value.replace("%", "");
          nameText.value = nameText.value.replace("#", "");
        }
      };
      const handleCostUnitCreation = async (costUnitName: string) => {
        if (!checkIfCostUnitExists(costUnitName)) {
          await store.dispatch(CREATE_NEW_COST_UNIT, {
            name: nameText.value,
            manager: Number(selectedManager.value),
            status: "Proposed",
            statusdate: selectedDate.value,
          });

          localCostUnits.value.push({
            id: newCostUnitId.value,
            name: nameText.value,
          });
          newCostUnitId.value = store.getters.getNewCostUnit;

          if (newCostUnitId.value != 0) {
            showProductAllocation.value = !showProductAllocation.value;
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Cost Unit Created Sucessfully!");
            disableCreateBtn.value = !disableCreateBtn.value;
            inputFieldsDisabled.value = !inputFieldsDisabled.value;
          }
        } else {
          store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Cost Unit With This Name Already exists!");
        }
      };

      const handleProductAllocation = async (): Promise<void> => {
        encodedName.value = nameText.value.replace(/&/g, "%26");

        if (selectedProduct.value != "" && budgetPercentage.value >= 1) {
          await store.dispatch(ADD_PRODUCT_TO_COST_UNIT, {
            product: Number(selectedProduct.value),
            costunit: Number(newCostUnitId.value),
            percentage: budgetPercentage.value,
          });

          if (fetchAddedProduct.value != 0) {
            await store.dispatch(UPDATE_PRODUCT_LIST, fetchAddedProduct.value);

            selectedProduct.value = "";
            selectedProductFamily.value = "";
            budgetPercentage.value = 0;
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Product Added Sucessfully!");
            remainingBudgetPercentage.value = 0;

            await store.dispatch("fetchNewCostUnitProducts", `?name=${encodedName.value}`);

            showProducts.value = true;
          }
        }
      };

      const checkIfCostUnitExists = (name: string): boolean => {
        for (const costUnit of localCostUnits.value) {
          if (costUnit.name.toUpperCase() == name.toUpperCase().trim()) {
            return true;
          }
        }
        return false;
      };

      const preventDotInInput = () => {
        budgetPercentage.value = parseInt(budgetPercentage.value.toString().split(".").join(""));
      };

      const handleNew = () => {
        store.dispatch(FETCH_ALL_PRODUCTS_IN_COST_UNIT);
        nameText.value = "";
        selectedManager.value = "";
        selectedDate.value = "";
        disableCreateBtn.value = !disableCreateBtn.value;
        inputFieldsDisabled.value = !inputFieldsDisabled.value;
        showProductAllocation.value = false;
        showProducts.value = false;
        budgetPercentage.value = 0;
      };

      const fetchProductsFromPF = async () => {
        await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedProductFamily.value.toString());
        isProductDropdownDisabled.value = false;
        selectedProduct.value = "";
      };

      const fetchRemainingPercentage = async (product: number) => {
        const payload = {
          productID: product,
          costUnitID: Number(newCostUnitId.value),
        };
        await store.dispatch(FETCH_REMAINING_PRODUCT_PERCENTAGE, payload);
      };

      checkInputs();

      watch([nameText, selectedDate, selectedManager], () => {
        checkInputs();
      });

      watch([selectedProduct], () => {
        budgetPercentage.value = 0;
      });

      watch([selectedProduct, budgetPercentage], () => {
        remainingBudgetPercentage.value = fetchProductRemainingPercentage.value;
        if (selectedProduct.value === "" || budgetPercentage.value === 0 || budgetPercentage.value > remainingBudgetPercentage.value) {
          disableAllocateBtn.value = true;
          isExceedingPercentage.value = false;
          if (budgetPercentage.value > 0) {
            isExceedingPercentage.value = true;
          }
        } else {
          disableAllocateBtn.value = false;
          isExceedingPercentage.value = false;
        }

        if (budgetPercentage.value.toString().length > 3) {
          budgetPercentage.value = parseInt(budgetPercentage.value.toString().slice(0, 3));
        }
        if (budgetPercentage.value > 100) {
          budgetPercentage.value = 100;
        }
        if (isNaN(budgetPercentage.value)) {
          budgetPercentage.value = 0;
        }
      });

      watch([fetchProductRemainingPercentage], () => {
        remainingBudgetPercentage.value = fetchProductRemainingPercentage.value;
      });

      watch([selectedProductFamily], () => {
        if (selectedProductFamily.value != "") {
          isProductDropdownDisabled.value = false;
        } else {
          isProductDropdownDisabled.value = true;
        }
      });

      watch([userRoles], () => {
        if (userRoles.value.length > 0) {
          if (userRoles.value.includes("edit-cost-unit")) {
            hasPermission.value = true;
          } else {
            router.push("/");
          }
        }
      });
      return {
        nameText,
        selectedManager,
        users,
        products,
        selectedDate,
        disableCreateBtn,
        disableAllocateBtn,
        fetchTodaysDate,
        showProductAllocation,
        handleCostUnitCreation,
        inputFieldsDisabled,
        selectedProduct,
        budgetPercentage,
        checkIfCostUnitExists,
        handleNew,
        handleProductAllocation,
        preventDotInInput,
        showProducts,
        filteredCUProducts,
        fetchRemainingPercentage,
        remainingBudgetPercentage,
        isExceedingPercentage,
        productFamily,
        selectedProductFamily,
        fetchProductsFromPF,
        isProductDropdownDisabled,
        hasPermission,
      };
    },
  });
</script>
