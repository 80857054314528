import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-requestmessage__wrapper",
  "data-testid": "requestmessage-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisibleRequestMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["c-requestmessage__container", [_ctx.color]]),
          "data-testid": "requestmessage"
        }, _toDisplayString(_ctx.requestMessage), 3)
      ]))
    : _createCommentVNode("", true)
}