import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";
import { IProduct } from "@/utils/interfaces/IProductTypes";

interface ProductFiltersState {
  productFilters: IProduct[];
  productsAreFetched: boolean;
}

interface ProductFiltersContext {
  commit: Commit;
  state: ProductFiltersState;
}

const state: ProductFiltersState = {
  productFilters: [],
  productsAreFetched: false,
};

const mutations = {
  setProductFilters(state: ProductFiltersState, payload: IProduct[]): void {
    state.productFilters = payload;
  },
  setAreProductsFetched(state: ProductFiltersState, areFetched: boolean): void {
    state.productsAreFetched = areFetched;
  },
};

const getters = {
  getProductFilters: (state: ProductFiltersState) => state.productFilters,
  getAreProductsFetched: (state: ProductFiltersState) => state.productsAreFetched,
};

const actions = {
  fetchProductFilters(context: ProductFiltersContext, searchString?: string) {
    AXIOS_REQUEST("GET", `/products${searchString}`)
      .then((response) => {
        const productFiltersDO: IProduct[] = [];
        for (const id in response.data) {
          productFiltersDO.push({
            id: id,
            ...response.data[id],
          });
        }
        productFiltersDO.sort((a, b) => a.name.localeCompare(b.name));
        context.commit("setProductFilters", productFiltersDO);
        context.commit("setAreProductsFetched", true);
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404") {
          context.commit("setProductFamily", []);
          context.commit("setProductFilters", []);
        } else {
          console.error(error);
        }
        context.commit("setAreProductsFetched", true);
      });
  },

  clearFilteredProducts(context: ProductFiltersContext) {
    const productsDO: IProduct[] = [];
    context.commit("setProductFilters", productsDO);
  },
};

const productFilteringModule = {
  state,
  getters,
  mutations,
  actions,
};

export default productFilteringModule;
