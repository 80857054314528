import { Commit } from "vuex";

interface RequestMessageState {
  requestMessage: string;
  isVisibleRequestMessage: boolean;
}

interface RequestMessageContext {
  commit: Commit;
  state: RequestMessageState;
}

const state: RequestMessageState = {
  requestMessage: "",
  isVisibleRequestMessage: false,
};

const mutations = {
  setRequestMessage(state: RequestMessageState, message: string) {
    state.requestMessage = message;
  },
  setIsVisibleRequestMessage(state: RequestMessageState, isVisible: boolean) {
    state.isVisibleRequestMessage = isVisible;
  },
};

const getters = {
  getRequestMessage: (state: RequestMessageState) => {
    return state.requestMessage;
  },
  getIsVisibleRequestMessage: (state: RequestMessageState) => {
    return state.isVisibleRequestMessage;
  },
};

const actions = {
  updateRequestMessage(context: RequestMessageContext, message: string) {
    context.commit("setRequestMessage", message);
    context.commit("setIsVisibleRequestMessage", true);
    setTimeout(() => {
      context.commit("setIsVisibleRequestMessage", false);
    }, 3000);
  },
};

const requestMessageModule = {
  state,
  getters,
  mutations,
  actions,
};

export default requestMessageModule;
