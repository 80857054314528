import { Commit } from "vuex";
import axios from "axios";
import { CST_BACKEND_URL } from "@/store/config";

const state = {
  currentUser: "",
  currentUserRoles: null,
  currentUserGroups: [],
  token: "",
  refreshToken: "",
};

interface CurrentUserState {
  currentUser: string | null;
  currentUserRoles: string[] | null;
  currentUserGroups: string[];
  token: string;
  refreshToken: string;
}

interface CurrentUserContext {
  commit: Commit;
  state: CurrentUserState;
}

const mutations = {
  setCurrentUser(state: CurrentUserState, user: string | null) {
    state.currentUser = user;
  },
  setCurrentUserRoles(state: CurrentUserState, userRoles: string[]) {
    state.currentUserRoles = userRoles;
  },
  setCurrentUserGroups(state: CurrentUserState, userGroups: string[]) {
    state.currentUserGroups = userGroups;
  },
  setToken(state: CurrentUserState, token: string) {
    state.token = token;
  },
  setRefreshToken(state: CurrentUserState, refreshToken: string) {
    state.refreshToken = refreshToken;
  },
};

const getters = {
  getCurrentUser: (state: CurrentUserState) => {
    return state.currentUser;
  },
  getCurrentUserRoles: (state: CurrentUserState) => {
    return state.currentUserRoles;
  },
  getCurrentUserGroups: (state: CurrentUserState) => {
    return state.currentUserGroups;
  },
  getToken: (state: CurrentUserState) => {
    return state.token;
  },
  getRefreshToken: (state: CurrentUserState) => {
    return state.refreshToken;
  },
};

const actions = {
  updateCurrentUser(context: CurrentUserContext, user: string | null) {
    context.commit("setCurrentUser", user);
  },
  updateCurrentUserRoles(context: CurrentUserContext, userRoles: string[]) {
    context.commit("setCurrentUserRoles", userRoles);
  },
  updateCurrentUserGroups(context: CurrentUserContext, userGroups: string[]) {
    context.commit("setCurrentUserGroups", userGroups);
  },
  updateToken(context: CurrentUserContext, token: string) {
    context.commit("setToken", token);
  },
  updateRefreshToken(context: CurrentUserContext, refreshToken: string) {
    context.commit("setRefreshToken", refreshToken);
  },
  downloadLogs(context: CurrentUserContext) {
    axios
      .get(`${CST_BACKEND_URL}/activitylog`, {
        responseType: "text",
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${context.state.token}`,
        },
      })
      .then((response) => {
        const textData = response.data;
        const blob = new Blob([textData], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "activityLog.txt";
        link.click();
      });
  },
};

const currentUserModule = {
  state,
  getters,
  mutations,
  actions,
};

export default currentUserModule;
