<template>
  <div class="c-productdetails__modal-overlay" v-if="showProductDetails">
    <div class="c-productdetails__modal-container" data-testid="product-details-container">
      <div v-if="isLoading" class="cst-spinner c-productdetails__spinner"></div>
      <div v-else>
        <div class="c-productdetails__modal-header">
          <h2 class="c-productdetails__modal-title">
            {{ product.name }}
          </h2>
          <div class="c-productdetails__modal-header-closebtn">
            <img src="../../../public/remove.svg" alt="Close modal icon" @click="closeModal" />
          </div>
        </div>
        <div class="c-productdetails__notes">
          <div class="c-productdetails__note">*Amounts are shown in {{ currency }}</div>
          <div class="c-productdetails__notes-wrapper">
            <span class="c-productdetails__label-green"></span>
            <div class="c-productdetails__note">Capital</div>
          </div>
          <div class="c-productdetails__notes-wrapper">
            <span class="c-productdetails__label-blue"></span>
            <div class="c-productdetails__note">Expenses</div>
          </div>
          <div class="c-productdetails__notes-wrapper" v-if="undefinedEnabled">
            <span class="c-productdetails__label-gray"></span>
            <div class="c-productdetails__note">Undefined</div>
          </div>
        </div>
        <div>
          <div class="c-productdetails__modal-content">
            <div>
              <ProductLastYearChart :product="product"></ProductLastYearChart>
            </div>
            <div>
              <ProductChart :productApplications="productApplications" :productProjects="productProjects" :product="product"></ProductChart>
            </div>
          </div>
          <div class="c-productdetails__modal-content-chart">
            <ProductNextYearChart :product="product"></ProductNextYearChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, watch, ref } from "vue";
  import ProductChart from "../charts/C_ProductChart.vue";
  import ProductLastYearChart from "../charts/C_ProductLastYearChart.vue";
  import ProductNextYearChart from "../charts/C_ProductNextYearChart.vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "productdetails",
    props: {
      selectedProductId: {
        type: String,
        required: true,
      },
    },
    components: {
      ProductChart,
      ProductLastYearChart,
      ProductNextYearChart,
    },
    setup(props) {
      const { FETCH_APP_IN_PRODUCT, CHANGE_PRODUCT_DETAILS_VIEW, FETCH_SINGLE_PRODUCT, FETCH_PROJECTS_IN_PRODUCT } = VUEX_ACTIONS;
      const store = useStore();
      const product = ref();
      const productApplications = ref([]);
      const productProjects = ref([]);
      const isLoading = ref(true);
      const currency = computed(() => store.getters.getSelectedCurrency);

      const showProductDetails = computed(() => store.getters.getShowProductDetails);

      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const undefinedEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.undefined_enabled;
        } else {
          return false;
        }
      });

      const closeModal = () => {
        store.dispatch(CHANGE_PRODUCT_DETAILS_VIEW, false);
      };

      watch(
        () => props.selectedProductId,
        async (newProductId) => {
          if (newProductId) {
            isLoading.value = true;

            await Promise.all([store.dispatch(FETCH_SINGLE_PRODUCT, newProductId), store.dispatch(FETCH_APP_IN_PRODUCT, newProductId), store.dispatch(FETCH_PROJECTS_IN_PRODUCT, newProductId)]);

            setTimeout(() => {
              product.value = store.getters.getProduct;
              productApplications.value = store.getters.getProductApplications;
              productProjects.value = store.getters.getProductProjects;
              isLoading.value = false;
            }, 500);
          }
        }
      );

      return {
        showProductDetails,
        closeModal,
        product,
        productApplications,
        productProjects,
        isLoading,
        currency,
        undefinedEnabled,
      };
    },
  });
</script>
