import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-pagination__wrapper",
  "data-testid": "pages-indicator"
}

import { ref } from "vue";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_Pagination',
  props: {
    totalPages: {},
    itemList: {}
  },
  emits: ["previousPage", "nextPage", "paginateList"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  const emit = __emit;
  const currentPage = ref(1);

  const nextPage = () => {
    if (currentPage.value * 10 < props.itemList.length) {
      currentPage.value = currentPage.value + 1;
      paginateList(currentPage.value);
    }
  };

  const previousPage = () => {
    if (currentPage.value != 1) {
      currentPage.value = currentPage.value - 1;
      paginateList(currentPage.value);
    }
  };

  const paginateList = (page: number) => {
    let startFrom = 0;
    let endAt = 10;
    endAt = endAt * page;
    startFrom = endAt - 10;
    const slicedList = props.itemList.slice(startFrom, endAt);
    emit("paginateList", slicedList);
  };

return (_ctx: any,_cache: any) => {
  return (_ctx.totalPages > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "c-pagination__button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (previousPage())),
          "data-testid": "previous-page"
        }, "<"),
        _createTextVNode(" " + _toDisplayString(currentPage.value) + " / " + _toDisplayString(_ctx.totalPages) + " ", 1),
        _createElementVNode("button", {
          class: "c-pagination__button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (nextPage())),
          "data-testid": "next-page"
        }, ">")
      ]))
    : _createCommentVNode("", true)
}
}

})