<template>
  <div class="c-newproduct__wrapper" data-testid="newprod-wrapper">
    <img class="c-newproduct__image" :src="product.image === '' ? 'https://placehold.co/240x140' : base64image" alt="Product" data-testid="newprod-image" />
    <p class="c-newproduct__name">{{ product.name }}</p>
    <BudgetSummary size="small" :budget="totalBudget" :forecast="product.forecast" :actuals="product.actuals"></BudgetSummary>
    <div class="c-newproduct__buttons">
      <button type="button" class="cst-button cst-button-primary" @click="handleAddProject" data-testid="newprod-addprojectbtn">Add Project</button>
      <button type="button" class="cst-button cst-button-primary" @click="handleAddApplication" data-testid="newprod-addapplicationbtn">Add Application</button>
    </div>
    <ProjectApplicationForm
      :type="'project'"
      :isModal="true"
      :openModal="projectModalState"
      :preselectProduct="true"
      :closeModal="closeProjectModal"
      @emitCostTypeInputs="handleEmitCostValues"
      @emitExistingBudget="handleExistingBudget"
      v-if="projectModalState"
      data-testid="newprod-projectapplicationform"
    />
    <ProjectApplicationForm :type="'application'" :isModal="true" :openModal="appModalState" :preselectProduct="true" :closeModal="closeAppModal" @emitCostTypeInputs="handleEmitCostValues" @emitExistingBudget="handleExistingBudget" v-if="appModalState" />
  </div>
</template>

<script lang="ts">
  import BudgetSummary from "../C_BudgetSummary.vue";
  import { useStore } from "vuex";
  import { defineComponent, ref } from "vue";
  import ProjectApplicationForm from "../forms/C_ProjectApplicationForm.vue";
  import { ICostTypeInputs } from "@/utils/interfaces/ICostTypeInputs";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "NewProduct",
    components: {
      BudgetSummary,
      ProjectApplicationForm,
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      base64image: String,
    },
    setup() {
      const store = useStore();
      const { FETCH_APP, FETCH_PROJECTS, FETCH_PURCHASE_ORDERS } = VUEX_ACTIONS;
      const appModalState = ref(false);
      const projectModalState = ref(false);

      const closeAppModal = () => {
        appModalState.value = false;
      };

      const closeProjectModal = () => {
        projectModalState.value = false;
      };

      const totalBudget = ref(0);

      const costTypeInputs = ref<ICostTypeInputs>({
        bci: null,
        bcm: null,
        bce: null,
        bei: null,
        bem: null,
        bee: null,
        undef: null,
      });

      const handleAddProject = () => {
        projectModalState.value = true;
        store.dispatch(FETCH_PURCHASE_ORDERS);
        store.dispatch(FETCH_PROJECTS);
      };

      const handleAddApplication = () => {
        appModalState.value = true;
        store.dispatch(FETCH_PURCHASE_ORDERS);
        store.dispatch(FETCH_APP);
      };

      const handleEmitCostValues = (values: ICostTypeInputs) => {
        costTypeInputs.value = values;
        calculateTotalBudget();
      };

      const handleExistingBudget = (values: ICostTypeInputs) => {
        totalBudget.value = totalBudget.value + Number(values);
      };

      const calculateTotalBudget = () => {
        for (const item in costTypeInputs.value) {
          const key = item as keyof typeof costTypeInputs.value;
          const value = costTypeInputs.value[key];
          if (value !== null && value !== undefined) {
            totalBudget.value = totalBudget.value + Number(value);
          }
        }
      };

      return {
        handleAddProject,
        handleAddApplication,
        projectModalState,
        appModalState,
        closeProjectModal,
        closeAppModal,
        handleEmitCostValues,
        handleExistingBudget,
        totalBudget,
      };
    },
  });
</script>
