<template>
  <div class="c-pagination__wrapper" v-if="totalPages > 1" data-testid="pages-indicator">
    <button class="c-pagination__button" @click="previousPage()" data-testid="previous-page">&lt;</button>
    {{ currentPage }} / {{ totalPages }}
    <button class="c-pagination__button" @click="nextPage()" data-testid="next-page">&gt;</button>
  </div>
</template>
<script lang="ts" setup>
  import { defineEmits, defineProps, ref } from "vue";
  const props = defineProps<{
    totalPages: number;
    itemList: Array<any>;
  }>();
  const emit = defineEmits(["previousPage", "nextPage", "paginateList"]);
  const currentPage = ref(1);

  const nextPage = () => {
    if (currentPage.value * 10 < props.itemList.length) {
      currentPage.value = currentPage.value + 1;
      paginateList(currentPage.value);
    }
  };

  const previousPage = () => {
    if (currentPage.value != 1) {
      currentPage.value = currentPage.value - 1;
      paginateList(currentPage.value);
    }
  };

  const paginateList = (page: number) => {
    let startFrom = 0;
    let endAt = 10;
    endAt = endAt * page;
    startFrom = endAt - 10;
    const slicedList = props.itemList.slice(startFrom, endAt);
    emit("paginateList", slicedList);
  };
</script>
