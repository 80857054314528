<template>
  <RequestMessage></RequestMessage>
  <div class="v-polist__main-container">
    <h1 class="v-polist__title-all" data-testid="v-polist__heading">List of all Purchase Orders</h1>
    <div v-if="!areOrdersFetched" class="cst-spinner" data-testid="v-listpo-spinner"></div>
    <div v-else-if="orders.length > 0" class="v-polist__table-container" data-testid="v-polist__table">
      <C_Search :placeholder="'Search by description...'" @input="filterPO($event)" />
      <div class="v-polist__table-wrapper" v-if="filteredOrders.length > 0">
        <table class="v-polist__table v-polist__list-table" aria-describedby="Table of purchase orders">
          <thead>
            <tr class="v-polist__table-head-row">
              <th data-testid="vlistpo-ordernumber-sort-ordernum" @click="sortByColumn('ordernumber', filteredOrders)">Order Number</th>
              <th data-testid="vlistpo-ordernumber-sort-status" @click="sortByColumn('status', filteredOrders)">Status</th>
              <th data-testid="vlistpo-ordernumber-sort-prownername" @click="sortByColumn('prownername', filteredOrders)">Owner</th>
              <th data-testid="vlistpo-ordernumber-sort-customercontact" @click="sortByColumn('customercontact', filteredOrders)">Customer Contact</th>
              <th data-testid="vlistpo-ordernumber-sort-sbuname" v-if="sbuEnabled" @click="sortByColumn('sbuname', filteredOrders)">Buying Unit</th>
              <th data-testid="vlistpo-ordernumber-sort-ordertext" @click="sortByColumn('ordertext', filteredOrders)">Description</th>
              <th data-testid="vlistpo-ordernumber-sort-suppliername" v-if="supplierEnabled" @click="sortByColumn('suppliername', filteredOrders)">Provider</th>
              <th data-testid="vlistpo-ordernumber-sort-orderamount" @click="sortByColumn('orderamount', filteredOrders)">PO Amount</th>
              <th data-testid="vlistpo-ordernumber-sort-amountCurrentYear" @click="sortByColumn('orderamount', filteredOrders)">Current Year</th>
              <th data-testid="vlistpo-ordernumber-sort-amountNextYear" @click="sortByColumn('orderamount', filteredOrders)">Next Year</th>
              <th data-testid="vlistpo-ordernumber-sort-currency" @click="sortByColumn('currency', filteredOrders)">Currency</th>
              <th data-testid="vlistpo-ordernumber-sort-podate" @click="sortByColumn('podate', filteredOrders)">Start Date</th>
              <th data-testid="vlistpo-ordernumber-sort-deliverydate" @click="sortByColumn('deliverydate', filteredOrders)" class="v-polist__table-head-row--deliverydate">Delivery Date (End Date)</th>
              <th v-if="accountingElementEnabled" @click="sortByColumn('accountingelement', filteredOrders)" data-testid="vlistpo-ordernumber-sort-accountingelement">Accounting Element</th>
              <th>Details</th>
              <th v-if="canCreateInvoice && createInvoiceEnabled">Create Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredOrders" :key="item.id" class="v-polist__table-row" data-testid="vlistpo-tablerow">
              <td data-testid="vpolist-prordernumber">
                {{ item.ordernumber }}
              </td>
              <td data-testid="vpolist-prstatus">{{ item.status }}</td>
              <td data-testid="vpolist-prownername">{{ item.prownername }}</td>
              <td data-testid="vpolist-customercontact" class="v-polist__table-row--contact">
                {{ item.customercontact }}
              </td>
              <td data-testid="vpolist-sbuname" v-if="sbuEnabled">
                {{ item.sbuname }}
              </td>
              <td data-testid="vpolist-ordertext" class="v-polist__table-row--ordertext">
                {{ item.ordertext }}
              </td>
              <td data-testid="vpolist-suppliername" v-if="supplierEnabled">
                {{ item.suppliername }}
              </td>
              <td data-testid="vpolist-poamount">
                {{ formatNumber((item.orderamount * currencyRate).toFixed(2)) }}
              </td>
              <td data-testid="vpolist-currentYear">
                {{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate).toFixed(2)) }}
              </td>
              <td data-testid="vpolist-nextYear">
                {{ formatNumber(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate).toFixed(2)) }}
              </td>
              <td data-testid="vpolist-pocurrency">{{ currency }}</td>
              <td data-testid="vpolist-podate" class="v-polist__table-row--startdate">
                {{ formatDate(item.podate) }}
              </td>
              <td data-testid="vpolist-deliverydate" class="v-polist__table-row--deliverydate">
                {{ item.deliverydate ? formatDate(item.deliverydate) : "" }}
              </td>
              <td data-testid="vpolist-accountingelement" v-if="accountingElementEnabled">
                {{ item.accountingelement }}
              </td>
              <td>
                <button title="Show" class="c-requestbtns c-requestbtns-secondary-show" data-testid="vpolist-showpobtn" @click="showPoDetails(item)">
                  <p>Show</p>
                  <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                    <path
                      d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                    />
                  </svg>
                </button>
              </td>
              <td v-if="canCreateInvoice && createInvoiceEnabled">
                <button title="Add Invoice" class="c-requestbtns c-requestbtns-secondary-add-invoice" data-testid="v-polist_invoicebtn" @click="createInvoice(item)">
                  Invoice
                  <svg width="14px" height="14px" viewBox="0 0 33 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                      <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-464.000000, -1087.000000)" fill="#000000">
                        <path
                          d="M480,1117 C472.268,1117 466,1110.73 466,1103 C466,1095.27 472.268,1089 480,1089 C487.732,1089 494,1095.27 494,1103 C494,1110.73 487.732,1117 480,1117 L480,1117 Z M480,1087 C471.163,1087 464,1094.16 464,1103 C464,1111.84 471.163,1119 480,1119 C488.837,1119 496,1111.84 496,1103 C496,1094.16 488.837,1087 480,1087 L480,1087 Z M486,1102 L481,1102 L481,1097 C481,1096.45 480.553,1096 480,1096 C479.447,1096 479,1096.45 479,1097 L479,1102 L474,1102 C473.447,1102 473,1102.45 473,1103 C473,1103.55 473.447,1104 474,1104 L479,1104 L479,1109 C479,1109.55 479.447,1110 480,1110 C480.553,1110 481,1109.55 481,1109 L481,1104 L486,1104 C486.553,1104 487,1103.55 487,1103 C487,1102.45 486.553,1102 486,1102 L486,1102 Z"
                          id="plus-circle"
                          sketch:type="MSShapeGroup"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="v-polist--empty">No orders available.</p>
      </div>
      <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="orders" @paginateList="paginateList" data-testid="pages-indicator" />
    </div>
    <div v-else>
      <h3 v-if="isOrderListEmpty" class="v-polist__no-orders-text" data-testid="v-polist-no-orders">No Purchase Orders to show!</h3>
    </div>
    <ListPODetails :selectedPOName="selectedPOName" :selectedItem="selectedItem" :supplierNameEnabled="supplierEnabled" v-if="selectedItem.id" />
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch } from "vue";

  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { sortByColumn } from "@/utils/helpers/sortByColumn";
  import ListPODetails from "@/components/modals/C_ListPODetails.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUserOrders } from "@/utils/interfaces/IPurchaseOrder";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";
  import C_Search from "@/components/C_Search.vue";
  import C_Pagination from "@/components/C_Pagination.vue";

  const { CHANGE_PO_DETAILS_VIEW, FETCH_PURCHASE_ORDERS, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const selectedPOName = ref("");
  const selectedItem = ref({} as IUserOrders);
  const orders = computed<IUserOrders[]>(() => store.getters.getPurchaseOrders);
  const filteredOrders = ref(orders.value.slice(0, 10));
  const paginatedList = ref(orders.value.slice(0, 10));

  const totalPages = computed(() => {
    if (orders.value.length > 10 && orders.value.length % 10 != 0) {
      return Math.floor(orders.value.length / 10) + 1;
    } else {
      return orders.value.length / 10;
    }
  });

  const areOrdersFetched = computed(() => store.getters.getAreOrdersFetched);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

  const canCreateInvoice = computed(() => {
    return userRoles.value?.includes("edit-invoice") ?? false;
  });

  const isOrderListEmpty = computed(() => {
    if (orders.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const accountingElementEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_accounting_element;
    } else {
      return false;
    }
  });

  const createInvoiceEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.create_invoice_enabled;
    } else {
      return false;
    }
  });

  const fetchPurchaseOrders = () => {
    store.dispatch(FETCH_PURCHASE_ORDERS);
  };

  fetchPurchaseOrders();

  const showPoDetails = (item: IUserOrders) => {
    store.dispatch(CHANGE_PO_DETAILS_VIEW, true);
    selectedPOName.value = item.ordertext;
    selectedItem.value = item;
  };

  const createInvoice = async (item: IUserOrders) => {
    store.commit("setInvoiceFromOrder", item);
    setTimeout(() => {
      router.push("/create-invoice");
    }, 500);
  };

  const filterPO = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredOrders.value = orders.value.filter((order: IUserOrders) => {
      if (order.ordertext) return order.ordertext.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
    if (searchTerm === "") {
      filteredOrders.value = paginatedList.value;
    }
  };

  watch([orders], () => {
    paginatedList.value = orders.value?.slice(0, 10);
    filteredOrders.value = orders.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IUserOrders>) => {
    filteredOrders.value = filteredList;
    paginatedList.value = filteredList;
  };
</script>
