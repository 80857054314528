<template>
  <RequestMessage></RequestMessage>
  <div class="v-archive__main-container">
    <h1 class="v-archive__requests-title">Archived Requests</h1>
    <div v-if="!isArchiveFetched" class="cst-spinner" data-testid="v-archive-spinner"></div>
    <div v-else>
      <div v-if="isArchiveListEmpty">
        <div class="v-archive__empty" data-testid="v-archive-empty">Archive is empty.</div>
      </div>
      <div v-else>
        <div>
          <table class="v-polist__table v-polist__list-table" aria-describedby="Table of purchase orders">
            <thead>
              <tr class="v-polist__table-head-row">
                <th>Description</th>
                <th>PR Owner</th>
                <th v-if="sbuEnabled">SBU</th>
                <th v-if="supplierEnabled">Supplier</th>
                <th>Order Amount</th>
                <th>Current Year</th>
                <th>Next Year</th>
                <th>Start Date</th>
                <th>Delivery Date</th>
                <th>Details</th>
                <th v-if="canEdit">Edit</th>
                <th>Restore</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredArchiveList" :key="item.id" class="v-polist__table-row">
                <td>
                  {{ item.ordertext.length > 38 ? item.ordertext.slice(0, 36) + "..." : item.ordertext }}
                </td>
                <td>
                  {{ item.prownername }}
                </td>
                <td v-if="sbuEnabled">
                  {{ item?.sbuname }}
                </td>
                <td v-if="supplierEnabled">
                  {{ item.suppliername }}
                </td>
                <td>
                  {{ formatNumber((Number(item.orderamount) * currencyRate).toFixed(2)) }}
                  {{ currency }}
                </td>
                <td>
                  {{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.orderamount * currencyRate).toFixed(2)) }}
                  {{ currency }}
                </td>
                <td>
                  {{ formatNumber(Number(item.amountNextYear ? item.amountNextYear : 0 * currencyRate).toFixed(2)) }}
                  {{ currency }}
                </td>
                <td>
                  {{ formatDate(item.podate) }}
                </td>
                <td class="vpolist">
                  {{ formatDate(item.deliverydate) }}
                </td>
                <td>
                  <button type="button" title="Details" class="c-requestbtns c-requestbtns-secondary-details c-requestbtns--no-margin" data-testid="v-archive-detailsbtn" @click="handleDetails(item)">
                    <p>Details</p>
                    <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                      <path
                        d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                      />
                    </svg>
                  </button>
                </td>
                <td v-if="canEdit">
                  <button type="button" class="c-requestbtns c-requestbtns-secondary-edit c-requestbtns--no-margin" @click="handleEdit(item)" alt="edit button icon" title="Edit Archived Request">
                    Edit
                    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" class="c-requestbtns-edit">
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />

                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4M9.35443 12.4346L15.9429 5.92003C16.4684 5.40046 17.3049 5.3718 17.8647 5.85418C18.4812 6.38542 18.5247 7.32554 17.96 7.91149L11.481 14.6335L9 15L9.35443 12.4346Z"
                          stroke="#000000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                </td>
                <td>
                  <button title="Restore to Open Requests" class="c-requestbtns c-requestbtns-secondary-edit c-requestbtns--no-margin" data-testid="v-archive-restorebtn" @click="handleRestore(item.id)">
                    <p>Restore</p>
                    <svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12 20.5001C16.6944 20.5001 20.5 16.6945 20.5 12.0001C20.5 9.17456 19.1213 6.67103 17 5.1255M13 22.4001L11 20.4001L13 18.4001M12 3.5001C7.30558 3.5001 3.5 7.30568 3.5 12.0001C3.5 14.8256 4.87867 17.3292 7 18.8747M11 5.6001L13 3.6001L11 1.6001"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </td>
                <td>
                  <button type="button" title="Delete Permanently" class="c-requestbtns cst-button-danger c-requestbtns--no-margin" data-testid="v-archive-deletebtn" @click="handleDelete(item.id)">
                    <p>Delete</p>
                    <img :src="'./trash.svg'" alt="delete button icon" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="archiveList" @paginateList="paginateList" data-testid="pages-indicator" />
        </div>
      </div>
    </div>
    <RequestDetails :selectedItemId="selectedItemId" :selectedPRListItems="selectedPRListItems" :showCTA="false"></RequestDetails>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import { formatDate } from "@/utils/helpers/formatDate";
  import RequestDetails from "../components/modals/C_RequestDetails.vue";
  import RequestMessage from "../components/C_RequestMessage.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import C_Pagination from "@/components/C_Pagination.vue";

  const archiveEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.purchase_request_archive_enabled;
    } else {
      return true;
    }
  });

  const { FETCH_ARCHIVE, DELETE_FROM_ARCHIVE, RESTORE_FROM_ARCHIVE, SHOW_PR_DETAILS_VIEW, FETCH_NOTIFICATIONS, FETCH_ALL_PRODUCTS } = VUEX_ACTIONS;
  const store = useStore();

  const archiveList = computed(() => store.getters.getArchive);
  const currentUserRoles = computed(() => store.getters.getCurrentUserRoles);
  const canEdit = computed(() => currentUserRoles.value?.includes("edit-purchase-request"));
  const isArchiveFetched = computed(() => store.getters.getIsArchiveFetched);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const selectedItemId = ref<number | undefined>(undefined);
  const selectedPRListItems = ref([]);
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_ALL_PRODUCTS);
  const filteredArchiveList = ref(archiveList.value.slice(0, 10));
  const router = useRouter();
  const totalPages = computed(() => {
    if (archiveList.value.length > 10 && archiveList.value.length % 10 != 0) {
      return Math.floor(archiveList.value.length / 10) + 1;
    } else {
      return archiveList.value.length / 10;
    }
  });
  const paginatedList = ref(archiveList.value?.slice(0, 10));

  const isArchiveListEmpty = computed(() => {
    if (archiveList.value.length === 0) {
      return true;
    } else {
      return false;
    }
  });

  const supplierEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.suppliers_enabled;
    } else {
      return false;
    }
  });

  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });

  const handleDetails = (item: IPurchaseRequest) => {
    store.dispatch(SHOW_PR_DETAILS_VIEW, true);
    selectedItemId.value = Number(item.id);
    selectedPRListItems.value = item.listitems;
  };

  const handleEdit = (item: IPurchaseRequest) => {
    const stringifiedData = JSON.stringify(item);
    localStorage.setItem("archiveItemEdit", stringifiedData);
    store.commit("setIsEditingPr", true);
    router.push("/edit-purchase-request");
  };

  const fetchArchive = async () => {
    await store.dispatch(FETCH_ARCHIVE);
  };

  fetchArchive();

  const handleRestore = (itemId: number) => {
    store.dispatch(RESTORE_FROM_ARCHIVE, itemId);
  };

  const handleDelete = (itemId: number) => {
    store.dispatch(DELETE_FROM_ARCHIVE, itemId);
  };

  if (!archiveEnabled.value) {
    router.push("/");
  }

  watch([archiveList], () => {
    paginatedList.value = archiveList.value?.slice(0, 10);
    filteredArchiveList.value = archiveList.value.slice(0, 10);
  });

  const paginateList = (filteredList: Array<IPurchaseRequest>) => {
    filteredArchiveList.value = filteredList;
    paginatedList.value = filteredList;
  };
</script>
