<template>
  <div class="c-costunitstatus__container">
    <div class="c-costunitstatus" :class="[color]" data-testid="costunit-status">
      {{ status }}<br />
      {{ formatDate(statusdate) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from "vue";
  import { formatDate } from "@/utils/helpers/formatDate";

  export default defineComponent({
    name: "CostUnitStatus",
    props: {
      status: {
        type: String,
        required: true,
      },
      statusdate: {
        type: String,
        required: true,
      },
    },
    computed: {
      color(): string {
        switch (this.status) {
          case "Active":
            return "c-costunitstatus__active";
          case "Retired":
            return "c-costunitstatus__retired";
          case "Proposed":
            return "c-costunitstatus__proposed";
          case "Global":
            return "c-costunitstatus__active";
          default:
            return "c-costunitstatus__retired";
        }
      },
    },
    setup() {
      return {
        formatDate,
      };
    },
  });
</script>
