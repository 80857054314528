import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cst-filter" }
const _hoisted_2 = { class: "cst-filter--header" }
const _hoisted_3 = { class: "cst-filter--select-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { for: 'filter-select-' + 1 }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value", "id"]
const _hoisted_8 = ["for"]
const _hoisted_9 = ["onUpdate:modelValue", "data-testid", "id"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isCostUnit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.filters[1].label) + " ", 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions[1]) = $event)),
                "data-testid": 'filter-select-' + 1,
                id: 'filter-select-' + 1
              }, [
                (_ctx.isProduct)
                  ? (_openBlock(), _createElementBlock("option", {
                      key: 0,
                      value: _ctx.APValue,
                      id: "All"
                    }, "All", 8, _hoisted_6))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters[1].options, (option, optionIndex) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: optionIndex,
                    value: option,
                    id: 'option-' + 1 + '-' + optionIndex
                  }, _toDisplayString(option.name), 9, _hoisted_7))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.selectedOptions[1]]
              ])
            ])
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filters, (filter, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "cst-filter--select-field",
              key: index
            }, [
              _createElementVNode("label", {
                for: 'filter-select-' + index
              }, [
                _createTextVNode(_toDisplayString(filter.label) + " ", 1),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((_ctx.selectedOptions[index]) = $event),
                  "data-testid": 'filter-select-' + index,
                  id: 'filter-select-' + index
                }, [
                  (_ctx.isProduct)
                    ? (_openBlock(), _createElementBlock("option", {
                        key: 0,
                        value: _ctx.APValue,
                        id: "All"
                      }, "All", 8, _hoisted_10))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.options, (option, optionIndex) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: optionIndex,
                      value: option,
                      id: 'option-' + index + '-' + optionIndex
                    }, _toDisplayString(option.name), 9, _hoisted_11))
                  }), 128))
                ], 8, _hoisted_9), [
                  [_vModelSelect, _ctx.selectedOptions[index]]
                ])
              ], 8, _hoisted_8)
            ]))
          }), 128)),
      _createElementVNode("button", {
        class: "cst-button cst-button-primary",
        "data-testid": "filter-submit",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submitFilters && _ctx.submitFilters(...args)))
      }, "Submit")
    ])
  ]))
}