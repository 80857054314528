import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-platformcard__wrapper" }
const _hoisted_2 = { class: "c-platformcard__name" }
const _hoisted_3 = { class: "c-platformcard__properties" }
const _hoisted_4 = { class: "c-platformcard__properties" }

import { PropType, computed, watch, ref } from "vue";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_PlatformCard',
  props: {
    unit: {
      type: Object as PropType<IPlatform>,
      required: true,
    },
  },
  emits: ["show-platform-details"],
  setup(__props, { emit: __emit }) {

  const props = __props;
  const store = useStore();
  const users = computed(() => store.getters.getUsers);
  const ownerUsername = ref("");

  function convertUsernameFromId() {
    if (users.value.length > 0) {
      users.value.filter((user: IUser) => {
        if (Number(user.id) === props.unit.owner) {
          ownerUsername.value = user.username;
        }
      });
    }
  }

  const handleDetailsClick = () => {
    emit("show-platform-details", props.unit);
  };

  convertUsernameFromId();

  watch([users], () => {
    convertUsernameFromId();
  });
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _createElementVNode("strong", null, _toDisplayString(props.unit.name), 1)
    ]),
    _createElementVNode("p", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Owner: ", -1)),
      _createTextVNode(_toDisplayString(ownerUsername.value), 1)
    ]),
    _createElementVNode("p", _hoisted_4, [
      _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Budget: ", -1)),
      _createTextVNode(_toDisplayString(props.unit.budget) + " " + _toDisplayString(selectedCurrency.value), 1)
    ]),
    _createElementVNode("div", { class: "c-platformcard__summary" }, [
      _createElementVNode("div", { class: "c-platformcard__btns" }, [
        _createElementVNode("button", {
          type: "button",
          class: "cst-button cst-button-primary",
          "data-testid": "c-platformcard-prdetails-btn",
          onClick: handleDetailsClick
        }, "Details")
      ])
    ])
  ]))
}
}

})