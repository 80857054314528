<template>
  <div>
    <div class="c-productchart__container" data-testid="product-chart">
      <Bar id="pf-currentyear-chart" :options="chartOptions" :data="chartData" />
      <div class="c-productchart__labels">
        <div>{{ financialGoalLabel }}</div>
        <div>Forecast</div>
        <div>Actuals</div>
        <div>PO's</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Bar } from "vue-chartjs";
  import { computed, defineComponent, ref } from "vue";
  import { IProjectApplication, IProduct } from "@/utils/interfaces/IProductTypes";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { useStore } from "vuex";
  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  export default defineComponent({
    name: "ProductChart",
    components: { Bar },
    props: {
      productApplications: {
        type: Array as () => IProjectApplication[],
        required: true,
      },
      productProjects: {
        type: Array as () => IProjectApplication[],
        required: true,
      },
      product: {
        type: Object as () => IProduct,
        required: true,
      },
    },
    setup(props) {
      const dataLoaded = ref(false);
      const chartData = ref<IChartData>({
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: [],
            stack: "",
          },
        ],
      });
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const financialGoalLabel = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.financial_goal_label;
        } else {
          return "Budget";
        }
      });

      let totalCapitalInfra = 0;
      let totalCapitalMaint = 0;
      let totalCapitalEnhanc = 0;
      let totalExpensesInfra = 0;
      let totalExpensesMaint = 0;
      let totalExpensesEnhanc = 0;

      let totalCapitalInfraForecast = 0;
      let totalCapitalMaintForecast = 0;
      let totalCapitalEnhancForecast = 0;
      let totalExpensesInfraForecast = 0;
      let totalExpensesMaintForecast = 0;
      let totalExpensesEnhancForecast = 0;

      let totalCapitalInfraActuals = 0;
      let totalCapitalMaintActuals = 0;
      let totalCapitalEnhancActuals = 0;
      let totalExpensesInfraActuals = 0;
      let totalExpensesMaintActuals = 0;
      let totalExpensesEnhancActuals = 0;
      let totalUndefined = 0;

      for (const application of props.productApplications) {
        totalCapitalInfra += Number(application.bci);
        totalCapitalMaint += Number(application.bcm);
        totalCapitalEnhanc += Number(application.bce);
        totalExpensesInfra += Number(application.bei);
        totalExpensesMaint += Number(application.bem);
        totalExpensesEnhanc += Number(application.bee);

        totalCapitalInfraForecast += Number(application.fci);
        totalCapitalMaintForecast += Number(application.fcm);
        totalCapitalEnhancForecast += Number(application.fce);
        totalExpensesInfraForecast += Number(application.fei);
        totalExpensesMaintForecast += Number(application.fem);
        totalExpensesEnhancForecast += Number(application.fee);

        totalCapitalInfraActuals += Number(application.aci);
        totalCapitalMaintActuals += Number(application.acm);
        totalCapitalEnhancActuals += Number(application.ace);
        totalExpensesInfraActuals += Number(application.aei);
        totalExpensesMaintActuals += Number(application.aem);
        totalExpensesEnhancActuals += Number(application.aee);

        totalUndefined += Number(application.undefined);
      }

      for (const project of props.productProjects) {
        totalCapitalInfra += Number(project.bci);
        totalCapitalMaint += Number(project.bcm);
        totalCapitalEnhanc += Number(project.bce);
        totalExpensesInfra += Number(project.bei);
        totalExpensesMaint += Number(project.bem);
        totalExpensesEnhanc += Number(project.bee);

        totalCapitalInfraForecast += Number(project.fci);
        totalCapitalMaintForecast += Number(project.fcm);
        totalCapitalEnhancForecast += Number(project.fce);
        totalExpensesInfraForecast += Number(project.fei);
        totalExpensesMaintForecast += Number(project.fem);
        totalExpensesEnhancForecast += Number(project.fee);

        totalCapitalInfraActuals += Number(project.aci);
        totalCapitalMaintActuals += Number(project.acm);
        totalCapitalEnhancActuals += Number(project.ace);
        totalExpensesInfraActuals += Number(project.aei);
        totalExpensesMaintActuals += Number(project.aem);
        totalExpensesEnhancActuals += Number(project.aee);

        totalUndefined += Number(project.undefined);
      }

      totalCapitalInfraForecast += Number(props.product.product_PR_fci);
      totalCapitalMaintForecast += Number(props.product.product_PR_fcm);
      totalCapitalEnhancForecast += Number(props.product.product_PR_fce);
      totalExpensesInfraForecast += Number(props.product.product_PR_fei);
      totalExpensesMaintForecast += Number(props.product.product_PR_fem);
      totalExpensesEnhancForecast += Number(props.product.product_PR_fee);

      totalCapitalInfraActuals += Number(props.product.prod_invoice_aci);
      totalCapitalMaintActuals += Number(props.product.prod_invoice_acm);
      totalCapitalEnhancActuals += Number(props.product.prod_invoice_ace);
      totalExpensesInfraActuals += Number(props.product.prod_invoice_aei);
      totalExpensesMaintActuals += Number(props.product.prod_invoice_aem);
      totalExpensesEnhancActuals += Number(props.product.prod_invoice_aee);

      chartData.value = {
        labels: [""],
        datasets: [
          {
            label: "Infrastructure",
            data: [totalCapitalInfra * currencyRate.value],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [totalCapitalMaint * currencyRate.value],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [totalCapitalEnhanc * currencyRate.value],
            backgroundColor: ["#00793a"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [totalExpensesInfra * currencyRate.value],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 0",
          },
          {
            label: "Maintenance",
            data: [totalExpensesMaint * currencyRate.value],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 0",
          },
          {
            label: "Enhancements",
            data: [totalExpensesEnhanc * currencyRate.value],
            backgroundColor: ["#004a96"],
            stack: "Stack 0",
          },
          {
            label: "Undefined",
            data: [totalUndefined * currencyRate.value],
            backgroundColor: ["lightgray"],
            stack: "Stack 0",
          },
          {
            label: "Infrastructure",
            data: [totalCapitalInfraForecast * currencyRate.value],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [totalCapitalMaintForecast * currencyRate.value],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [totalCapitalEnhancForecast * currencyRate.value],
            backgroundColor: ["#00793a"],
            stack: "Stack 1",
          },
          {
            label: "Infrastructure",
            data: [totalExpensesInfraForecast * currencyRate.value],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 1",
          },
          {
            label: "Maintenance",
            data: [totalExpensesMaintForecast * currencyRate.value],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 1",
          },
          {
            label: "Enhancements",
            data: [totalExpensesEnhancForecast * currencyRate.value],
            backgroundColor: ["#004a96"],
            stack: "Stack 1",
          },
          {
            label: "Infrastructure",
            data: [totalCapitalInfraActuals * currencyRate.value],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 2",
          },
          {
            label: "Maintenance",
            data: [totalCapitalMaintActuals * currencyRate.value],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 2",
          },
          {
            label: "Enhancements",
            data: [totalCapitalEnhancActuals * currencyRate.value],
            backgroundColor: ["#00793a"],
            stack: "Stack 2",
          },
          {
            label: "Infrastructure",
            data: [totalExpensesInfraActuals * currencyRate.value],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 2",
          },
          {
            label: "Maintenance",
            data: [totalExpensesMaintActuals * currencyRate.value],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 2",
          },
          {
            label: "Enhancements",
            data: [totalExpensesEnhancActuals * currencyRate.value],
            backgroundColor: ["#004a96"],
            stack: "Stack 2",
          },

          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalCI) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#84bd4b"],
            stack: "Stack 3",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalCM) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#65ac1e"],
            stack: "Stack 3",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalCE) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#00793a"],
            stack: "Stack 3",
          },
          {
            label: "Infrastructure",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalEI) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#90cfe8"],
            stack: "Stack 3",
          },
          {
            label: "Maintenance",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalEM) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#21a0d2"],
            stack: "Stack 3",
          },
          {
            label: "Enhancements",
            data: [parseFloat((Number(props.product.purchaseorderscurrentyear?.[0]?.totals.totalEE) * currencyRate.value).toFixed(2))],
            backgroundColor: ["#004a96"],
            stack: "Stack 3",
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Current Year",
            font: {
              family: "customFont",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
        },
        indexAxis: "x" as const,
      } as BarChartOptions;

      return {
        dataLoaded,
        chartData,
        chartOptions,
        currency,
        financialGoalLabel,
      };
    },
  });
</script>
