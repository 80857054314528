import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";

interface SBUState {
  SBU: string[];
}

const state: SBUState = {
  SBU: [],
};

interface SBUContext {
  commit: Commit;
  state: SBUState;
}

const mutations = {
  setSBU(state: SBUState, payload: string[]) {
    state.SBU = payload;
  },
};

const getters = {
  getSBU: (state: SBUState) => state.SBU,
};

const actions = {
  async fetchSBU(context: SBUContext) {
    await AXIOS_REQUEST("GET", "/sbu")
      .then((response) => {
        const SBUDO: SBUState[] = [];
        for (const id in response.data) {
          SBUDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setSBU", SBUDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const SBUModule = {
  state,
  getters,
  mutations,
  actions,
};

export default SBUModule;
