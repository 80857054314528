import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-justificationmodal__wrapper"
}
const _hoisted_2 = { class: "c-justificationmodal__container" }
const _hoisted_3 = { class: "c-justificationmodal__top-container" }
const _hoisted_4 = { class: "c-justificationmodal__title-div" }
const _hoisted_5 = { class: "c-justificationmodal__items-group" }
const _hoisted_6 = { class: "c-justificationmodal__item" }
const _hoisted_7 = { class: "c-justificationmodal__item" }
const _hoisted_8 = { class: "c-justificationmodal__item" }
const _hoisted_9 = { class: "c-justificationmodal__item" }
const _hoisted_10 = { class: "c-justificationmodal__cta" }
const _hoisted_11 = { class: "c-justificationmodal__cta-buttons" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["disabled"]

import { ref, watch, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatNumber } from "@/utils/helpers/formatNumber";

  interface RequestData {
    id: number;
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_JustificationModal',
  props: {
    itemId: Number,
    pramount: {
      type: Number,
      default: 0,
    },
  },
  setup(__props) {

  const props = __props;

  const { CHANGE_JUSTIFICATION_MODAL_VIEW, CHANGE_PURCHASE_REQUEST_STATUS, DECLINE_PURCHASE_REQUEST, FETCH_PURCHASE_REQUESTS } = VUEX_ACTIONS;
  const selectedItem = ref<RequestData | null>(null);

  const propsItemId = computed(() => props.itemId);
  const store = useStore();
  const selectedDate = ref("");
  const user = computed(() => store.getters.getCurrentUser);
  const requests = computed(() => store.getters.getPurchaseRequests);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const showJustificationModal = computed(() => store.getters.getShowJustificationModal);
  const justificationComment = ref("");

  onMounted(() => {
    if (showJustificationModal.value) {
      selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === props.itemId) || null;
    }
  });

  const confirmAction = () => {
    const payload = {
      prID: props.itemId,
      status: "Proposed",
      isJustification: true,
      justification_status: "Signed",
      justified_by_name: user.value,
      justification_comment: justificationComment.value,
      justification_date: selectedDate.value,
    };
    store.dispatch(CHANGE_PURCHASE_REQUEST_STATUS, payload);
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
    store.dispatch(FETCH_PURCHASE_REQUESTS);
    justificationComment.value = "";
    selectedDate.value = "";
  };

  const declineAction = () => {
    const payload = {
      itemId: props.itemId,
      message: justificationComment.value,
    };
    store.dispatch(DECLINE_PURCHASE_REQUEST, payload);
    justificationComment.value = "";
    selectedDate.value = "";
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
  };

  const closeModal = () => {
    store.dispatch(CHANGE_JUSTIFICATION_MODAL_VIEW, false);
    justificationComment.value = "";
    selectedDate.value = "";
  };

  const checkInputLength = () => {
    return justificationComment.value.length < 1 || selectedDate.value.length < 1;
  };

  watch([showJustificationModal], () => {
    selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === propsItemId.value) || null;
  });

return (_ctx: any,_cache: any) => {
  return (showJustificationModal.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "c-justificationmodal__title" }, "Review Purchase Request", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_6, [
                    _cache[2] || (_cache[2] = _createElementVNode("span", null, "Person that approves: ", -1)),
                    _createElementVNode("p", null, _toDisplayString(user.value), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", null, "PR Amount: ", -1)),
                    _createElementVNode("p", null, _toDisplayString(_unref(formatNumber)((__props.pramount * currencyRate.value).toFixed(2))) + " " + _toDisplayString(currency.value), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "c-justificationmodal__label" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory c-justificationmodal__label" }, "* "),
                        _createTextVNode("Date: ")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "date",
                        id: "date",
                        required: "",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedDate).value = $event))
                      }, null, 512), [
                        [_vModelText, selectedDate.value]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "c-justificationmodal__label" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory c-justificationmodal__label" }, "* "),
                        _createTextVNode("Comment: ")
                      ], -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        class: "cst-input",
                        id: "justification-comment",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((justificationComment).value = $event)),
                        maxlength: "200",
                        required: ""
                      }, null, 512), [
                        [_vModelText, justificationComment.value]
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                type: "button",
                disabled: checkInputLength(),
                class: "cst-button cst-button-primary c-justificationmodal__confirm-btn",
                onClick: confirmAction
              }, "Approve", 8, _hoisted_12),
              _createElementVNode("button", {
                type: "button",
                class: "cst-button cst-button-primary c-justificationmodal__decline-btn",
                onClick: declineAction,
                disabled: justificationComment.value.length < 1
              }, "Decline", 8, _hoisted_13),
              _createElementVNode("button", {
                class: "cst-button c-justificationmodal__cancel-btn",
                onClick: closeModal
              }, "Cancel")
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})