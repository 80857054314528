import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "c-cudetails__modal-overlay"
}
const _hoisted_2 = {
  class: "c-cudetails__modal-container",
  "data-testid": "cudetails-modal"
}
const _hoisted_3 = { class: "c-cudetails__modal-header" }
const _hoisted_4 = {
  class: "c-cudetails__modal-title",
  "data-testid": "cudetails-title"
}
const _hoisted_5 = { class: "c-cudetails__modal-header-closebtn" }
const _hoisted_6 = {
  key: 0,
  class: "c-cudetails__table-container",
  "data-testid": "cudetails-table"
}
const _hoisted_7 = { class: "c-cudetails__table-wrapper" }
const _hoisted_8 = {
  class: "c-cudetails__table c-cudetails__list-table",
  "aria-describedby": "Cost Unit Details"
}
const _hoisted_9 = { "data-testid": "cudetails-id" }
const _hoisted_10 = { "data-testid": "cudetails-prownername" }
const _hoisted_11 = { "data-testid": "cudetails-status" }
const _hoisted_12 = { "data-testid": "cudetails-suppliername" }
const _hoisted_13 = { "data-testid": "cudetails-orderamount" }
const _hoisted_14 = { "data-testid": "cudetails-currency" }
const _hoisted_15 = { "data-testid": "cudetails-deliverydate" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 0,
  class: "c-cudetails__listitems"
}
const _hoisted_18 = { class: "c-cudetails__listitems-header" }
const _hoisted_19 = { "data-testid": "cudetails-listitems-title" }
const _hoisted_20 = {
  class: "c-cudetails__listitems-container",
  "data-testid": "cudetails-listitems-container"
}
const _hoisted_21 = {
  key: 1,
  class: "c-cudetails__empty-msg",
  "data-testid": "cudetails-listitems-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListItemCard = _resolveComponent("ListItemCard")!

  return (_ctx.showPODetails)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.selectedProductName + " (" + _ctx.selectedCostUnit + ")"), 1),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Remove list item button",
                  "data-testid": "cudetails-closemodal-button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                })
              ])
            ])
          ]),
          (_ctx.selectedDetails === `orders` ? _ctx.relatedOrders.length > 0 : _ctx.relatedRequests.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("table", _hoisted_8, [
                    _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", { class: "c-cudetails__table-head-row" }, [
                        _createElementVNode("th", null, "ID"),
                        _createElementVNode("th", null, "Owner"),
                        _createElementVNode("th", null, "Status"),
                        _createElementVNode("th", null, "Supplier"),
                        _createElementVNode("th", null, "Amount"),
                        _createElementVNode("th", null, "Currency"),
                        _createElementVNode("th", null, "Delivery Date (End Date)"),
                        _createElementVNode("th", null, "Details")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedDetails === `orders` ? _ctx.relatedOrders : _ctx.relatedRequests, (item, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: index,
                          class: "c-cudetails__table-row"
                        }, [
                          _createElementVNode("td", _hoisted_9, _toDisplayString(item.id), 1),
                          _createElementVNode("td", _hoisted_10, _toDisplayString(item.prownername), 1),
                          _createElementVNode("td", _hoisted_11, _toDisplayString(item.status === "" ? "-" : item.status), 1),
                          _createElementVNode("td", _hoisted_12, _toDisplayString(item.suppliername), 1),
                          _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.formatNumber(item.orderamount)), 1),
                          _createElementVNode("td", _hoisted_14, _toDisplayString(item.currency), 1),
                          _createElementVNode("td", _hoisted_15, _toDisplayString(item.deliverydate ? _ctx.formatDate(item.deliverydate) : ""), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("button", {
                              onClick: ($event: any) => (_ctx.showLIDetails(item.id)),
                              "data-testid": "cudetails-button-show"
                            }, "Show", 8, _hoisted_16)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]),
                (_ctx.selectedDetailsID !== undefined)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("h4", _hoisted_19, "List items for order ID: " + _toDisplayString(_ctx.selectedDetailsID), 1),
                        _createElementVNode("button", {
                          class: "cst-button-close c-cudetails__listitems-closebtn",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeListItems && _ctx.closeListItems(...args))),
                          "data-testid": "cudetails-button-closelistitems"
                        }, "X")
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItems, (item) => {
                          return (_openBlock(), _createBlock(_component_ListItemCard, {
                            isDetails: true,
                            data: item,
                            key: item.id
                          }, null, 8, ["data"]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("h2", _hoisted_21, "List is empty!"))
        ])
      ]))
    : _createCommentVNode("", true)
}