<template>
  <div v-if="showConfirmModal" class="c-requestconfirm__wrapper" data-testid="requestconfirm-modal-wrapper">
    <div class="c-requestconfirm__container">
      <div class="c-requestconfirm__top-container">
        <div class="c-requestconfirm__title-div">
          <h4 class="c-requestconfirm__title">{{ action }}</h4>
          <div class="c-requestconfirm__items-group">
            <div>
              <div v-if="selectedItem?.id" class="c-requestconfirm__item">
                <span>Request ID: </span>
                <p data-testid="requestid">{{ selectedItem.id }}</p>
              </div>
              <div v-if="props.action !== 'Do you want to decline the request?'">
                <div class="c-requestconfirm__item">
                  <span class="c-requestconfirm__label">Customer contact: </span>

                  <input class="cst-input" type="text" id="customer-contact" v-model="customerContactInput" maxlength="32" data-testid="customercontact" />
                </div>
                <div class="c-requestconfirm__item">
                  <span class="c-requestconfirm__label">Order number: </span>

                  <input class="cst-input" type="text" id="order-number" v-model="orderNumberInput" maxlength="32" data-testid="ordernumber" required />
                </div>
                <div class="c-requestconfirm__note" v-if="orderNumberInput.length < 1">*Order number has to be entered</div>
              </div>

              <div v-if="undefinedFlag">
                <p class="c-requestconfirm__undefined-message" data-testid="undefined-message">
                  This request has some list items with cost type "<strong>Undefined</strong>".<br />
                  You must change the <strong>Undefined</strong> cost type to a specific cost type:
                </p>
                <div class="c-requestconfirm__listitem-container">
                  <div v-for="item in listItems" :key="item.id">
                    <div v-if="item.costtype === `Undefined`" class="c-requestconfirm__listitem">
                      <p>
                        <b>{{ item.name }}</b>
                      </p>
                      <p>{{ item.amount + " " + item.currency }}</p>
                      <p><b>Current cost type:</b></p>
                      <p>{{ item.costtype }}</p>
                      <p><b>New cost type:</b></p>
                      <div class="c-requestconfirm__listitem-select">
                        <select class="cst-select-field" @change="handleTypeChange($event, item.id)" id="select-costtype">
                          <option disabled selected value="">Select a Cost Type</option>
                          <option>Capital Infrastructure</option>
                          <option>Capital Maintenance</option>
                          <option>Capital Enhancement</option>
                          <option>Expense Infrastructure</option>
                          <option>Expense Maintenance</option>
                          <option>Expense Enhancement</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-requestconfirm__cta">
        <div class="c-requestconfirm__cta--wrapper">
          <input v-if="props.action === 'Do you want to decline the request?'" type="text" placeholder="Message..." id="messageinput" class="c-requestconfirm__cta--wrapper__message" v-model="messageInput" data-testid="messageinput" />
        </div>
        <div class="c-requestconfirm__cta-buttons">
          <button type="button" :disabled="undefinedFlag || checkInputLength()" class="cst-button cst-button-primary c-requestconfirm__confirm-btn" @click="confirmAction" data-testid="confirmbtn">Confirm</button>
          <button class="cst-button c-requestconfirm__cancel-btn" @click="closeModal" data-testid="cancelbtn">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { VUEX_ACTIONS } from "@/utils/constants";

  interface RequestData {
    id: number;
  }

  export default defineComponent({
    name: "RequestConfirmModal",
    props: {
      itemId: Number,
      action: String,
    },

    setup(props, { emit }) {
      const { ASSIGN_COST_TYPE, CHANGE_CONFIRM_MODAL_VIEW } = VUEX_ACTIONS;
      const selectedItem = ref<RequestData | null>(null);
      const messageInput = ref<"" | null>(null);
      const propsItemId = computed(() => props.itemId);
      const store = useStore();
      const requests = computed(() => store.getters.getPurchaseRequests);
      const showConfirmModal = computed(() => store.getters.getShowConfirmModal);
      const listItems = computed(() => store.getters.getListItems);
      const orderNumberInput = ref("");
      const customerContactInput = ref("");

      onMounted(() => {
        if (showConfirmModal.value) {
          selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === props.itemId) || null;
        }
      });

      const handleTypeChange = (event: Event, itemID: string) => {
        const newType = (event.target as HTMLSelectElement).value;
        store.dispatch(ASSIGN_COST_TYPE, { itemID, newType });
      };

      const undefinedFlag = computed(() => {
        return listItems.value ? listItems.value.some((item: IListItem) => item.costtype === "Undefined") : false;
      });

      const confirmAction = () => {
        const message = messageInput.value;
        const orderNumber = orderNumberInput.value;
        const customerContact = customerContactInput.value;
        if (props.action === "Do you want to decline the request?") {
          emit("decline", { itemId: props.itemId, message });
        }
        if (props.action === "Approve") {
          emit("approve", {
            itemId: props.itemId,
            orderNumber,
            customerContact,
          });
        }
        customerContactInput.value = "";
        orderNumberInput.value = "";
        store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, false);
        messageInput.value = null;
      };

      const closeModal = () => {
        store.dispatch(CHANGE_CONFIRM_MODAL_VIEW, false);
        messageInput.value = null;
        customerContactInput.value = "";
        orderNumberInput.value = "";
      };

      const checkInputLength = () => {
        if (props.action === "Approve") {
          return orderNumberInput.value.length < 1;
        }

        if (props.action === "Do you want to decline the request?") {
          return (messageInput.value?.length ?? 0) < 1;
        }
      };

      watch([showConfirmModal], () => {
        selectedItem.value = requests.value.find((item: RequestData) => Number(item.id) === propsItemId.value) || null;
      });

      return {
        selectedItem,
        closeModal,
        confirmAction,
        handleTypeChange,
        messageInput,
        showConfirmModal,
        listItems,
        undefinedFlag,
        props,
        orderNumberInput,
        checkInputLength,
        customerContactInput,
      };
    },
  });
</script>
