import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  src: './info.svg',
  width: "16",
  height: "16",
  alt: "info"
}
const _hoisted_2 = { class: "c-budgetsummary__budget--hover" }
const _hoisted_3 = { class: "c-budgetsummary__financials-budget" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "c-budgetsummary__currency" }
const _hoisted_6 = {
  key: 0,
  src: './info.svg',
  width: "16",
  height: "16",
  alt: "info"
}
const _hoisted_7 = { class: "c-budgetsummary__forecast--hover" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "c-budgetsummary__currency" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "c-budgetsummary__currency" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-budgetsummary__wrapper", [_ctx.isOverview]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["c-budgetsummary__financials", [_ctx.sizeClass]])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.size === 'small' ? 'c-budgetsummary__financials-card' : '')
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["c-budgetsummary__budget--wrapper", _ctx.budgetlimit || _ctx.target ? 'c-budgetsummary__budget' : ''])
        }, [
          _createTextVNode(_toDisplayString(_ctx.financialGoalLabel) + " ", 1),
          (_ctx.size === 'small' && (_ctx.budgetlimit > 0 || _ctx.target > 0))
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.financialHoverLabel) + ": ", 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.budgetlimit ? _ctx.formatNumber(((_ctx.budgetlimit - _ctx.budget) * _ctx.currencyRate).toFixed(2)) : _ctx.target ? _ctx.formatNumber((_ctx.target * _ctx.currencyRate).toFixed(2)) : "-") + " " + _toDisplayString(_ctx.currency), 1)
            ])
          ])
        ], 2),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.budget ? _ctx.formatNumber((_ctx.budget * _ctx.currencyRate).toFixed(2)) : "-") + " ", 1),
          (_ctx.size === 'small')
            ? (_openBlock(), _createElementBlock("br", _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currency), 1)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["c-budgetsummary__spacer", [_ctx.spacerSizeClass]])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.size === 'small' ? 'c-budgetsummary__financials-card' : '')
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["c-budgetsummary__forecast--wrapper", _ctx.size === 'small' && _ctx.forecast ? 'c-budgetsummary__forecast' : ''])
        }, [
          _cache[4] || (_cache[4] = _createTextVNode(" Forecast ")),
          (_ctx.size === 'small' && _ctx.forecast > 0)
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (_ctx.hasplatformcost)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Regular forecast: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.forecast ? _ctx.formatNumber(((_ctx.forecast - Number(_ctx.platformcost)) * _ctx.currencyRate).toFixed(2)) : "-") + " " + _toDisplayString(_ctx.currency), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.hasplatformcost && (_ctx.productforecast || _ctx.budgetlimit))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Product forecast: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.forecast ? _ctx.formatNumber((Number(_ctx.productforecast) * _ctx.currencyRate).toFixed(2)) : "-") + " " + _toDisplayString(_ctx.currency), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.hasplatformcost)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Proj/App forecast: ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.forecast ? _ctx.formatNumber(((_ctx.forecast - Number(_ctx.platformforecast) - Number(_ctx.productforecast)) * _ctx.currencyRate).toFixed(2)) : "-") + " " + _toDisplayString(_ctx.currency), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Platform forecast: ", -1)),
              _createTextVNode(_toDisplayString(_ctx.platformcost ? _ctx.formatNumber((Number(_ctx.platformcost) * _ctx.currencyRate).toFixed(2)) : _ctx.platformforecast > 0 ? _ctx.formatNumber((Number(_ctx.platformforecast) * _ctx.currencyRate).toFixed(2)) : "-") + " " + _toDisplayString(_ctx.currency), 1)
            ])
          ])
        ], 2),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.getColorClass(_ctx.forecast, _ctx.budget))
        }, [
          _createTextVNode(_toDisplayString(_ctx.forecast ? _ctx.formatNumber((_ctx.forecast * _ctx.currencyRate).toFixed(2)) : "-") + " ", 1),
          (_ctx.size === `small`)
            ? (_openBlock(), _createElementBlock("br", _hoisted_11))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.currency), 1)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["c-budgetsummary__spacer", [_ctx.spacerSizeClass]])
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.size === 'small' ? 'c-budgetsummary__financials-card' : '')
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("p", null, "Actuals", -1)),
        _createElementVNode("p", {
          class: _normalizeClass(_ctx.getColorClass(_ctx.actuals, _ctx.forecast))
        }, [
          _createTextVNode(_toDisplayString(_ctx.actuals ? _ctx.formatNumber((_ctx.actuals * _ctx.currencyRate).toFixed(2)) : "-") + " ", 1),
          (_ctx.size === `small`)
            ? (_openBlock(), _createElementBlock("br", _hoisted_13))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.currency), 1)
        ], 2)
      ], 2)
    ], 2)
  ], 2))
}