import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";

interface Regions {
  id: string;
  name: string;
}

interface RegionsState {
  regions: Regions[];
}

interface RegionsContext {
  commit: Commit;
  state: RegionsState;
}

const state: RegionsState = {
  regions: [],
};

const mutations = {
  setRegions(state: RegionsState, payload: Regions[]) {
    state.regions = payload;
  },
};

const getters = {
  getRegions: (state: RegionsState) => state.regions,
};

const actions = {
  fetchRegions(context: RegionsContext) {
    AXIOS_REQUEST("GET", "/regions")
      .then((response) => {
        const regionsDO: Regions[] = [];
        for (const id in response.data) {
          regionsDO.push({
            id: id,
            ...response.data[id],
          });
        }
        context.commit("setRegions", regionsDO);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const regionsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default regionsModule;
