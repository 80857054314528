import { AppConfiguration } from "@/utils/interfaces/AppConfiguration";
import { AXIOS_REQUEST } from "../../config";
import { Commit } from "vuex";

interface AppConfigurationState {
  appconfiguration: AppConfiguration[];
  appVersion: string;
}

interface AppConfigurationContext {
  commit: Commit;
}

const state: AppConfigurationState = {
  appconfiguration: [],
  appVersion: "1.0.0",
};

const mutations = {
  setAppConfiguration(state: AppConfigurationState, payload: AppConfiguration[]) {
    state.appconfiguration = payload;
  },
  setAppVersion(state: AppConfigurationState, payload: string) {
    state.appVersion = payload;
  },
};

const getters = {
  getAppConfiguration: (state: AppConfigurationState) => state.appconfiguration,
  getAppVersion: (state: AppConfigurationState) => state.appVersion,
};

const actions = {
  fetchConfiguration(context: AppConfigurationContext) {
    AXIOS_REQUEST("GET", "/configuration")
      .then((response) => {
        context.commit("setAppConfiguration", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  fetchAppVersion(context: AppConfigurationContext) {
    AXIOS_REQUEST("GET", "/actuator/version")
      .then((response) => {
        context.commit("setAppVersion", response.data.version);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const appConfigurationModule = {
  state,
  getters,
  mutations,
  actions,
};

export default appConfigurationModule;
