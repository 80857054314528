<template>
  <RequestMessage></RequestMessage>
  <div class="v-platformoverview__wrapper">
    <div class="v-platformoverview__products-wrapper">
      <div class="v-platformoverview__summary-overview">
        <div class="v-platformoverview__summary-overview--title">
          <p v-if="focusedPlatform !== 'Platform Overview'"><strong>Platform:</strong> {{ focusedPlatform }}</p>
          <p v-else>
            {{ focusedPlatform }}
          </p>

          <button class="v-platformoverview__summary-overview--btn cst-button cst-button-primary" type="button" @click="console.log('Manage platform')" v-if="focusedPlatform != 'Platform Overview'">
            <b>Manage</b>
          </button>
        </div>

        <div class="c-budgetsummary__wrapper--empty" v-if="selectedPlatform">
          <div>
            <strong>Total budget:</strong> {{ selectedPlatform.budget }}
            {{ selectedCurrency.value }}
          </div>
          <div>
            <strong>Platform owner:</strong>
            {{ convertUsernameFromId(selectedPlatform.owner) }}
          </div>
        </div>
        <div class="c-budgetsummary__wrapper--empty" v-else>
          {{ platformDescription }}
        </div>
      </div>
      <div class="v-platformoverview__search" v-if="focusedPlatform == 'Platform Overview'">
        <input class="v-platformoverview__search-input" type="text" id="platformname" required maxlength="40" data-testid="platform-search" placeholder="Search platform..." @input="filterPlatforms($event)" />
      </div>
      <div class="v-platformoverview__no-data" v-if="filteredPlatforms.length === 0 && !isPlatformFocused">
        <h2>No platforms found.</h2>
      </div>
      <div class="v-platformoverview__no-data" v-if="productsInPlatform.length === 0 && isPlatformFocused && arePlatformDetailsFetched">
        {{ filteredPlatforms.length }}
        <h2>Platform has no associated products.</h2>
      </div>
      <div class="v-platformoverview__product-cards" v-if="!isPlatformFocused">
        <div v-for="platform in filteredPlatforms" :key="platform.id">
          <C_PlatformCard :unit="platform" @show-platform-details="handlePlatformDetails" />
        </div>
      </div>

      <div v-if="isPlatformFocused && arePlatformDetailsFetched">
        <h2><strong>Products in platform:</strong></h2>
        <div class="v-platformoverview__table--wrapper">
          <table class="v-platformoverview__table--overview" aria-describedby="Table of platforms">
            <thead>
              <tr class="v-platformoverview__table-head-row">
                <th>Name</th>
                <th>Owner</th>
                <th>Budget</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody v-for="(product, index) in productsInPlatform" :key="index">
              <tr colsp class="v-platformoverview__table-row">
                <td>{{ product.name }}</td>
                <td>{{ convertUsernameFromId(product.owner) }}</td>
                <td>
                  {{ formatNumber((product.budget * currencyRate).toFixed(2)) + " " + selectedCurrency }}
                </td>
                <td>
                  <button @click="handleProductDetailsClick(product, index)" class="c-requestbtns c-requestbtns-secondary-show">
                    <p>{{ expandedIndex === index ? "Hide" : "Show" }}</p>
                    <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                      <path
                        d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
              <tr v-if="expandedIndex !== null && expandedIndex === index">
                <td colspan="4">
                  <strong><p class="v-platformoverview__label">Projects:</p></strong>
                  <div class="v-platformoverview__li-table-header">
                    <p class="v-platformoverview__li-table-header--first">No.</p>
                    <p>Name</p>
                    <p>Owner</p>
                    <p>Budget</p>
                    <p>Details</p>
                  </div>
                  <div v-if="areProjectsFromProductFetched && projectsInProduct.length > 0" class="v-showinvoices__li-table--wrapper">
                    <div v-for="(item, index) in projectsInProduct" :key="index">
                      <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                        <p class="v-showinvoices__li-table-row--first">
                          {{ index + 1 }}
                        </p>
                        <p>{{ item.name }}</p>
                        <p>{{ convertUsernameFromId(Number(item.owner)) }}</p>
                        <p>
                          {{ formatNumber((item.totalbudget * currencyRate).toFixed(2)) + " " + item.currency }}
                        </p>
                        <div>
                          <button @click="handleProjectAppDetailsClick(item, 'project')" class="c-requestbtns c-requestbtns-secondary-show">
                            <p>Show</p>
                            <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                              <path
                                d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!areApplicationsFromProductFetched" class="cst-spinner"></div>
                  <div v-else>
                    <p class="v-platformoverview__label">No projects found.</p>
                  </div>
                  <strong><p class="v-platformoverview__label">Applications:</p></strong>
                  <div class="v-platformoverview__li-table-header">
                    <p class="v-platformoverview__li-table-header--first">No.</p>
                    <p>Name</p>
                    <p>Owner</p>
                    <p>Budget</p>
                    <p>Details</p>
                  </div>
                  <div v-if="areApplicationsFromProductFetched && applicationsInProduct.length > 0" class="v-platformoverview__li-table--wrapper">
                    <div v-for="(item, index) in applicationsInProduct" :key="index">
                      <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                        <p class="v-showinvoices__li-table-row--first">
                          {{ index + 1 }}
                        </p>
                        <p>{{ item.name }}</p>
                        <p>{{ convertUsernameFromId(Number(item.owner)) }}</p>
                        <p>
                          {{ formatNumber((item.totalbudget * currencyRate).toFixed(2)) + " " + item.currency }}
                        </p>
                        <div>
                          <button @click="handleProjectAppDetailsClick(item, 'application')" class="c-requestbtns c-requestbtns-secondary-show">
                            <p>Show</p>
                            <svg fill="#000000" width="15px" height="15px" viewBox="-1 0 19 19" class="cf-icon-svg">
                              <path
                                d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM5.85 3.309a6.833 6.833 0 1 0 2.65-.534 6.787 6.787 0 0 0-2.65.534zm2.654 1.336A1.136 1.136 0 1 1 7.37 5.78a1.136 1.136 0 0 1 1.135-1.136zm.792 9.223V8.665a.792.792 0 1 0-1.583 0v5.203a.792.792 0 0 0 1.583 0z"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!areApplicationsFromProductFetched" class="cst-spinner"></div>
                  <div v-else>
                    <p class="v-platformoverview__label">No applications found.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="!arePlatformDetailsFetched && isPlatformFocused && productsInPlatform.length == 0" class="cst-spinner"></div>
    </div>
  </div>
  <ProjectAppModal v-if="isProjAppModalOpen" :projappName="focusedProjApp.name" :type="focusedProjApp.type" :is-modal-enabled="isProjAppModalOpen" @isProjectAppModalVisible="isProjAppModalOpen = false"></ProjectAppModal>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import C_PlatformCard from "@/components/cards/C_PlatformCard.vue";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import { IProduct, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import ProjectAppModal from "@/components/modals/C_ProjectAppModal.vue";

  const { FETCH_NOTIFICATIONS, FETCH_USERS, FETCH_ALL_PLATFORMS, FETCH_PRODUCTS_IN_PLATFORM, FETCH_PROJECTS_IN_PRODUCT, FETCH_APP_IN_PRODUCT, FETCH_PROJECT_PURCHASE_ORDERS, FETCH_APPLICATION_PURCHASE_ORDERS } = VUEX_ACTIONS;
  const store = useStore();
  const platforms = computed(() => store.getters.getPlatforms);
  const selectedPlatform = ref<IPlatform>();
  const focusedPlatform = ref("Platform Overview");
  const platformDescription = ref("Select a platform from the list to see the details.");

  const filteredPlatforms = ref<IPlatform[]>(platforms.value);
  const isProjAppModalOpen = ref(false);
  const loggedUser = computed(() => store.getters.getCurrentUser);
  const users = computed(() => store.getters.getUsers);
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);
  const productsInPlatform = computed(() => store.getters.getProductsInPlatform);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const focusedProjApp = ref({
    name: "",
    type: "",
  });
  const arePlatformDetailsFetched = computed(() => store.getters.getArePlatformDetailsFetched);
  const projectsInProduct = computed(() => store.getters.getProductProjects);
  const applicationsInProduct = computed(() => store.getters.getProductApplications);
  const areApplicationsFromProductFetched = computed(() => store.getters.getApplicationsInProductFetched);
  const areProjectsFromProductFetched = computed(() => store.getters.getProjectsInProductFetched);

  const isPlatformFocused = ref(false);
  const expandedIndex = ref<number | null>(null);
  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_ALL_PLATFORMS);

  const filterPlatforms = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;
    filteredPlatforms.value = platforms.value.filter((platform: IPlatform) => {
      return platform.name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };

  const handlePlatformDetails = (platform: IPlatform) => {
    selectedPlatform.value = platform;
    focusedPlatform.value = `${platform.name}`;
    isPlatformFocused.value = true;
    filteredPlatforms.value = [];
    store.dispatch(FETCH_PRODUCTS_IN_PLATFORM, platform.id);
  };

  const handleProductDetailsClick = (product: IProduct, index: number) => {
    store.commit("setProjectsInProductFetched", false);
    store.commit("setApplicationsInProductFetched", false);

    if (expandedIndex.value === index) {
      expandedIndex.value = null;
    } else {
      expandedIndex.value = index;
      store.dispatch(FETCH_PROJECTS_IN_PRODUCT, product.id);
      store.dispatch(FETCH_APP_IN_PRODUCT, product.id);
    }
  };

  const handleProjectAppDetailsClick = (projapp: IProjectApplication, type: string) => {
    focusedProjApp.value.name = projapp.name;
    focusedProjApp.value.type = type;
    isProjAppModalOpen.value = true;

    if (type === "project") {
      store.dispatch(FETCH_PROJECT_PURCHASE_ORDERS, projapp.id);
    } else {
      store.dispatch(FETCH_APPLICATION_PURCHASE_ORDERS, projapp.id);
    }
  };

  const convertUsernameFromId = (id: number) => {
    let username = "Unknown";
    users.value.filter((user: IUser) => {
      if (Number(user.id) === id) {
        username = user.username;
      }
    });
    return username;
  };

  watch([platforms], () => {
    filteredPlatforms.value = platforms.value;
  });
</script>
