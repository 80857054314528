import { isAxiosError } from "axios";
import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";

interface ImageState {
  image: string | null;
  allProductImages: { id: string; image_data: string }[];
  uploadedImage: string | null;
  uploadedImageId: string | null;
  singleImage: {
    id: number;
    data: string;
  }[];
}

interface ImageContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: ImageState = {
  image: null,
  allProductImages: [],
  uploadedImage: null,
  singleImage: [],
  uploadedImageId: null,
};

const mutations = {
  setImage(state: ImageState, payload: string) {
    state.image = payload;
  },
  setAllProductImages(state: ImageState, payload: { id: string; image_data: string }[]) {
    state.allProductImages = payload;
  },
  setUploadedImage(state: ImageState, payload: string) {
    state.uploadedImage = payload;
  },
  setUploadedImageId(state: ImageState, payload: string) {
    state.uploadedImageId = payload;
  },
  setSingleImage(
    state: ImageState,
    payload: {
      id: number;
      data: string;
    }[]
  ) {
    state.singleImage = payload;
  },
};

const getters = {
  getImage: (state: ImageState) => state.image,
  getAllProductImages: (state: ImageState) => state.allProductImages,
  getUploadedImage: (state: ImageState) => state.uploadedImage,
  getUploadedImageId: (state: ImageState) => state.uploadedImageId,
  getSingleImage: (state: ImageState) => state.singleImage,
};

const actions = {
  async fetchUploadedImage(context: ImageContext, uploadedImageId: number) {
    await AXIOS_REQUEST("GET", `/images/${uploadedImageId}`)
      .then((response) => {
        context.commit("setImage", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async fetchAllProductImages(context: ImageContext) {
    await AXIOS_REQUEST("GET", `/images/`)
      .then(async (response) => {
        context.commit("setAllProductImages", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  clearUploadedImage(context: ImageContext) {
    context.commit("setImage", "");
  },

  async uploadNewProductImage(context: ImageContext, base64ImageData: string) {
    try {
      const response = await AXIOS_REQUEST("POST", "/images/", {
        newImageData: base64ImageData,
      });
      context.commit("setUploadedImageId", response.data.id);
      context.commit("setUploadedImage", response.data.image_data);
    } catch (error) {
      if (isAxiosError(error)) {
        if (error?.response?.data?.message === "Same image already exists!") {
          context.dispatch("updateRequestMessage", `Error: Same image already exists!`);
        } else {
          context.dispatch("updateRequestMessage", `Error while adding the image`);
        }
      } else {
        context.dispatch("updateRequestMessage", `Error while adding the image`);
      }
    }
  },
};

const imageModule = {
  state,
  getters,
  mutations,
  actions,
};

export default imageModule;
