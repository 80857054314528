import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-productcard__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "c-productcard__info" }
const _hoisted_4 = {
  class: "c-productcard__product-name",
  "data-testid": "product-name"
}
const _hoisted_5 = {
  key: 0,
  class: "c-productcard__family-name-tag"
}
const _hoisted_6 = {
  key: 1,
  class: "c-productcard__type"
}
const _hoisted_7 = { class: "c-projapp__owner-wrapper" }
const _hoisted_8 = { class: "c-productcard__summary" }
const _hoisted_9 = { class: "c-productcard__button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BudgetSummary = _resolveComponent("BudgetSummary")!
  const _component_ProductModal = _resolveComponent("ProductModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "c-productcard__product-image",
      src: _ctx.productImage,
      alt: "placeholder",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOverviewClick(_ctx.product.id, _ctx.product.name, _ctx.product_po_forecast, _ctx.product_invoice_actuals)))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.product.name), 1),
        (_ctx.product.platform_cost != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Platform "))
          : (_openBlock(), _createElementBlock("p", _hoisted_6, "Product"))
      ]),
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleGraphClick && _ctx.handleGraphClick(...args))),
        src: './graph.svg',
        width: "26",
        height: "26",
        alt: "product details icon"
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[5] || (_cache[5] = _createElementVNode("p", { class: "c-projapp__owner-label" }, "Owner:", -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.ownerUsername), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_BudgetSummary, {
        size: "small",
        budget: Number(_ctx.product.budget),
        target: Number(_ctx.product.target),
        forecast: Number(_ctx.product.capitalforecast) + Number(_ctx.product.expensesforecast) + Number(_ctx.product_po_forecast),
        actuals: Number(_ctx.product.capitalactuals) + Number(_ctx.product.expensesactuals) + Number(_ctx.product_invoice_actuals),
        budgetlimit: Number(_ctx.product.budget_limit),
        productforecast: Number(_ctx.product_po_forecast),
        platformforecast: Number(_ctx.product.total_consumers_forecast)
      }, null, 8, ["budget", "target", "forecast", "actuals", "budgetlimit", "productforecast", "platformforecast"]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleOverviewClick(_ctx.product.id, _ctx.product.name, _ctx.product_po_forecast, _ctx.product_invoice_actuals))),
          "data-testid": "productcard-overviewbtn"
        }, "Overview"),
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDetailsClick && _ctx.handleDetailsClick(...args))),
          "data-testid": "productcard-detailsbtn"
        }, "Details")
      ])
    ]),
    (_ctx.isProdModalOpen)
      ? (_openBlock(), _createBlock(_component_ProductModal, {
          key: 0,
          prodName: _ctx.product.name,
          "is-modal-enabled": _ctx.isProdModalOpen,
          onIsProdModalVisible: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isProdModalOpen = false))
        }, null, 8, ["prodName", "is-modal-enabled"]))
      : _createCommentVNode("", true)
  ]))
}